import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import { Card, CardContent, CardActionArea, CardActions, Box, Stack } from '@mui/material'
import Typography from 'src/components/Typography/Typography'
import ChallengeCardItem, {
  ChallengeCardItemProps,
} from 'src/components/ChallengeCardItem/ChallengeCardItem'
import ChallengeCardButton from 'src/components/ChallengeCardButton/ChallengeCardButton'
import Image from 'src/components/Image/Image'
import { GameTemplateType } from 'src/helpers/types'
import ImageWithTitle from '../ImageWithTitle/ImageWithTitle'

type ChallengeCardMediaProps = Pick<ChallengeCardProps, 'bgSrc' | 'title' | 'iconSrc'>

const ChallengeCardMedia = ({ bgSrc, title, iconSrc }: ChallengeCardMediaProps) => (
  <Box>
    <Box
      sx={(theme) => ({
        height: '100px',
        position: 'relative',
        borderBottom: `1px solid ${theme.palette.gray[800]}`,
        [theme.breakpoints.down('md')]: {
          height: '85px',
        },
      })}
    >
      <Image
        src={bgSrc}
        alt="Frontland"
        fill
        // priority
      />
    </Box>
    
     {iconSrc ? 
        <ImageWithTitle
            typographyProps={{ variant:  'h7' }}
            imageProps={{
              src: iconSrc,
              sx: (theme) => ( {
                [theme.breakpoints.down('md')]: {
                  width: '22px',
                  height: '22px',
                },
              }),          
            }}
            title={title}
            imageBoxProps={{ size: 'xs' }}
            rootStackProps={  
                    
              { spacing:'7px',
                sx(theme) {
                return {
                    position: 'absolute',
                    top: 10,
                    left: 10,
                    [theme.breakpoints.down('md')]: {
                      top: '5px',
                      left: '5px',
                    },
                }
              },}
            }
          />
             :  
        <Typography
          variant="h7"
          sx={(theme) => ({
            position: 'absolute',
            top: 14,
            left: 14,
            [theme.breakpoints.down('md')]: {
              top: '7px',
              left: '7px',
            },
          })}
        >
          {title}
        </Typography>
    }
  </Box>
)

export type ChallengeCardProps = {
  loading?: boolean
  bgSrc: HTMLImageElement['src']
  title: string
  iconSrc?: HTMLImageElement['src']
  description?: string
  disabled?: boolean
  dataSource: ChallengeCardItemProps[]
  btnProps: {
    label: string
    subLabel: string
    iconSrc?: string
    onClick?: () => void
  }
  gameTemplate?: GameTemplateType
}

export default function ChallengeCard({
  loading,
  bgSrc,
  title,
  iconSrc,
  disabled,
  dataSource,
  btnProps,
}: ChallengeCardProps) {
  const theme = useTheme()

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        // width: '191px',
        width: '100%',
        minHeight: 352,
        bgcolor: theme.palette.gray[900],
        borderRadius: 0,
        boxShadow: 0,
        border: `1px solid ${theme.palette.gray[700]}`,
        '&:hover': {
          border: `1px solid ${theme.palette.gray[500]}`,
        },
        backgroundImage: 'none',
        [theme.breakpoints.down('md')]: {
          // width: '173px',
          minHeight: '315px',
        },
        // [theme.breakpoints.down('md834')]: {
        //   width: '164px',
        // },
      }}
    >
      {!loading ? (
        <>
          <CardActionArea
            sx={{
              '&:hover .MuiCardActionArea-focusHighlight': {
                opacity: 0,
              },
            }}
            disableRipple={true}
          >
            <ChallengeCardMedia bgSrc={bgSrc} title={title} iconSrc={iconSrc}/>
            <CardContent
              sx={{
                padding: '18px 13px 13px',
                minHeight: '188px',
                [theme.breakpoints.down('md')]: {
                  padding: '12px 7px 7px',
                  minHeight: '165px',
                },
              }}
            >
              <Stack direction="column" gap={{ xs: '8px', md: '14px' }}>
                {dataSource?.length &&
                  dataSource.map(({ icon, label, value, tokenIconSrc}, index) => (
                    <ChallengeCardItem key={index} icon={icon} label={label} value={value} tokenIconSrc={tokenIconSrc}/>
                  ))}
              </Stack>
            </CardContent>
          </CardActionArea>
          <CardActions
            sx={{
              padding: '4px',
              background: theme.palette.background.lines,
              boxShadow: `0px -1px 0px ${theme.palette.gray[800]}`,
              flexGrow: 1,
            }}
          >
            {disabled ? (
              <Box
                sx={{
                  minHeight: '54px',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  pt: '2px',
                }}
              >
                <Typography variant="buttonMedium" color="gray.500" flexGrow={1} align="center">
                  Disabled
                </Typography>
              </Box>
            ) : (
              <ChallengeCardButton
                label={btnProps.label}
                subLabel={btnProps.subLabel}
                iconSrc={btnProps.iconSrc}
                onClick={btnProps.onClick}
                fullWidth
              />
            )}
          </CardActions>
        </>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            m: '2px',
            background: theme.palette.background.lines,
          }}
        />
      )}
    </Card>
  )
}

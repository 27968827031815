import React, { useState } from 'react';
import { useInterval } from './useInterval';

type DrawingProps = {
	char: string
}

export const DrawWaiting = ({ char } : DrawingProps) => {
	const [dot, setDot] = useState("");
	const [delay, setDelay] = useState(800);

	useInterval(() => {

		if (dot.length === 1) {

		}
		else if (dot.length === 2) {

		}
		else if (dot.length === 3) {
			setDelay(delay => 1200);
		}
		else if (dot.length === 4) {
		}

		if (dot.length > 4) {
			setDot("");
			setDelay(delay => 300);
		}
		else {
			setDot(dot => dot + char);

		}
	}, delay);

	return (
		<span className="dotText">{dot}</span>
		)
}
import React from 'react'
import HorizontalWinnerBox from 'src/components/HorizontalWinnerBox/HorizontalWinnerBox'
import VerticalWinnerBox from 'src/components/VerticalWinnerBox/VerticalWinnerBox'
import { WinnerPlaceTextT } from '../WinnerPlaceNum/WinnerPlaceNum'

export type WinnerLobbyBoxProps = {
  isVertical: boolean
  avatarSrc?: string
  name: string
  place?: number
  placeText?: WinnerPlaceTextT
  amount: string,
  tokenImgSrc: string
  showAmount?: boolean
}

export default function WinnerLobbyBox({
  isVertical,
  avatarSrc,
  name,
  place = 1,
  placeText,
  amount,
  tokenImgSrc,
  showAmount = true,
}: WinnerLobbyBoxProps) {
  return isVertical ? (
    <VerticalWinnerBox
      avatarSrc={avatarSrc}
      name={name}
      place={place}
      placeText={placeText}
      amount={amount}
      tokenImgSrc={tokenImgSrc}
      showAmount={showAmount}
      
    />
  ) : (
    <HorizontalWinnerBox
      avatarSrc={avatarSrc}
      name={name}
      place={place}
      placeText={placeText}
      amount={amount}
      tokenImgSrc={tokenImgSrc}
      showAmount={showAmount}
    />
  )
}

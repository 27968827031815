import React, { useState } from 'react'
import { Box, Stack, useTheme } from '@mui/material'
import Button from '../Button/Button'
import SvgIcon from '../SvgIcon/SvgIcon'
import Typography from '../Typography/Typography'
import TextField from '../TextField/TextField'
import Comment from 'src/components/Comment/Comment'
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as schemas from 'src/helpers/schemas'
import { connectPlayFab } from "../../sagas/apiSagas";
import {useAppDispatch} from "src/hooks";
import { registrationResult } from "src/actions/userActions";
import { showErrorPopup } from "src/actions/dialogActions";
import { Avatar } from '../SelectAvatar/SelectAvatar'
import { clearHttpHttpsAndDomain } from 'src/helpers/commonData'
import Link from 'src/components/Link/Link'

const ValidationSchema = yup.object().shape({
  username: schemas.username(),
  password: schemas.requiredPassword(),
});

export default function LoginForm({ selectedAvatar, toggleForm  }: {selectedAvatar?: Avatar, toggleForm: () => void}) {
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validateOnChange: true,
        validateOnMount: true,
        validationSchema: ValidationSchema,
        onSubmit: (values) => {
        console.log(values);
          formik.setStatus('');  
          connectPlayFab(values.username,values.password,clearHttpHttpsAndDomain(selectedAvatar?.src))
          .then(result => {
            dispatch(registrationResult(result));
        })
        .catch((error) => {
            dispatch(showErrorPopup('Login error', error.message));
            //showErrorToast('Sign up error', error.message);
            //formik.setStatus(error.message);
        })
        .finally(() => formik.setSubmitting(false))
        }
    });

   

  return (
    <Box>
      <Comment>Login</Comment>
      <Stack
          direction="column"
          sx={{
            border: `1px solid ${theme.palette.gray[700]}`,
            padding: '1px',            
          }}
        >
         <form id="login" onSubmit={formik.handleSubmit}>

        <Stack
          direction="column"
          gap="5px"
          sx={{
            background: theme.palette.gray[800],
            padding: { xs: '18px 16px 28px', sm: '25px 23px 35px' },
            marginBottom: '2px',
          }}
        >
          <Typography
            variant="bodySmall"
            align="center"
            color={theme.palette.gray[25]}
            sx={{
              m: '0 auto 22px auto',
              width: '358px',
              [theme.breakpoints.down('sm')]: {
                m: '0 auto 18px auto',
                width: '100%',
              },
            }}
          >
           Connect your Frontland account to Challenge by logging in with your Frontland credentials.

          </Typography>
          <Typography
            variant="bodySmall"
            align="center"
            color={theme.palette.gray[25]}
            sx={{
              m: '0 auto 22px auto',
              width: '358px',
              [theme.breakpoints.down('sm')]: {
                m: '0 auto 18px auto',
                width: '100%',
              },
            }}
          >
            Do not have a Frontland account? <Link size="small" onClick={toggleForm} href={''}>Signup here!</Link>
          </Typography>
	        <Typography  variant="bodyMedium"
            align="center"
            color={theme.palette.error[25]}
            sx={{
              m: '0 auto 22px auto',
              width: '358px',
            }}
            style={{color:'red'}}>
              
              {formik.status}
          </Typography>
         
                  <TextField id="username" name="username" placeholder="Enter your login" label="Username" size="large"
                          value={formik.values.username}
                          onChange={formik.handleChange}
                          error={(formik.touched.username && Boolean(formik.errors.username))}
                          helperText={formik.touched.username && formik.errors.username !== undefined ? formik.errors.username : undefined }
                      />
                  
                  <TextField id="password" name="password" placeholder="Enter password" label="Password" size="large" type="password"
                              value={formik.values.password}
                              onChange={formik.handleChange}
                              error={formik.touched.password && Boolean(formik.errors.password)}
                              helperText={formik.touched.password && formik.errors.password !== undefined ? formik.errors.password : undefined}
                          />
                               

                </Stack>
                  <Button variant="spaceBetween" size="large" endIcon={<SvgIcon name="arrow-right-line" sx={{ mr: '4px' }} />} type="submit" disabled={!formik.dirty || formik.isSubmitting} >
                   <Typography variant="buttonMassive">Login</Typography>
                </Button>
                </form>
            </Stack>        
    </Box>
  )
}

import { takeEvery, put } from 'redux-saga/effects';
import { apiProfileSlice, Tags } from 'src/api/profileApi';
import { showErrorToast, showInfoToast, showSuccessToast } from "../components/Toast/Toasts";

export default function* profileSagas() {
    yield takeEvery("REWARDS_RECEIVED", rewardReceivedWorker);
}

function* rewardReceivedWorker(action) {

    const rewards = action.rewards;
    for (const reward of rewards) {
        // Show success toast for each reward
        showInfoToast('Reward received', `${reward.rewardedPoints} points for '${reward.rewardDescription}'`);
    }
    // Invalidate the latest rewards and reward summary tags to force a refresh
    yield put(apiProfileSlice.util.invalidateTags([Tags.LatestRewards, Tags.RewardSummary])) 
}
import React, { useEffect } from 'react'

export type VideoProps = React.DetailedHTMLProps<
  React.VideoHTMLAttributes<HTMLVideoElement>,
  HTMLVideoElement
> & {
  sourceProps: React.DetailedHTMLProps<
    React.SourceHTMLAttributes<HTMLSourceElement>,
    HTMLSourceElement
  >
  fill?: boolean
  objectPosition?: string
  objectFit?: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down'
}

const Video = (props: VideoProps) => {
  const videoRef = React.useRef<HTMLVideoElement>(null)

  const { sourceProps, objectFit, fill, ...videoProps } = props
  const style: VideoProps['style'] = {}
  if (objectFit) {
    style.objectFit = props.objectFit
  }
  if (props.width) {
    style.width = props.width + 'px'
  }
  if (props.height) {
    style.height = props.height + 'px'
  }

  if (props.fill) {
    style.position = 'absolute'
    style.top = 0
    style.left = 0
    style.width = '100%'
    style.height = '100%'
    style.objectFit = 'cover'
    style.objectPosition = 'top'
  }

  if (props.objectPosition) {
    style.objectPosition = props.objectPosition
  }

  useEffect(() => {
    if (props.autoPlay && videoRef?.current) {
      videoRef.current.muted = true
    }
  }, [videoRef?.current, sourceProps.src])

  return (
    <video key={sourceProps.src} ref={videoRef} {...videoProps} style={style}>
      <source type="video/mp4" {...sourceProps}></source>
    </video>
  )
}

export default Video

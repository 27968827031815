import { PrizeType, TokenType } from "./types";
import { PNG_ICONS } from "src/assets/icons/icons";
import { Avatar } from "src/components/SelectAvatar/SelectAvatar";
import { config } from "src/config/config";
import { formatTokenToString } from "src/utils/tokenUtil";



export const formatPrizePool = (prizes:PrizeType[], tokenType:TokenType)=>{
    //['1.8 CC', '1.0 CC', '0.5 CC']
    let result = [];
    for (let i = 0; i < prizes?.length; i++) {
        if(prizes[i].payout>0){
            result.push(formatTokenToString(prizes[i].payout, tokenType))
        }
    }
    return result;
 }

 export const formatPrizePoolWithMax = (prizes:PrizeType[], tokenType:TokenType, max:number)=>{
    //['1.8 CC', '1.0 CC', '0.5 CC']
    let result = [];
    const to = max < prizes.length ? max : prizes.length;
    for (let i = 0; i < to; i++) {
        if(prizes[i].payout>0){
            result.push(formatTokenToString(prizes[i].payout,tokenType))
        }
    }
    return result;
 }

 //function to create string from prizes:PrizeType[] and a min index
export const formatPrizePoolFrom = (prizes:PrizeType[], tokenType:TokenType,from:number)=>{
    //['1.8 CC', '1.0 CC', '0.5 CC']
    let result = "";
    for (let i = from; i < prizes?.length; i++) {
        if(prizes[i].payout>0){
            result= `${result} ${addOrdinalSuffix(i+1)} ${formatTokenToString(prizes[i].payout,tokenType)}${i+1<prizes.length?',':''}`
        }
    }
    return result;
    }


export function addOrdinalSuffix(i:number) {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}

function createDateAsUTC(date : Date) {
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()));
}


export function secondsLeft(date: Date, waitTimeInSec:number) : number {
    let dateObj = date instanceof Date ? date : new Date(date);
    var utcDate = new Date(dateObj.getTime() + waitTimeInSec * 1000);
    let now = new Date()
    var difference_s = (utcDate.getTime() - now.getTime())/1000;
    return difference_s;
}


export function getTokenImgSrc(token: TokenType) : string{
    switch (token) {
        case TokenType.CT:
            return PNG_ICONS.wct;
        case TokenType.WETH:
            return PNG_ICONS.weth;
        default:
            return "";
    }
}

export function getAvatars(urlPrefix:string) :Avatar[] {
    return [
        { id: '1', src: urlPrefix +'/avatars/cat1.png' },
        { id: '2', src: urlPrefix +'/avatars/cat2.png' },
        { id: '3', src: urlPrefix +'/avatars/cat3.png' },
        { id: '4', src: urlPrefix +'/avatars/cat5.png' },
        { id: '5', src: urlPrefix +'/avatars/cat6.png' },
        { id: '6', src: urlPrefix +'/avatars/cat7.png' },
        { id: '7', src: urlPrefix +'/avatars/avatar1.png' },
        { id: '8', src: urlPrefix +'/avatars/avatar2.png' },
        { id: '9', src: urlPrefix +'/avatars/avatar3.png' },
        { id: '10', src: urlPrefix +'/avatars/avatar4.png' },
        { id: '11', src: urlPrefix +'/avatars/avatar5.png' },
        { id: '12', src: urlPrefix +'/avatars/avatar6.png' },
        { id: '13', src: urlPrefix +'/avatars/avatar7.png' },
        { id: '14', src: urlPrefix +'/avatars/avatar8.png' },
        { id: '15', src: urlPrefix +'/avatars/avatar9.png' },
        { id: '16', src: urlPrefix +'/avatars/avatar10.png' },
        { id: '17', src: urlPrefix +'/avatars/avatar11.png' },
        { id: '18', src: urlPrefix +'/avatars/avatar12.png' },
        { id: '19', src: urlPrefix +'/avatars/avatar13.png' },
        { id: '20', src: urlPrefix +'/avatars/avatar14.png' },
        { id: '21', src: urlPrefix +'/avatars/avatar15.png' },
        { id: '22', src: urlPrefix +'/avatars/avatar16.png' },
        { id: '23', src: urlPrefix +'/avatars/avatar17.png' },
        { id: '24', src: urlPrefix +'/avatars/avatar18.png' },
        { id: '25', src: urlPrefix +'/avatars/avatar19.png' },
        { id: '26', src: urlPrefix +'/avatars/avatar20.png' },
        { id: '27', src: urlPrefix +'/avatars/avatar21.png' },
        { id: '28', src: urlPrefix +'/avatars/avatar22.png' },
        { id: '29', src: urlPrefix +'/avatars/avatar23.png' },
        { id: '30', src: urlPrefix +'/avatars/avatar24.png' },
        { id: '31', src: urlPrefix +'/avatars/avatar25.png' },
        { id: '32', src: urlPrefix +'/avatars/avatar26.png' },
        { id: '33', src: urlPrefix +'/avatars/avatar27.png' },
        { id: '34', src: urlPrefix +'/avatars/avatar28.png' },
        { id: '35', src: urlPrefix +'/avatars/avatar29.png' },
        { id: '36', src: urlPrefix +'/avatars/avatar30.png' },
        { id: '37', src: urlPrefix +'/avatars/avatar31.png' },
        { id: '38', src: urlPrefix +'/avatars/avatar32.png' },
        { id: '39', src: urlPrefix +'/avatars/avatar33.png' },
        { id: '40', src: urlPrefix +'/avatars/avatar34.png' },
        { id: '41', src: urlPrefix +'/avatars/avatar35.png' },
        { id: '42', src: urlPrefix +'/avatars/avatar36.png' },
        { id: '43', src: urlPrefix +'/avatars/avatar37.png' },
        { id: '44', src: urlPrefix +'/avatars/avatar38.png' },
        { id: '45', src: urlPrefix +'/avatars/avatar39.png' },
        { id: '46', src: urlPrefix +'/avatars/avatar40.png' },
        { id: '47', src: urlPrefix +'/avatars/avatar41.png' },
      ]
}

export function getProfilePicture(profilePicture:string|undefined, defaultValue:string|undefined=undefined) : string|undefined{
    if(profilePicture===undefined){
        return defaultValue;
    }
    return config.url.STORAGE_URL + profilePicture;
}

export const clearHttpHttpsAndDomain = (url:string|undefined) : string|undefined => {
    if (!url) {
      return undefined;
    }
    return url.replace(/^https?:\/\/[^/]+/, '');
}

export const appendUrlPrefix = (url:string|undefined) : string => {
    if (!url) {
      return "";
    }
    //if url starts with http or https then use it as is otherwise add https://
    if(url.startsWith("http://") || url.startsWith("https://")){
        return url;
    }
    return config.url.STORAGE_URL + url;
}

export const isTargetBlank = (url:string|undefined) : boolean => {
    if (!url) {
      return false;
    }
    //if url starts with http or https then use it as is otherwise add https://
    if(url.startsWith("http://") || url.startsWith("https://")){
        return true;
    }
    return false;
   
}

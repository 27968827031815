import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from 'src/store/configureStore';

// Input selector to get activeChallenges from state
const selectActiveChallenges = (state: RootState) => state.challengeReducer.activeChallenges;

// Factory function to create a memoized selector for a specific tournamentId
export const makeGetTournamentWithCurrentRound = (tournamentId: number) => {
    return createSelector(
        [selectActiveChallenges],
        (activeChallenges) => {
            return activeChallenges.find((x: any) => x.tournamentId === tournamentId && x.isTournamentBase);
        }
    );
};
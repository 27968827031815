import React, { useCallback } from 'react'
import { Stack, StackProps, Box, useTheme } from '@mui/material'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import Typography from 'src/components/Typography/Typography'
import ImageBox from 'src/components/ImageBox/ImageBox'
import ImageCardMedia from 'src/components/ImageCardMedia/ImageCardMedia'
import GradientBorder from 'src/components/GradientBorder/GradientBorder'
import { stat } from 'fs'

export enum TOURNAMENT_BRACKET_STATUSES {
  TBD = 'TBD',
  SEARCHING = 'SEARCHING',
  WAITING = 'WAITING',
  PLAYING = 'PLAYING',
  PLAYED = 'PLAYED',
  WIN = 'WIN',
  LOSE = 'LOSE',
  EMPTY='EMPTY',
  CANCELLED='CANCELLED',
}

type TournamentBracketProps = {
  participant: {
    id?: string
    name?: string
    status?: `${TOURNAMENT_BRACKET_STATUSES}`
    avatar?: string
    resultText?: string
    error? : boolean
  }
  onWatchGame?: (id?: string | number) => void
}

export default function TournamentBracket({ participant, onWatchGame }: TournamentBracketProps) {
  const status = participant?.status || TOURNAMENT_BRACKET_STATUSES.TBD

  const renderTournamentBracketBodyByStatus = useCallback(() => {
    if (status === TOURNAMENT_BRACKET_STATUSES.TBD) {
      return <Tbd />
    } else if (status === TOURNAMENT_BRACKET_STATUSES.SEARCHING) {
      return <Searching />
    } else if (status === TOURNAMENT_BRACKET_STATUSES.EMPTY) {
      return <Missing />
    } else if (
      status === TOURNAMENT_BRACKET_STATUSES.WAITING ||
      status === TOURNAMENT_BRACKET_STATUSES.PLAYING ||
      status === TOURNAMENT_BRACKET_STATUSES.LOSE ||
      status === TOURNAMENT_BRACKET_STATUSES.WIN  ||
      status === TOURNAMENT_BRACKET_STATUSES.CANCELLED  
    ) {
      return <CommonStatusWrapper participant={participant} onWatchGame={onWatchGame} />
    } else {
      return null
    }
  }, [status, participant, onWatchGame])

  return <Box>{renderTournamentBracketBodyByStatus()}</Box>
}

type StatusWrapperProps = {
  StackProps?: StackProps
  children: React.ReactNode
  onClick?: () => void
}

function StatusWrapper({ StackProps, children, ...restProps }: StatusWrapperProps) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap="12px"
      height="44px"
      p="4px 12px 4px 4px"
      bgcolor="gray.900"
      border="1px solid"
      borderColor="gray.700"
      {...StackProps}
      {...restProps}
      sx={{
        '&:hover': {
          borderColor: 'gray.500',
        },
        ...StackProps?.sx,
      }}
    >
      {children}
    </Stack>
  )
}

function Tbd() {
  return (
    <StatusWrapper>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="gray.500"
        width="36px"
        height="36px"
      >
        <SvgIcon name="group-line" fontSize="extraSmall" color="inherit" />
      </Box>
      <Box display="flex">
        <Typography variant="paragraphSmall" color="gray.500">
          TBD
        </Typography>
        {/*<div>{topParty.resultText ?? resultFallback(topParty)}</div>*/}
      </Box>
    </StatusWrapper>
  )
}

function Missing() {
  return (
    <StatusWrapper>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="gray.500"
        width="36px"
        height="36px"
      >
        <SvgIcon name="alert-error" fontSize="extraSmall" color="inherit" />
      </Box>
      <Box display="flex">
        <Typography variant="paragraphSmall" color="gray.500">
          NO PLAYER
        </Typography>
        {/*<div>{topParty.resultText ?? resultFallback(topParty)}</div>*/}
      </Box>
    </StatusWrapper>
  )
}

function Searching() {
  const theme = useTheme()
  return (
    <StatusWrapper
      StackProps={{
        sx: {
          background: theme.palette.background.linesFooter,
        },
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="36px"
        height="36px"
        color="primary.main"
      >
        <SvgIcon name="user-search-line" fontSize="extraSmall" color="inherit" />
      </Box>
      <Box display="flex">
        <Typography variant="paragraphSmall" color="gray.25">
          Searching for Player...
        </Typography>
      </Box>
    </StatusWrapper>
  )
}

type CommonStatusWrapperProps = Pick<TournamentBracketProps, 'participant'> &
  Pick<TournamentBracketProps, 'onWatchGame'>

function CommonStatusWrapper({ participant, onWatchGame }: CommonStatusWrapperProps) {
  const { id = '', name, status, avatar, resultText, error } = participant
  return (
    <GradientBorder place={status === TOURNAMENT_BRACKET_STATUSES.WIN ? 1 : 0}>
      <StatusWrapper
        onClick={() => onWatchGame && onWatchGame(id)}
        StackProps={{
          sx: {
            '& .WATCH': {
              display: 'none',
            },
            '&:hover': {
              borderColor: 'gray.500',
              [`& .${TOURNAMENT_BRACKET_STATUSES.PLAYING}`]: {
                display: 'none',
              },
              '& .WATCH': {
                display: 'flex',
              },
              ...(status === TOURNAMENT_BRACKET_STATUSES.PLAYING && {
                cursor: 'pointer',
              }),
            },
            ...(status === TOURNAMENT_BRACKET_STATUSES.WIN
              ? {
                  border: 0,
                  background:
                    'radial-gradient(78.16% 51.09% at 50.36% 97.28%, rgba(238, 164, 13, 0.15) 0%, rgba(238, 164, 13, 0) 100%), #121A2A',
                }
              : {
                  border: '1px solid',
                  borderColor: 'gray.700',
                }),
          },
        }}
      >
        <ImageBox
          borderColor="gray.600"
          sx={{ opacity: status === TOURNAMENT_BRACKET_STATUSES.LOSE || status===TOURNAMENT_BRACKET_STATUSES.CANCELLED || error ? 0.7 : 1 }}
        >
          <ImageCardMedia src={avatar} />
        </ImageBox>
        <Box flexGrow={1}>
          <Typography
            variant="paragraphMedium"
            color={status === TOURNAMENT_BRACKET_STATUSES.LOSE || status===TOURNAMENT_BRACKET_STATUSES.CANCELLED  || error ? 'gray.300' : 'gray.25'}
          >
            {name}
          </Typography>

          {status === TOURNAMENT_BRACKET_STATUSES.WAITING && (
            <Typography
              variant="paragraphSmall"
              color={error ? '#B2322C' : 'gray.300'}
              display="flex"
              alignItems="center"
              gap="6px"
            >
              <>
                <Box component="span" width="6px" height="6px" bgcolor="gray.500" />
                {error ? 'Error' : 'Waiting for match'} 
              </>
            </Typography>
          )}
          {status === TOURNAMENT_BRACKET_STATUSES.CANCELLED  || (status === TOURNAMENT_BRACKET_STATUSES.PLAYING && error) && (
            <Typography
              variant="paragraphSmall"
              color= {error ? '#B2322C' : 'gray.300'}
              display="flex"
              alignItems="center"
              gap="6px"
            >
              <>
                <Box component="span" width="6px" height="6px" bgcolor="gray.500" />
                {error ? 'Error' : 'Canceled'} 
              </>
            </Typography>
          )}
          {status === TOURNAMENT_BRACKET_STATUSES.PLAYING &&  !error && (
            <>
              <Typography
                className={TOURNAMENT_BRACKET_STATUSES.PLAYING}
                variant="paragraphSmall"
                color="gray.300"
                display="flex"
                alignItems="center"
                gap="6px"
              >
                <>
                  <Box component="span" width="6px" height="6px" bgcolor="primary.main" />
                  <Box component="span" color="primary.main">
                    Playing
                  </Box>
                </>
              </Typography>
              <Typography
                className="WATCH"
                variant="paragraphSmall"
                color="primary.light"
                display="flex"
                alignItems="center"
                gap="4px"
              >
                <>
                  <SvgIcon
                    name="arrow-right-s-line"
                    color="inherit"
                    sx={{ width: '15px', height: '15px' }}
                  />
                  <Box component="span" color="inherit">
                    Watch the game
                  </Box>
                </>
              </Typography>
            </>
          )}
        </Box>
        {(status === TOURNAMENT_BRACKET_STATUSES.PLAYING ||
          status === TOURNAMENT_BRACKET_STATUSES.LOSE ||
          status === TOURNAMENT_BRACKET_STATUSES.WIN) && (
          <Stack direction="row" alignItems="center" gap="8px">
            {status === TOURNAMENT_BRACKET_STATUSES.WIN && (
              <Box display="flex" color="primary.main">
                <SvgIcon name="trophy-line" fontSize="extraSmall" color="inherit" />
              </Box>
            )}
            <Typography variant="h7" color="gray.500">
              {resultText}
            </Typography>
          </Stack>
        )}
      </StatusWrapper>
    </GradientBorder>
  )
}

import { takeEvery, call, put, delay, select, fork, race, all, take, cancel, cancelled  } from "redux-saga/effects";
import { push, replace } from '@lagunovsky/redux-react-router'
import { config } from "../config/config";
import { authHeader } from "../utils/authHeader";
import { ChallengeStatus, ChallengeType, BlockchainEventType, BlockchainEventStatus, ParticipantStatus,GameId } from "../utils/const";
import { RoundType,TokenType } from "../helpers/types";
import { getGameServerContract, getWethContract, getWethSwapContract, getChallengeCoinContract } from "../utils/ethersUtil";
import { showErrorToast, showInfoToast, showSuccessToast } from "../components/Toast/Toasts";
import SignerManager from 'src/utils/signerManager';
import { parseMetamaskError } from "src/utils/errorParser";
import { compareStrings } from 'src/utils/stringUtils';
import {ROUTE_PATH} from 'src/routes'
import { ethers } from 'ethers';
import { formatTokenToStringWithSymbol,toToken } from 'src/utils/tokenUtil';
import { apiProfileSlice, Tags } from "src/api/profileApi";
import Cookies from 'js-cookie';

export default function* apiSagas() {

    yield takeEvery("LOGIN_USER_SMART_WALLET", loginSmartWalletWorker);
   // yield takeEvery("LOGIN_USER", loginWorker);
    yield takeEvery("LOGOUT_USER", logoutWorker);
    yield takeEvery("CLEAR_USER", clearWorker);
    yield takeEvery("GET_USER", getUserWorker);
    yield takeEvery("GO_TO", gotoWorker);
    yield takeEvery("CHALLENGE_SIGNUP", signupWorker);
    yield takeEvery("CHALLENGE_LEAVE", leaveWorker);
    yield takeEvery("CLOSE_LOBBY", closeLobbyWorker);
    yield takeEvery("GET_ACTIVE_CHALLENGE", getActiveChallengeWorker);
    yield takeEvery("GET_FINISHED_CHALLENGE", getFinishedChallengeWorker);
    yield takeEvery("CHALLENGE_REMOVE_WITH_DELAY", removeWithDelayChallengeWorker);
    yield takeEvery("CHALLENGE_EVENT", challengeEventWorker);
    yield takeEvery("CHALLENGE_ROUND_RESULT_EVENT", challengeRoundResultEventWorker )
    yield takeEvery("STREAMER_CHALLENGE_EVENT", streamerChallengeEventWorker);
    yield takeEvery("CHALLENGE_RESULT", challengeResultWorker);
    yield takeEvery("CHALLENGE_REFUND", challengeRefundWorker);
    yield takeEvery("STREAMER_CHALLENGE_RESULT", streamerChallengeResultWorker);
   // yield takeEvery("REGISTER_USER", signup);
   // yield takeEvery("REGISTER_USER_STEP_TWO", signupStep2);
  //  yield takeEvery("REGISTER_USER_STEP_THREE", epicVerificationWorker);
   // yield takeEvery("EPIC_VERIFICATION_2FA", epicVerification2FAWorker);
    yield takeEvery("EPIC_ACCOUNT_FROM_CODE", epicAccountFromCodeWorker);
    //yield takeEvery("VERIFY_EMAIL", verifyEmailWorker);
    //yield takeEvery("FORGOT_PASSWORD", forgotPasswordWorker);
    //yield takeEvery("RESET_PASSWORD", resetPasswordWorker);
    yield takeEvery("CHALLENGE_ERROR", challengeErrorWorker);
    yield takeEvery("STREAMER_CHALLENGE_ERROR", streamerChallengeErrorWorker);
    yield takeEvery("CHALLENGE_PARTICIPANT_EVENT", challengeParticipantEventWorker);
    yield takeEvery("STREAMER_CHALLENGE_PARTICIPANT_EVENT", streamerChallengeParticipantEventWorker)
    yield takeEvery("CHALLENGE_PARTICIPANTS_ERROR", challengeParticipantsErrorWorker);
    yield takeEvery("STREAMER_CHALLENGE_PARTICIPANTS_ERROR", streamerChallengeParticipantsErrorWorker);

    yield takeEvery("GO_TO_URL", gotoUrlWorker);

    yield takeEvery("GET_TRANSACTIONS", getTransactionsWorker);
   // yield takeEvery("UPDATE_USER", updateUserWorker);
   // yield takeEvery("UPDATE_USER_NICKNAME", updateUserNicknameWorker);
   // yield takeEvery("REGISTER_PLAYFAB_USER", registerPlayFabUserWorker)

    yield takeEvery("UPLOAD_PROFILE_PICTURE", uploadProfilePictureWorker);
    yield takeEvery("CHANGE_AVATAR", changeAvatarWorker);

    //yield takeEvery("DEPOSIT", depositWorker);
   // yield takeEvery("VERIFY_DEPOSIT", verifyDepositWorker);
    //yield takeEvery("CANCEL_DEPOSIT", cancelDepositWorker);

    //yield takeEvery("WITHDRAW", withdrawWorker);

    //yield takeEvery("REDEEM_CODE", redeemCodeWorker);

    yield takeEvery("GET_CARUSEL", getCaruselWorker);

    yield takeEvery("REQUEST_SUBSCRIPTION_TO_STREAMER_LOBBY", requestSubscriptionToStreamerWorker);
    yield takeEvery("CLOSE_STREAMER_LOBBY", closeStreamerLobby);

    yield takeEvery("TOURNAMENT_UPDATED", tournamentUpdatedWorker);
    yield takeEvery("TOURNAMENT_ROUND_RESULT_EVENT", tournamentRoundResultEventWorker);
    yield takeEvery("TOURNAMENT_CHALLENGE_RESULT", tournamentChallengeResultWorker);

    yield takeEvery("VALIDATE_GAME_USER", validateGameUserWorker);

    yield takeEvery("USER_ERROR", userToastErrorWorker);
    yield takeEvery("SHOW_ERROR_POPUP", showErrorPopupWorker);


    yield takeEvery("UPDATE_BALANCE", updateBalanceWorker);
    yield takeEvery("UPDATE_TOKEN_BALANCE", updateTokenBalanceWorker);
    yield takeEvery("SET_BALANCE", setBalanceWorker);
    yield takeEvery("NICKNAME_RESULT", updateNicknameWorker)

    yield takeEvery("SWAP_ETH_TO_WETH", swapETHToWETH);
    yield takeEvery("GET_ETH_BALANCE", getETHBalance);
}



function* gotoUrlWorker(action) {

    const { url, from } = action;

    //history.push('/'); //will set what is hit when using back button.

    if (from !== undefined) {
        yield put(push(url, { from: from, refresh: true }))
    }
    else {
        yield put(push(url, { refresh: true }))
    }
}

/*
function* loginWorker(action) {
    console.log("login worker");

    try {
        yield put({ type: "IS_WORKING" });
        const { username, password } = action;
        const user = yield call(login, username, password);
        yield put({ type: "USER_LOGGED_IN", user });
        yield put(push('/', { refresh: true }))

        LogRocket.identify(user.id, {
            email: user.username,
        });
    }
    catch (e) {
        console.log("Login failed," + e)
        yield put(openErrorAlert({ title: e.message}));
        yield put({ type: "LOGIN_FAILED", payload: e });
    }
}*/

function* loginSmartWalletWorker(action) {
    try {
        yield put({ type: "IS_WORKING" });  
        const signer = SignerManager.signer;
        if (!signer) {
            throw new Error('No signer found');
        }

        const { loginType, email } = action;

        const account = yield signer.getAddress();
        const getNonce = yield call(checkWallet, account,loginType, email);

        let signature = yield signer.signMessage(`Please login to challenge.gg\nby signing this nonce:\n\n${getNonce.nonce}`);
        yield call(clearWorker);

        const [reply, coins] = yield all([call(verifyWalletSignature, account, signature),call(getUserCoinBalanceWorker,account,false)]);
        reply.user.coins = coins;

        yield call(updateLocalStoreUser,reply.user, reply.token, reply.expires);
        yield put({ type: "USER_LOGGED_IN", userData: reply });
        yield put({ type: "SUBSCRIBE_CHALLENGES" });


        const pathname = yield select(state => state.router.location.pathname);
        if(pathname!==ROUTE_PATH.registration){
            yield put(push(pathname, { refresh: true }))
        }
        else if ((yield select(state => state.router.location.state)) !== null &&
                (yield select(state => state.router.location.state.from))) {
                const prevPage = yield select(state => state.router.location.state.from.pathname);
                if (prevPage === undefined)
                    prevPage = yield select(state => state.router.location.state.from);

                if (prevPage.startsWith('/lobby'))
                    yield put({ type: "UNSUBSCRIBE_CHALLENGES" }); //Since we will be redirected to lobby and already conneced with no auth. So we need to force reconnect. If we would go to main page we would connect again
                yield put(push(prevPage, { refresh: true }))
                console.log("Prev page=" + prevPage);
        }
        else {
            yield put(push('/', { refresh: true }))
        }    
    }
    catch (e) {
        const erorMessage = parseMetamaskError(e);
        showErrorToast('Connection error', erorMessage);
        yield put({ type: "LOGIN_FAILED", payload: erorMessage});
    }
    yield put({ type: "FINISHED_WORKING" }); 
}

function* validateGameUserWorker(action) {
    const { address, nonce, gameName, gameId} = action;

    try {
        const signer = SignerManager.signer;
        if (!signer) {
            showErrorToast('Connection error', 'Wallet is not connected, please reload the page to reestablish connection');
            return;
        }
       
        let signature = yield signer.signMessage(`Please login to ${gameName}\nby signing this nonce:\n\n${nonce}`);
        const verifyReply = yield call(verifyGameSignature, gameId, address, signature);
        showInfoToast('Action', `Use this code ${verifyReply.loginCode} to login to game`);

    }
    catch (e) {
        showErrorToast('Error validaing user',e.message);
        yield put({ type: "VALIDATE_GAME_USER_FAILED", payload: e });
    }
}

function* logoutWorker(action) {
    yield call(clearWorker);
    yield put(push('/'));
    //We need to subscribe again since we are not logged in anymore
    yield put({ type: "SUBSCRIBE_CHALLENGES" });  
}

function* clearWorker(action) {
    yield put({ type: "LOGOUT" });
    yield put({ type: "UNSUBSCRIBE_CHALLENGES" });    
    yield call(removeFromLocalStorage);
    yield put({ type: "RESET_WAIT_DIALOG" });
}


/*
function* signup(action) {
    console.log('signup');

    try {
        yield put({ type: "IS_WORKING" });
        const { firstName, lastName, countryIso, username, password, confirmPassword} = action;
        const user = yield call(registerUser, firstName, lastName, countryIso, username, password, confirmPassword);
        yield put({ type: "USER_LOGGED_IN", user });
        yield put(push('/', { refresh: true }))

        LogRocket.identify(user.id, {
            email: user.username,
        });

    }
    catch (e) {
        yield put({ type: "FINISHED_WORKING" });
        console.log('registration failed; ', e);
        yield put(openErrorAlert({title: e.message }));
        yield put({ type: "REGISTRATION_FAILED", payload: e });
    }
}*/

/*
function* signupStep2(action) {
    console.log('signup, step 2');

    try {
        const { firstName, lastName, address1, address2, zip, city, state, countryIso } = action;
        // remove comment to enable actual submit to server
        const userInfo = yield call(registerUserStepTwo, firstName, lastName, address1, address2, zip, city, state, countryIso);
        yield put({ type: "UPDATE_USER_SUCCESS", userInfo});

        yield put(push('/verification'));
    }
    catch (e) {
        console.log('registration failed: ', e);
        yield put(openErrorAlert({ title: e.message }));
        yield put({ type: "REGISTRATION_FAILED", payload: e });
    }
}*/

/*
function* epicVerificationWorker(action) {
    console.log('verification, step 3');
    try {
        yield put({ type: "IS_WORKING" });
        const { email, password } = action;
        const payload = yield call(epicVerification, email, password);
        yield put({ type: "VERIFICATION_SUCCESS", verificationReply: payload });
        yield put({ type: "FINISHED_WORKING" });
        yield put(push('/'));
    }
    catch (e) {
        console.log('Epic verification failed: ', e);
        yield put({ type: "FINISHED_WORKING" });
        if (typeof e.errorCode !== 'undefined' && e.errorCode === 431) {
            yield put({ type: "2FA_VERIFICATION", cookies: e.cookies });
            yield put(push('/verification_step2'));
        }
        else if (typeof e.errorCode !== 'undefined' && e.errorCode === 412) {
            yield put({ type: "CAPTCHA_VERIFICATION" });
            yield put(push('/verification_captcha'));
        }
        else {
            showErrorToast(e.message);
            yield put({ type: "REGISTRATION_FAILED", payload: e });
        }
    }
}

function* epicVerification2FAWorker(action) {
    try {
        yield put({ type: "IS_WORKING" });
        const { code } = action;
        const cookies = yield select(state => state.userReducer.cookies);
        const payload = yield call(epicVerification2FA, code, cookies);
        yield put({ type: "VERIFICATION_SUCCESS", verificationReply: payload });
        yield put({ type: "FINISHED_WORKING" });
        yield put(push('/'));
    }
    catch (e) {
        yield put({ type: "FINISHED_WORKING" });
        if (typeof e.errorCode !== 'undefined' && e.errorCode === 412) {
            yield put({ type: "CAPTCHA_VERIFICATION" });
            yield put(push('/verification_captcha'));
        }
        else {
            showErrorToast(e.message);
            yield put({ type: "REGISTRATION_FAILED", payload: e });
        }
    }

}*/

function* epicAccountFromCodeWorker(action) {
    try {
        const { code } = action;
        //Call will update localstorage
        const payload = yield call(epicAccountFromCode, code);
        yield put(push('/profile'));
        yield put({ type: "CONNECTED_ACCOUNT", account: payload });      
        showSuccessToast('Connected','Epic account has sucessfully been connected')
    }
    catch (e) {
        yield put({ type: "FINISHED_WORKING" });
        yield put(push('/profile'));
        showErrorToast('Error verifying Epic account', e.detail!== undefined ? e.detail : e.message);
        yield put({ type: "REGISTRATION_FAILED", payload: e });
       
    }

}

/*
function* verifyEmailWorker(action) {
    try {
        const { id, code } = action;
        const payload = yield call(verifyEmail, id, code);
        yield put({ type: "EMAIL_VERIFICATION_SUCCESS" });
        //yield put(push('/'));
        yield put(openAlert({
            icon: 'success',
            title: 'Email Verified',
            background: successGradient,
            confirmButtonColor: 'rgb(80, 227, 194)',
            confirmButtonText: 'Ok',
            onClose: 'GoToHome',
        }));
    }
    catch (e) {
        yield put({ type: "EMAIL_VERIFICATION_FAILED", payload: e });
        yield put(openErrorAlert({ title: e.message }));       
    }
}

function* forgotPasswordWorker(action) {
    try {
        yield put({ type: "IS_WORKING" });
        const { email } = action;
        yield call(forgotPassword, email);
        yield put({ type: "FORGOT_PASSWORD_EMAIL_SENT" });
        yield put({ type: "FINISHED_WORKING" });
        yield put(push('/'));
        yield put(openSuccessAlert({ title: "An email has been sent to you, please follow the instructions to reset you password!" }));
    }
    catch (e) {
        yield put({ type: "FINISHED_WORKING" });
        yield put(openErrorAlert({ title: e.message }));
        yield put({ type: "FORGOT_PASSWORD_FAILED", payload: e });

    }
}

function* resetPasswordWorker(action) {
    try {
        yield put({ type: "IS_WORKING" });
        const { id, code, password } = action;
        console.log("id="+id);
        yield call(resetPassword, id, code, password);
        yield put({ type: "RESET_PASSWORD_EMAIL_SENT" });
        yield put({ type: "FINISHED_WORKING" });
        yield put(push('/'));
        yield put(openSuccessAlert({ title: "Password has been reset!" }));
    }
    catch (e) {
        yield put({ type: "FINISHED_WORKING" });
        yield put(openErrorAlert({ title: e.message }));
        yield put({ type: "RESET_PASSWORD_FAILED", payload: e });

    }
}*/

function* getUserWorker(action) {
    console.log("getUser worker");

    try {
        const forceRefresh = action.forceRefresh;
        const isUserInfoFresh = yield select(state => state.userReducer.userInfoFresh);
        const isLoggedIn = yield select(state => state.userReducer.loggedIn);

        if (isLoggedIn) {
            if (forceRefresh || isUserInfoFresh === false) {
                
                if(!forceRefresh){ //If we force refresh we got it from singnalr so not posssible to get signer needed for fetching balance
                    const account = yield select(state => state.userReducer.userInfo.username);
                    const [userInfo, coins] = yield all([call(getUser), call(getUserCoinBalanceWorker,account)]);
                    userInfo.coins = coins;
                    yield put({ type: "USER_INFO", userInfo });
                }
                else{
                    const userInfo = yield call(getUser);
                    userInfo.coins = undefined; //Reducer will preserve coins when updating user info
                    yield put({ type: "USER_INFO", userInfo });
                }
               
            }
            else {
                yield put({ type: "USER_INFO_ALREADY_LOADED" });
            }
        }
        else {
            yield put({ type: "USER_NOT_LOGGED_IN" });
        }
    }
    catch (e) {
        console.log("GetUser failed," + e)
        yield put({ type: "GET_USER_FAILED", payload: e });
    }
}

function* getETHBalance(action) {      
    const signer = SignerManager.signer;
    if (!signer) {
        throw new Error('No signer found');
    }
    const balanceWei = yield signer.getBalance();
    const account = yield signer.getAddress();   
    console.log("ETH balance:" + balanceWei + " for account:" + account);
    yield put({ type: "USER_BALANCE_TOKEN", balance: balanceWei+"", tokenType: TokenType.ETH });
}

function* getETHBalanceInternal(signer) {
    const balanceWei = yield signer.getBalance();    
    return balanceWei;
}

function* swapETHToWETH(action) {
    try{        
        const signer = SignerManager.signer;
        if (!signer) {
            throw new Error('No signer found');
        }
                
        yield put({ type: "SHOW_WAIT_DIALOG", title: 'Swapping ETH to WETH', subtitle: 'Please sign transaction in wallet to swap'});        

        const { amount } =  action;
        const amountInWei = toToken(amount);

        const  wethSwap  =  yield call(getWethSwapContract,signer);
        
        let currentWETHBalance = yield select(state => state.userReducer.userInfo.coins.weth);
        let currentETHBalance = yield select(state => state.userReducer.userInfo.coins.eth);

        /* global BigInt */
        currentWETHBalance = BigInt(currentWETHBalance);
        currentETHBalance = BigInt(currentETHBalance);
        const newBalance = currentWETHBalance + BigInt(amountInWei);
        const newETHBalance = currentETHBalance - BigInt(amountInWei);
        
        const tx = yield call([wethSwap, wethSwap.deposit], { value: amountInWei });
        //const tx = yield wethSwap.deposit({value: amountInWei}); // Will also work but is not the saga way
        yield put({ type: "UPDATE_WAIT_DIALOG", subtitle: 'Waiting for transaction to complete' });
        yield call([tx, tx.wait]);
        
        yield call(internalUpdateBalanceWorker,newBalance,TokenType.WETH);
        yield call(internalUpdateBalanceWorker,newETHBalance,TokenType.ETH);

        yield put({ type: "UPDATE_WAIT_DIALOG",   subtitle: `You swapped ${amount} ETH to WETH`, status: 'success', timeout: 2000 }); 
        //showSuccessToast('Swap', `You swapped ${amount} ETH to WETH`);
    }
    catch(e){
        yield put({ type: "UPDATE_WAIT_DIALOG",  title:'Swapping ETH to WETH failed',  subtitle: parseMetamaskError(e), status: 'error', timeout: 3000 }); 
        showErrorToast('Swap', parseMetamaskError(e));
        console.log("swapETHToWETH failed," + e)
        yield put({ type: "SWAP_ETH_TO_WETH_FAILED", payload: e });
    }
}

function* getUserCoinBalanceWorker(account, updateLocalStorage=true) {
    
    try {
        const signer = SignerManager.signer;
        if (!signer) {
            throw new Error('No signer found');
        }

        const [ weth, challengeCoin ] =  yield all([call(getWethContract, signer),call(getChallengeCoinContract,signer)]);       
        const [walletWETHBalance, walletChallengeTokenBalance, ethBalance] = yield all([
            call(weth.balanceOf, account),
            call(challengeCoin.balanceOf, account),
            call(getETHBalanceInternal, signer)

        ]);

        var coins = {weth: walletWETHBalance+"", challengeCoins: walletChallengeTokenBalance+"", eth: ethBalance+""}; //Need to be string since JSON.stringify do not handle BigInt
        console.log("coins:" + JSON.stringify(coins));
        if(updateLocalStorage){            
            yield call(updateLocalStoreUserTokens,coins);
        }
        return coins;
    }
    catch (e) {
        const erorMessage = parseMetamaskError(e);
        console.log("getUserCoinBalanceWorker failed," + erorMessage)
        showErrorToast('Incorrect network', erorMessage);
        return {};       
    }   

}

//We know user is logged in
function* updateBalanceWorker() {
    const account = yield select(state => state.userReducer.userInfo.username);
    const balance = yield call(getUserCoinBalanceWorker,account);
    yield put({ type: "USER_BALANCE", balance });
}

function* setBalanceWorker(action){
    console.log(`User recieved: ${action.amount} ${action.tokenType}, new user balence: ${action.balance}`)
    yield call(internalUpdateBalanceWorker,action.balance,action.tokenType);
    showInfoToast('Transfer', `You received ${formatTokenToStringWithSymbol(action.amount, action.tokenType)}`);
}

function* updateTokenBalanceWorker(action){

    const {tokenType,amount } = action;

    let balance;

    switch (tokenType) {
        case TokenType.WETH:            
            balance = yield select(state => state.userReducer.userInfo.coins.weth);
            break;
        case TokenType.CT:
            balance = yield select(state => state.userReducer.userInfo.coins.challengeCoins);
             break;
        case TokenType.ETH:
            balance = yield select(state => state.userReducer.userInfo.coins.eth);
            break;
        default:
    }

    balance = balance || "0";
    balance = ethers.BigNumber.from(balance);
    const newBalance = balance.add(toToken(amount+""));
   
    yield call(internalUpdateBalanceWorker,newBalance,tokenType);
}

export function* internalUpdateBalanceWorker(newBalance, tokenType){
    yield call(updateLocalStoreUserToken,newBalance+"",tokenType); //Need to be string since JSON.stringify do not handle BigInt
    yield put({ type: "USER_BALANCE_TOKEN", balance: newBalance.toString(), tokenType: tokenType });
}

function* updateNicknameWorker(action) {
    //To go back to page before registration
    const prevPage = yield select(state => state.router.location.state?.from);
    const nextPage = (prevPage !== undefined && prevPage!==null) ? prevPage : '/';

    yield put(push(nextPage));

    const tokenText = displayReceivedTokens(action.result.freeTokens);


    if (action.result.alreadyCreated) {
        showInfoToast('Account already exists', `You already have a PlayFab account with username ${action.result.playFabUsername} and nickname  ${action.result.user.nickname}.${tokenText}`);
    }
    else{
        showSuccessToast('Account created',`Your account has been created, you can now join Challenges!${tokenText}`);
    }
    //reply contains full user info including connected games..
    yield put({ type: "USER_INFO", userInfo: action.result.user });
}

function displayReceivedTokens(freeTokens) {
    // Check if the array is empty
    if (!freeTokens || freeTokens.length === 0) {
        return '';
    }

    // Map each token to a string describing the amount and type
    const tokenDescriptions = freeTokens.map(token => {
        return `${formatTokenToStringWithSymbol(token.amount,token.tokenType)}`;
    });

    // Join the descriptions with commas and add an introductory message
    //I want to add a new line before the message

    return ` You have received: ${tokenDescriptions.join(' and ')}. Refresh page to see updated balance.`;
}

function* gotoWorker(action) {

        //   yield put(showJoinChallengeConfirmDialog(action.id));
        yield put(push('/lobby/' + action.id))

}


function* signupWorker(action) {
    try {
        const isLoggedIn = yield select(state => state.userReducer.loggedIn);
        if (isLoggedIn) {

            //if (action.fee > 0 || action.isTemplate)
            yield call(internalSignupBlockchain, { id: action.id, fee: action.fee, tokenType: action.tokenType, isTemplate: action.isTemplate, isSpecial: action.isSpecial, isTournament: action.isTournament, tournamentId: action.tournamentId });
            
            //yield call(internalSignup, { id: action.id, fee: action.fee, isSpecial: action.isSpecial });
        }
        else {
            //Should not get here since check is made before call is made
            showErrorToast('No account', "Please create an account to take part in challenges!");
        }
    }
    catch (e) {
       // closeWaitDialog();
      
        console.log("Signup failed," + JSON.stringify(e));             
        //yield put({ type: "CHALLENGE_SIGNUP_FAILED", payload: e });   

       
        if (e.errorCode !== undefined && e.errorCode === 9082) {
             //Nickname not set
            yield put(push('/registration'));
            showInfoToast('Profile', "Please create an account to take part in challenges!");
        }
        else if (e.errorCode !== undefined && e.errorCode === 9090) {
            //PlayFab account not set 
            yield put(push('/profile'));
            showInfoToast('Profile', "Please create an account to take part in challenges!");
        }
        else if (e.errorCode !== undefined && e.errorCode === 9004) {
             //Epic account not set (verified)
            yield put(push('/profile'));
            showInfoToast('Profile', "Please link your Epic account to participate in Fortnite challenges");
        }
        else {
            //error from backend commes in e.message, from metamask e.data.message
            //transactionFlowError({ title: "Signing up", paragraph: parseMetamaskError(e), time: 3000 });
            yield put({ type: "SHOW_WAIT_DIALOG", showWait: true, title:'Signup failed', subtitle: parseMetamaskError(e), status: 'error', timeout: 0 });
          
        }
    }
}

function* internalSignup(action) {
    if (yield call(signUp, action.id)) { //will return true or thow error if check fails
        if (!action.isSpecial) {
            yield put(push('/lobby/' + action.id));
        }
        showSuccessToast('Challenge joined');
    }
}


// Signup with blockchain for both challenges and tournaments
function* internalSignupBlockchain(action) {

    const signer = SignerManager.signer;
    if (!signer) {
        showErrorToast('Connection error', 'Wallet is not connected, please reload the page to reestablish connection');
        return;
    }

   const walletAccount = yield signer.getAddress();
   
   const [ weth, challengeCoin, gameServer ] =  yield all(
        [
            call(getWethContract, signer),
            call(getChallengeCoinContract,signer),
            call(getGameServerContract, signer)
        ]
    );      
   
   const account = yield select(state => state.userReducer.userInfo.username);
   console.log("walletAccount:" + walletAccount);
   if(compareStrings(walletAccount,account) !== 0){
        showErrorToast('Incorrect account', "Please switch account in your wallet to the same as you have logged in with");
        return;
   }
   
   if (yield call(checkJoin, action.id, action.isTemplate)) {   //will return true or thow error if check fails
        
        yield put({ type: "SHOW_WAIT_DIALOG", title: 'Signing up'});

        yield put({ type: "UPDATE_WAIT_DIALOG", subtitle: 'Checking balance' });

        let contract=undefined;
        if(action.tokenType === TokenType.CT){
            contract = challengeCoin;
        }
        else if(action.tokenType === TokenType.WETH){
            contract = weth;
        }  
        else{
            throw new Error('Incorrect token type');
        }

        const balance = yield contract.balanceOf(account);
        console.log(`balance(${action.tokenType}): ${balance}`);

        if (balance < action.fee) {            
            
            yield put({ type: "UPDATE_WAIT_DIALOG", title:'Signup failed',  subtitle: 'You do not have enough tokens', status: 'error', timeout: 3000 });
           // transactionFlowError({ title: "Signing up", paragraph: "You do not have enough coins", time: 3000 });
             return;
        }

        yield put({ type: "UPDATE_WAIT_DIALOG", subtitle: 'Checking allowance' });
               
        const contactAddress = yield gameServer.address;
        const allowance = yield contract.allowance(account, contactAddress);
       
        console.log(`allowance(${action.tokenType}): ${allowance}, fee: ${action.fee}`);

        if (action.fee > allowance) {
            yield put({ type: "UPDATE_WAIT_DIALOG", subtitle: 'Fee is bigger than allowance, please approve allowance in Wallet' });
            
            //approve 10*fee or balance if balance lower
            const approveAmount = (action.fee*10) > balance ? balance : (action.fee*10);
            const tx1 = yield contract.approve(contactAddress, approveAmount.toString()); //,options

            yield put({ type: "UPDATE_WAIT_DIALOG", subtitle: 'Waiting for transaction to complete' });
            console.log("tx1:" + JSON.stringify(tx1));
            const tx11 = yield tx1.wait();
        }

        yield put({ type: "UPDATE_WAIT_DIALOG",  subtitle: `Please sign transaction in wallet to join ${action.isTournament ? 'tournament':'challenge'}` });

        var tx2;
        if(action.isTemplate){
            tx2 = yield gameServer.joinChallenge(action.id);           
        }
        else{
            tx2 = yield gameServer.joinChallengeById(action.id);
        }
        
        yield put({ type: "UPDATE_WAIT_DIALOG", subtitle: 'Waiting for transaction to complete' });
        console.log("tx2:" + JSON.stringify(tx2));

        //const tx22 = yield tx2.wait();        
        // console.log("tx22:" + JSON.stringify(tx22));
        
        const [txDone, preConfirm] = yield all([tx2.wait(), preConfirmOrTimeout()]);

        console.log("Transaction completed");
        yield put({ type: "UPDATE_WAIT_DIALOG",   subtitle: 'Transaction completed, verifying payment' });

        yield waitJoinWorker(txDone, action.special, action.isTournament, action.tournamentId);
        console.log("Done");
    }
}

function* preConfirmOrTimeout() {
    try {
        const { preconfirm, timeout } = yield race({
            timeout: delay(60000),
            preconfirm: take('USER_ACTION_PRE_CONFIRM')
        })

        return { "preconfirm": preconfirm, "timeout": timeout };
    }
    finally {
        if (yield cancelled()) {
            console.log("preConfirmOrTimeout canceled");
        }
        else {
            console.log("preConfirmOrTimeout ended without cancel");
        }
    }
}


function* waitJoinWorker(tx, special, isTournament, tournamentId) {    
    
    console.log("Pre confirmation recieved");

    const reply = yield call(verifyTransactionHelper,tx, BlockchainEventType.JOIN_CHALLENGE);

    if (reply.done) {
        //closeWaitDialog();
        if (reply.verifyReply.status === BlockchainEventStatus.PROCESSING_SUCCEEDED) {


           // transactionFlowSuccess({ title: "Challenge joined", paragraph: "Good luck!", time: 2000 });
           yield put({ type: "UPDATE_WAIT_DIALOG",  title:`${isTournament ? 'Tournament':'Challenge'} joined`,  subtitle: 'Good luck!', status: 'success', timeout: 2000 }); 
           yield delay(2000);

            if(isTournament && tournamentId !== undefined){ //Pre created tournament
                yield put(replace(`/tournaments/${tournamentId}`)); //Since we are coming from /tournamnets page and we do not want to go back to that page
            }
            else if(isTournament){ //Tournament from template
                yield put(replace(`/tournaments/${reply.verifyReply.tournamentId}`)); 
            }
            else if (!special) {
                yield put(push(`/lobby/${reply.verifyReply.challengeId}`));
            }
            yield call(updateBalanceWorker);

        }
        else {
            yield put(push('/'));
            //transactionFlowError({ title: "Signing up", paragraph: reply.verifyReply.errorMessage, time: 3000 });
            yield put({ type: "UPDATE_WAIT_DIALOG",  title:'Signup failed',  subtitle: reply.verifyReply.errorMessage, status: 'error', timeout: 3000 }); 
        }
    }
    else {
        yield put(push('/'));
        //transactionFlowError({ title: "Signing up", paragraph: "Could not verify payment, please contact support", time: 5000 });
        yield put({ type: "UPDATE_WAIT_DIALOG",  title:'Signup failed',  subtitle: 'Could not verify payment, please contact support', status: 'error', timeout: 0 }); 

    }
        
}

function* verifyTransactionHelper(tx, blockchainEventType) {

    let i = 0;
    let done = false;
    let verifyReply;

    //We try now since we probably got a event that is was done.
    try {
        verifyReply = yield call(verifyTransaction, blockchainEventType, tx.from, tx.to, tx.transactionHash, tx.blockHash, tx.blockNumber);
        done = verifyReply.found && (verifyReply.status === BlockchainEventStatus.PROCESSING_SUCCEEDED || verifyReply.status === BlockchainEventStatus.PROCESSING_FAILED);
    }
    catch (e) { console.log("verifyTransaction  throw exception:" + e) }

    if (done) {
        return { done: true, verifyReply: verifyReply }
    }
    else {
        while (i < 10 && !done) {
            yield delay(4000);
            try {
                verifyReply = yield call(verifyTransaction, blockchainEventType, tx.from, tx.to, tx.transactionHash, tx.blockHash, tx.blockNumber);
                done = verifyReply.found && (verifyReply.status === BlockchainEventStatus.PROCESSING_SUCCEEDED || verifyReply.status === BlockchainEventStatus.PROCESSING_FAILED);
            }
            catch (e) { console.log("verifyTransaction  throw exception:" + e) }
            i++;
        }

        return { done: done, verifyReply: verifyReply }
    }
}

/*
function* tournamentSignupWorker(action) {
    try {
        const isLoggedIn = yield select(state => state.userReducer.loggedIn);
        if (isLoggedIn) {
            const joinedInfo = yield call(tournamentSignUp, action.id);
            const challengeId= joinedInfo.challengeId;
            showSuccessToast('Tournamnet join', 'Tournament joined');

            yield put({ type: "SUBSCRIBE_TO_LOBBY", id: challengeId });
        }
        else {
            //Should not get here since check is made before call is made
            showErrorToast('No account', "Please create an account to take part in challenges!" );
        }
    }
    catch (e) {
        console.log("Tournament signup failed," + e)

        yield put({ type: "TOURNAMENT_SIGNUP_FAILED", payload: e });
        showErrorToast('Error signing up', e.message);
    }
}*/

function* leaveWorker(action) {
    const { id, blockchainChallengeId, fee, challengeType, isTournament } = action;
    try {
       
            yield call(internalLeaveBlockchain, { id: blockchainChallengeId, challengeType: challengeType, isTournament: isTournament});
       
            //Previosuly we used internal leave when fee was 0, but now we use blockchain leave for all
            //yield call(internalLeave, { id: id, challengeType: challengeType });
        
    }
    catch (e) {
        console.log("Leave failed," + e)
        //yield put({ type: "LEAVE_FAILED", payload: e });

        //error from backend commes in e.message, from metamask e.data.message
        //transactionFlowError({ title: "Leaving challenge", paragraph: parseMetamaskError(e), time: 3000 });
        yield put({ type: "UPDATE_WAIT_DIALOG",  showWait: true, title:'Leaving failed',  subtitle: parseMetamaskError(e), status: 'error', timeout: 0 }); 

    }
}
function* internalLeave(action) {
    const { id, challengeType } = action;

    if (yield call(leave, id)) { //will return true or thow error if check fails
        if (!(challengeType === ChallengeType.SPECIAL_1 || challengeType === ChallengeType.SPECIAL_2)) {
            yield put({ type: "CLEAR_ACTIVE_LOBBY", id: id });
            yield put({ type: "UNSUBSCRIBE_LOBBY", id: id });
            if (challengeType === ChallengeType.QUALIFIER)
                yield put(push('/fridayplays'))
            else
                yield put(push('/'))
        }
        else {
            showSuccessToast('Challenge left');
        }
    }
}

function* internalLeaveBlockchain(action) {
    const signer = SignerManager.signer;
    if (!signer) {
        showErrorToast('Connection error', 'Wallet is not connected, please reload the page to reestablish connection');
        return;
    }

    const walletAccount = yield signer.getAddress();   
    const gameServer = yield call(getGameServerContract, signer);           

    const account = yield select(state => state.userReducer.userInfo.username);
    if(compareStrings(walletAccount,account) !== 0){
         showErrorToast('Incorrect account', "Please switch account in your wallet to the same as you have logged in with");
         return;
    }
    
    yield put({ type: "SHOW_WAIT_DIALOG", title: 'Leaving' });
   
    if (yield call(checkLeave, action.id)) {   //will return true or thow error if check fails

        yield put({ type: "UPDATE_WAIT_DIALOG", subtitle: `Please sign transaction in wallet to leave ${action.isTournament ? 'tournament': 'challenge'}` });

        const tx2 = yield gameServer.leaveChallenge(action.id);
       
        yield put({ type: "UPDATE_WAIT_DIALOG", subtitle: 'Waiting for transaction to complete' });

        const [txDone, preConfirm] = yield all([tx2.wait(), preConfirmOrTimeout()]);

        console.log("Transaction completed");
        yield put({ type: "UPDATE_WAIT_DIALOG", subtitle: 'Transaction completed, verifying payment' });

        yield waitLeaveWorker(txDone, action.challengeType, action.isTournament);
        console.log("Done");
    }
}

function* waitLeaveWorker(tx, challengeType, isTournament) {
    
        console.log("Pre confirmation recieved");

        const reply = yield call(verifyTransactionHelper, tx, BlockchainEventType.LEAVE_CHALLENGE);

        if (reply.done) {
            
            //closeWaitDialog();            
            if (reply.verifyReply.status === BlockchainEventStatus.PROCESSING_SUCCEEDED) {                
                
                //transactionFlowSuccess({ title: "Challenge left", paragraph: "Please join another one!", time: 2000 });
                yield put({ type: "UPDATE_WAIT_DIALOG",  title:`${isTournament ? 'Tournament': 'Challenge'} left`,  subtitle: 'Please join another one!', status: 'success', timeout: 2000 }); 
                yield delay(2000);

                if (!(challengeType === ChallengeType.SPECIAL_1 || challengeType === ChallengeType.SPECIAL_2)) {
                    yield put({ type: "CLEAR_ACTIVE_LOBBY", id: reply.verifyReply.challengeId });
                    yield put({ type: "UNSUBSCRIBE_LOBBY", id: reply.verifyReply.challengeId });
                    if (challengeType === ChallengeType.QUALIFIER)
                        yield put(push('/fridayplays'))
                    else
                        yield put(push('/'))
                }
                yield call(updateBalanceWorker);
            }
            else {
                yield put(push('/'));
                yield put({ type: "UPDATE_WAIT_DIALOG",  title:'Error leaving',  subtitle: reply.verifyReply.errorMessage, status: 'error', timeout: 3000 }); 
                //transactionFlowError({ title: "Leaving challenge", paragraph: reply.verifyReply.errorMessage, time: 3000 });
            }
        }
        else {
            yield put(push('/'));
            yield put({ type: "UPDATE_WAIT_DIALOG",  title:'Error leaving',  subtitle: 'Could not verify transaction, please contact support', status: 'error', timeout: 0 }); 
            //transactionFlowError({ title: "Leaving challenge", paragraph: "Could not verify transaction, please contact support", time: 5000 });

        }        
    
}

/*
function* tournamentLeaveWorker(action) {
    const { id } = action;
    try {
        const leaveInfo = yield call(tournamentLeave, id);
        //TODO How about active lobby?
        showSuccessToast('Tournament left');
    }
    catch (e) {
        console.log("Tournament Leave failed," + e)
        showErrorToast('Error leaving', e.message);
        //yield put({ type: "TOURNAMENT_LEAVE_FAILED", payload: e });
    }
}*/

function* closeLobbyWorker(action) {
    const { id, returnTo } = action;

    yield put({ type: "CLEAR_ACTIVE_LOBBY", id: id });
    yield put({ type: "UNSUBSCRIBE_LOBBY", id: id });
    yield put(push(returnTo))

}

function* closeStreamerLobby(action) {
    const { id } = action;
    yield put({ type: "CLEAR_ACTIVE_LOBBY", id: id });
}

function* updateChallenge(action) {
    try {

        const userId = action.userId;
        const challengeId = action.challengeId;
        const account = action.account;
        const updateBalance = action.updateBalance;
        const updateUser = action.updateUser;     

        if(updateBalance){
            if(updateUser){
                const [challengeAndUser, balance] = yield all([call(getChallengeWithUser, challengeId), call(getUserCoinBalanceWorker,account)]);              
                yield put({ type: "USER_BALANCE", balance });
                yield put({ type: "USER_INFO", userInfo: challengeAndUser.user });
                yield put({ type: "LOBBY_INFO", challenge: challengeAndUser.challenge, userId : userId });
            }
            else{
                const [challenge, balance] = yield all([call(getChallenge, challengeId, false), call(getUserCoinBalanceWorker,account)]);              
                yield put({ type: "USER_BALANCE", balance });
                yield put({ type: "LOBBY_INFO", challenge: challenge, userId : userId });
            }
        }
        else{
            if(updateUser){
                const challengeAndUser = yield call(getChallengeWithUser, challengeId);
                yield put({ type: "USER_INFO", userInfo: challengeAndUser.user });
                yield put({ type: "LOBBY_INFO", challenge: challengeAndUser.challenge, userId : userId });
            }
            else{
                const challenge = yield call(getChallenge, challengeId, false);
                yield put({ type: "LOBBY_INFO", challenge: challenge, userId : userId });
            }
        }
    }
    catch (e) {
        console.log("updateChallenge failed," + e)
        yield put({ type: "UPDATE_CHALLENGE_FAILED", payload: e });
    }
}

function* updateUser(action) {
    try {
        const userId = action.userId;
        const account = action.account;
        const updateBalance = action.updateBalance;
        if(updateBalance){
            const [user, balance] = yield all([call(getUser), call(getUserCoinBalanceWorker,account)]);              
            yield put({ type: "USER_BALANCE", balance });
            yield put({ type: "USER_INFO", userInfo: user });
        }
        else{
            const user = yield call(getUser);              
            yield put({ type: "USER_INFO", userInfo: user });
        }
    }
    catch (e) {
        console.log("updateUser failed," + e)
        yield put({ type: "UPDATE_USER_FAILED", payload: e });
    }
}

function* updateStreamerChallenge(action) {
    try {

        const challengeId = action.challengeId;

        const challenge = yield call(getChallenge, challengeId, true);

        yield put({ type: "LOBBY_INFO", challenge: challenge});
    }
    catch (e) {
        console.log("updateStreamerChallenge failed," + e)
        yield put({ type: "UPDATE_STREAMER_CHALLENGE_FAILED", payload: e });
    }
}

/**
 * Not used
 * @param {any} action
 */
function* getActiveChallengeWorker(action) {
    try {
        const userId = yield select(state => state.userReducer.userInfo.id);

        const challenge = yield call(getCurrentChallenge);
        yield put({ type: "CURRENT_CHALLENGE_INFO", challenge: challenge, userId: userId });
        yield put({ type: "SUBSCRIBE_TO_LOBBY", id: challenge.id });
    }
    catch (e) {
        console.log("getActiveChallenge failed," + e)
        yield put({ type: "CURRENT_CHALLENGE_INFO_FAILED", payload: e });
    }
}

function* getFinishedChallengeWorker(action) {
    try {
        yield put({ type: "CLEAR_CHALLENGE_HISTORY"});
        const userId = yield select(state => state.userReducer.userInfo.id);
        const challengeId = action.id;
        const challenge = yield call(getChallenge, challengeId, true);
        yield put({ type: "CHALLENGE_HISTORY", challenge: challenge, userId: userId });
    }
    catch (e) {
        console.log("Failed getting finished challenge," + e)
        showErrorToast('Error getting challenges',e.message);
    }
}

function* removeWithDelayChallengeWorker(action) {
    yield delay(2000);
    yield put({ type: "CHALLENGE_REMOVE", id: action.id });
}

function* challengeEventWorker(action) {     
    const id = action.challengeEvent.challengeId;
    const status = action.challengeEvent.status;
    const userId = yield select(state => state.userReducer.userInfo.id);
    const account = yield select(state => state.userReducer.userInfo.username);

    //Starting STARTING_SCORE_COLLECTED event will not be sent if no pre cooldown is done
    if (status === ChallengeStatus.STARTING_SCORE_COLLECTED) {

        yield call(updateChallenge, {challengeId: id, userId: userId, account: account, updateBalance: true, updateUser: true}); 
    }
    else if (status === ChallengeStatus.PRE_COOLDOWN_DONE || status === ChallengeStatus.RUNNING || status === ChallengeStatus.CANCELLED) {

        yield call(updateChallenge, {challengeId: id, userId: userId, account: account, updateBalance: false, updateUser: false}); 
    }
    else if (status === ChallengeStatus.FINAL_DONE) {

        yield call(updateChallenge, {challengeId: id, userId: userId, account: account, updateBalance: false, updateUser: true }); 
    }
    else if(status === ChallengeStatus.READY_TO_START || status=== ChallengeStatus.PLAY_TIME_OVER){
        yield put({type:"CHALLENGE_STATUS_UPDATE", challengeId: id, status: status});
    }
}

function* challengeRoundResultEventWorker(action) {
    //Could show toast
    yield put({ type: "CHALLENGE_ROUND_RESULT_EVENT_FORWARDED", challengeRoundResultEvent: action.challengeRoundResultEvent });
}

function* tournamentUpdatedWorker(action){
    const userId = yield select(state => state.userReducer.userInfo.id);
    const activeChallenges = yield select(state => state.challengeReducer.activeChallenges);

    for (const bracket of action.tournament.brackets) {
        for (const match of bracket.matches) {
          if (match.status === ChallengeStatus.READY_TO_START && match.players.some(player => player.id === userId)) {
            const challenge = activeChallenges.find(x => x.id === match.id);
            if (challenge === undefined)
                yield put({ type: "SUBSCRIBE_TO_LOBBY", id: match.id, isTournament: false });
          }
        }
    }
}

function* tournamentRoundResultEventWorker(action) {

    yield put({ type: "TOURNAMENT_ROUND_RESULT_EVENT_FORWARDED", tournamentRoundResult: action.tournamentRoundResult });

    //If final round
    if(action.tournamentRoundResult.finalRound){
        const roundWinner = action.tournamentRoundResult.playerRoundResults.find(player => player.roundWinner);
        if(roundWinner !== undefined){
            yield call(insertPlayerToNextLevel, {isThirdPlaceMatch: false, tournamentId: action.tournamentRoundResult.tournamentId,  challengeId: action.tournamentRoundResult.challengeId, playerId: roundWinner.userId });     
            //Get the other player, disregard placment,should be only one and if it is not undefined insert it to next level
            const otherPlayer = action.tournamentRoundResult.playerRoundResults.find(player => player.userId !== roundWinner.userId);
            if(otherPlayer !== undefined){
                yield call(insertPlayerToNextLevel, {isThirdPlaceMatch: true, tournamentId: action.tournamentRoundResult.tournamentId, challengeId: action.tournamentRoundResult.challengeId, playerId: otherPlayer.userId }); 
            }        
        }
    }

}

function* tournamentChallengeResultWorker(action) {
    yield put({ type: "TOURNAMENT_CHALLENGE_RESULT_FORWARDED", challengeResult: action.challengeResult });

    const roundWinner = action.challengeResult.results.find(player => player.placement===1);
    if(roundWinner !== undefined){
        yield call(insertPlayerToNextLevel, { isThirdPlaceMatch: false, tournamentId: action.challengeResult.tournamentId, challengeId: action.challengeResult.challengeId,  playerId: roundWinner.participantId }); 
        //Get the other player, disregard placment,should be only one and if it is not undefined insert it to next level
        const otherPlayer = action.challengeResult.results.find(player => player.participantId !== roundWinner.participantId);
        if(otherPlayer !== undefined){
            yield call(insertPlayerToNextLevel, { isThirdPlaceMatch: true, tournamentId: action.challengeResult.tournamentId, challengeId: action.challengeResult.challengeId,  playerId: otherPlayer.participantId }); 
        }
    }
}


function* insertPlayerToNextLevel(action) {

    const playerId=action.playerId;
    const tournamentId=action.tournamentId;
    const challengeId=action.challengeId;
    const isThirdPlaceMatch = action.isThirdPlaceMatch;
 

    const activeChallenges = yield select(state => state.challengeReducer.activeChallenges);            
    const tournament = activeChallenges.find(c => c.tournamentId===tournamentId && c.isTournamentBase);
    
    let player = null;
    let bracket = null;
    let currentBracketIndex = -1;
    let matchIndex = -1;
    
    if(tournament !== undefined){
        let currentRound = tournament.currentRound-1; //Current round starts at 1, but we want to start at 0
        // Find the player of the given match and its position, we start at current round to avoid looping everything
        for (let i = currentRound; i < tournament.brackets.length; i++) {
            for (let j = 0; j < tournament.brackets[i].matches.length; j++) {
                if (tournament.brackets[i].matches[j].id === challengeId && (i < tournament.brackets.length-1)) { //Skip if last bracket
                    player = tournament.brackets[i].matches[j].players.find(player => player.id === playerId);
                    bracket = tournament.brackets[i];
                    currentBracketIndex = i;
                    matchIndex = j;
                    break;
                }
            }
            if (player) break;
        }
    }
    if(player !== null){

        //Only insert to third place match if we are in semi final
        if(isThirdPlaceMatch && bracket.roundType !== RoundType.SEMI_FINAL)
            return;

        const nextBracketIndex = currentBracketIndex + 1;
        const nextmatchIndex = isThirdPlaceMatch ? 1 : Math.floor(matchIndex / 2) //If third place match we always insert to second match
        const nextPlayerIndex = matchIndex % 2 === 0 ? 0 : 1;

        const insertPlayer = {
            tournamentId: tournamentId,
            id: player.id,
            nickname: player.nickname,
            profilePictureUrl: player.profilePictureUrl,
            status:ParticipantStatus.JOINED,
            bracketIndex: nextBracketIndex,
            matchIndex: nextmatchIndex,
            playerIndex: nextPlayerIndex,
        }
        console.log("insertPlayer:" + JSON.stringify(insertPlayer));
        yield put({ type: "INSERT_TOURNAMENT_PLAYER", player: insertPlayer });
    }
}

function* streamerChallengeEventWorker(action) {
    const id = action.challengeEvent.challengeId;
    const status = action.challengeEvent.status;

    if (status === 'STARTING_SCORE_COLLECTED' || status === 'PRE_COOLDOWN_DONE'
        || status === 'FINAL_DONE' || status === 'CANCELLED') {

        yield call(updateStreamerChallenge, { challengeId: id }); //Will trigger an update of challenge info
    }
}

function* challengeResultWorker(action) {
    const id = action.challengeResult.challengeId;
    const userId = yield select(state => state.userReducer.userInfo.id);
    const account = yield select(state => state.userReducer.userInfo.username);
    // yield put({ type: "SHOW_CHALLENGE_EVENT", event: "RESULTS", extraData: action.challengeResult });

    //Get my payout, check if undefined
    const myResult = action.challengeResult.results.find(x => x.participantId === userId)
    const myPayout = myResult?.payout;
    const tokenType = action.challengeResult.payoutTokenType;
    
    if (myPayout !== undefined && myPayout > 0) {
        //Only show toast if we are in Debug mode, in other modes we get event that shows this
       // if (config.environment === EnvironmentType.DEVELOPMENT){
            showInfoToast('Challenge result', `You received ${formatTokenToStringWithSymbol(myPayout,tokenType)}`);
       // }
        
        var currentBalance;
        switch (tokenType) {
            case TokenType.WETH:
                currentBalance = yield select(state => state.userReducer.userInfo.coins.weth);
                break;
            case TokenType.CT:
                currentBalance = yield select(state => state.userReducer.userInfo.coins.challengeCoins);
                break;
                default:
                    currentBalance = 0;
        }
        //currentBalance to number
        currentBalance = Number(currentBalance);
        const newBalance= currentBalance + myPayout;
        yield call(internalUpdateBalanceWorker,newBalance,tokenType);     
    }

    //Only update if not tournament result
    if(action.challengeResult.isTournamentResult===undefined || !action.challengeResult.isTournamentResult){
        yield call(updateChallenge, { challengeId: id, userId: userId, account: account, updateBalance: false, updateUser: true }); //230621 Changed updateUser to true, needed to get LastWinning
    }
    else{
        yield call(updateUser, { userId: userId, account: account, updateBalance: false }); 
    }

    //Invalidate match history
    yield put(apiProfileSlice.util.invalidateTags([Tags.MatchHistory])) 
}

function* challengeRefundWorker(action) {
    const id = action.challengeRefund.challengeId;
    const userId = yield select(state => state.userReducer.userInfo.id);
    const account = yield select(state => state.userReducer.userInfo.username);

    //only update if player was refunded
    if (action.challengeRefund.results.findIndex(x => x.participantId === userId) > -1) {

        //Get my refund
        var myResult = action.challengeRefund.results.find(x => x.participantId === userId);
        const myRefund = myResult?.fee;
        const tokenType = action.challengeRefund.tokenType;

        if (myRefund !== undefined && myRefund > 0) {
            //Only show toast if we are in Debug mode, in other modes we get event that shows this
           // if (config.environment === EnvironmentType.DEVELOPMENT){
                showInfoToast('Challenge refund', `You where refunded ${formatTokenToStringWithSymbol(myRefund,tokenType)}`);
           // }
            
            var currentBalance;
            switch (tokenType) {
            case TokenType.WETH:
                currentBalance = yield select(state => state.userReducer.userInfo.coins.weth);
                break;
            case TokenType.CT:
                currentBalance = yield select(state => state.userReducer.userInfo.coins.challengeCoins);
                break;
                default:
                    currentBalance = 0;
            }

            //currentBalance to number
            currentBalance = Number(currentBalance);
            const newBalance= currentBalance + myRefund;
            yield call(internalUpdateBalanceWorker,newBalance,tokenType);
        }
        if(action.challengeRefund.isTournamentRefund===undefined || !action.challengeRefund.isTournamentRefund){
            yield call(updateChallenge, { challengeId: id, userId: userId, account: account, updateBalance: false, updateUser: false  }); 
        }
        else{
            yield call(updateUser, { userId: userId, account: account, updateBalance: false }); 
        }
    }

    //Invalidate match history
    yield put(apiProfileSlice.util.invalidateTags([Tags.MatchHistory])) 
}

function* streamerChallengeResultWorker(action) {
    const id = action.challengeResult.challengeId;
    yield call(updateStreamerChallenge, { challengeId: id }); //Will trigger an update of challenge info
}

function* challengeErrorWorker(action) {
    //Does not affect user as of yet so no need to refresh user bu we do it anyway :-)
    const id = action.error.challengeId;
    const userId = yield select(state => state.userReducer.userInfo.id);
    const account = yield select(state => state.userReducer.userInfo.username);

    showErrorToast('Challenge error', action.error.message);

    yield call(updateChallenge, { challengeId: id, userId: userId, account: account, updateBalance: true, updateUser: true }); //will trigger reload of user to get any changes in amount. Will also trigger an update of challenge info

    //Invalidate match history
    yield put(apiProfileSlice.util.invalidateTags([Tags.MatchHistory])) 
}

function* streamerChallengeErrorWorker(action) {
    //Does not affect user as of yet so no need to refresh user bu we do it anyway :-)
    const id = action.error.challengeId;

    showErrorToast('Challenge error',action.error.message);

    yield call(updateStreamerChallenge, { challengeId: id }); // Will trigger an update of challenge info
}

function* challengeParticipantEventWorker(action) {
    const id = action.challengeParticipantEvent.challengeId;
    const forUserId = action.challengeParticipantEvent.participantId;
    const status = action.challengeParticipantEvent.status;

    const currentUserId = yield select(state => state.userReducer.userInfo.id);
    if (currentUserId === forUserId) {
        //Show info as a toast
        if (status === 'DONE') {
            //toast.info("Final complete!. You achieved a score of " + action.challengeParticipantEvent.score);
            //yield put({ type: "SHOW_CHALLENGE_EVENT", event: status, extraData: action.challengeParticipantEvent.score }); //For animation
        }
    }
    yield put({ type: "CHALLENGE_PARTICIPANT_EVENT_FORWARDED", challengeParticipantEvent: action.challengeParticipantEvent}); 
}

function* streamerChallengeParticipantEventWorker(action) {
    yield put({ type: "CHALLENGE_PARTICIPANT_EVENT_FORWARDED", challengeParticipantEvent: action.challengeParticipantEvent});
}

function* challengeParticipantsErrorWorker(action) {
    const id = action.error.challengeId;

    const currentUserId = yield select(state => state.userReducer.userInfo.id);
    if (action.error.participantIds.findIndex(x=>x === currentUserId) !== -1) {
        //Show error as a toast
        showErrorToast('Player error', action.error.message);
    }
    yield put({ type: "CHALLENGE_PARTICIPANTS_ERROR_FORWARDED", error: action.error, currentUserId: currentUserId }); //currentUserId does nothing
}

function* streamerChallengeParticipantsErrorWorker(action) {

    yield put({ type: "CHALLENGE_PARTICIPANTS_ERROR_FORWARDED", error: action.error});
}


function* userToastErrorWorker(action) {
    showErrorToast('User error', action.error.message);
}

function* showErrorPopupWorker(action){
    yield put({ type: "SHOW_WAIT_DIALOG",  title:action.title,  subtitle: action.subtitle, status: 'error', timeout: 0 }); 
}

function* requestSubscriptionToStreamerWorker(action) {
    const nick = action.nick;
    try {
        //const streamer = yield call(isStreamer, nick);
        const streamer = { "nick": nick, "isStreamer": true }; //We skip the sstreamer check an allow all to stream

        if (streamer.isStreamer) {
            yield put({ type: "FOLLOWING_STREAMER", streamer: streamer });
            yield put({ type: "SUBSCRIBE_TO_STREAMER_LOBBY", nick: nick });
        }
        else {
            showErrorToast('User error',"User is not a streamer");
        }
    }
    catch (e) {
        console.log("requestSubscriptionToStreamerWorker failed," + e)
        yield put({ type: "FOLLOWING_STREAMER_FAILED", payload: e });
    }
}

function* getTransactionsWorker(action) {
    try {

        const transactions = yield call(getTransactions);
        yield put({ type: "TRANSACTION_INFO", transactions: transactions });
    }
    catch (e) {
        console.log("monthlyLeaderboard failed," + e)
        yield put({ type: "TRANSACTION_INFO_FAILED", payload: e });
    }
}

function* getCaruselWorker(action) {
    try {

        const streams = yield call(getTwitchStreams);
        yield put({ type: "TWITCH_STREAMS", streams: streams });
    }
    catch (e) {
        console.log("getTwitchStreams failed," + e)
        yield put({ type: "TWITCH_STREAMS_FAILED", payload: e });
    }
}

/*
function* updateUserWorker(action) {
    try {
        yield put({ type: "IS_WORKING" });
        const { firstName, lastName, address1, address2, zip, city, state, countryIso } = action;
        // remove comment to enable actual submit to server
        const userInfo = yield call(registerUserStepTwo, firstName, lastName, address1, address2, zip, city, state, countryIso);
        yield put({ type: "UPDATE_USER_SUCCESS", userInfo });
        yield put({ type: "FINISHED_WORKING" });
        yield put(openSuccessAlert({ title: 'Information updated!' }));
    }
    catch (e) {
        yield put({ type: "FINISHED_WORKING" });
        console.log('update settings failed: ', e);
        yield put(openErrorAlert({title: e.message}));
        yield put({ type: "UPDATE_USER_FAILED", payload: e });
    }
}


function* updateUserNicknameWorker(action) {
    try {
        yield put({ type: "IS_WORKING" });
        const { nickname} = action;
        // remove comment to enable actual submit to server
        const userInfo = yield call(updateUserNickname, nickname);
        yield put({ type: "UPDATE_USER_SUCCESS", userInfo });
        yield put({ type: "FINISHED_WORKING" });
        yield put(push('/'))
        showSuccessToast('Nickname updated!');
    }
    catch (e) {
        yield put({ type: "FINISHED_WORKING" });
        console.log('update settings failed: ', e);
        showErrorToast(e.message);
        yield put({ type: "UPDATE_USER_NICK_FAILED", payload: e });
    }
}


function* registerPlayFabUserWorker(action) {
    try {
        yield put({ type: "IS_WORKING" });
        const { password } = action;
        // remove comment to enable actual submit to server
        const userInfo = yield call(registerPlayFabUser, password);
        yield put({ type: "REGISTER_PLAYFAB_USER_SUCCESS", userInfo });
        yield put({ type: "FINISHED_WORKING" });
        yield put(push('/'))
        showSuccessToast('Versus account created!');
    }
    catch (e) {
        yield put({ type: "FINISHED_WORKING" });
        showErrorToast(e.message);
        yield put({ type: "REGISTER_PLAYFAB_USER_FAILED", payload: e });
    }
} 
*/

/*
function* depositWorker(action) {
    let wallet;
    let web3;
  
    try {
        const { amount, transactionFee } = action;


        const { provider, signer, wallet, challengeCoin } = yield call(getBlockchain);
        console.log("provider:" +provider);
        const price = ethers.parseEther('3');
        console.log("price:" + price);
        const accounts = yield provider.listAccounts();
        console.log("account:" + accounts[0].address);
        const balance = yield provider.getBalance(accounts[0].address);
        console.log("balance(ether):" + balance);

        const tokenBalance = yield wallet.challengeTokenBalance();
        console.log("balance(cc):" + tokenBalance);

        const walletTokenBalance = yield challengeCoin.balanceOf(wallet.address);
        console.log("wallet balance(cc):" + walletTokenBalance);

        const allowance = yield challengeCoin.allowance(accounts[0].address, wallet.address);
        console.log("allowance(cc):" + allowance);

        console.log("challengeCoin address:" + challengeCoin.address);

        //Send ETh to contract
       /* const tx = yield signer.sendTransaction({
            to: challengeCoin.address,
            value: ethers.parseEther(amount.toString())
        });*/

        /*
        const gasEstimation = yield challengeCoin.estimateGas.sendViaCall(accounts[0]);
        console.log("gasEstimation:" + gasEstimation); //Units

        const gasPrice = yield provider.getGasPrice() // In wei
        console.log("gasPrice:" + gasPrice);

        const gasPriceSigner = yield signer.getGasPrice() // In wei
        console.log("gasPrice2:" + gasPriceSigner);

        const txReturn = yield challengeCoin.sendViaCall(accounts[0]);
        console.log("txReturn:" + JSON.stringify(txReturn));
        const txReturn2 = yield txReturn.wait();
        console.log("txReturn2:" + JSON.stringify(txReturn2));


        const cumulativeGasUsed = txReturn2.cumulativeGasUsed;
        console.log("cumulativeGasUsed:" + cumulativeGasUsed);
        const cost =  ethers.utils.formatEther(txReturn2.cumulativeGasUsed.mul(gasPrice));
        console.log("cost:" + cost); //ETH*/


/*
        var options = { gasLimit: 85000 };

      
        const tx1 = yield challengeCoin.approve(wallet.address, ethers.parseEther(amount.toString())); //,options
        console.log("tx1:" + JSON.stringify(tx1));
        const tx11 =  yield tx1.wait();
        console.log("tx11:" + JSON.stringify(tx11));
        const tx2 = yield wallet.depositChallengeTokens(ethers.parseEther(amount.toString()));
        console.log("tx2:" + JSON.stringify(tx2));
        yield put(push('deposit_wait'));
        const tx22 = yield tx2.wait(); //wait for mining
        console.log("tx22:" + JSON.stringify(tx22));

        let i = 0;
        let transactionFound = false;
        let verifyReply;
        while (i < 10 && !transactionFound) {
            console.log("Delay " + i + 1);
            yield put({ type: "UPDATE_WAIT_DIALOG" });
            yield delay(4000);
            verifyReply = yield call(verifyChallengeCoinDeposit, tx22.from, tx22.to, tx22.transactionHash, tx22.blockHash, tx22.blockNumber);

            transactionFound = verifyReply.depositFound;
            i++;
        }

        if (transactionFound) {
            yield put({ type: "USER_BALANCE", balance: verifyReply.totalAmount });
            //BigInt need to movve to ts
            //showSuccessToast(translateDepositSuccededResult(ethers.formatEther(BigInt(verifyReply.amount.toString())), ethers.formatEther(BigInt(verifyReply.totalAmount.toString()))));
        }
        else {
            showErrorToast('Deposit error', 'Could not verify deposit, please contact support');
      
        }
        yield put(push('/'));

        //check if depsoit whent ok and update amount

        /*web3 = yield call(getWeb3);
        console.log("web3:" + web3);
        const accounts = yield call(web3.eth.getAccounts);
        console.log("accounts:" + accounts);
        const networkId = yield call(web3.eth.net.getId);
        console.log("networkId:" + networkId);
        const deployedNetwork = Wallet.networks[networkId];
        console.log("deployedNetwork:" + deployedNetwork);

        const contract = new web3.eth.Contract(
            Wallet.abi,
            deployedNetwork && deployedNetwork.address,
        );
        console.log("instance:" + contract);


        const amount1 = yield call(contract.methods.challengeTokenBalance().call, {from: accounts[0]});
        console.log("amount:" + amount1);


        var inWei = web3.toWei('0.05', 'ether')
        console.log("amount:" + inWei);

        var result = yield call(contract.methods.depositChallengeTokens(inWei).send, { from: accounts[0]});
        console.log("result:" + JSON.stringify(result));

*/

        //const depositReply = yield call(deposit, amount, transactionFee);
        //yield put(push('deposit_wait', { payPalUrl: depositReply.acceptUrl }))

/*
 
    }
    catch (e) {
        console.log('Deposit request failed: ', e);
        showErrorToast('Deposit error',e.message);
        yield put(push('/'));
        yield put({ type: "DEPOSIT_REQUEST_FAILED", payload: e });
    }
}*/

/*
function* verifyDepositWorker(action) {
    try {
        const { orderId, payerId } = action;
        const verifyReply = yield call(verifyDeposit,orderId,payerId);
        yield put({ type: "NEW_AMOUNT", newAmount: verifyReply.totalAmount });
        //yield put(push('/deposit_done', { amount: verifyReply.amount, totalAmount: verifyReply.totalAmount }))
        yield put(push('/'));
        yield put(openSuccessAlert({ title: translateDepositSuccededResult(verifyReply.amount, verifyReply.totalAmount) }));
    }
    catch (e) {
        console.log('Deposit verification failed: ', e);
        yield put(push('/'));
        yield put(openErrorAlert({title: e.message}));
        yield put({ type: "DEPOSIT_VERIFICATION_FAILED", payload: e });
    }
}*/

/*
function* cancelDepositWorker(action) {
    try {
        const { orderId } = action;
        const cancelReply = yield call(cancelDeposit, orderId);
        yield put(push('/'))
    }
    catch (e) {
        console.log('Cancel deposit failed: ', e);
        showErrorToast('Deposit error', e.message);
        yield put({ type: "DEPOSIT_CANCELLATION_FAILED", payload: e });
    }
}*/

/*
function* withdrawWorker(action) {
    try {
        yield put({ type: "IS_WORKING" });
        const { amount, email } = action;
        const withdrawReply = yield call(withdraw, amount, email);
        yield put({ type: "NEW_AMOUNT", newAmount: withdrawReply.totalAmount })
        yield put({ type: "WITHDRAW_REQUEST_SENT" });
        yield put({ type: "FINISHED_WORKING" });
        yield put(push('/'));
        showSuccessToast(translateWithdrawRequestSuccededResult(withdrawReply.amount, withdrawReply.totalAmount));
    }
    catch (e) {
        yield put({ type: "FINISHED_WORKING" });
        console.log('Withdraw request failed: ', e);
        showErrorToast('Withdraw error', e.message );
        yield put({ type: "WITHDRAW_REQUEST_FAILED", payload: e });
    }
}

function* redeemCodeWorker(action) {
    try {
        yield put({ type: "IS_WORKING" });
        const { code } = action;
        const redeemCodeReply = yield call(redeemCode, code);
        yield put({ type: "NEW_AMOUNT", newAmount: redeemCodeReply.totalAmount })
        yield put({ type: "REDEEM_CODE_REQUEST_SENT" });
        yield put({ type: "FINISHED_WORKING" });
        yield put(push('/'));
        showSuccessToast(translateRedeemCodeSuccededResult(redeemCodeReply.amount, redeemCodeReply.totalAmount));
    }
    catch (e) {
        yield put({ type: "FINISHED_WORKING" });
        console.log('Redeem code request failed: ', e);
        showErrorToast('Redeem error', e.message);
        yield put({ type: "REDEEM_CODE_REQUEST_FAILED", payload: e });
    }
} */

function* uploadProfilePictureWorker(action) {
    const { file } = action;
    console.log("File is " + file.size);
    try {
        const pictureInfo = yield call(uploadProfilePicture, file);
        yield put({ type: "PICTURE_INFO", pictureInfo });
    }
    catch (e) {
        console.log('Upload profile picture failed: ', e);
        showErrorToast('Profile update error', e.message);
        yield put({ type: "UPDATE_PROFILE_PICTURE_FAILED", payload: e });
    }
}

function* changeAvatarWorker(action) {
    const { id, src } = action;
    try {
        yield call(changeAvatar, id, src);
        yield put({ type: "AVATAR_UPDATED", profilePictureUrl: src, profilePictureUpdated: new Date().toISOString() });
        showSuccessToast('Avatar updated!');
    }
    catch (e) {
        console.log('Change avatar failed: ', e);
        showErrorToast('Failed to change avatar', e.message);
        yield put({ type: "CHANGE_AVATAR_FAILED", payload: e });
    }
}

/*
function login(username, password) {
    // console.log("Username=" + username);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };
    return fetch(`${config.url.BASE_URL}/api/account/authenticate`, requestOptions)
        .then(handleResponse)
        .then(console.log('Logging in: ', requestOptions))
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));

            return user;
        });
}*/

// User includes coins
function updateLocalStoreUser(user, token, expires) {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem('userInfo', JSON.stringify(user));
    localStorage.setItem('user', JSON.stringify({ token: token, expires: expires }));
}

function updateLocalStoreUserProfilePicture(profilePictureUrl) {
    var existing = localStorage.getItem('userInfo');
    existing = existing ? JSON.parse(existing) : {};
    existing.profilePictureUrl = profilePictureUrl;
    existing.profilePictureUpdated = new Date().toISOString();
    // Save back to localStorage
    localStorage.setItem('userInfo', JSON.stringify(existing));
}

//Update user info but preserve coins
function updateLocalStoreUserPreserveCoins(user) {

    var existing = localStorage.getItem('userInfo');
    existing = existing ? JSON.parse(existing) : {coins: {} };

    //preserve coins
    var coins = existing.coins; //Same as existing['coins']
    if (coins !== undefined) {
        user.coins = coins;
    }

    localStorage.setItem('userInfo', JSON.stringify(user));
}

// store new balance in local storage
function updateLocalStoreUserTokens(coins) {    
    var existing = localStorage.getItem('userInfo');
    
    existing = existing ? JSON.parse(existing) : {};

    if(existing.coins === undefined){
        existing.coins = {  weth: coins.weth, challengeCoins:coins.challengeCoins, eth: coins.eth};
    }
    else{
        existing.coins.weth = coins.weth;
        existing.coins.challengeCoins = coins.challengeCoins;
        existing.coins.eth = coins.eth;
    }

    // Save back to localStorage
    localStorage.setItem('userInfo', JSON.stringify(existing));     
}

function updateLocalStoreUserToken(token, tokenType) {
    var existing = localStorage.getItem('userInfo');

    switch (tokenType) {
        case TokenType.WETH:            
            existing = existing ? JSON.parse(existing) : {coins: {weth:"0"}};
            existing.coins.weth = token;
        break;
        case TokenType.CT:
            existing = existing ? JSON.parse(existing) : {coins: {challengeCoins:"0"}};
            existing.coins.challengeCoins = token;
        break;
        case TokenType.ETH:
            existing = existing ? JSON.parse(existing) : {coins: {eth:"0"}};
            existing.coins.eth = token;
        default:
    }

    
    // Save back to localStorage
    console.log("updateLocalStoreUserToken:" + JSON.stringify(existing));
    localStorage.setItem('userInfo', JSON.stringify(existing));
}

function updateLocalStorageUserConnectedGames(connectedGame){
     
    // update connected accounts in localstorage
     var existing = localStorage.getItem('userInfo');
     existing = existing ? JSON.parse(existing) : {};     

     existing.connectedGames = existing.connectedGames.map((game) => {
         if(game.intGameId==GameId.FORTNITE){
             return connectedGame
         }
         return game;
     });

     // Save back to localStorage
     localStorage.setItem('userInfo', JSON.stringify(existing));
}

function removeFromLocalStorage() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('userInfo');
}

/* Return public address and nonce */
export function loginWallet(account) {
    // console.log("Username=" + username);
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    return fetch(`${config.url.BASE_URL}/api/users/publicaddress/${account}`, requestOptions)
        .catch (rethrowConnectinError)
        .then(handleResponse);       
}

/* Return public address and nonce */
function createWalletUser(account) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ publicAddress: account })
    };
    return fetch(`${config.url.BASE_URL}/api/account/users`, requestOptions)
        .catch (rethrowConnectinError)
        .then(handleResponse);
        
}

/* Return public address and nonce */
function checkWallet(account,loginType, email) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ publicAddress: account, loginType, email, allowCreate: true })
    };
    return fetch(`${config.url.BASE_URL}/api/account/check`, requestOptions)
        .catch (rethrowConnectinError)
        .then(handleResponse);
        
}

/* returns object with user object included and token and expires
 challengecoin balance is set after this call
 */
function verifyWalletSignature(account, signature) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ publicAddress: account, signature })
    };
    return fetch(`${config.url.BASE_URL}/api/account/verifysignature`, requestOptions)
        .catch(rethrowConnectinError)
        .then(handleResponse);

}

function verifyGameSignature(gameId, address, signature) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ gameId, publicAddress: address, signature })
    };
    return fetch(`${config.url.BASE_URL}/api/games/verifysignature`, requestOptions)
        .then(handleResponse);

}


/* 
function registerUser(firstName, lastName, countryIso, username, password, confirmPassword) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ firstName, lastName, countryIso, username, password, confirmPassword })
    };
    return fetch(`${config.url.BASE_URL}/api/account/register`, requestOptions)
        .then(handleResponse)
        .then(console.log('registration sent: ', requestOptions))
        .then(user => {
            // register also log in user, store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));

            return user;
        });
} 

function registerUserStepTwo(firstName, lastName, address1, address2, zip, city, state, countryIso) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ firstName, lastName, address1, address2, zip, city, state, countryIso })
    };
    return fetch(`${config.url.BASE_URL}/api/account/updatecontactinfo`, requestOptions)
    .then(handleResponse)
    .then(console.log('registration sent: ', requestOptions))
    .then(user => {
            // store user details in local storage
            localStorage.setItem('userInfo', JSON.stringify(user));
            return user;
    });
} */

// Is called by registration form, form will get result and send new userobject to reducer so full update is done
export function setCredentials(username, password, selectedAvatar) {
    const requestOptions = {
        method: 'PUT',
        credentials: 'include', // Ensure cookies are sent with the request
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ username, password, selectedAvatar })
    };
    return fetch(`${config.url.BASE_URL}/api/users/me/credentials`, requestOptions)
        .catch(rethrowConnectinError)
        .then(handleResponse)
        .then(reply => {
            // store user details in local storage
            updateLocalStoreUserPreserveCoins(reply.user);    
              // Delete the 'referral' cookie on successful request
            Cookies.remove('referral', { domain: config.url.COOKIES_DOMAIN, sameSite:"None", secure:true});       
            return reply;
        });
}

// Is called by login form to link existing Frontland account, form will get result and send new userobject to reducer so full update is done
export function connectPlayFab(username, password, selectedAvatar) {
    const requestOptions = {
        method: 'PUT',
        credentials: 'include', // Ensure cookies are sent with the request
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ username, password, selectedAvatar })
    };
    return fetch(`${config.url.BASE_URL}/api/users/me/connect/playfab`, requestOptions)
        .catch(rethrowConnectinError)
        .then(handleResponse)
        .then(reply => {
            // store user details in local storage
            updateLocalStoreUserPreserveCoins(reply.user);    
              // Delete the 'referral' cookie on successful request
            Cookies.remove('referral', { domain: config.url.COOKIES_DOMAIN, sameSite:"None", secure:true});       
            return reply;
        });
}


/*
function updateUserNickname(nickname) {
    const requestOptions = {
        method: 'PUT',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ nickname })
    };
    return fetch(`${config.url.BASE_URL}/api/users/me/nickname`, requestOptions)
        .catch(rethrowConnectinError)
        .then(handleResponse)
        .then(user => {
            // store user details in local storage

            var existing = localStorage.getItem('userInfo');
            existing = existing ? JSON.parse(existing) : {};

            //preserve challengecoins
            var challengeCoins = existing.challengeCoins; //Same as existing['challengeCoins']
            if (challengeCoins !== undefined) {
                user.challengeCoins = challengeCoins;
            }
         
            localStorage.setItem('userInfo', JSON.stringify(user)); 
            return user;
        });
}
*/

/*
function registerPlayFabUser(password) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ password })
    };
    return fetch(`${config.url.BASE_URL}/api/users/me/playfab`, requestOptions)
        .then(handleResponse);
}*/

/*
function deposit(amount, transactionFee) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ amount, transactionFee })
    };
    return fetch(`${config.url.BASE_URL}/api/users/me/deposit`, requestOptions)
        .then(handleResponse)
        .then(console.log('Deposit request sent: ', requestOptions));
}*/

/*
function verifyDeposit(orderId,payerId) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ orderId, payerId })
    };
    return fetch(`${config.url.BASE_URL}/api/users/me/verifydeposit`, requestOptions)
        .then(handleResponse)
        .then(console.log('Deposit verification request sent: ', requestOptions))
        .then(verificationReply => {
            // store new amount in local storage
            var existing = localStorage.getItem('userInfo');
            existing = existing ? JSON.parse(existing) : {};
            existing['amount'] = verificationReply.totalAmount;
            // Save back to localStorage
            localStorage.setItem('userInfo', JSON.stringify(existing));
            return verificationReply;
        });
}*/

{/*
function verifyChallengeCoinDeposit(fromAddress, toAddress, transactionHash, blockHash, blockNumber) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ fromAddress, toAddress, transactionHash, blockHash, blockNumber })
    };
    return fetch(`${config.url.BASE_URL}/api/users/me/verifyblockchaindeposit`, requestOptions)
        .then(handleResponse)
        .then(console.log('Deposit verification request sent: ', requestOptions))
        .then(verificationReply => {
            // store new amount in local storage
            var existing = localStorage.getItem('userInfo');
            existing = existing ? JSON.parse(existing) : {};
            existing['challengeCoins'] = verificationReply.totalAmount;
            // Save back to localStorage
            localStorage.setItem('userInfo', JSON.stringify(existing));
            return verificationReply;
        });
} */}

function verifyTransaction(type, fromAddress, toAddress, transactionHash, blockHash, blockNumber) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ type, fromAddress, toAddress, transactionHash, blockHash, blockNumber })
    };
    return fetch(`${config.url.BASE_URL}/api/challenges/verifytransaction`, requestOptions)
        .catch(rethrowConnectinError)
        .then(handleResponse)
        .then(console.log('Verify transaction request sent: ', requestOptions));
}

/*
function cancelDeposit(orderId) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ orderId })
    };
    return fetch(`${config.url.BASE_URL}/api/users/me/canceldeposit`, requestOptions)
        .then(handleResponse)
        .then(console.log('Deposit cancellation request sent: ', requestOptions));
}

function withdraw(amount, email) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ amount, email })
    };
    return fetch(`${config.url.BASE_URL}/api/users/me/withdraw`, requestOptions)
        .then(handleResponse)
        .then(console.log('Withdraw request sent: ', requestOptions))
        .then(withdrawReply => {
            // store new amount in local storage
            var existing = localStorage.getItem('userInfo');
            existing = existing ? JSON.parse(existing) : {};
            existing['amount'] = withdrawReply.totalAmount;
            // Save back to localStorage
            localStorage.setItem('userInfo', JSON.stringify(existing));
            return withdrawReply;
        });
}

function redeemCode(code) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ code })
    };
    return fetch(`${config.url.BASE_URL}/api/users/me/redeemcode`, requestOptions)
        .then(handleResponse)
        .then(console.log('redeem code request sent: ', requestOptions))
        .then(redeemCodeReply => {
            // store new amount in local storage
            var existing = localStorage.getItem('userInfo');
            existing = existing ? JSON.parse(existing) : {};
            existing['amount'] = redeemCodeReply.totalAmount;
            // Save back to localStorage
            localStorage.setItem('userInfo', JSON.stringify(existing));
            return redeemCodeReply;
        });
}
*/

/*
function epicVerification(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ email, password })
    };
    return fetch(`${config.url.BASE_URL}/api/Account/verifyepicaccount`, requestOptions)
    .then(handleResponse)
    .then(console.log('verify epic account request sent: ', requestOptions))
    .then(verificationReply => {
        // store new amount in local storage
        var existing = localStorage.getItem('userInfo');
        existing = existing ? JSON.parse(existing) : {};
        existing['epicVerified'] = verificationReply.verified;
        existing['epicDisplayName'] = verificationReply.displayName;
        existing['epicAccountId'] = verificationReply.accountId;
        // Save back to localStorage
        localStorage.setItem('userInfo', JSON.stringify(existing));
        return verificationReply;
    });
}

function epicVerification2FA(code, cookies) {

    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ code: code, cookies: cookies  })
    };
    return fetch(`${config.url.BASE_URL}/api/Account/verifyepicaccount2fa`, requestOptions)
        .then(handleResponse)
        .then(console.log('verify epic account 2FA request sent: ', requestOptions))
        .then(verificationReply => {
            // store new amount in local storage
            var existing = localStorage.getItem('userInfo');
            existing = existing ? JSON.parse(existing) : {};
            existing['epicVerified'] = verificationReply.verified;
            existing['epicDisplayName'] = verificationReply.displayName;
            existing['epicAccountId'] = verificationReply.accountId;
            // Save back to localStorage
            localStorage.setItem('userInfo', JSON.stringify(existing));
            return verificationReply;
        });
}*/

export function connectEpicGames(){
    getEpicRedirectUrl().then(result => {
      window.location.href = result.redirectUrl;
    })
    .catch((error) => {
      showErrorToast('Error connecting Epic Account', error.message, { toastId: 'pwd' })
    });
}

export function getEpicRedirectUrl() {
    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/Account/epic/redirecturl`, requestOptions)
        .catch(rethrowConnectinError)
        .then(handleResponse);        
}

function epicAccountFromCode(code) {

    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ code: code})
    };
    return fetch(`${config.url.BASE_URL}/api/Account/epic/oauth`, requestOptions)
        .catch(rethrowConnectinError)
        .then(handleResponse)
        .then(connectedGame => {
            updateLocalStorageUserConnectedGames(connectedGame);
            return connectedGame;
        });
}

function verifyEmail(id, code) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        
    };
    return fetch(`${config.url.BASE_URL}/api/account/verifyemail?id=${id}&code=${code}`, requestOptions)
        .then(handleResponse)
        .then(console.log('verify email sent: ', requestOptions));
}

function forgotPassword(email) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }),
        body: JSON.stringify({ email: email })
    };
    return fetch(`${config.url.BASE_URL}/api/Account/forgotpassword`, requestOptions)
        .catch(rethrowConnectinError)
        .then(handleResponse)
        .then(console.log('Forgot password sent: ', requestOptions))
       ;
}

function resetPassword(id,code,password) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }),
        body: JSON.stringify({ id: id, code: code, password: password })
    };
    return fetch(`${config.url.BASE_URL}/api/Account/resetpassword`, requestOptions)
        .catch(rethrowConnectinError)
        .then(handleResponse)
        .then(console.log('Reset password sent: ', requestOptions))
        ;
}


function errorCallout(error) {
    alert(error.message);
}

function displayError(errorCode) {
    console.log('There was an error: ', errorCode)
}

function getUser() {
    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/users/me`, requestOptions)
        .then(handleResponse)
        .then(userInfo => {
            updateLocalStoreUserPreserveCoins(userInfo);
            return userInfo;
        });
}

function checkJoin(id, isTemplate) {
    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/challenges/${id}/join/check?isTemplate=${isTemplate}`, requestOptions)
        .catch (rethrowConnectinError)
        .then(handleResponse);
}

function checkLeave(id) {
    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/challenges/${id}/leave/check`, requestOptions)
        .catch (rethrowConnectinError)
        .then(handleResponse);
}

function signUp(id) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/challenges/${id}/signup`, requestOptions)
        .then(handleResponse);
}

function leave(id) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/challenges/${id}/leave`, requestOptions)
        .then(handleResponse);
}

function getChallenge(id, sortLogEvents) {
    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/challenges/${id}?sortLogEvents=${sortLogEvents}`, requestOptions)
        .then(handleResponse);
}

function getChallengeWithUser(id) {
    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/challenges/${id}/withuser`, requestOptions)
        .then(handleResponse)
        .then(response => {
            updateLocalStoreUserPreserveCoins(response.user);
            return response;
        });
}

function getTournament(id) {
    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/tournaments/${id}`, requestOptions)
        .then(handleResponse);
}

/**
 * Not used
 * */
function getCurrentChallenge() {
    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/challenges/current`, requestOptions)
        .then(handleResponse);
}

function getTransactions() {

    const ascending = false;

    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/users/me/transactions?ascending=${ascending}`, requestOptions).then(handleResponse);
}

function getTwitchStreams() {

    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }),
    };
    return fetch(`${config.url.BASE_URL}/api/carusel/twitch/streams`, requestOptions).then(handleResponse);
}

function uploadProfilePicture(file) {
    const formData = new FormData();
    formData.append('file', file);

    const requestOptions = {
        method: 'POST',
        headers: Object.assign(authHeader()),
        body: formData
    };
    return fetch(`${config.url.BASE_URL}/api/uploads/me/profilepicture`, requestOptions)
        .then(handleResponse)
        .then(userInfo => {
            updateLocalStoreUserPreserveCoins(userInfo);
            return userInfo;
        });;
}

function changeAvatar(id, src) {
    const requestOptions = {
        method: 'PUT',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ id, src })
    };
    return fetch(`${config.url.BASE_URL}/api/users/me/avatar`, requestOptions)
        .then(handleResponse)
        .then(userInfo => {
            updateLocalStoreUserProfilePicture(src);
            return userInfo;
        });;
}

function isStreamer(nick) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }),
        body: JSON.stringify({ nick: nick })
    };
    return fetch(`${config.url.BASE_URL}/api/users/isstreamer`, requestOptions)
        .catch(rethrowConnectinError)
        .then(handleResponse)
        ;
}

function handleResponse(response) {

    //console.log("Response=" + response);
    return response.text().then(text => {
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                removeFromLocalStorage();
                window.location.reload(true);
                //location.reload isn't used when verifying credentials during login, it's for after the user is logged in if their authentication token becomes invalid for any reason 
                //which causes a 401 response to be returned by the api.If a 401 is returned logout() is called which removes the user from local storage, 
                //then location.reload(true) is called which refreshes the app to redirect the user back to the login page.
            }
            else if (response.status === 431) {
                //Will be returned or verification when user has enabled 2FA
                const data = text && JSON.parse(text);
                const error2FA = { errorCode: 431, cookies: data.cookies };
                return Promise.reject(error2FA);
            }
            else if (response.status === 412) {
                //Will be returned or verification when captcha is required
                const errorCaptcha = { errorCode: 412 };
                return Promise.reject(errorCaptcha);
            }
            else if (response.status === 503) {
                //Service unavailable, will be returned in case of maintenance
                const error = { errorCode: 503, message: 'Service is undergoing maintenance' };
                return Promise.reject(error);
            }

            const data = text && JSON.parse(text);
            if (typeof data !== 'undefined' && typeof data.message !== 'undefined') {
                return Promise.reject(data);
            }
            else if (typeof data !== 'undefined' && typeof data.title !== 'undefined') {
                const error = { errorCode: -1, message: typeof data.detail !== 'undefined' ? data.detail: data.title};
                return Promise.reject(error);
            }
            else {
                const error = { errorCode: -1, message: response.statusText };
                return Promise.reject(error);
            }
        }
        const data = text && JSON.parse(text);
        //console.log("data=" + JSON.stringify(data));
        //console.log("statusText=" + response.statusText);
        return data;
    });
}

function rethrowConnectinError(error) {
    if (error.message === 'Failed to fetch') {
        const msg = { errorCode: -1, message: 'Unable to connect to backend, please check you internet connection' };
        throw msg;
    }

    throw error;

}

import React from 'react'
import dayjs from 'src/dayjs.config'
import { useTheme } from '@mui/material/styles'
import { Box, Stack, StackProps } from '@mui/material'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import Typography, { TypographyProps } from 'src/components/Typography/Typography'
import CoinImageWithAmount, {
  CoinImageWithAmountProps,
} from 'src/components/CoinImageWithAmount/CoinImageWithAmount'
import TimeAgo from 'react-timeago'

export type LiveUpdateMessageProps = StackProps & {
  id?: string
  date: Date
  message?: string
  messageTypographyProps?: TypographyProps
  isLastMessage?: boolean
  highlighted?: boolean
  highlightedColor?: string
  coinImageWithAmountProps?: CoinImageWithAmountProps
}

function formatter( value:number, unit:string, suffix:string, epoc:any, nextFormatter:any ) {
  if (suffix==='from now' || (value < 60 && unit === 'second')) {
      return 'Just now'
  }
  else if(unit==='minute'){
    return `${value} min ${suffix}`;
  }
  else
      return nextFormatter();
}

export default function LiveUpdateMessage({
  date,
  message,
  messageTypographyProps,
  isLastMessage = false,
  highlighted,
  highlightedColor = 'primary.main',
  coinImageWithAmountProps,
  ...restRootStackProps
}: LiveUpdateMessageProps) {
  const theme = useTheme()
  return (
    <Stack direction="row" alignItems="center" {...restRootStackProps}>
      <Stack
        direction="row"
        spacing="8px"
        alignItems="center"
        minWidth="135px"
        p="16px"
        pl="8px"
        sx={{
          [theme.breakpoints.down('sm')]: {
            pl: 0,
          },
        }}
      >
        <Box display="flex" color={isLastMessage ? highlightedColor : 'gray.300'} fontSize="8px">
          <SvgIcon name="arrow-right-fill" fontSize="inherit" />
        </Box>
        <Typography variant="bodySmall" color="gray.400" minHeight="16px">
        {/* {dayjs(date).customFromNow()} */} <TimeAgo date={date} formatter={formatter} />
        </Typography>
      </Stack>
      <Stack
        direction="row"
        spacing="4px"
        alignItems="center"
        flexWrap="wrap"
        width="100%"
        p="16px"
        pl="8px"
      >
        {message && !messageTypographyProps?.children && (
          <Typography
            variant="bodySmall"
            color={highlighted ? highlightedColor : 'gray.50'}
            minHeight="16px"
            display="flex"
            alignItems="center"
            {...messageTypographyProps}
          >
            {message}
          </Typography>
        )}
        {!message && messageTypographyProps?.children && messageTypographyProps?.children}
        {coinImageWithAmountProps?.amount && <CoinImageWithAmount {...coinImageWithAmountProps} />}
      </Stack>
    </Stack>
  )
}

import React from 'react'
import { Box, BoxProps, Stack, StackProps } from '@mui/material'
import Typography, { TypographyProps } from 'src/components/Typography/Typography'
import placeWinnerBg1 from '../../assets/images/place-winner-bg-1.svg'
import placeWinnerBg2 from '../../assets/images/place-winner-bg-2.svg'
import placeWinnerBg3 from '../../assets/images/place-winner-bg-3.svg'

type TournamentViewBoxProps = {
  BoxProps?: BoxProps
  children?: React.ReactNode
  childrenStackProps?: StackProps
  title: string
  titleTypographyProps?: TypographyProps
  place: number
  bgImageProps?: BoxProps
}

function TournamentViewBox({
  children,
  childrenStackProps,
  title,
  titleTypographyProps,
  place,
  bgImageProps,
  ...restProps
}: TournamentViewBoxProps) {
  const placeBgImage = (placeNum: number) => {
    switch (placeNum) {
      case 1:
        return placeWinnerBg1
      case 2:
        return placeWinnerBg2
      case 3:
        return placeWinnerBg3

      default:
        return ''
    }
  }
  return (
    <Box
      position="absolute"
      width="300px"
      minHeight="664px"
      overflow="hidden"
      flexGrow={0}
      mx="auto"
      top={0}
      left={0}
      pt="93px"
      {...restProps?.BoxProps}
      sx={{
        inset: 0,
        // background: `url(${placeBgImage(place)}) no-repeat top left`,
        ...restProps?.BoxProps?.sx,
      }}
    >
      <Box
        {...bgImageProps}
        sx={{
          position: 'absolute',
          top: '0',
          left: '0',
          bottom: '0',
          right: '0',
          background: `url(${placeBgImage(place)}) no-repeat top left`,
          ...bgImageProps?.sx,
        }}
      />
      <Typography
        variant="h1"
        color="gray.900"
        px="15px"
        position="absolute"
        top="5px"
        left={0}
        right={0}
        zIndex={0}
        {...titleTypographyProps}
      >
        {title}
      </Typography>
      {children && (
        <Stack
          direction="row"
          justifyContent="center"
          position="relative"
          zIndex={1}
          {...childrenStackProps}
        >
          {children}
        </Stack>
      )}
    </Box>
  )
}

export default TournamentViewBox

import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Box, Grid, SxProps } from '@mui/material'
import Typography, { TypographyProps } from 'src/components/Typography/Typography'

type MonthlyRateBox = {
  label: string
  totalGameCount?: number
  current?: number
  sxBackground?: SxProps
  sxTypography?: TypographyProps['sx']
}

export default function MonthlyRateBox(props: MonthlyRateBox) {
  const theme = useTheme()

  // const heightPercent = useMemo(() => {
  //   let percent = 0
  //   if (props.current && props.totalGameCount) {
  //     percent = props.current / props.totalGameCount
  //     return percent === 1 ? 100 : percent * 75
  //   }
  //   return percent
  //   // eslint-disable-next-line
  // }, [props.current, props.totalGameCount])

  return (
    <Box sx={{ p: '1px', borderLeft: `1px solid ${theme.palette.gray[700]}` }}>
      <Box pt="2px" px="2px" pb="4px">
        <Grid container spacing="2px">
          <Grid item>
            <Typography
              // @ts-ignore
              component="div"
              variant="uiElement"
              color="gray.600"
              height="13px"
            >
              {' //'}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              // @ts-ignore
              component="div"
              variant="uiElement"
              color="gray.500"
              height="13px"
            >
              {props.label || '-'}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          height: { xs: '63px', md2: '75px' },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          justifyContent: 'end',
          width: '99px',
          background: `repeating-linear-gradient(41deg, transparent, transparent 3px, #121A2A 3px, #121A2A 5px)`,
          [theme.breakpoints.down('md2')]: {
            width: '100%',
          },
        }}
      >
        <Box
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Box
            sx={{
              // height: heightPercent + '%',
              height: (100 * (props.current || 0)) / (props.totalGameCount || 0) + '%',
              width: '100%',
              marginTop: 'auto',
              position: 'relative',
              ...(props.sxBackground || {}),
              transition: 'height 0.4s ease-in-out',
            }}
          >
            <Typography
              variant="h7"
              sx={props.sxTypography}
              position="absolute"
              top="-19px"
              right="2px"
            >
              {props.current}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

import React from 'react'
import SlickSlider, { Settings } from 'react-slick'
import { Box, SxProps, Theme } from '@mui/material'

type SliderProps = Settings & {
  slideWidth?: string
  slideSpacing?: string
  sx?: SxProps<Theme>
}

export default function Slider({
  slideWidth = '164px',
  slideSpacing = '8px',
  sx,
  children,
  ...restSliderProps
}: SliderProps) {
  return (
    <Box
      component={SlickSlider}
      dots={true}
      infinite={false}
      speed={500}
      slidesToShow={2}
      arrows={false}
      slidesToScroll={1}
      customPaging={() => <div className="dot" />}
      {...restSliderProps}
      sx={{
        '& .slick-list': {
          overflow: 'visible',
        },
        '& .slick-list .slick-track': {
          display: 'flex',
          '& .slick-slide': {
            width: `${slideWidth} !important`,
            marginRight: `${slideSpacing}`,
          },
        },
        '& .slick-dots': {
          display: 'flex !important',
          justifyContent: 'center',
          gap: '7px',
          position: 'relative',
          bottom: 'unset',
          marginTop: '11px',
          marginBottom: '15px',
          li: {
            margin: 0,
            width: 'auto',
            height: 'auto',
            padding: '1.5px',
            border: '1px solid',
            borderColor: 'transparent',
            '& .dot': {
              width: '5px',
              height: '5px',
              bgcolor: 'gray.600',
            },
            '&.slick-active': {
              borderColor: 'gray.700',
              '& .dot': {
                bgcolor: 'gray.200',
              },
            },
          },
        },
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

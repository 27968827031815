import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { useSearchParams  } from 'react-router-dom';
import { FormikHelpers } from 'formik'
import { Box, Dialog, Grid, useMediaQuery, useTheme } from '@mui/material'
import assets from 'src/assets'
import PageContainer from 'src/components/PageContainer/PageContainer'
import PatternLines from 'src/components/PatternLines/PatternLines'
import TitleWithDot from 'src/components/TitleWithDot/TitleWithDot'
import Comment from 'src/components/Comment/Comment'
import ProfileMenu from 'src/components/ProfileMenu/ProfileMenu'
import LoginInfoForm from 'src/components/LoginInfoForm/LoginInfoForm'
import WalletInfo from 'src/components/WalletInfo/WalletInfo'
import LinkedAccounts, {
  AccountOptions,
  InitialValues as LinkedAccountsInitialValues,
} from 'src/components/LinkedAccounts/LinkedAccounts'
import DownloadBanner from 'src/components/DownloadBanner/DownloadBanner'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import SettingsNotifications from 'src/components/SettingsNotifications/SettingsNotifications'
import TableHistoryProfile from 'src/components/TableHistoryProfile/TableHistoryProfile'
import ProfileChallengesBox from 'src/components/ProfileChallengesBox/ProfileChallengesBox'
import ProfileHotStreakBox from 'src/components/ProfileHotStreakBox/ProfileHotStreakBox'
import TableWrapper from 'src/components/TableWrapper/TableWrapper'
import { showErrorToast, showInfoToast} from "src/components/Toast/Toasts";
import { useAppSelector, useAppDispatch } from 'src/hooks'
import { connectEpicGames } from "../../sagas/apiSagas";
import { logout, getEpicAccountFromCode, changeAvatar, swapETHToWETH } from "src/actions/userActions";
import { filteredStreaks } from "src/selectors/userStreakSelector";
import { accountMapper, gamesPlayedMapper } from 'src/helpers/data';
import { GameId } from 'src/utils/const';
import { StreakType, TokenType } from 'src/helpers/types'
import { setCurrentProfileMenu } from 'src/actions/pageActions';
import { Avatar } from 'src/components/SelectAvatar/SelectAvatar'
import ChangeAvatarDialog from 'src/components/ChangeAvatarDialog/ChangeAvatarDialog'
import { clearHttpHttpsAndDomain, getAvatars, getProfilePicture } from 'src/helpers/commonData';
import { config } from 'src/config/config';
import SwapETHDialog from 'src/components/SwapETHDialog/SwapETHDialog';
import { useDisconnect, useWallet} from '@thirdweb-dev/react';
import ctx from 'src/context/context';
import * as Sentry from '@sentry/browser';
import { formatTokenToString } from 'src/utils/tokenUtil';
import { Rewards } from './rewards';
import { resetProfileApiState, useFetchCTBalanceQuery, useFetchMatchHistoryQuery, useFetchPwdQuery } from 'src/api/profileApi';
import { resetLeaderboardApiState } from 'src/api/leaderboardApi';
import { resetStakingApiState } from 'src/api/stakingApi';
import ReferralCode from 'src/components/ReferralCode/ReferralCode';

export default function Profile() {
  const dispatch =  useAppDispatch();
  const [searchParams] = useSearchParams();
  const theme = useTheme()
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))
  const disconnect = useDisconnect();
  const activeWallet = useWallet();
  const blockchain = ctx.useBlockchain();
  const code = searchParams.get('code');
  //const [selectedProfileMenuItemId, setSelectedProfileMenuItemId] = useState(code!==null ? 'loginInfo' : 'statistics');

  const [isOpenChangeAvatarDialog, setIsOpenChangeAvatarDialog] = useState(false);
  const [isOpenSwapETHDialog, setIsOpenSwapETHDialog] = useState(false);
  const [isRevealOpen, setIsRevealOpen] = useState(false);

  //const [selectedAvatar, setSelectedAvatar] = useState<Avatar>()
 
  const user = useAppSelector(state => state.userReducer.userInfo);
  const avatar = user.profilePictureUrl ? { id: '0', src: getProfilePicture(user?.profilePictureUrl)} as Avatar : {} as Avatar;
  
  const selectedProfileMenuItemId = useAppSelector(state => state.pageReducer.currentProfileMenu);

  const filteredStreaksMemo = useMemo(() => filteredStreaks, []);
  const streaks = useAppSelector(state => filteredStreaksMemo(state, GameId.FRONTLAND, StreakType.ALLTIME)); 
  const accounts = accountMapper(user.connectedGames,code!==null);

  const { data:gamesPlayed, error:gamesPlayedError, isLoading:isGamesPlayedLoading, refetch:refetchLatest } =  useFetchMatchHistoryQuery(undefined, { });
  const profileHistoryTable =  gamesPlayedMapper(gamesPlayed);

  const { data: pwd, error, isLoading } = useFetchPwdQuery();

  const { data: balanceCT, error:erroCT, isLoading:isLoadingCT } = useFetchCTBalanceQuery({address:user?.username, chainId:config.blockchainStaking.chainIdNumber+''}, { });

  const linkedAccountOptions: AccountOptions = useMemo(
    () => accounts.notConnected,
    [code]
  );
  const linkedAccountsInitialValues: LinkedAccountsInitialValues = useMemo(
    () => ({
      accounts: accounts.connected,
    }),
    [code]
  )


  {/* 
[
      {
        id: 'epicgames',
        SvgIconProps: { name: 'epic-games' },
        LinkProps: { href: '#' },
      },
      // {
      //   id: 'epicgames2',
      //   AvatarProps: { src: assets.png['epic-games'] },
      //   LinkProps: { href: '#' },
      // },
    ]


    [
        {
          value: 'i_am_Masterchief',
          status: Statuses.CONNECTED,
          SvgIconProps: { name: 'play-fab' },
          disabled: true,
          LinkProps: {
            href: '#',
          },
        },
        // {
        //   value: '@MS_Chief2005',
        //   // status: Statuses.PROCESS,
        //   status: Statuses.CONNECTED,
        //   SvgIconProps: { name: 'epic-games' },
        // },
      ]
*/}

  const profileHotStreakBoxData = [
    { title: 'Latest streak', value: streaks.streak, width: '50%' },
    { title: 'Best streak', value: streaks.bestStreak, width: '50%' },
    //{ title: 'Win streak', value: 21 },
  ]

  useEffect(() => {
     if(code!==undefined && code!==null){
      dispatch(setCurrentProfileMenu("loginInfo"));
      dispatch(getEpicAccountFromCode(code));     
     }

  },[code,dispatch])

 
 const onOpenChangeAvatarDialog = () => {
    setIsOpenChangeAvatarDialog(true)
  }

  const onCloseChangeAvatarDialog = () => {
    setIsOpenChangeAvatarDialog(false)
  }

  const onOkChangeAvatarDialog = (avatar?: Avatar) => {
   // setSelectedAvatar(avatar)
    onCloseChangeAvatarDialog()
    
    if(avatar!==undefined){
      dispatch(changeAvatar(avatar.id,clearHttpHttpsAndDomain(avatar.src)));
    }
  }

  const onCancelChangeAvatarDialog = () => {
    onCloseChangeAvatarDialog()
  }

  
  const onOkSwapETHDialog = (amount?: string) => {
      setIsOpenSwapETHDialog(false)
      console.log(amount);
      dispatch(swapETHToWETH(amount));    
   }

  const onMenuItemClick = useCallback((id: string) => {
    //setSelectedProfileMenuItemId(id)
    dispatch(setCurrentProfileMenu(id));
  }, [])

  const onLogout = async () => {
    dispatch(logout());
    dispatch(resetProfileApiState());
    dispatch(resetLeaderboardApiState());
    dispatch(resetStakingApiState());
    Sentry.setUser(null);
    await disconnect();
  }

  const loginInfoFormInitialValues = useMemo(
    () => ({
      username: user?.nickname,
      password: pwd?.password || '',
    }),
    [pwd]
  )

  const loginInfoFormOnSubmit = useCallback(
    async (
      values: typeof loginInfoFormInitialValues,
      formikHelpers: FormikHelpers<typeof loginInfoFormInitialValues>
    ) => {
      // console.log(values, formikHelpers)
      // some async logic to await and then save
      formikHelpers.setSubmitting(false)
    },
    []
  )
  

  const linkedAccountsOnSubmit = useCallback(
    async (
      values: LinkedAccountsInitialValues,
      formikHelpers: FormikHelpers<LinkedAccountsInitialValues>
    ) => {
      // console.log(values, formikHelpers)
      // some async logic to await and then save
      formikHelpers.setSubmitting(false)
    },
    []
  )

  const onConnect = (options:AccountOptions[0]) => {
    switch(options.id){
      case 'epicgames':
        connectEpicGames();
        break;
    }
  }

  function handleRevealClose(event: {}, reason: 'backdropClick' | 'escapeKeyDown'): void {
    setIsRevealOpen(false);
  }

  const openSwapDialog = useCallback(
    async () => {      
      if (activeWallet) {
        if(blockchain.isNetworkMismatch===true){
          showInfoToast('Info', `Please switch to ${config.blockchain.chainDisplayName} network`, { toastId: 'network' })
          return;
        }
        setIsOpenSwapETHDialog(true);
      } else {
        showInfoToast('Info', 'Please wait until wallet is connected', { toastId: 'connect' })
      }
    },
    [activeWallet,blockchain.isNetworkMismatch]
  )

  return (
    <Box bgcolor="gray.900" position="relative">
      <ChangeAvatarDialog
        open={isOpenChangeAvatarDialog}
        onClose={onCloseChangeAvatarDialog}
        onOk={onOkChangeAvatarDialog}
        onCancel={onCancelChangeAvatarDialog}
        avatars={getAvatars(config.url.STORAGE_URL)}
        selected={avatar}
      />
      <SwapETHDialog
        open={isOpenSwapETHDialog}
        onClose={() => setIsOpenSwapETHDialog(false)}
        onOk={onOkSwapETHDialog}
        onCancel={() => setIsOpenSwapETHDialog(false)}
      /> 
      <PageContainer
        sx={{
          pt: '17px',
          [theme.breakpoints.down('sm')]: {
            pt: '37px',
          },
        }}
        innerContainerProps={{
          sx: { position: 'relative' },
        }}
      >
        <PatternLines
          sx={{
            height: '262px',
            top: '9px',
            [theme.breakpoints.down('lg4')]: {
              ml: 'calc(-100vw - 12px)',
              height: '238px',
            },
            [theme.breakpoints.down('sm')]: {
              height: '66px',
              ml: 'calc(-100vw - 8px)',
              top: -10,
            },
          }}
        />
        <TitleWithDot
          typographyProps={{ variant: isMobileView ? 'h3' : 'h2' }}
          dotProps={{ bottom: { xs: '12px', sm: '16px' } }}
          rootStackProps={{ spacing: { xs: '6px', sm: '10px' }, mb: { xs: '20px', sm: '24px' } }}
        >
          your profile
        </TitleWithDot>
        <Box mb={{ xs: '31px', sm: '38px', md: '68px' }}>
          <Grid
            container
            direction={{ xs: 'column', sm2: 'row' }}
            spacing={{ xs: '20px', sm: '28px' }}
          >
            <Grid item>
              <Box mt="1px">
                <Comment>profile menu</Comment>
                <ProfileMenu
                  UploadAvatarProps={{
                    name: user?.nickname,
		                onChange: onOpenChangeAvatarDialog,
                    ...avatar,
                  }}
                  MenuItemButtonListProps={{
                    selectedId: selectedProfileMenuItemId,
                    onMenuItemClick,
                  }}
                  onLogout={onLogout}
                />
              </Box>
            </Grid>
            {selectedProfileMenuItemId === 'statistics' && (
              <Grid item xs md2={6} minWidth={{ xs: 'auto', md2: '658px' }}>
                <Box width="100%">
                  <Comment rootStackProps={{ mb: '1px' }}>Your stats</Comment>
                  <ProfileChallengesBox data={{ title: "Games",  
                          subTitle:"Total challenges",
                          totalGames: user.statistics.overal.totalGamesPlayed,
                          winGames: user.statistics.overal.totalGamesWon,
                          rows: [
                            {
                              title: 'This month',
                              value: user.statistics.currentMonth.totalGamesPlayed,
                            },
                       
                        ],
                      }}
                  />
                  <ProfileHotStreakBox title="Your hot streaks" data={profileHotStreakBoxData} />

                  <TableWrapper
                    title="Match history"
                    width="100%"
                    height="auto"
                    minHeight={{ xs: '520px', sm2: '522px', md: '527px' }}
                    mt={{ xs: '16px', sm: '18px', md: '28px' }}
                    p="1px 9px 0"
                    bodyStackProps={{
                      paddingTop: '17px',
                      paddingLeft: '8px',
                      paddingRight: '9px',
                      paddingBottom: '17px',
                    }}
                    TitleDotProps={{ bottom: { xs: '7px', sm: '8px' } }}
                  >
                    <TableHistoryProfile {...profileHistoryTable} />
                  </TableWrapper>
                </Box>
              </Grid>
            )}
            {(selectedProfileMenuItemId === 'loginInfo' || selectedProfileMenuItemId === 'myassets') && (
              <Grid item xs>
                <Box maxWidth={{ xs: 'auto', sm: '100%', md: '576px', lg: '629px' }}>
                  {selectedProfileMenuItemId === 'loginInfo' &&
                    <Comment>account info</Comment>
                  }
                  {selectedProfileMenuItemId === 'myassets' &&
                    <Comment>assets</Comment>
                  }
                  <Box width="100%" mt="1.8px" border="1px solid" borderColor="gray.700">
                    {selectedProfileMenuItemId === 'loginInfo' &&
                      <LoginInfoForm
                        initialValues={loginInfoFormInitialValues}
                        onSubmit={loginInfoFormOnSubmit}
                      />
                    }                    
                    <WalletInfo
                      address={user?.username}
                      isEmbedded={user.loginType === 'embedded'}
                      setIsRevealOpen={() => setIsRevealOpen(true)}
                      tokenInfo={[
                        {
                          total: formatTokenToString(user?.coins?.eth,TokenType.ETH,6,false,true,false),
                          label: 'ETH',
                          chain: config.blockchain.chainDisplayName,
                          icon: assets.png.eth,
                          isWithDrawDisabled: false,
                          isBuyDisabled: true,
                          onWithdrawClick: () => openSwapDialog(),
                        },
                        {
                          total: formatTokenToString(user?.coins?.weth,TokenType.WETH,6,false,true,false),
                          label: 'WETH',
                          chain: config.blockchain.chainDisplayName,
                          icon: assets.png.weth,
                          isWithDrawDisabled: true,
                          isBuyDisabled: true,
                          onWithdrawClick: () => openSwapDialog(),
                        },
                        {
                          total: formatTokenToString(user?.coins?.challengeCoins,TokenType.CT,6,false,true,false),
                          label: 'WCT',
                          chain: config.blockchain.chainDisplayName,
                          icon: assets.png.wct,
                          isWithDrawDisabled: true,
                          isBuyDisabled: true,
                        },
                        {
                          total: formatTokenToString(balanceCT?.balance,TokenType.CT,6,false,true,false),
                          label: 'CT',
                          chain: config.blockchainStaking.chainDisplayName,
                          icon: assets.png.ct,
                          isWithDrawDisabled: true,
                          isBuyDisabled: true,
                        },
                      ]}                  
                      
                 

                    />
                    {selectedProfileMenuItemId === 'loginInfo' &&
                     <ReferralCode referralCode={user.referralCode}/>
                    }
                    {selectedProfileMenuItemId === 'loginInfo' &&
                      <LinkedAccounts
                        accountOptions={linkedAccountOptions}
                        initialValues={linkedAccountsInitialValues}
                        onSubmit={linkedAccountsOnSubmit}
                        onAccountOptionClick={(option)=> onConnect(option)}
                      /> 
                    }
                  </Box>
                </Box>
              </Grid>
            )}
             {selectedProfileMenuItemId === 'rewards' && (
                <Rewards/>
             )}
            {selectedProfileMenuItemId === 'other' && (
              <>
                <Grid item xs display="flex" flexDirection="column">
                  <Comment>Settings</Comment>
                  <SettingsNotifications />
                </Grid>
                <Grid item display={{ xs: 'none', lg: 'block' }}>
                  <Box mt="17.2px">
                    <DownloadBanner
                      description="Description of Game, some presentation text for it and some more, bla bla bla Description of Game, some presentation text for it and some more, bla bla bla"
                      imageProps={{
                        alt: 'Game banner',
                        src: assets.png.bgAvailable,
                      }}
                      linkProps={{
                        children: 'Download for free',
                        href: '/',
                        startIcon: <SvgIcon name="windows-fill" />,
                      }}
                      title="Frontland"
                    />
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Box>       
      </PageContainer>
      <Dialog open={isRevealOpen} onClose={handleRevealClose}>
        <iframe
          src={`https://embedded-wallet.thirdweb.com/sdk/2022-08-12/embedded-wallet/export?clientId=${config.thirdWeb.clientId}`}
          style={{ width: '480px', height: '440px', border: 'none' }}
          allow="clipboard-write"
        />
      </Dialog>
    </Box>
  )
}


import React from 'react'
import { Stack, StackProps } from '@mui/material'
import GameNumber from 'src/components/GameNumber/GameNumber'
import Comment from 'src/components/Comment/Comment'
import Typography from 'src/components/Typography/Typography'
import VerticalDividerWithContent from 'src/components/VerticalDividerWithContent/VerticalDividerWithContent'

type CountWithLabelProps = StackProps & {
  count?: number
  total?: number
  label?: string
}

export default function CountTotalWithLabel({
  count = 0,
  total = 0,
  label,
  ...restRootStackProps
}: CountWithLabelProps) {
  return (
    <VerticalDividerWithContent rootStackProps={restRootStackProps}>
      <Stack direction="row" spacing="8px" alignItems="center">
        <Stack direction="row" spacing="2px" alignItems="center">
          <GameNumber count={count} />
          <Comment rootStackProps={{ spacing: '2px' }} typographySlashProps={{ color: 'gray.600' }}>
            <GameNumber count={total} />
          </Comment>
        </Stack>
        {label && (
          <Typography variant="uiElement" color="gray.500">
            {label}
          </Typography>
        )}
      </Stack>
    </VerticalDividerWithContent>
  )
}

import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Box, Stack, ButtonBase } from '@mui/material'
import { Options } from '../../../typings/common'
import Typography from '../Typography/Typography'
import SvgIcon, { SvgIconProps } from '../SvgIcon/SvgIcon'
import Link from 'src/components/Link/Link'

type UnderChallengeButton = {
  dataSource: Options
  linkProps?: {
    href: string
    iconName?: SvgIconProps['name']
    label?: string
  }
}

export default function UnderChallengeButton({
  dataSource = [],
  linkProps = { href: '/', label: 'See all challenges' },
}: UnderChallengeButton) {
  const theme = useTheme()

  return (
    <Box
      sx={{
        minHeight: { xs: '62px', md834: '80px' },
        display: 'flex',
        border: `1px solid ${theme.palette.gray[700]}`,
        '& button': {
          transition: theme.transitions.create(['background']),
        },
        '& .MuiLink-root .MuiLink-endIcon.MuiLink-iconSizeMedium': {
          ml: { xs: '12px', md834: '28px' },
          '& .MuiSvgIcon-root': {
            fontSize: '24px',
          },
        },
        '&:hover': {
          borderColor: theme.palette.gray[500],
          '& .MuiLink-root': {
            color: theme.palette.gray[0],
          },
          '& button': {
            background: theme.palette.gray[900],
          },
        },
      }}
    >
      <ButtonBase
       
        sx={{
          m: '1px',
          p: '15.25px',
          px: '24px',
          flexGrow: 1,
          justifyContent: 'space-between',
          backgroundColor: theme.palette.gray[800],
          [theme.breakpoints.down('md')]: {
            pl: '27px',
          },
          [theme.breakpoints.down('md834')]: {
            p: '9px 16px 8px 16px',
          },
        }}
        disableRipple={true}
      >
        <Stack direction="row" gap={{ xs: '12px', md834: '43px' }}>
          {dataSource?.length &&
            dataSource.map(({ label, value }) => (
              <div key={label}>
                <Typography
                  variant="h7"
                  color="gray.0"
                  align="left"
                  marginBottom={{ xs: '1px', md834: '4px' }}
                >
                  {value}
                </Typography>
                <Typography
                  variant="paragraphMedium"
                  align="left"
                  color="gray.500"
                  textTransform="capitalize"
                >
                  {label}
                </Typography>
              </div>
            ))}
        </Stack>
        {linkProps?.href && (
          <Link
            href={linkProps.href}
            color="secondary"
            endIcon={<SvgIcon name={linkProps.iconName || 'arrow-right-up-line'} />}
          >
            {linkProps.label}
          </Link>
        )}
      </ButtonBase>
    </Box>
  )
}

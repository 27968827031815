import {
  Box,
  BoxProps as MuiBoxProps,
  Modal,
  ModalProps as MuiModalProps,
  Stack,
  StackProps as MuiStackProps,
} from '@mui/material'
import React from 'react'
import Button, { ButtonProps } from '../Button/Button'
import Typography, { TypographyProps } from '../Typography/Typography'
import SvgIcon from '../SvgIcon/SvgIcon'

type ModalStyledProps = {
  /** [The root element of this component uses the ModalProps from Material UI](https://mui.com/material-ui/react-modal/) */
  ModalProps?: MuiModalProps
  /** [The root element of this component uses the BoxProps from Material UI](https://mui.com/material-ui/react-box/) */
  rootBoxProps?: MuiBoxProps
  /** [The root element of this component uses the StackProps from Material UI](https://mui.com/material-ui/react-stack/) */
  childrenStackProps?: MuiStackProps
  /** [The root element of this component uses the StackProps from Material UI](https://mui.com/material-ui/react-stack/) */
  buttonStackProps?: MuiStackProps
  /** [The root element of this component uses the BoxProps from Material UI](https://mui.com/material-ui/react-box/) */
  confirmBtnTextBoxProps?: MuiBoxProps
  /** [The root element of this component uses the BoxProps from Material UI](https://mui.com/material-ui/react-box/) */
  emptyConfirmBtnBoxProps?: MuiBoxProps
  closeBtnProps?: ButtonProps
  cancelBtnProps?: ButtonProps
  confirmBtnProps?: ButtonProps
  openModal: boolean
  children: React.ReactNode
  cancelBtn?: boolean
  cancelBtnText?: string
  cancelTypographyProps?: TypographyProps
  closeBtn?: boolean
  confirmBtn?: boolean
  setOpenModal?: (value: boolean) => void
  confirmEvent?: (value: boolean) => void
  confirmBtnText?: string
  confirmTextTypographyProps?: TypographyProps
  confirmBtnIcon?: React.ReactNode
  confirmBtnSubText?: string
  confirmSubTextTypographyProps?: TypographyProps
  buttonSize?: 'small' | 'medium' | 'large'
}

function ModalStyled({
  ModalProps,
  rootBoxProps,
  childrenStackProps,
  buttonStackProps,
  closeBtnProps,
  confirmBtnProps,
  cancelBtnProps,
  confirmBtnTextBoxProps,
  emptyConfirmBtnBoxProps,
  setOpenModal,
  openModal,
  children,
  cancelBtn = true,
  cancelBtnText = 'Cancel',
  cancelTypographyProps,
  closeBtn = true,
  confirmBtn = true,
  confirmEvent,
  confirmBtnText = 'Confirm',
  confirmTextTypographyProps,
  confirmBtnIcon = <SvgIcon name="arrow-right-line" />,
  confirmBtnSubText,
  confirmSubTextTypographyProps,
  buttonSize = 'medium',
}: ModalStyledProps) {
  const handleClose = () => {
    if (setOpenModal) {
      setOpenModal(false)
    }
  }

  const handleConfirm = () => {
    if (confirmEvent && setOpenModal) {
      confirmEvent(true)
      setOpenModal(false)
    }
  }

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      {...ModalProps}
    >
      <Box
        width="406px"
        border="1px solid"
        borderColor="gray.700"
        p="1px"
        position="absolute"
        top="50%"
        left="50%"
        {...rootBoxProps}
        sx={{
          transform: 'translate(-50%, -50%)',
          ...rootBoxProps?.sx,
        }}
      >
        {closeBtn && (
          <Button
            onClick={handleClose}
            variant="text"
            sx={{
              paddingX: '20px',
              paddingY: '22px',
              position: 'absolute',
              zIndex: 2,
              right: 0,
              top: 0,
              color: 'gray.25',
              '&:hover': {
                background: 'none',
              },
            }}
            {...closeBtnProps}
          >
            <SvgIcon name="exit-icon" fontSize="small" sx={{ width: '17px' }} />
          </Button>
        )}

        <Stack bgcolor="gray.800" position="relative" direction="column" {...childrenStackProps}>
          {children}
        </Stack>

        {(cancelBtn || confirmBtn) && (
          <Stack direction="row" gap="3px" mt="2px" {...buttonStackProps}>
            {confirmBtn ? (
              <Button
                variant="challenge"
                size={buttonSize}
                endIcon={confirmBtnIcon}
                sx={{
                  width: '100%',
                }}
                onClick={handleConfirm}
                {...confirmBtnProps}
              >
                <Box width="100%" textAlign="left" {...confirmBtnTextBoxProps}>
                  <Typography
                    variant="buttonMedium"
                    sx={{
                      lineHeight: '18px',
                      display: 'inline-block',
                    }}
                    color="gray.800"
                    {...confirmTextTypographyProps}
                  >
                    {confirmBtnText}
                  </Typography>
                  {confirmBtnSubText && (
                    <Typography
                      variant="bodySmall"
                      color="gray.800"
                      sx={{ lineHeight: '12px' }}
                      {...confirmSubTextTypographyProps}
                    >
                      {confirmBtnSubText}
                    </Typography>
                  )}
                </Box>
              </Button>
            ) : (
              <Box bgcolor="gray.800" {...emptyConfirmBtnBoxProps}></Box>
            )}
            {cancelBtn && (
              <Button
                variant="outlined"
                size={buttonSize}
                onClick={handleClose}
                {...cancelBtnProps}
              >
                <Typography
                  variant="buttonMedium"
                  sx={{
                    lineHeight: '18px',
                    display: 'block',
                  }}
                  textAlign="center"
                  {...cancelTypographyProps}
                >
                  {cancelBtnText}
                </Typography>
              </Button>
            )}
          </Stack>
        )}
      </Box>
    </Modal>
  )
}

export default ModalStyled

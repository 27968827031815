import { Box, Stack } from '@mui/material'
import { useCallback } from "react"
import CopyToClipboard from 'react-copy-to-clipboard'
import { toast } from 'src/components/Notification/Notification'
import Typography from 'src/components/Typography/Typography'
import TextField from 'src/components/TextField/TextField'
import ButtonIcon from 'src/components/ButtonIcon/ButtonIcon'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'

type ReferralCodeProps = {
    referralCode?: string
  }


export default function ReferralCode({ referralCode }: ReferralCodeProps) {
    const onCopy = useCallback(() => {
        toast.success('Copied to clipboard', { toastId: 'copied' })
      }, [])

    const baseUrl = window.location.origin;  

    return (
    <>
        <Box
            pt={{ xs: '16px', sm: '24px' }}
            px={{ xs: '16px', sm: '24px' }}
            pb="36px"
            m="1px"
            bgcolor="gray.800"
            >
            <Typography variant="h7" color="common.white">
                Referral Codes
            </Typography>
            <Box mt="21px">
                <Stack direction="row" spacing="4px" alignItems="flex-end">
                    <TextField name="address" label="Invite referral link"  fullWidth disabled 
                        value={referralCode ? `${baseUrl}/registration?ref=${referralCode}` :''} 
                        sx={{
                            '& .Mui-disabled': {
                                color: 'lightgray !important', 
                                opacity: '0.7 !important' ,
                            },
                            }}
                        />
                    <Box>
                        <CopyToClipboard text={referralCode ? `${baseUrl}/registration?ref=${referralCode}`:''} onCopy={onCopy}  >              
                        <ButtonIcon variant="outlined" size="small" sx={{ mb: '14px' }}>
                            <SvgIcon name="file-copy-line" />
                        </ButtonIcon>
                        </CopyToClipboard>
                    </Box>  
                </Stack>
            </Box>
        </Box>
    </>
    );
    

}
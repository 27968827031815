import { Stack, StackProps as MuiStackProps, useTheme } from '@mui/material'
import React from 'react'
import TournamentDetailInfo from '../TournamentDetailInfo/TournamentDetailInfo'
import TournamentUserStatistic from '../TournamentUserStatistic/TournamentUserStatistic'
import { GamingEventDetailsOptProps } from '../GamingEventDetailsOpt/GamingEventDetailsOpt'

type TournamentTopStatWrapperProps = {
  /** [The root element of this component uses the StackProps from Material UI](https://mui.com/material-ui/react-stack/) */
  StackProps?: MuiStackProps
  dataDetailInfo: GamingEventDetailsOptProps[]
  dataUserStatistic: GamingEventDetailsOptProps[]
}

function TournamentTopStatWrapper({
  StackProps,
  dataDetailInfo,
  dataUserStatistic,
}: TournamentTopStatWrapperProps) {
  const theme = useTheme()

  return (
    <Stack
      mt="1px"
      mb="45px"
      gap="28px"
      direction="row"
      justifyContent="space-between"
      sx={{
        [theme.breakpoints.down('md834')]: {
          flexDirection: 'column',
          gap: '17px',
        },
      }}
      {...StackProps}
    >
      <TournamentDetailInfo title="Details" data={dataDetailInfo} BoxProps={{ flexGrow: 1 }} />
      <TournamentUserStatistic title="your stat" data={dataUserStatistic} />
    </Stack>
  )
}

export default TournamentTopStatWrapper

import React from 'react'
import { Box, BoxProps } from '@mui/material'

type GradientLeaderBoardProps = BoxProps & {
  children?: React.ReactNode
  place?: number
}

function GradientLeaderBoard({ place, children, ...restProps }: GradientLeaderBoardProps) {
  // Color change if there is Place
  let startColor
  let endColor
  let defaultBg
  switch (place) {
    case 1:
      startColor = '238,164,13,0.15'
      endColor = '238,164,13,0'
      defaultBg = '#040811'
      break
    case 2:
      startColor = '169,175,190,0.15'
      endColor = '169,175,190,0'
      defaultBg = '#040811'
      break
    case 3:
      startColor = '198,128,64,0.15'
      endColor = '198,128,64,0'
      defaultBg = '#040811'
      break
  }

  return (
    <Box
      position="relative"
      zIndex={0}
      sx={{
        background: `radial-gradient(71.35% 43.75% at 27.59% 100%, rgba(${startColor}) 0%, rgba(${endColor}) 100%), ${defaultBg}`,
      }}
      {...restProps}
    >
      {children}
    </Box>
  )
}

export default GradientLeaderBoard

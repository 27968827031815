import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Box } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import Image, { ImageProps } from 'src/components/Image/Image'
import GameCardButton from 'src/components/GameCardButton/GameCardButton'
import Comment from 'src/components/Comment/Comment'
import GameNumber from 'src/components/GameNumber/GameNumber'
import ImageWithTitle from 'src/components/ImageWithTitle/ImageWithTitle'
import useMediaQuery from '@mui/material/useMediaQuery'

export type GameBannerProps = {
  bodyImgSrc: ImageProps['src']
  gamesCount: number
  iconSrc: ImageProps['src']
  title: string
  onClick?: () => void
}

export default function GameBanner({
  bodyImgSrc,
  gamesCount,
  iconSrc,
  title,
  onClick,
}: GameBannerProps) {
  const theme = useTheme()
  const isSmView = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box
      border="1px solid"
      borderColor="gray.700"
      maxWidth="848px"
      sx={{
        '&:hover': {
          borderColor: theme.palette.gray[500],
        },
      }}
    >
      <Grid container gap="1px" m="1px">
        <Grid xs>
          <Box
            maxWidth="737px"
            minHeight={{ xs: '116px', sm: '150px' }}
            position="relative"
            overflow="hidden"
            bgcolor="gray.800"
          >
            <Image src={bodyImgSrc} alt="Game banner" fill />
            <Box
              sx={{
                width: '324px',
                position: 'absolute',
                top: -1,
                bottom: -1,
                left: -1,
                zIndex: 1,
                background: theme.palette.background.gameBanner,
                transform: 'rotate(180deg)',
              }}
            />
            <Box sx={{ position: 'absolute', top: 14, left: 14, zIndex: 2 }}>
              <Comment>
                <GameNumber count={gamesCount} />
              </Comment>
            </Box>
            <Box
              sx={{
                position: 'absolute',
                bottom: 20,
                left: 24,
                zIndex: 2,
                [theme.breakpoints.down('sm')]: {
                  bottom: '14px',
                  left: '14px',
                },
              }}
            >
              <ImageWithTitle
                imageProps={{
                  src: iconSrc,
                }}
                typographyProps={{
                  variant: isSmView ? 'h6' : 'h4',
                }}
                title={title}
              />
            </Box>
          </Box>
        </Grid>
        <Grid>
          <GameCardButton label="See challenges" onClick={onClick} />
        </Grid>
      </Grid>
    </Box>
  )
}

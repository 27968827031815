import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Box, Typography, Stack } from '@mui/material'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import TitleWithDot from '../TitleWithDot/TitleWithDot'

type HotStreakBoxProps = {
  fiveKillRow: number
  treeKillRow: number
}

export default function HotStreakBox(props: HotStreakBoxProps) {
  const theme = useTheme()
  return (
    <Stack
      direction="row"
      bgcolor="gray.900"
      sx={{
        maxWidth: '411px',
        height: '90px',
        flexGrow: 1,
        border: `1px solid ${theme.palette.gray[700]}`,
        '&:hover': {
          borderColor: theme.palette.gray[500],
        },
        [theme.breakpoints.down('md')]: {
          maxWidth: '47%',
          height: '86px',
        },
        [theme.breakpoints.down('sm2')]: {
          maxWidth: '100%',
          height: '77px',
        },
      }}
    >
      <Box
        sx={{
          paddingLeft: '18px',
          marginTop: '18px',
          flexGrow: 1,
          [theme.breakpoints.down('md')]: {
            marginTop: '23px',
          },
          [theme.breakpoints.down('sm2')]: {
            paddingLeft: '12px',
            marginTop: '12px',
          },
        }}
      >
        <SvgIcon name="medal" sx={{ marginBottom: { xs: '1px', md: '9px' }, fontSize: '21px' }} />
        <TitleWithDot
          typographyProps={{ variant: 'h7' }}
          dotProps={{ width: '4px', height: '4px', bottom: '5px' }}
          rootStackProps={{ spacing: '5px' }}
        >
          Your win streaks
        </TitleWithDot>
      </Box>
      <Stack direction="row" pr={{ xs: '12px', sm2: '6%', md: '10px' }}>
        <Box sx={{ paddingTop: { xs: '15px', sm2: '26px', md: '22px' }, paddingLeft: '10px' }}>
          <Typography
            variant="paragraphSmall"
            sx={{ color: theme.palette.gray[500], marginBottom: { xs: '4px', sm2: '3px' } }}
          >
           Current streak
          </Typography>
          <Typography variant="h6">{props.fiveKillRow || 0}</Typography>
        </Box>
        <Box
          sx={{
            paddingTop: '22px',
            marginLeft: '26px',
            [theme.breakpoints.down('md')]: {
              paddingTop: '22px',
              marginLeft: '2.6vw',
            },
            [theme.breakpoints.down('sm2')]: {
              paddingTop: '15px',
              marginLeft: '10px',
            },
          }}
        >
          <Typography
            variant="paragraphSmall"
            sx={{ color: theme.palette.gray[500], marginBottom: '3px' }}
          >
            Best streak
          </Typography>
          <Typography variant="h6">{props.treeKillRow || 0}</Typography>
        </Box>
      </Stack>
    </Stack>
  )
}

import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Box, BoxProps, Container, useTheme } from '@mui/material'
import Image from '../Image/Image'
import { PNG_ICONS } from 'src/assets/icons/icons'
import Video, { VideoProps } from 'src/components/Video/Video'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'

const HeroVideo = ({ src, ...restProps }: { src: VideoProps['sourceProps']['src'] } & BoxProps) => (
  <Box
    component={Video}
    sourceProps={{ src }}
    autoPlay
    loop
    fill
    sx={{ zIndex: 1, width: 'calc(100% + 4px) !important', opacity: 0.24 }}
    {...restProps}
  />
)

const LogoImage = () => (
  <Box
    component={Image}
    src={PNG_ICONS.bgLogoImg}
    alt="Logo"
    fill
    // priority
    sx={{
      inset: 'unset !important',
      maxWidth: '1277px !important',
      height: '302px !important',
      left: '9px !important',
      bottom: '0px !important',
      zIndex: 4,
    }}
  />
)

const ShadowRectangle = () => (
  <Box
    sx={(theme) => ({
      background: theme.palette.background.gameBanner,
      transform: 'matrix(-1, 0, 0, 1, 0, 0)',
      position: 'absolute',
      top: 0,
      height: '666px',
      maxWidth: '1410px',
      width: '100%',
      zIndex: 2,
    })}
  />
)

const ShadowRectangle2 = () => (
  <Box
    sx={(theme) => ({
      background: theme.palette.background.gameBanner2,
      position: 'absolute',
      top: 0,
      right: 0,
      height: '666px',
      maxWidth: '602px',
      width: '100%',
      zIndex: 3,
    })}
  />
)

type HeroWrapperProps = {
  src: VideoProps['sourceProps']['src']
  children: React.ReactNode
}

export default function HeroImageWrapper({ src, children }: HeroWrapperProps) {
  const theme = useTheme()
  const isSmView = useMediaQuery(theme.breakpoints.down('md834'))
  const isMdView = useMediaQuery(theme.breakpoints.down('md'))

  if (isSmView) {
    return (
      <Box
        bgcolor="gray.900"
        borderTop="1px solid"
        borderBottom="1px solid"
        borderColor="gray.700"
        position="relative"
        overflow="hidden"
        minHeight="519px"
        sx={{
          [theme.breakpoints.down('md')]: {
            mx: '-28px',
            px: '28px',
            pb: '27px',
          },
          [theme.breakpoints.down('sm')]: {
            mx: '-16px',
            px: '16px',
            pb: '21px',
          },
        }}
      >
        <HeroVideo src={src} sx={{ opacity: 0.12, minHeight: '660px', zIndex: 1 }} />
        <SvgIcon
          name="mobile-hero-logo"
          sx={{
            position: 'absolute',
            left: -3,
            bottom: 0,
            width: 592,
            height: 165,
            opacity: 0.05,
            zIndex: 2,
          }}
        />
        <Box
          py="23px"
          px={{ xs: '16px', sm: '28px' }}
          width="100%"
          height="100%"
          position="absolute"
          top={0}
          left={0}
          zIndex={3}
        >
          {children}
        </Box>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        minHeight: { xs: '547px', md: '600px' },
        mx: '8px',
        background: theme.palette.gray[900],
        border: `1px solid ${theme.palette.gray[700]}`,
        [theme.breakpoints.down('lg4')]: {
          mx: '-20px',
        },
        [theme.breakpoints.down('md')]: {
          mx: '-28px',
          borderLeft: 'none',
          borderRight: 'none',
        },
        [theme.breakpoints.down('sm')]: {
          mx: '-16px',
        },
      }}
    >
      <HeroVideo src={src} />
      {isMdView ? (
        <SvgIcon
          name="mobile-hero-logo"
          sx={{
            position: 'absolute',
            left: 0,
            bottom: 2,
            width: '100%',
            height: 'auto',
            opacity: 0.05,
            zIndex: 3,
          }}
        />
      ) : (
        <LogoImage />
      )}
      <ShadowRectangle />
      <ShadowRectangle2 />
      <Container
        maxWidth="lg2"
        disableGutters
        sx={{
          position: 'relative',
          zIndex: 5,
          [theme.breakpoints.down('lg3')]: {
            px: '18px',
          },
          [theme.breakpoints.down('md')]: {
            px: '28px',
          },
        }}
      >
        {children}
      </Container>
    </Box>
  )
}

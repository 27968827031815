import React, { useCallback } from 'react'
import { Box, BoxProps } from '@mui/material'
import { useTheme } from '@mui/material/styles'

export type WinnerBoxProps = BoxProps & {
  place?: number
}

export default function WinnerBox({ place, children, sx, ...restRootBoxProps }: WinnerBoxProps) {
  const theme = useTheme()

  const getBackgroundColor = useCallback(() => {
    switch (place) {
      case 1:
        return theme.palette.background.winner1stPlace
      case 2:
        return theme.palette.background.winner2ndPlace
      case 3:
        return theme.palette.background.winner3rdPlace
      default:
        return theme.palette.gray[800]
    }
  }, [place, theme.palette.background, theme.palette.gray])

  return (
    <Box p="7px" sx={{ background: getBackgroundColor(), ...sx }} {...restRootBoxProps}>
      {children}
    </Box>
  )
}

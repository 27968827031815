import { createSelector } from '@reduxjs/toolkit';
import { TopListType } from 'src/helpers/types';
import { RootState } from 'src/store/configureStore'; // Adjust the path as necessary

const selectLeaderboardData = (state: RootState): TopListType[] | undefined => state.apiLeaderboard.queries['fetchLeaderboard(undefined)']?.data as TopListType[] | undefined;

export const selectFilteredLeaderboard = createSelector(
  [selectLeaderboardData, (state: RootState, gameId: string, gameMode: string | undefined) => ({ gameId, gameMode })],
  (leaderboardData, { gameId, gameMode }) => {
    if (!leaderboardData) return [];
    return leaderboardData.filter((x: any) => 
      x.intGameId === gameId && (gameMode === undefined || x.gameMode === gameMode)
    );
  }
);
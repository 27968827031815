import React, {useEffect} from 'react';
import { Box } from '@mui/material'
import { Outlet, useLocation } from "react-router-dom";
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { InfoBar } from './InfoBar';
import { logout, updateBalance } from "../actions/userActions";
import { subscribeToChallenges } from "../actions/challengeActions";
import { ActiveChallenge } from '../components/ActiveChallenge/ActiveChallenge';
import Header from 'src/components/Header/Header'
import Footer from 'src/components/Footer/Footer'
import PageCorner from 'src/components/PageCorner/PageCorner'
import ctx from 'src/context/context'
import { useAppSelector, useAppDispatch } from 'src/hooks'
import { WaitDialog } from 'src/components/WaitDialog/WaitDialog';
import { useConnectionStatus, useSwitchChain, useWallet, useDisconnect, useChainId, useSigner } from '@thirdweb-dev/react';
import SignerManager from 'src/utils/signerManager';
import { config } from 'src/config/config';
import { showErrorToast } from 'src/components/Toast/Toasts';
import { parseMetamaskError } from 'src/utils/errorParser';
import { ROUTE_PATH } from 'src/routes';
import { hideChangeChainPanel, showChangeChainPanel } from 'src/actions/infoPanelActions';
import * as Sentry from '@sentry/browser';
import { resetProfileApiState } from 'src/api/profileApi';
import { resetLeaderboardApiState } from 'src/api/leaderboardApi';
import { resetStakingApiState } from 'src/api/stakingApi';

export default function MainLayout() {
    const theme = useTheme()
    const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))

    const dispatch =  useAppDispatch();
    const activeWallet = useWallet();
    const connectionStatus = useConnectionStatus();    
    const chainId = useChainId();
    const signer = useSigner();
    const disconnect = useDisconnect();    
    const switchChain = useSwitchChain();
    const auth = ctx.useAuth();
    const blockchain = ctx.useBlockchain();
    const router = useLocation();
    const path = router.pathname;
    const currentPageIsLobby = path.startsWith('/lobby');
    const currentPageIsTournament = path.startsWith('/tournaments/') && !/\/signup\/?$/.test(path) && !path.includes('/signup?'); //exlude signup page we want to connect signalr for that page
    const currentPageIsRoot = path==='/';
    const isStakingPage = location.pathname===ROUTE_PATH.staking;
    const configuredChainId = isStakingPage ? config.blockchainStaking.chainIdNumber : config.blockchain.chainIdNumber;

    const isLoggedIn = useAppSelector(state => state.userReducer.loggedIn);
    const user = useAppSelector(state => state.userReducer.userInfo);


    useEffect(() => {
        auth.setWalletConnected(isLoggedIn);
        auth.setUser(user);
        Sentry.setUser({ id: user.id, email: user.email, username: user.nickname || user.username});
    }, [isLoggedIn,user]);

    useEffect(() => { 
        if(connectionStatus === 'disconnected' && isLoggedIn){           
            dispatch(logout());
            dispatch(resetProfileApiState());
            dispatch(resetLeaderboardApiState());
            dispatch(resetStakingApiState());
            Sentry.setUser(null);
        }
    }, [connectionStatus,isLoggedIn]);  

    //Can not use useNetworkMismatch from ThirdWeb since it will return false if chainId is undefined, which is not correct
    useEffect(() => {
        if(!chainId)
            blockchain.setNetworkMismatch(undefined);  
        else{
            //Different chain used for Staking
            blockchain.setNetworkMismatch(chainId !== configuredChainId);
        }
        
    }, [chainId,isStakingPage]);
    
    useEffect(() => {
        SignerManager.signer = signer;
    }, [signer]);

    useEffect(() => {
        const walletConnected = async () => {
            try{
                console.log('Wallet connected, setting signer...');
                if(activeWallet){
                    SignerManager.signer = await activeWallet.getSigner();
                    blockchain.setWalletConnected(true);
                }
                else{
                    blockchain.setWalletConnected(false);
                }
            }catch (error) {
                const parsedEthersError = parseMetamaskError(error);
                showErrorToast('Error',parsedEthersError, { toastId: 'error' })
                return;
            }
        };
        walletConnected();    
    }, [activeWallet]);


    //For reload, if we reload on lobby page we will do another connect at the samt time when subscribing to lobby, do not want that!
    useEffect(() => {
        if(!currentPageIsLobby && !currentPageIsTournament){ 
            dispatch(subscribeToChallenges());
        }
    }, [currentPageIsLobby,currentPageIsTournament])

    useEffect(() => {
        const updateMismatchAsync = async () => {
            if( isLoggedIn && blockchain.isWalletConnected && chainId !== configuredChainId) {
                try {
                    if(chainId===undefined){
                        return;
                    }  
                    dispatch(showChangeChainPanel(configuredChainId,`Please switch to ${isStakingPage ? config.blockchainStaking.chainDisplayName : config.blockchain.chainDisplayName}`));
                    /*
                    const walletId= activeWallet?.walletId;
                    if(walletId==='embeddedWallet'){
                        //add delay, to avoid error 'Chain not configured'
                        await new Promise(resolve => setTimeout(resolve, 100));
                        await switchChain(configuredChainId);
                     
                    }
                    else{    
                        showErrorToast('Network mismatch',`Please switch to ${isStakingPage ? config.blockchainStaking.chainDisplayName : config.blockchain.chainDisplayName}`, { toastId: 'error' })
                    }*/


                } catch (error) {
                    const parsedEthersError = parseMetamaskError(error);
                    showErrorToast('Error',parsedEthersError, { toastId: 'error' })
                    return;
                }            
            }
            else{
                dispatch(hideChangeChainPanel());
            }
        }; 
       updateMismatchAsync();    
    }, [isLoggedIn,blockchain.isWalletConnected,chainId,isStakingPage]);

    useEffect(() => {
        if(!currentPageIsRoot && isLoggedIn && blockchain.isWalletConnected && chainId === configuredChainId){
            console.log('Updating balance in mainlayout...'); 
            dispatch(updateBalance());
        }
    }, [currentPageIsRoot,isLoggedIn,blockchain.isWalletConnected,chainId]);

    const logoutHandler = async () => {
        dispatch(logout())
        Sentry.setUser(null);
        await disconnect();
    };

    return (
            <Box
                sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                position: 'relative',
                }}
	        >
                <Header chainId={configuredChainId} isWalletConnected={auth.isWalletConnected} user={user} logout={logoutHandler} />
                <InfoBar />                    
                <Box sx={{ display: 'flex', flexGrow: 1 }}>
                    <Box flexGrow={1} px={{ xs: '16px', sm: '28px', lg4: '0' }}>
                        <Outlet />
                    </Box>
                </Box>
                <ActiveChallenge/>                
                <Footer />
                <WaitDialog/>
	            {!isMobileView && (
                <>
                    <PageCorner
                        position="topLeft"
                        width={220}
                        height={244}
                        dot={true}
                        fullWidth={currentPageIsLobby}
                    />
                    <PageCorner position="topRight" />
                    <PageCorner
                        position="bottomRight"
                        width={220}
                        height={418}
                        dot={true}
                        fullWidth={currentPageIsLobby}
                    />
                </>
      	        )}
            </Box>
        );
}

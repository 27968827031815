import { PNG_ICONS } from "src/assets/icons/icons";
import { TournamentIconTextPairProps } from "src/components/TournamentIconTextPair/TournamentIconTextPair";
import { ActiveChallengeType, BracketType, ChallengeStatus, CustomBracketType,  MatchType, ParticipantStatus, RoundType, StartType, TokenType, TournamentStatus, TournamentType, UserType } from "./types";
import { translateGameMode, translateGameType, translateTournamentType } from "src/utils/translate";
import { getTimeFromSeconds, localeDateFormat } from "src/utils/datetime";
import { formatPrizePoolFrom, formatPrizePoolWithMax, getProfilePicture, getTokenImgSrc } from "./commonData";
import { GamingEventDetails, GamingEventDetailsOptProps } from "src/components/GamingEventDetailsOpt/GamingEventDetailsOpt";
import { EventInfo } from "src/components/EventInfoOpt/EventInfoOpt";
import ImageWithTitleWrapper from "src/components/ImageWithTitleWrapper/ImageWithTitleWrapper";
import TournamentPrizesRow from "src/components/TournamentPrizesRow/TournamentPrizesRow";
import { TOURNAMENT_BRACKET_STATUSES } from "src/components/TournamentBracket/TournamentBracket";
import { FILTER } from "src/components/TournamentBraketsFilter/TournamentBraketsFilter";
import { TournamentWinnersBoxT } from "src/components/TournamentWinnersWrapper/TournamentWinnersWrapper";
import { GameId } from "src/utils/const";
import { formatTokenToString, formatTokenToStringWithSymbol } from "src/utils/tokenUtil";

const numberofPrizesToShow = 3;

export function mapOnTimeData(onTimeChallenges : TournamentType[]) {
    if(!onTimeChallenges) return [];
    
    return onTimeChallenges.map((onTimeChallenge) => (     
        {
            bodyImgSrc: PNG_ICONS.tournamentBanner,
            title: getBannerTitle(onTimeChallenge),
            subTitle: onTimeChallenge.isTournamentBase ? mapOnTimeTournamentSubtitle(onTimeChallenge.error,onTimeChallenge.tournamentStatus) : mapOnTimeChallengeSubtitle(onTimeChallenge.error,onTimeChallenge.status),
            bgText: onTimeChallenge.isTournamentBase ? 'Tournament' : 'Challenge',
            buttonText: 'See Details',
            data: [
                {
                  title: 'Players joined',
                  value:  `${onTimeChallenge.numberOfParticipants} / ${onTimeChallenge.maxParticipans} (min ${onTimeChallenge.minParticipans})`,
                },
                {
                  icon: 'flag-2-line',
                  title: 'Start date',
                  value: localeDateFormat(onTimeChallenge.startTime),
                },
                {
                  icon: 'price-tag-3-line',
                  title: 'Entry fee',
                  value: formatTokenToStringWithSymbol(onTimeChallenge.fee,onTimeChallenge.tokenType),
                },
                {
                  icon: 'trophy-line',
                  title: `${onTimeChallenge.prices.length} prizes`,
                  value: formatPrizePoolWithMax(onTimeChallenge.prices,onTimeChallenge.tokenType,numberofPrizesToShow) ,
                  otherPrizes: numberofPrizesToShow < onTimeChallenge.prices.length? {
                    value: `+${onTimeChallenge.prices.length - numberofPrizesToShow}`,
                    tooltipTitle: 'Additional Prizes',
                    tooltipText: formatPrizePoolFrom(onTimeChallenge.prices,onTimeChallenge.tokenType,numberofPrizesToShow),
                  }:undefined,
                  prizeIconSrc:getTokenImgSrc(onTimeChallenge.payoutTokenType)    
                },
              ] as TournamentIconTextPairProps[],
        }
      ));
    }

 function getBannerTitle(onTimeChallenge : TournamentType) {
    
      return `${onTimeChallenge.name}. ${onTimeChallenge.description}`;
 }

 export function mapEventDetailsOpt(tournament : TournamentType) {

    const gamingEventDetailsOpt = [
        {
          event: GamingEventDetails.PlayersJoin,
          value: [tournament?.numberOfParticipants, tournament?.maxParticipans, tournament?.minParticipans],
          props: {           
            minPlayersTypographyProps: {
              variant: 'bodySmall',
              ml: '5px',
            },           
          },
        },
        {
          event: GamingEventDetails.GameMode,
          value: `${translateGameType(tournament?.gameType)} ${translateGameMode(tournament?.gameMode)}`,
        },
        {
          event: GamingEventDetails.Game,
          value: tournament?.gameName
        },
        {
          event: GamingEventDetails.TournamentType,
          value: translateTournamentType(tournament?.tournamentType)
        },
      ]

      return gamingEventDetailsOpt;
}

export function mapEventDetailsOpt2(tournament : TournamentType) {
    const gamingEventDetailsOpt = [
      {
        event: GamingEventDetails.PlayersJoin,
        value: [tournament?.numberOfParticipants, tournament?.maxParticipans, tournament?.minParticipans],
        props: {
          currentPlayersTypographyProps: {
            variant: 'h3',
            color: 'primary.light',
          },
          maxPlayersTypographyProps: {
            variant: 'h6',
            color: 'gray.0',
            ml: '8px',
          },
          minPlayersTypographyProps: {
            variant: 'bodySmall',
            ml: '18px',
          },
          showDot: true,
          dotProps: {
            right: '-12px',
            bottom: '-1px',
          },
        },
      },
      {
        event: GamingEventDetails.GameMode,
        value: `${translateGameType(tournament?.gameType)} ${translateGameMode(tournament?.gameMode)}`,
      },
      {
        event: GamingEventDetails.Game,
        value: tournament?.gameName
      },
      {
        event: GamingEventDetails.TournamentType,
        value: translateTournamentType(tournament?.tournamentType),
      },
    ]
    return gamingEventDetailsOpt;
}

export function mapEventInfoOpt(tournament : TournamentType) {
    
    const eventInfoOpt = [
        {
          event: EventInfo.Date,
          label: 'Start time',
          icon: 'flag-2-line',
          value: new Date(tournament?.startTime as Date),
        },
        {
          event: EventInfo.Date2,
          label: 'Approximate end time',
          icon: 'flag-2-line',
          value: new Date(tournament?.tournamentFinishes as Date),
        },
        {
          event: EventInfo.Fee,
          label: 'Entry fee',
          icon: 'price-tag-3-line',
          value: formatTokenToStringWithSymbol(tournament?.fee,tournament?.tokenType),
        },
        {
          event: EventInfo.Prizes,
          label: `${tournament?.prices.length} prizes`,
          icon: 'trophy-line',
          value: {
            prizes: tournament?.prices? formatPrizePoolWithMax(tournament?.prices,tournament.tokenType, numberofPrizesToShow) : undefined,
            otherPrizes: numberofPrizesToShow < tournament?.prices.length? {
              value: `+${tournament?.prices.length - numberofPrizesToShow}`,
              tooltipTitle: 'Additional Prizes',
              tooltipText: formatPrizePoolFrom(tournament?.prices, tournament.tokenType, numberofPrizesToShow),
            } : undefined,
            prizeIconSrc:getTokenImgSrc(tournament?.payoutTokenType)    
          },
        },
      ]
      return eventInfoOpt;
}

export function mapEventInfoOpt2(challenge : TournamentType)  {
    const eventInfoOpt = [
      {
        event: challenge.startType === StartType.ON_TIME ? EventInfo.Date : EventInfo.Text,
        label: 'Start time',
        icon: 'flag-2-line',
        value:  challenge.startType === StartType.ON_TIME ? new Date(challenge?.startTime as Date) : 'When full',
      },
      {
        event: challenge.startType === StartType.ON_TIME ? EventInfo.Date2 : EventInfo.Text2,
        label: challenge.startType === StartType.ON_TIME ? 'Approximate end time' : 'Approximate running time',
        icon: 'flag-2-line',
        value: challenge.startType === StartType.ON_TIME ? new Date(challenge?.tournamentFinishes as Date) : getTimeFromSeconds(challenge.runningTime),
      },
      {
        event: EventInfo.Prizes,
        label: `${challenge?.prices.length} prizes`,
        icon: 'trophy-line',
        value: {
          prizes: challenge?.prices? formatPrizePoolWithMax(challenge?.prices,challenge.tokenType,numberofPrizesToShow) : undefined,
          otherPrizes: numberofPrizesToShow < challenge?.prices.length? {
            value: `+${challenge?.prices.length - numberofPrizesToShow}`,
            tooltipTitle: 'Additional Prizes',
            tooltipText: formatPrizePoolFrom(challenge?.prices,challenge.tokenType,numberofPrizesToShow),
          } : undefined,
          prizeIconSrc:getTokenImgSrc(challenge.payoutTokenType)    
        },
      },
    ]
    return eventInfoOpt;
}

export function mapTournamentModalMiniTable(tournament : TournamentType) {
    const tournamentModalMiniTable = [
        { title: 'Max players', value: tournament.maxParticipans+'' },
        { title: 'Min players', value: tournament.minParticipans ? tournament.minParticipans+'' : tournament.maxParticipans+'' },
        {
          title: 'Game',
          value: () => (
            <ImageWithTitleWrapper
              variant="small"
              icon={tournament.intGameId===GameId.FRONTLAND ? PNG_ICONS.frontland : PNG_ICONS.fortnite}
              text={tournament.gameName === undefined ? (tournament.intGameId===GameId.FRONTLAND ? 'Frontland' : 'Fortnite') : tournament.gameName}
              TypographyProps={{
                noWrap: false,
                sx: {
                  width: 'auto !important',
                },
              }}
            />
          ),
        },
        { title: 'Game mode', value: translateGameType(tournament.gameType)},
        { title: 'Map', value: translateGameMode(tournament.gameMode)},
        { title: 'Tournament type', value: translateTournamentType(tournament.tournamentType) },
        { title: tournament.startTime!== undefined ? 'Start date': 'Starts', value: tournament.startTime!== undefined ?localeDateFormat(tournament.startTime) : 'When full' },
        {
          title: 'Prize pool',
          value: () => (
            <TournamentPrizesRow
              showCoin={true}
              variant="smallText"
              prizes={formatPrizePoolWithMax(tournament.prices,tournament.payoutTokenType, numberofPrizesToShow)}
              otherPrizes={numberofPrizesToShow < tournament?.prices.length? {
                value: `+${tournament?.prices.length - numberofPrizesToShow}`,
                tooltipTitle: 'Additional Prizes',
                tooltipText: formatPrizePoolFrom(tournament?.prices,tournament.payoutTokenType,numberofPrizesToShow),
              } : undefined}
              prizeIconSrc={getTokenImgSrc(tournament.payoutTokenType)}
              StackProps={{ sx: { transform: 'translateY(-2px)' } }}
            />
          ),
        },
      ]
      return tournamentModalMiniTable;
}

export function mapTableTournament(activeChallenge : ActiveChallengeType, user : UserType) {
   const tableTournament = {
    currentUserId: user ? user.id+'': '0',
    data: {
      headRow: {
        cell: [{ value: 'Player' }, { value: 'Status' }],
      },
      bodyRow: activeChallenge.participants.map((participant) => {
        return {
          cell: [
            { value: participant.nickname  ? participant.nickname : 'Player', src: getProfilePicture(participant.profilePictureUrl) },
            { value: 'Joined' },
          ],
          userId: participant.userId+'',
        }       
      })
    },
  }
 
  if(activeChallenge.status !== ChallengeStatus.CANCELLED){
    //Add spot/s for empty    
    // @ts-ignore
    tableTournament.data.bodyRow = [...tableTournament.data.bodyRow, ...(Array.apply(null,  Array(activeChallenge.maxParticipans-activeChallenge.numberOfParticipants)).map((e, i) => (
      { cell: null }
    )))];
  }

  return tableTournament;
}

export function mapTournamentTopDetailsInfo(tournament : TournamentType) : GamingEventDetailsOptProps[]{
  const detailInfo = [
    {
      event: GamingEventDetails.PlayersJoin,
      label: 'Players left',
      value: [(tournament.tournamentStatus===TournamentStatus.NOT_STARTED || tournament.tournamentStatus===TournamentStatus.READY_TO_START) ? tournament.numberOfParticipants : tournament.playersLeft, tournament.numberOfParticipants],
    },
    {
      event: GamingEventDetails.TournamentType,
      label: 'Tournament type',
      value: translateTournamentType(tournament.tournamentType),
    },
    {
      event: GamingEventDetails.Prizes,
      label: 'Prizes',
      value: {
        prizes: tournament?.prices? formatPrizePoolWithMax(tournament?.prices,tournament.tokenType,numberofPrizesToShow) : undefined,
        otherPrizes: numberofPrizesToShow < tournament?.prices.length? {
            value: `+${tournament?.prices.length - numberofPrizesToShow}`,
            tooltipTitle: 'Additional Prizes',
            tooltipText: formatPrizePoolFrom(tournament?.prices,tournament.tokenType,numberofPrizesToShow),
        } : undefined,
        prizeIconSrc:getTokenImgSrc(tournament.payoutTokenType)        
      },
    },
    {
      event: GamingEventDetails.Game,
      label: 'Game',
      value: tournament.gameName,
    },
    {
      event: GamingEventDetails.GameMode,
      label: 'Game mode',
      value:  translateGameType(tournament?.gameType),
    },
    {
      event: GamingEventDetails.Date,
      label: 'Approximate end time',
      value: tournament?.tournamentFinishes,
    },
  ]
  return detailInfo;
}

export function mapTournamentTopDetailsUserStats(tournament : TournamentType, playerId: number|undefined) : GamingEventDetailsOptProps[]{
   const userStats =  [
      {
        event: GamingEventDetails.GameMode,
        label: 'Played games',
        value: playerId!= undefined ? calculateNumberOfPlayedMatches(playerId,tournament.brackets)+'' : '0',
      },
      {
        event: GamingEventDetails.GameMode,
        label: 'Kills total',
        value: playerId!= undefined ? calculateTotalKills(playerId,tournament.brackets)+'' : '0',
      }    
    ]
    return userStats;
}

export function mapBrackets(brackets: BracketType[]) : CustomBracketType{
   
    if(brackets===undefined)
      return {
        rounds: [],
        thirdPlaceMatch: undefined
      }; 
    
    let thirdPlaceMatch: MatchType|undefined;

    const rounds = brackets.map((bracket,index) => {
      thirdPlaceMatch = bracket.matches.find(match => match.isThirdPlaceMatch);
      return {
        title: mapTitle(bracket.roundType,index),
        filter: mapFilter(bracket.roundType),
        seeds: bracket.matches.filter(match=>!match.isThirdPlaceMatch).map((match) => {
          return mapMatchData(match);
        }),
      }
    })

    return { 
          rounds: rounds, 
          thirdPlaceMatch: thirdPlaceMatch ? mapMatchData(thirdPlaceMatch) : undefined
    };
}

function mapMatchData(match: MatchType) {
  const playerCount=2;
  return {
      id: match.id,
      status: match.status,
      error: match.error,
      teams: [...match.players.map((player,index) => {
        return {
          id: player.id,
          name: player.nickname,
          status: mapPlayerStatus(player.status,match.status,player.placement),
          avatar: getProfilePicture(player.profilePictureUrl),
          resultText: player.score!==undefined ? player.score+'' : undefined,
          error: match.error || player.error,
        }
      }), 
        ...(match.players.length>0 ? Array.apply(null,  Array(playerCount-match.players.length)).map((e, i) => (
          { status: TOURNAMENT_BRACKET_STATUSES.EMPTY }
        )) : Array.apply(null,  Array(2)).map((e, i) => (
          { status: TOURNAMENT_BRACKET_STATUSES.TBD }
        )) )
    ],
  }
}


function calculateTotalKills(playerId:number, brackets: BracketType[]) {
  let totalScore = 0;
  if(brackets===undefined)
    return totalScore;
  for (let i = 0; i < brackets.length; i++) {
    const matches = brackets[i].matches;
    for (let j = 0; j < matches.length; j++) {
        const players = matches[j].players;
        for (let k = 0; k < players.length; k++) {
            if (players[k].id === playerId) {
                totalScore += players[k].score || 0;
            }
        }
    }
  }
  return totalScore;
}

function calculateNumberOfPlayedMatches(playerId:number, brackets: BracketType[]) {
  let numberOfPlayedMatches = 0;
  if(brackets===undefined)
    return numberOfPlayedMatches;
  for (let i = 0; i < brackets.length; i++) {
    const matches = brackets[i].matches;
    for (let j = 0; j < matches.length; j++) {
        const players = matches[j].players;
        for (let k = 0; k < players.length; k++) {
            if (players[k].id === playerId && players[k].status === ParticipantStatus.DONE) {
              numberOfPlayedMatches++;
            }
        }
    }
  }
  return numberOfPlayedMatches;
}


export function mapWinners(tournament : TournamentType){
 
  //Take all participants where placement has value then filter top 3 participants into one array, participants needs to be sorted by placement
  const winners = tournament.participants.filter((participant) => participant.payout !== undefined).slice(0,3);
  const mappedWinners = winners.map((winner,index) => {
      return {
        place: index+1,
        name: winner.nickname,
        amount: `+${formatTokenToString(winner.payout,tournament.payoutTokenType)}`,
        tokenImgSrc: getTokenImgSrc(tournament.payoutTokenType),
        avatar: getProfilePicture(winner.profilePictureUrl),
        bgText: index === 0 ? '1-ST' : index === 1 ? '2-ND' : '3-RD',
      }
    }
  );

  const others = tournament.participants.filter((participant) => participant.payout !== undefined).slice(3);
  const mappedOthers = others.map((other,index) => {
    return {
      place: 2,
      name: other.nickname,
      amount: `+${formatTokenToString(other.payout,tournament.payoutTokenType)}`,
      tokenImgSrc: getTokenImgSrc(tournament.payoutTokenType),
      avatar: getProfilePicture(other.profilePictureUrl)
    }
  });

  return {
    winners: mappedWinners as TournamentWinnersBoxT[],
    otherPrizes: mappedOthers as TournamentWinnersBoxT[]
  };
}
  
   
function mapPlayerStatus(status:ParticipantStatus, challengeStatus: ChallengeStatus, placement?: number) : TOURNAMENT_BRACKET_STATUSES {
  if(challengeStatus === ChallengeStatus.CANCELLED){
    return TOURNAMENT_BRACKET_STATUSES.CANCELLED;
  }
  switch(status){
    case ParticipantStatus.NOT_PARTICIPATING:
      return TOURNAMENT_BRACKET_STATUSES.TBD;
    case ParticipantStatus.JOINED:
      if(challengeStatus === ChallengeStatus.RUNNING)
          return TOURNAMENT_BRACKET_STATUSES.PLAYING;
      return TOURNAMENT_BRACKET_STATUSES.WAITING;
    case ParticipantStatus.PLAYING:
      return TOURNAMENT_BRACKET_STATUSES.PLAYING;
    case ParticipantStatus.DONE:
      if(placement === 1){
      return TOURNAMENT_BRACKET_STATUSES.WIN;
      }else{
        return TOURNAMENT_BRACKET_STATUSES.LOSE;
      }
    case ParticipantStatus.EMPTY:
      return TOURNAMENT_BRACKET_STATUSES.EMPTY;
    default:
      return TOURNAMENT_BRACKET_STATUSES.TBD;
  }
}

// Returns index or title for round
function mapTitle(roundType:RoundType, index: number) : string {
  switch(roundType){
    case RoundType.QUALIFIER:
      return index+'';
    case RoundType.QUARTER_FINAL:
      return 'Quarter Final';
    case RoundType.SEMI_FINAL:
      return 'Semi Final';
    case RoundType.FINAL:
      return 'Final';
    default:
      return index+'';
  }
}

function mapFilter(roundType:RoundType) : FILTER|undefined {
  switch(roundType){
    case RoundType.QUALIFIER:
      return undefined;
    case RoundType.QUARTER_FINAL:
      return FILTER.QUARTER_FINAL;
    case RoundType.SEMI_FINAL:
      return FILTER.SEMI_FINAL;
    case RoundType.FINAL:
      return FILTER.FINAL;
    default:
      return undefined;
  }
}

function mapOnTimeTournamentSubtitle(hasError:boolean|undefined, status:TournamentStatus) : string {
  if(hasError){
    return 'Tournament Terminated';
  }
  switch(status){
    case TournamentStatus.NOT_STARTED:
      return 'Sign up for Tournament';
    case TournamentStatus.READY_TO_START:
      return 'Tournament is Ready to start';
    case TournamentStatus.WAITING_FOR_NEXT_ROUND:
        return 'Waiting for next round';
    case TournamentStatus.RUNNING:
      return 'Tournament Running';
    case TournamentStatus.FINAL_DONE:
      return 'Tournament Completed!';
    case TournamentStatus.CANCELLED:
      return 'Tournament Canceled';
    default:
      return '';
  }
}


function mapOnTimeChallengeSubtitle(hasError:boolean|undefined, status:ChallengeStatus) : string {
  if(hasError){
    return 'Challenge Terminated';
  }
  switch(status){
    case ChallengeStatus.NOT_STARTED:
      return 'Sign up for Challenge';
    case ChallengeStatus.READY_TO_START:
      return 'Challenge is Ready to start';
    case ChallengeStatus.PRE_COOLDOWN_DONE:
      return 'Challenge Running';
    case ChallengeStatus.PLAY_TIME_OVER:
        return 'Challenge Running';
    case ChallengeStatus.RUNNING:
      return 'Challenge Running';
    case ChallengeStatus.FINAL_DONE:
      return 'Challenge Completed!';
    case ChallengeStatus.CANCELLED:
      return 'Challenge Canceled';
    default:
      return '';
  }
}
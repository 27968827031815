import React from 'react'
import { Stack, Box } from '@mui/material'
import VerticalDividerWithContent, {
  VerticalDividerWithContentProps,
} from 'src/components/VerticalDividerWithContent/VerticalDividerWithContent'

type GameLocationsDividerProps = Pick<
  VerticalDividerWithContentProps,
  'fromRight' | 'dividerHeight'
> & {
  /** The array of game locations */
  dataSource: string[]
}
export default function GameLocationsDivider({
  fromRight = true,
  dividerHeight = '74px',
  dataSource,
}: GameLocationsDividerProps) {
  return (
    <VerticalDividerWithContent
      fromRight={fromRight}
      dividerHeight={dividerHeight}
      rootStackProps={{
        spacing: '20px',
        alignItems: 'flex-start',
      }}
      typographyProps={{
        minWidth: '54px',
        minHeight: '56px',
        textAlign: fromRight ? 'right' : 'left',
      }}
    >
      <Stack direction="column" minHeight="56px" justifyContent="space-between">
        <div>
          {dataSource?.length
            ? dataSource.map((location, index, arr) => (
                <Box key={location} mb={index !== arr.length - 1 ? '5px' : '0px'}>
                  {location}
                </Box>
              ))
            : null}
        </div>
        {dataSource?.length ? <div>...</div> : null}
      </Stack>
    </VerticalDividerWithContent>
  )
}

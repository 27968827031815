import { ActiveChallengeType, GameTemplateType, TournamentType } from "src/helpers/types";
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material'
import ModalStyled from "../ModalStyled/ModalStyled";
import ModalContentTournament from "../ModalContentTournament/ModalContentTournament";
import { useAppDispatch } from "src/hooks";
import { signup } from "src/actions/challengeActions";
import { ChallengeCardProps } from "../ChallengeCard/ChallengeCard";
import { ImageProps } from "../Image/Image";
import EnterChallengePopup, { EnterChallengePopupProps } from "../Popup/EnterChallengePopup/EnterChallengePopup";
import { useSwitchChain, useWallet } from "@thirdweb-dev/react";
import { useCallback } from "react";
import { config } from "src/config/config";
import { showErrorToast, showInfoToast } from "../Toast/Toasts";
import { getParsedEthersError } from "@enzoferey/ethers-error-parser";
import { parseMetamaskError } from "src/utils/errorParser";
import ctx from "src/context/context";
import SignerManager from "src/utils/signerManager";
import { formatTokenToStringWithSymbol } from "src/utils/tokenUtil";

type ModalSignupProps = {
    data: TournamentType | (ChallengeCardProps & {
        iconSrc?: ImageProps['src']
        tableData?: EnterChallengePopupProps['tableData']
        gameTemplate?: GameTemplateType
    })
    open: boolean
    setOpen?: (open: boolean) => void
    onCancel?: () => void
    handleJoin?: () => void
  }
  

export default function ModalSignup({data, open, setOpen, onCancel, handleJoin}: ModalSignupProps){
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const blockchain = ctx.useBlockchain();
    const switchChain = useSwitchChain();
    const activeWallet = useWallet();
    
    let tournament: TournamentType | undefined;
    let challengeCard: (ChallengeCardProps & {
        iconSrc?: ImageProps['src']
        tableData?: EnterChallengePopupProps['tableData']
        gameTemplate?: GameTemplateType
    }) | undefined;
    var dispatchFunc:any = undefined;

    if(isGameTemplate(data)){ //Will be true if gametemplate or start on time challenge
        challengeCard = data;
        const isTemplate = (challengeCard.gameTemplate as ActiveChallengeType).startType === undefined; 
        const id = (challengeCard.gameTemplate as ActiveChallengeType).startType === undefined ? challengeCard.gameTemplate?.id : (challengeCard.gameTemplate as ActiveChallengeType).blockchainChallengeId;
        dispatchFunc = ()=>dispatch(signup(id, challengeCard?.gameTemplate?.fee, challengeCard?.gameTemplate?.tokenType, isTemplate, false, challengeCard?.gameTemplate?.isTournament, undefined));
        if(challengeCard.gameTemplate?.isTournament){
            tournament = challengeCard.gameTemplate as unknown as TournamentType;            
        }
    }
    else{                      
        tournament = data;        
        dispatchFunc = () => dispatch(signup(tournament?.blockchainChallengeId,tournament?.fee,tournament?.tokenType,false,false,true,tournament?.tournamentId));
    }


    const callDispatchFunc = useCallback(
        async () => {
            if (activeWallet) {
                if(blockchain.isNetworkMismatch){
                    try{
                        await switchChain(config.blockchain.chainIdNumber);
                        SignerManager.signer = await activeWallet.getSigner();
                    }
                    catch(error:any){
                        const parsedEthersError = parseMetamaskError(error);
                        showErrorToast('Error',parsedEthersError, { toastId: 'error' })
                        return;
                    }
                }
                dispatchFunc();
            }
            else{
                showInfoToast('Info', 'Please wait until wallet is connected', { toastId: 'connect' })
            }
        },
        [activeWallet,dispatchFunc,blockchain.isNetworkMismatch]
      )

    return(
        <>
        {tournament !== undefined ? 
            <ModalStyled
                openModal={open}
                setOpenModal={setOpen}
                confirmEvent={()=>callDispatchFunc()}
                closeBtn={true}
                cancelBtn={true}
                confirmBtnSubText={formatTokenToStringWithSymbol(tournament?.fee,tournament?.tokenType)}
                buttonSize="medium"
                rootBoxProps={{
                sx: {
                    [theme.breakpoints.down('sm')]: {
                    width: '343px',
                    },
                },
                }}
            >
                <ModalContentTournament tournament={tournament} />
            </ModalStyled>
            :
            <EnterChallengePopup
                title="Enter Challenge"
                description={challengeCard?.description as string}
                fee={challengeCard ? challengeCard.btnProps.subLabel: ''}
                open={open}
                onCancel={onCancel || (() => {})}
                handleJoin={() => {
                    handleJoin && handleJoin();
                    callDispatchFunc();
                }
                }
                gameTitle={challengeCard?.title}
                gameIconSrc={challengeCard?.iconSrc}
                headerBackgroundSrc={challengeCard?.bgSrc}
                tableData={challengeCard?.tableData}
            />
        }
      </>
    )
}

function isGameTemplate(data: any): data is (ChallengeCardProps & {
    iconSrc?: ImageProps['src']
    tableData?: EnterChallengePopupProps['tableData']
    gameTemplate?: GameTemplateType
}) {
    
    const challengeCard = (data as (ChallengeCardProps & {
        iconSrc?: ImageProps['src']
        tableData?: EnterChallengePopupProps['tableData']
        gameTemplate?: GameTemplateType
    }));
    
    return challengeCard.gameTemplate !== undefined;
}


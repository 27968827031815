import React from 'react'
import { Grid, Box, Stack, useTheme } from '@mui/material'
import Comment from 'src/components/Comment/Comment'
import ChallengeCardButton, {
  ChallengeCardButtonProps,
} from 'src/components/ChallengeCardButton/ChallengeCardButton'
import GamingEventDetailsOpt, {
  GamingEventDetailsOptProps,
} from 'src/components/GamingEventDetailsOpt/GamingEventDetailsOpt'
import EventInfoOpt, { EventInfoOptProps } from 'src/components/EventInfoOpt/EventInfoOpt'
import BgLinesWrapper from 'src/components/BgLinesWrapper/BgLinesWrapper'

type TournamentDetailsCardProps = {
  gamingEventDetailsOpt: GamingEventDetailsOptProps[]
  eventInfoOpt: EventInfoOptProps[]
  ChallengeCardButtonProps: ChallengeCardButtonProps
}

export default function TournamentDetailsCard({
  ChallengeCardButtonProps,
  gamingEventDetailsOpt,
  eventInfoOpt,
}: TournamentDetailsCardProps) {
  const theme = useTheme()

  return (
    <Box maxWidth={{ xs: '100%', md: '688px' }} width="100%">
      <Comment>Details</Comment>
      <Box mt="2px" bgcolor="gray.900" border="1px solid" borderColor="gray.700">
        <Stack
          direction="column"
          gap="24px"
          m="1px"
          bgcolor="gray.800"
          p={{ xs: '14px', sm: '24px' }}
        >
          <Grid
            container
            rowSpacing={{ xs: '12px', sm: '28px' }}
            columnSpacing={{ xs: '28px' }}
            justifyContent={{ sm: 'space-between' }}
          >
            {gamingEventDetailsOpt.map((item) => (
              <Grid item key={item?.event} xs={6} sm="auto">
                <GamingEventDetailsOpt {...item} />
              </Grid>
            ))}
          </Grid>
          <Stack
            direction="row"
            gap="28px"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            bgcolor="gray.700"
            p={{ xs: '12px', sm: '16px' }}
            sx={{
              height: 'auto',
              [theme.breakpoints.down('xsm')]: {
                height: '332px',
              },
            }}
          >
            {eventInfoOpt.map((item) => (
              <EventInfoOpt key={item?.event} {...item} />
            ))}
          </Stack>
        </Stack>
        <BgLinesWrapper>
          <ChallengeCardButton fullWidth {...ChallengeCardButtonProps} />
        </BgLinesWrapper>
      </Box>
    </Box>
  )
}

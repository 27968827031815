import React from 'react'
import { Stack, StackProps, useMediaQuery, useTheme } from '@mui/material'
import Typography, { TypographyProps } from 'src/components/Typography/Typography'
import Loader from 'src/components/Loader/Loader'

type TitleWithLoaderProps = StackProps & {
  title?: string
  titleTypographyProps?: TypographyProps
  loading?: boolean
}

export default function TitleWithLoader({
  title,
  titleTypographyProps,
  loading,
  children,
  ...restRootStackProps
}: TitleWithLoaderProps) {
  const theme = useTheme()
  const isSm2View = useMediaQuery(theme.breakpoints.down('sm2'))
  return (
    <Stack
      direction="column"
      alignItems="center"
      {...restRootStackProps}
      spacing={{
        xs: '7px',
        sm: '24px',
        ...(typeof restRootStackProps?.spacing === 'object' ? restRootStackProps?.spacing : {}),
      }}
    >
      {title && !titleTypographyProps?.children && (
        <Typography
          variant="h5"
          align="center"
          color="gray.0"
          display="flex"
          justifyContent="center"
          alignItems="center"
          {...titleTypographyProps}
          sx={{
            [theme.breakpoints.down('md')]: {
              fontSize: '28px',
            },
            [theme.breakpoints.down('sm2')]: {
              fontSize: '20px',
            },
            ...titleTypographyProps?.sx,
          }}
        >
          {title}
        </Typography>
      )}
      {!title && titleTypographyProps?.children && titleTypographyProps?.children}
      {loading ? <Loader size={isSm2View ? 'small' : 'medium'} /> : children}
    </Stack>
  )
}

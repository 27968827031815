import { Box, SxProps, useTheme } from '@mui/material'
import React from 'react'
import SvgIcon from '../SvgIcon/SvgIcon'
import Typography from '../Typography/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

type AfterVotingBlockProps = {
  title: string
  subtitle?: string
  sx?: SxProps
}

export default function AfterVotingBlock({ title, subtitle, sx }: AfterVotingBlockProps) {
  const theme = useTheme()
  const isSm2View = useMediaQuery(theme.breakpoints.down('sm2'))
  return (
    <Box textAlign="center" maxWidth={358} sx={{ ...sx }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 48,
          height: 48,
          margin: '0 auto 25px',
          bgcolor: 'success.200',
          borderRadius: '24px',
        }}
      >
        <SvgIcon name="done-vote-circle" fontSize="small" color="success" />
      </Box>

      <Typography variant={isSm2View ? 'h6' : 'h5'} mb={{ xs: '24px', sm2: '25px' }}>
        {title}
      </Typography>

      {subtitle && <Typography variant="bodySmall">{subtitle}</Typography>}
    </Box>
  )
}

import React, { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material'
import assets from 'src/assets'
import TournamentPageMediaWrapper from 'src/components/TournamentPageMediaWrapper/TournamentPageMediaWrapper'
import PageContainer from 'src/components/PageContainer/PageContainer'
import ButtonIcon from 'src/components/ButtonIcon/ButtonIcon'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import Typography from 'src/components/Typography/Typography'
import TitleWithDot from 'src/components/TitleWithDot/TitleWithDot'
import Link from 'src/components/Link/Link'
import TournamentDetailsCard from 'src/components/TournamentDetailsCard/TournamentDetailsCard'
import GameTip from 'src/components/GameTip/GameTip'
import { useAppSelector } from 'src/hooks'
import { mapEventDetailsOpt, mapEventInfoOpt } from 'src/helpers/tournamentData'
import { TournamentStatus, TournamentType } from 'src/helpers/types'
import { getGameLink } from "src/selectors/gameLinkSelector";
import ctx from 'src/context/context'
import { getTimeBetweenDatesText } from 'src/utils/datetime'
import ModalSignup from 'src/components/ModalSignup/ModalSignup'
import { appendUrlPrefix, isTargetBlank } from 'src/helpers/commonData'
import { selectTournament } from 'src/selectors/tournamentSelector'
import { GameId } from 'src/utils/const'
import { formatTokenToStringWithSymbol } from 'src/utils/tokenUtil'




export default function TournamentSignup() {
  const theme = useTheme()
  const isLgView = useMediaQuery(theme.breakpoints.down('lg'))
  const isSmView = useMediaQuery(theme.breakpoints.down('sm'))
  const auth = ctx.useAuth();
  const { tournamentId }  = useParams<{ tournamentId: string }>();
  let numericId = tournamentId ? parseInt(tournamentId, 10) : 0;
  const [open, setOpen] = React.useState(false)

  const navigate = useNavigate()

  const selectItemById = useMemo(() => selectTournament, []);
  const currentTournament: TournamentType = useAppSelector(state => selectItemById(state, numericId));

  const gamingEventDetailsOpt = mapEventDetailsOpt(currentTournament);
  const eventInfoOpt = mapEventInfoOpt(currentTournament)

  
  let intGameId = currentTournament ? currentTournament.intGameId : GameId.FRONTLAND;
  var gameName='Frontland'; //This should be dynamic
  
  const linkPart = useAppSelector(state => getGameLink(state, intGameId));
  var gameLink = appendUrlPrefix(linkPart);
  const targetBlank = isTargetBlank(linkPart);

  //Check if currentTournament.description contains '${date}' then replace with 'getTimeBetweenDatesText'  
  let description = currentTournament?.tournamentDescription;
  if(currentTournament && currentTournament.tournamentDescription?.includes('${time}')){   
    const txt = getTimeBetweenDatesText(new Date(currentTournament?.startTime as Date), new Date(currentTournament?.tournamentFinishes))
    description = currentTournament.tournamentDescription.replace('${time}', txt);
  }

  return (
    <>
      <Box bgcolor="gray.900" position="relative">
        <TournamentPageMediaWrapper
          imageProps={{
            src: isSmView
              ? assets.png.mobileTournamentPageImg
              : isLgView
              ? assets.png.tabletTournamentPageImg
              : assets.png.tournamentPageImg,
          }}
          height="670px"
          leftShadowBoxProps={{
            height: { sm2: '670px' },
          }}
          rightShadowBoxProps={{
            display: 'none',
          }}
          bottomShadowBoxProps={{
            height: { xs: '126px' },
            sx: {
              [theme.breakpoints.down('sm2')]: {
                maxWidth: '100%',
              },
            },
          }}
        />
        <PageContainer
          sx={{
            pt: '17px',
            display: 'flex',
            [theme.breakpoints.down('sm2')]: {
              pt: '20px',
              position: 'relative',
              top: '8px',
            },
          }}
          innerContainerProps={{
            sx: { display: 'flex', flexDirection: 'column', position: 'relative' },
          }}
        >
          <Box position="absolute" top={{ xs: '0' }} left={0}>
            <ButtonIcon
              variant="outlined"
              size="small"
              onClick={() => {
                navigate(-1)
              }}
            >
              <SvgIcon name="arrow-left-line" />
            </ButtonIcon>
          </Box>
          <Stack
            mt={{ xs: 0, sm: '30px' }}
            mb={{ xs: '43px', sm: '36px', md: '18px' }}
            direction="column"
            alignItems="center"
          >
            <Typography
              variant="labelBig"
              color="primary.light"
              mb={{ xs: '16px', sm: '14px' }}
              display="inline-block"
              align="center"
              sx={{
                [theme.breakpoints.down('md')]: {
                  ...theme.typography.labelMedium,
                },
              }}
            >
              Show you skills
            </Typography>
            <TitleWithDot
              typographyProps={{
                variant: 'h2',
                align: 'center',
                sx: {
                  [theme.breakpoints.down('md')]: {
                    ...theme.typography.h3,
                  },
                },
              }}
              dotProps={{
                bottom: '16px',
                sx: {
                  [theme.breakpoints.down('md')]: { bottom: '8px' },
                  [theme.breakpoints.down('xsm')]: {
                    bottom: '12px',
                    left: '-20px',
                  },
                },
              }}
              rootStackProps={{
                spacing: { xs: 0, sm: '8.5px', md: '11px' },
                mb: '24px',
                sx: {
                  [theme.breakpoints.down('xsm')]: {
                    maxWidth: '268px',
                  },
                },
              }}
            >
              Sign up for Tournament {currentTournament?.tournamentName}
            </TitleWithDot>
            <Typography
              variant="bodyMedium"
              color="gray.25"
              align="center"
              sx={{
                mb: '8px',
                [theme.breakpoints.down('sm')]: {
                  ...theme.typography.bodySmall,
                },
              }}
            >
              {description}
            </Typography>
            <Link
              href="/"
              color="secondary"
              size="small"
              startIcon={<SvgIcon name="arrow-right-line" />}
            >
              Read more
            </Link>
          </Stack>
          <Box display="flex" justifyContent="center">
            {currentTournament  && currentTournament.tournamentStatus===TournamentStatus.NOT_STARTED? <TournamentDetailsCard
              ChallengeCardButtonProps={{
                label: 'Join tournament',
                subLabel: formatTokenToStringWithSymbol(currentTournament?.fee, currentTournament?.tokenType),
                onClick: () => auth.isWalletConnected ? setOpen(true) : undefined,
                disabled: currentTournament.tournamentStatus !== TournamentStatus.NOT_STARTED,
              }}
              gamingEventDetailsOpt={gamingEventDetailsOpt}
              eventInfoOpt={eventInfoOpt}
            /> :
            <Typography
              variant="bodyBig"
              color="gray.25"
              align="center"
              sx={{
                mb: '8px',
                [theme.breakpoints.down('sm')]: {
                  ...theme.typography.bodySmall,
                },
              }}
            >
              {currentTournament ? getTournamentStatusText(currentTournament.tournamentStatus) : 'No tournament is currently active'}
            </Typography>
            }
            
          </Box>
          <GameTip 
            StackProps={{ mt: '24px', mb: { xs: '48px', sm: '53px', md: '51px' } }} 
            text='If you don’t have the Frontland game yet, download it here'
            link={gameLink}
            disabled={gameLink==="" ? true : false}
            target={targetBlank ? '_blank' : undefined}
            />
        </PageContainer>
      </Box>
      {currentTournament &&
        <ModalSignup data={currentTournament} open={open} setOpen={setOpen} />
      }
    </>
  )
 
}

function getTournamentStatusText(status: TournamentStatus) {
  switch (status) {
    case TournamentStatus.CANCELLED:
      return 'Tournament has been cancelled'
    case TournamentStatus.FINAL_DONE:
      return 'Tournament has been finished, please login to view results!'
    default:
      return ''
  }
}
import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Stack, StackProps as MuiStackProps, Breakpoint, useMediaQuery } from '@mui/material'
import TotalTokens, { TotalTokensProps } from 'src/components/TotalTokens/TotalTokens'
import Button from 'src/components/Button/Button'
import ButtonIcon from 'src/components/ButtonIcon/ButtonIcon'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'

type TokenControlsProps = {
  /** [The root element of this component uses the StackProps from Material UI](https://mui.com/material-ui/react-stack/) */
  StackProps?: MuiStackProps
  /**
   * [Props applied to the TotalTokens element.](/?path=/docs/components-totaltokens--basic)
   * */
  TotalTokensProps: TotalTokensProps
  withdrawLabel?: string
  buyLabel?: string
  onWithdrawClick?: () => void
  onBuyClick?: () => void
  isWithDrawDisabled?: boolean
  isWithDrawVisibile?: boolean
  isBuyDisabled?: boolean
  isBuyVisible?: boolean
  isMobile?: boolean
  breakpoint?: number | Breakpoint
}

export default function TokenControls({
  StackProps,
  // eslint-disable-next-line
  TotalTokensProps,
  withdrawLabel="Swap",
  buyLabel="Buy tokens",
  onWithdrawClick,
  onBuyClick,
  isWithDrawDisabled=false,
  isWithDrawVisibile=true,
  isBuyDisabled=false,
  isBuyVisible=true,
  isMobile,
  breakpoint = 'md720',
}: TokenControlsProps) {
  const theme = useTheme()
  const isXsmView = useMediaQuery(theme.breakpoints.down('xsm'))

  return (
    <Stack
      direction="row"
      gap={{ xs: '12px', sm: '8px' }}
      flexWrap="wrap"
      justifyContent="space-between"
      alignItems="center"
      {...StackProps}
      sx={{
        bgcolor: 'gray.700',
        maxWidth: isMobile ? 'auto' : '577px',
        minHeight: '64px',
        p: '8px',
        [theme.breakpoints.down(breakpoint)]: {
          maxWidth: '100%',
          minHeight: '60px',
        },
        ...StackProps?.sx,
      }}
    >
      <TotalTokens {...TotalTokensProps} />
      <Stack direction="row" spacing="8px" flexGrow={!isMobile && isXsmView ? 1 : 0}>
        {isWithDrawVisibile && (
        <Button
          variant="outlined"
          size="small"
          startIcon={isMobile ? null : <SvgIcon name="share-forward-line" />}
          onClick={onWithdrawClick}
          disabled={isWithDrawDisabled}
          fullWidth={!isMobile && isXsmView}
        >
           {withdrawLabel}
        </Button>
        )}
  
        {isBuyVisible ? 
          (isMobile ? (
            <ButtonIcon size="small" onClick={onBuyClick} disabled={isBuyDisabled} >
              <SvgIcon name="hand-coin-line" />
            </ButtonIcon>
          ) : (
            <Button
              size="small"
              startIcon={<SvgIcon name="hand-coin-line" />}
              onClick={onBuyClick}
              disabled={isBuyDisabled}
              fullWidth={isXsmView}
            >
              {buyLabel}
            </Button>
          ))
          : undefined }

      </Stack>
    </Stack>
  )
}

import React, { useEffect, useState } from 'react'
import { Box, useTheme } from '@mui/material'
import Typography from 'src/components/Typography/Typography'
import Image from 'src/components/Image/Image'
import { PNG_ICONS } from 'src/assets/icons/icons'
import Logo from 'src/components/Logo/Logo'
import RegistrationForm from 'src/components/RegistrationForm/RegistrationForm'
import PatternLinesRegistration from 'src/components/PatternLines/PatternLinesRegistration'
import Comment from 'src/components/Comment/Comment'
import TitleWithDot from 'src/components/TitleWithDot/TitleWithDot'
import useMediaQuery from '@mui/material/useMediaQuery'
import SelectAvatar, { Avatar } from 'src/components/SelectAvatar/SelectAvatar'
import { useAppSelector } from 'src/hooks'
import { getAvatars } from 'src/helpers/commonData'
import { config } from 'src/config/config'
import SelectWallet from 'src/components/SelectWallet/SelectWallet'
import Button from 'src/components/Button/Button'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import { useLocation,useNavigate  } from 'react-router-dom';
import Cookies from 'js-cookie';
import LoginForm from 'src/components/LoginForm/LoginForm'


const unknownData = {
  title: '0x...',
  iconData: {
    src: PNG_ICONS.registerLogo,
    sx: { width: '44px', height: '44px' },
  },
}

export const Registration: React.FC = () => {
  const [selectedAvatar, setSelectedAvatar] = useState<Avatar>()
  const [showForm, setShowForm] = useState(false)
  const [isLogin, setIsLogin] = useState(false); // State to toggle between login and register


  const [cartWalletModalIsOpen, setCartWalletModalIsOpen] = useState(false)
 
  const onSelectAvatar = (item: Avatar) => {
    setSelectedAvatar(item)
  }

  const onRegistrate = () => {
    setShowForm(true)
  }

  const toggleForm = () => {
    setIsLogin((prevIsLogin) => !prevIsLogin); // Toggle between true (login) and false (register)
  }

  const theme = useTheme()
  const location = useLocation();
  const navigate = useNavigate();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))
  const user = useAppSelector(state => state.userReducer.userInfo);
  const loggedIn = useAppSelector(state => state.userReducer.loggedIn);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const ref = params.get('ref');
    if(ref){
      setReferralCookie(ref);
      const currentUrl = new URL(window.location.toString());
      currentUrl.searchParams.delete('ref');
      navigate(currentUrl.pathname + currentUrl.search, { replace: true });
      return;
    }
  }, [])

  const setReferralCookie = (referralCode : string) => {
    // Set a cookie named "referral" with the referral code
    // You can also specify additional options as the 3rd argument, such as expires, path, etc.
    Cookies.set('referral', referralCode, { expires: 2, sameSite:"None", secure:true, domain:config.url.COOKIES_DOMAIN }); // Expires in 2 days
    console.log('Referral code set:', referralCode);
  };
 


  return (
    <>
    <Box
      sx={{
        position: 'relative',
        padding: { xs: '25px 16px 62px', sm: '49px 0 62px 0' },
        overflowX: 'hidden',
        background: `${theme.palette.gray[900]}`,
        minHeight: '100vh',
      }}
    >
      {/*top*/}
      <PatternLinesRegistration
        sx={{
          position: 'absolute',
          top: '0',
          right: '0',
          width: '881px',
          height: '587px',
          overflow: 'hidden',
          [theme.breakpoints.down('lg')]: {
            height: '358px',
          },
          [theme.breakpoints.down('sm')]: {
            width: '290px',
            height: '161px',
          },
          '&:after': {
            content: '" "',
            position: 'absolute',
            top: { xs: '-488px', sm: '-402px', lg: '-223px' },
            right: 332,
            transform: 'rotate(120deg)',
            background: `${theme.palette.gray[900]}`,
            width: 650,
            height: 1300,
          },
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: '86px',
          right: '26px',
          transform: 'rotate(30deg)',
          [theme.breakpoints.down('lg')]: {
            top: '48px',
            right: '-9px',
          },
          [theme.breakpoints.down('sm')]: {
            top: '25px',
            right: '-5px',
          },
        }}
      >
        <Logo
          size="large"
          variant="logo-black"
          sx={{
            width: { xs: '195px', sm: '300px', lg: '449px' },
            height: 'auto',
          }}
        />
      </Box>
      {/*bottom*/}
      <PatternLinesRegistration
        sx={{
          position: 'absolute',
          bottom: '0',
          width: '625px',
          height: '110px',
          left: 0,
          [theme.breakpoints.down('sm')]: {
            width: '312px',
            height: '27px',
          },
          overflow: 'hidden',
          '&:after': {
            content: '" "',
            position: 'absolute',
            top: '-213px',
            right: { xs: '-251px', sm: '-125px', md: '-89px' },
            transform: 'rotate(120deg)',
            background: `${theme.palette.gray[900]}`,
            width: '275px',
            height: '365px',
          },
        }}
      />
      <Box sx={{ position: 'relative' }}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="labelBig" sx={{ color: theme.palette.primary.light }}>
            {'Welcome to'}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: { xs: '7px', sm: '16px' },
          }}
        >
          <TitleWithDot
            rootStackProps={{
              spacing: { xs: '8px', sm: '14px' },
              ml: { xs: '15px', sm: '21px' },
            }}
            dotProps={{
              sx: {
                bottom: 0,
                [theme.breakpoints.down('sm')]: {
                  width: '6px',
                  height: '6px',
                },
              },
            }}
          >
            {() => <Logo size={isMobileView ? 'xs' : 'large'} variant="logo-white" />}
          </TitleWithDot>
        </Box>
        <Box
          sx={{
            textAlign: 'center',
            margin: '0 auto',
            px: 0,
            // marginBottom: '44px',
            maxWidth: '410px',
            // [theme.breakpoints.down('sm')]: {
            //   px: '5px',
            //
            //   marginBottom: '32px',
            //   width: '100%',
            // },
            marginTop: '36px',
            marginBottom: '20px',
            [theme.breakpoints.up('sm')]: {
              marginTop: '96px',
              marginBottom: '28px',
            },
            [theme.breakpoints.up('md')]: {
              marginTop: '64px',
            },

            // marginTop: '64px',
            // marginBottom: '28px',
            // [theme.breakpoints.down('md')]: {
            //   marginTop: '96px',
            // },
            // [theme.breakpoints.down('sm')]: {
            //   marginTop: '36px',
            //   marginBottom: '32px',
            // },
          }}
        >
          <Typography variant="paragraphBig">
            {loggedIn ? 'This is your first time logging into Challenge. Lets setup your account': 'Please connect your wallet to start'}
          </Typography>
        </Box>

        <Box
          sx={{
            marginBottom: '32px',
            [theme.breakpoints.up('sm')]: {
              marginBottom: '44px',
            },
          }}
        >
          <Box sx={{ width: '207px', margin: '0 auto' }}>
            <Comment>your wallet</Comment>
            <Box
              sx={{
                border: `1px solid ${theme.palette.gray[700]}`,
                width: '207px',
                '&:hover': {
                  borderColor: theme.palette.gray[500],
                },
              }}
            >
              <Box display={'flex'} sx={{ width: '207px' }}>
                <Box
                  sx={{
                    background: theme.palette.gray[800],
                    border: `1px solid ${theme.palette.gray[700]}`,
                  }}
                >
                  <Box
                    sx={{
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      component={Image}
                      src={selectedAvatar?.src || unknownData.iconData.src}
                      alt="coints"
                      sx={unknownData.iconData.sx}
                    ></Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    marginLeft: '6px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box>
                    <Typography variant="labelSmall">{user.username ? `${user.username.slice(0, 7)} ... ${user.username.slice(38)}` : unknownData.title}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'relative',
          zIndex: 10,
        }}
      >
        {showForm ? (
          <Box
            sx={{
              width: { sm: '410px', xs: '100%' },
              margin: '0 auto',
            }}
          >
             {isLogin ?
                <LoginForm selectedAvatar={selectedAvatar} toggleForm={toggleForm} />
             :
                <RegistrationForm selectedAvatar={selectedAvatar} toggleForm={toggleForm} />
             }
          </Box>
        ) : (
          <Box
            sx={(theme) => ({
              margin: '0 auto',
              [theme.breakpoints.up('sm')]: {
                maxWidth: '576px',
              },
              [theme.breakpoints.up('md')]: {
                maxWidth: '630px',
              },
            })}
          >
            {loggedIn ?
            <SelectAvatar
              avatars={getAvatars(config.url.STORAGE_URL)}
              selected={selectedAvatar}
              onSelect={onSelectAvatar}
              onNext={onRegistrate}
            />
            :
            <Box
              sx={{
                marginLeft: '6px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >  
              <Button
                  endIcon={<SvgIcon name="wallet-line" sx={{ marginRight: '4px' }} />}
                  sx={{ minWidth: !isMobileView ? '217px' : '172px' }}
                  variant="contained"
                  size={!isMobileView ? 'big' : 'medium'}
                  onClick={() => setCartWalletModalIsOpen(true)}
                  disabled={false}
                >
                  Connect wallet
              </Button> 
            </Box>        
          }
          </Box>
        )}
      </Box>
    </Box>
    <SelectWallet
            open={cartWalletModalIsOpen}  
            chainId={config.blockchain.chainIdNumber} 
            onClose={() => setCartWalletModalIsOpen(false)}        
          />
    </>
  )
}

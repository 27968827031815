import { Box, BoxProps as MuiBoxProps, Stack, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import Comment from '../Comment/Comment'
import GamingEventDetailsOpt, {
  GamingEventDetails,
  GamingEventDetailsOptProps,
} from '../GamingEventDetailsOpt/GamingEventDetailsOpt'

type TournamentUserStatisticProps = {
  /** [The root element of this component uses the BoxProps from Material UI](https://mui.com/material-ui/react-box/) */
  BoxProps?: MuiBoxProps
  title: string
  data: GamingEventDetailsOptProps[]
}

function TournamentDetailInfo({ title, BoxProps, data }: TournamentUserStatisticProps) {
  const theme = useTheme()
  const isLg = useMediaQuery(theme.breakpoints.down('lg'))

  const eventTypeStylesProps = {
    [GamingEventDetails.PlayersJoin]: {
      props: {
        StackProps: {
          gap: '4px',
          mr: '70px',
          sx: {
            [theme.breakpoints.down('lg2')]: { mr: '30px' },
            [theme.breakpoints.down('lg')]: {
              order: 1,
              gap: '0',
              mr: '57px',
            },
            [theme.breakpoints.down('md834')]: {
              order: 1,
              mr: '90px',
            },
          },
        },
        labelProps: { TypographyProps: { variant: 'bodySmall' } },
        currentPlayersTypographyProps: {
          color: 'primary.light',
          variant: isLg ? 'h5' : 'h3',
        },
        maxPlayersTypographyProps: {
          variant: isLg ? 'h7' : 'h6',
          mt: '1px',
          sx: {
            [theme.breakpoints.down('lg')]: {
              height: '16px',
            },
          },
        },
        StackTypographyProps: {
          gap: '8px',
          height: 'auto',
          sx: {
            [theme.breakpoints.down('lg')]: {
              gap: '2px',
            },
          },
        },
        showDot: true,
        dotProps: {
          bottom: 0,
          right: '-12px',
          sx: {
            [theme.breakpoints.down('lg')]: {
              bottom: '-3px',
              right: '-9px',
            },
          },
        },
      },
    },
    [GamingEventDetails.TournamentType]: {
      props: {
        StackProps: {
          gap: '20px',
          mr: '43px',
          sx: {
            [theme.breakpoints.down('lg2')]: { mr: '30px' },
            [theme.breakpoints.down('lg')]: {
              order: 2,
              gap: '9px',
              mr: '47px',
            },
            [theme.breakpoints.down('md834')]: {
              order: 2,
              mr: '0',
            },
          },
        },
        labelProps: { TypographyProps: { variant: 'bodySmall' } },
      },
    },
    [GamingEventDetails.Prizes]: {
      props: {
        StackProps: {
          gap: '21px',
          mr: '21px',
          sx: {
            [theme.breakpoints.down('lg2')]: { mr: '30px' },
            [theme.breakpoints.down('lg')]: {
              order: 4,
              gap: '12px',
              mr: '61px',
            },
            [theme.breakpoints.down('md834')]: {
              order: 3,
              mr: '0',
              width: '100%',
              mb: '8px',
            },
          },
        },
        labelProps: { TypographyProps: { variant: 'bodySmall' } },
      },
    },
    [GamingEventDetails.Game]: {
      props: {
        StackProps: {
          gap: '17px',
          mr: '63px',
          sx: {
            [theme.breakpoints.down('lg2')]: { mr: '30px' },
            [theme.breakpoints.down('lg')]: {
              order: 3,
              gap: '6px',
            },
            [theme.breakpoints.down('md')]: {
              mr: '0',
            },
            [theme.breakpoints.down('md834')]: {
              order: 4,
              mr: '42px',
            },
          },
        },
        labelProps: { TypographyProps: { variant: 'bodySmall' } },
      },
    },
    [GamingEventDetails.GameMode]: {
      props: {
        StackProps: {
          gap: '17px',
          mr: '63px',
          sx: {
            [theme.breakpoints.down('lg')]: {
              order: 5,
              gap: '7px',
            },
            [theme.breakpoints.down('md834')]: {
              order: 5,
            },
          },
        },
        labelProps: {
          TypographyProps: {
            variant: 'bodySmall',
          },
        },
      },
    },
    [GamingEventDetails.Date]: {
      props: {
        StackProps: {
          gap: '17px',

          sx: {
            [theme.breakpoints.down('lg')]: {
              order: 6,
              gap: '7px',
            },
            [theme.breakpoints.down('md834')]: {
              order: 6,
            },
          },
        },
        labelProps: {
          TypographyProps: {
            variant: 'bodySmall',
          },
        },
      },
    },
  }

  return (
    <Box {...BoxProps}>
      <Comment rootStackProps={{ mb: '1px' }}>{title}</Comment>
      <Stack
        direction="row"
        flexWrap="wrap"
        bgcolor="gray.900"
        border="1px solid"
        borderColor="gray.700"
        padding="18px 24px 0 24px"
        height="98px"
        sx={{
          [theme.breakpoints.down('lg')]: {
            height: '148px',
            padding: '14px 14px 0 14px',
            gap: '12px',
            alignContent: 'flex-start',
          },
          [theme.breakpoints.down('md')]: {
            pr: '0',
          },
          [theme.breakpoints.down('md834')]: {
            height: 'auto',
            pb: '12px',
          },
        }}
      >
        {data.map((item, index) => (
          <GamingEventDetailsOpt key={index} {...item} {...eventTypeStylesProps[item?.event]} />
        ))}
      </Stack>
    </Box>
  )
}

export default TournamentDetailInfo

import React, { useCallback, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Box, Dialog, Stack } from '@mui/material'
import { toast } from 'src/components/Notification/Notification'
import Typography from 'src/components/Typography/Typography'
import TextField from 'src/components/TextField/TextField'
import ButtonIcon from 'src/components/ButtonIcon/ButtonIcon'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import TokenControls from 'src/components/TokenControls/TokenControls'
import Button from '../Button/Button'
import { config } from 'src/config/config'

type WalletInfoProps = {
  address: string
  isEmbedded: boolean
  tokenInfo: TokenInfoProps[]
  setIsRevealOpen: (value: boolean) => void
}

type TokenInfoProps = {
  total: string
  label: string
  chain?: string
  icon: string
  onWithdrawClick?: () => void
  onBuyClick?: () => void
  isWithDrawDisabled?: boolean
  isBuyDisabled?: boolean
}

export default function WalletInfo({ address, isEmbedded, tokenInfo, setIsRevealOpen }: WalletInfoProps) {
  const onCopy = useCallback(() => {
    toast.success('Copied to clipboard', { toastId: 'copied' })
  }, [])

 
  const revealPrivateKey = useCallback(() => {
    setIsRevealOpen(true)
  }, [])


  return (
    <>
    <Box
      pt={{ xs: '16px', sm: '24px' }}
      px={{ xs: '16px', sm: '24px' }}
      pb="36px"
      m="1px"
      bgcolor="gray.800"
    >
      <Typography variant="h7" color="common.white">
        Wallet
      </Typography>
      <Box mt="21px">
        <Stack direction="row" spacing="4px" alignItems="flex-end">
          <TextField name="address" label="My Wallet"  fullWidth disabled  style={{borderColor: "rgba(238, 164, 13, .4)", color: "red !important"}}
              value={`${address?.substring(0, 7)} ... ${address?.substring(38)}`} 
              InputLabelProps={{
                style: { color: 'rgba(238, 164, 13, 1.0)' },
              }}
              sx={{
                '& .Mui-disabled': {
                  color: 'lightgray !important', 
                  opacity: '0.7 !important' ,
                },
              }}
              />
          <Box>
            <CopyToClipboard text={address} onCopy={onCopy}>              
              <ButtonIcon variant="outlined" size="small" sx={{ mb: '14px' }}>
                <SvgIcon name="file-copy-line" />
              </ButtonIcon>
            </CopyToClipboard>
          </Box>
        </Stack>
        {isEmbedded &&
          <Stack direction="row" spacing="2px" alignItems="flex-end">
            <Button
              variant="outlined"
              size="small"
              startIcon={<SvgIcon name="locked" />}      
              disabled={false}
              fullWidth={false}
              onClick={revealPrivateKey}
            >
              Export Embedded Wallet
            </Button>
          </Stack>
        }

        <Box mt="16px">
          {tokenInfo.map(({ total, label, chain, icon, onWithdrawClick, onBuyClick, isBuyDisabled, isWithDrawDisabled }) => (
          <TokenControls key={label}
            TotalTokensProps={{
              total,
              label,
              chain,        
              icon,    
              ImageBoxProps: {
                sx: {
                  width: '48px',
                  height: '48px',
                },
              },
            }}
            onWithdrawClick={onWithdrawClick}
            onBuyClick={onBuyClick}
            isWithDrawDisabled={isWithDrawDisabled}
            isBuyDisabled={isBuyDisabled}            
          />
          ))}
        </Box>
      </Box>     
    </Box>   
    </>
  )
}

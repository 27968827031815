import React, { useState, useEffect, useCallback } from 'react'
import ExchangeRates, {ExchangeRatesProps} from 'src/components/ExchangeRates/ExchangeRates'
import { axiosInstance } from "src/api/api";

export function ExchangeRateTicker({ BoxProps, ExchangeRateProps }: Omit<ExchangeRatesProps,'data'> ) {
    const [tickerCT, setTickerCT] = useState(0);
    const [ticker, setTicker] = useState(0);
    useEffect(() => {

      // Function to check if we should fetch new data
      const shouldFetchNewData = () => {
           const data = localStorage.getItem('ETHPriceData');
           if (!data) {
               return true; // No data, fetch needed
           }

           const { timestamp } = JSON.parse(data);
           const now = new Date().getTime();
           // Check if the stored data is older than 2 minutes
           return now - timestamp > 120000; // 120,000 milliseconds = 2 minutes
       };

       // Function to fetch price
       const fetchETHPrice = () => {
           if (!shouldFetchNewData()) {
               // Use stored data if it's recent
               const { price } = JSON.parse(localStorage.getItem('ETHPriceData') as string);
               setTicker(price);
               return; // Exit function, no need to fetch
           }

           axiosInstance.get('/api/ticker/ETH')
               .then(response => {
                   const data = response.data;
                   setTicker(data.price); // Update with new price
                   // Store new price and timestamp in localStorage
                   localStorage.setItem('ETHPriceData', JSON.stringify({ price: data.price, timestamp: new Date().getTime() }));
           })
               .catch(console.error);
       };

       fetchETHPrice(); // Fetch price on mount

       // Set interval to fetch price every 5 minutes
       const interval = setInterval(fetchETHPrice, 360000);

       // Cleanup interval on component unmount
       return () => clearInterval(interval);
    }, []);

    useEffect(() => {

      // Function to check if we should fetch new data
      const shouldFetchNewData = () => {
           const data = localStorage.getItem('ETHPriceDataCT');
           if (!data) {
               return true; // No data, fetch needed
           }

           const { timestamp } = JSON.parse(data);
           const now = new Date().getTime();
           // Check if the stored data is older than 2 minutes
           return now - timestamp > 120000; // 120,000 milliseconds = 2 minutes
       };

       // Function to fetch price
       const fetchPrice = () => {
           if (!shouldFetchNewData()) {
               // Use stored data if it's recent
               const { price } = JSON.parse(localStorage.getItem('ETHPriceDataCT') as string);
               setTickerCT(price);
               return; // Exit function, no need to fetch
           }

           axiosInstance.get('/api/ticker/CT')
               .then(response => {
                   const data = response.data;
                   setTickerCT(data.price); // Update with new price
                   // Store new price and timestamp in localStorage
                   localStorage.setItem('ETHPriceDataCT', JSON.stringify({ price: data.price, timestamp: new Date().getTime() }));
                })
           .catch(console.error);
       };

       fetchPrice(); // Fetch price on mount

       // Set interval to fetch price every 5 minutes
       const interval = setInterval(fetchPrice, 300000);

       // Cleanup interval on component unmount
       return () => clearInterval(interval);
   }, []);

    const parseCurrency = (value: number | string, fractionDigits:number) => {
        return (+value).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: fractionDigits,
        })
      }
      
    return (
        <ExchangeRates
              ExchangeRateProps={ExchangeRateProps} BoxProps={BoxProps}
              data={[
                  {
                  label: 'CT/USD',
                  value:  tickerCT!==0 ? parseCurrency(tickerCT,5) : "",
                },
                {
                  label: 'ETH/USD',
                  value:  ticker!==0 ? parseCurrency(ticker,2) : "",
                },
              ]}
            />
       
    )
}
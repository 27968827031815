import { Box, Grid, Stack } from '@mui/material'
import { useClaimRewardsMutation, useFetchLatestRewardsQuery, useFetchRewardSummaryQuery } from 'src/api/profileApi'
import assets from 'src/assets'
import Comment from 'src/components/Comment/Comment'
import ProfileChallengesBox from 'src/components/ProfileChallengesBox/ProfileChallengesBox'
import ProfileHotStreakBox from 'src/components/ProfileHotStreakBox/ProfileHotStreakBox'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import TableHistoryProfile from 'src/components/TableHistoryProfile/TableHistoryProfile'
import TableWrapper from 'src/components/TableWrapper/TableWrapper'
import TokenControls from 'src/components/TokenControls/TokenControls'
import Typography from 'src/components/Typography/Typography'
import Link from 'src/components/Link/Link'
import { Reward } from 'src/helpers/rewardTypes'
import { TokenType } from 'src/helpers/types'
import { useAppDispatch } from 'src/hooks'
import CoinImageWithAmount from 'src/components/CoinImageWithAmount/CoinImageWithAmount'
import { getTokenImgSrc } from 'src/helpers/commonData'
import { Token } from '@mui/icons-material'

export function Rewards(){
   
    const { data:summaryData, error:summaryError, isLoading:isSummaryLoading, refetch:refetchSummary } =  useFetchRewardSummaryQuery(undefined, { });
    const { data:latestData, error:latestError, isLoading:isLatestLoading, refetch:refetchLatest } =  useFetchLatestRewardsQuery(undefined, { });

    const rewardsHistoryTable =  rewardsMapper(latestData);

    const dispatch = useAppDispatch();

    const [claimRewards, { isLoading:isClaimRewardsLoading }] = useClaimRewardsMutation();
    
    const claim = async  (points:number) => {

      try{
        dispatch({ type: "SHOW_WAIT_DIALOG", title: `Claiming`, subtitle: `Claiming ${points} WCT`});
        await claimRewards({ points }).unwrap();
        dispatch({ type: "UPDATE_WAIT_DIALOG",   subtitle: `You claimed ${points} WCT`, status: 'success', timeout: 2000 }); 

        dispatch({ type: "UPDATE_TOKEN_BALANCE", amount: points, tokenType: TokenType.CT });
      }
      catch(e:any){
        console.log('Error claiming', e);
        dispatch({ type: "UPDATE_WAIT_DIALOG",  title:'Claim failed',  subtitle: e.data?.message, status: 'error', timeout: 3000 }); 
      }

    }

    return (
        <>        
        <Grid item xs md2={6} minWidth={{ xs: 'auto', md2: '658px' }}>
                <Box width="100%">
                    <Comment>rewards</Comment>

                    
                    
                    <ProfileChallengesBox data={{ title: "WCT",  
                            subTitle:"Total reward tokens earned",
                            totalGames: summaryData?.totalRewardPoints || 0,
                            rows: [
                                {
                                title: 'Today',
                                value: summaryData?.rewardPointsToday || 0,
                                },
                                {
                                title: summaryData===undefined || summaryData.rewardsEnabled ? 'Max today' : 'Rewards currently disabled',
                                value: summaryData===undefined || !summaryData.rewardsEnabled ? 0 :  summaryData.maxDailyUserPoints,
                                },
                            
                        
                            ],
                        }}
                    />
                    <ProfileHotStreakBox title="Total" data={[ { title: 'Claimed', value: summaryData?.totalWithdrawnPoints||0},{ title: 'Available', value: summaryData?.totalAvailablePoints || 0 }]} />
                    
                    <Box maxWidth={{ xs: 'auto', sm: '100%', md: '576px', lg: '629px' }}>

                        <Box width="100%" mt="1.8px" border="1px solid" borderColor="gray.700">
                            <Box
                                pt={{ xs: '16px', sm: '24px' }}
                                px={{ xs: '16px', sm: '24px' }}
                                pb="36px"
                                m="1px"
                                bgcolor="gray.800"
                                >
                                <Typography variant="h7" color="common.white">
                                    Claim
                                </Typography>
                                <Box mt="21px">
                                <TokenControls key={1}
                                        TotalTokensProps={{
                                            total: `${summaryData?.totalAvailablePoints || 0} WCT`,
                                            label:`Rewards available to claim (Minimun claim amount ${summaryData?.maxDailyUserPoints || 0} WCT)`,        
                                            icon: assets.png.wct,    
                                            ImageBoxProps: {
                                                sx: {
                                                width: '48px',
                                                height: '48px',
                                                },
                                            },
                                        }}
                                        onWithdrawClick={()=>claim(summaryData?.totalAvailablePoints || 0)}
                                        onBuyClick={()=>undefined}
                                        withdrawLabel="Claim"
                                        isWithDrawDisabled={isClaimRewardsLoading || summaryData?.totalAvailablePoints===undefined || summaryData.totalAvailablePoints < summaryData.maxDailyUserPoints ? true : false}
                                        isBuyDisabled={true}     
                                        isBuyVisible={false}       
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <TableWrapper
                        title="Rewards history"
                        width="100%"
                        height="auto"
                        minHeight={{ xs: '520px', sm2: '522px', md: '527px' }}
                        mt={{ xs: '16px', sm: '18px', md: '28px' }}
                        p="1px 9px 0"
                        bodyStackProps={{
                        paddingTop: '17px',
                        paddingLeft: '8px',
                        paddingRight: '9px',
                        paddingBottom: '17px',
                        }}
                        TitleDotProps={{ bottom: { xs: '7px', sm: '8px' } }}
                    >
                        <TableHistoryProfile {...rewardsHistoryTable} />
                    </TableWrapper>
                </Box>
        </Grid>

        </>             
    )
}

export function rewardsMapper(rewardHistory:Reward[]|undefined){

    if(rewardHistory === undefined){
        return {
            limitRows: 8,
            data:{
              headRow: {
                cell: [
                  { value: 'Date',  },
                  { value: 'Description' },
                  { value: 'Points' },
                  { value: '' },
                ],
              },
              bodyRow: []
            }
          };
    }
    
    return {
      limitRows: 8,
      data:{
        headRow: {
          cell: [
            { value: 'Date',  },
            { value: 'Description' },
            { value: 'Points' },
            { value: 'Over limit' },
            { value: '' },
          ],
        },
        bodyRow: rewardHistory.map((history) => (
           {
            cell: [
              { value: new Date(history.created).toLocaleDateString()+''},
              { value: rewardTaskDescriptionMapper(history) },
              { value: history.points+' WCT',},
              { value: history.overDailyLimit || history.overMonthlyLimit ? (history.overDailyLimit ? 'Daily':'Monthly') : '',color:'#EEA40D'},
              {
                value:  ((history.forChallenge && history.challengeId) || (history.forTournament && history.tournamentId)) ? () => (
                 
                    <Link href={history.forTournament ? `/tournaments/${history.tournamentId}` : `/lobby/history/${history.challengeId}`} color="gray.25" style={{ display: 'flex' }} disabled>
                        <SvgIcon name="arrow-right-line" />
                    </Link>
                 
                ) : undefined,
              },

            ]
           }  
        ))
      }
    };
}

function rewardTaskDescriptionMapper(reward:Reward) : string{

    if(reward.rewardTaskType === 'REFERRAL'){
        return `Referral reward for ${reward.rewardForUserNickname}`;
    }
    else{
        return reward.rewardTaskDescription;
    }


}
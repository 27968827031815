import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Box, Grid } from '@mui/material'
import Typography from 'src/components/Typography/Typography'
import MonthlyRateBox from 'src/components/MonthlyRateBox/MonthlyRateBox'
import TitleWithDot from '../TitleWithDot/TitleWithDot'
import useMediaQuery from '@mui/material/useMediaQuery'

type MonthlyChallengesProps = {
  totalGames: number
  winGamesCount: number
  loseGamesCount: number
}

export default function MonthlyChallengesBox(props: MonthlyChallengesProps) {
  const theme = useTheme()
  const isMd2View = useMediaQuery(theme.breakpoints.down('md2'))
  return (
    <Box
      sx={{
        maxWidth: { xs: '100%', md: '411px' },
        border: `1px solid ${theme.palette.gray[700]}`,
        '&:hover': {
          border: `1px solid ${theme.palette.gray[500]}`,
        },
      }}
    >
      <Grid container bgcolor="gray.900">
        <Grid
          item
          sx={{
            [theme.breakpoints.down('md2')]: {
              width: '58.5%',
            },
            [theme.breakpoints.down('sm2')]: {
              width: '35%',
            },
          }}
        >
          <Box
            sx={{
              p: '16.5px 16px 16.5px 17px',
              width: '205px',
              height: '100%',
              [theme.breakpoints.down('md2')]: {
                p: '11px 6px 11px 11px',
              },
            }}
          >
            <Typography variant="paragraphSmall" color="gray.500" height="16px" mb="5px">
              {isMd2View ? `This Month's Challenges` : 'Challenges for this Month'}
            </Typography>
            <Grid container spacing={1} alignItems="baseline" height="39px">
              <Grid item>
                <Typography variant="h3" color="primary.light" maxWidth="90px">
                  {props.totalGames}
                </Typography>
              </Grid>
              <Grid item>
                <TitleWithDot
                  typographyProps={{ variant: 'h6' }}
                  dotProps={{ bottom: { xs: '8px', md2: '7px' }, width: '4px', height: '4px' }}
                  rootStackProps={{ spacing: '5px' }}
                >
                  games
                </TitleWithDot>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid
          item
          sx={{
            [theme.breakpoints.down('md2')]: {
              width: '41.5%',
              ml: 'auto',
            },
            [theme.breakpoints.down('sm2')]: {
              width: '47.5%',
              maxWidth: '163px',
            },
          }}
        >
          <Grid container>
            <Grid
              item
              sx={{
                [theme.breakpoints.down('md2')]: {
                  width: '50%',
                },
              }}
            >
              <MonthlyRateBox
                label="win"
                totalGameCount={props.totalGames}
                current={props.winGamesCount}
                sxBackground={{ background: theme.palette.primary.main }}
                sxTypography={{ color: theme.palette.primary.main }}
              />
            </Grid>
            <Grid
              item
              sx={{
                [theme.breakpoints.down('md2')]: {
                  width: '50%',
                },
              }}
            >
              <MonthlyRateBox
                label="lose"
                totalGameCount={props.totalGames}
                current={props.loseGamesCount}
                sxBackground={{ background: theme.palette.gray[400] }}
                sxTypography={{ color: theme.palette.gray[400] }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

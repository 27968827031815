import React from 'react'
import { Stack, StackProps } from '@mui/material'
import SvgIcon, { SvgIconProps } from 'src/components/SvgIcon/SvgIcon'
import Typography, { TypographyProps } from 'src/components/Typography/Typography'
import Link, { LinkProps } from 'src/components/Link/Link'

export type TitleWithLinkSvgIconProps = {
  StackProps?: StackProps
  LinkProps?: LinkProps
  SvgIconProps?: SvgIconProps
  TypographyProps?: TypographyProps
} & React.PropsWithChildren

export default function TitleWithLinkSvgIcon({
  StackProps,
  LinkProps,
  SvgIconProps,
  TypographyProps,
  children,
}: TitleWithLinkSvgIconProps) {
  return (
    <Stack direction="row" alignItems="center" gap="4px" {...StackProps}>
      <Typography variant="h7" color="gray.25" {...TypographyProps}>
        {TypographyProps?.children || children}
      </Typography>
      <Link href="/" size="small" {...LinkProps}>
        {LinkProps?.children || (SvgIconProps?.name && <SvgIcon {...SvgIconProps} />)}
      </Link>
    </Stack>
  )
}

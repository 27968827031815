import React from 'react';
import { Grid, useTheme } from '@mui/material'
import TableWrapper from 'src/components/TableWrapper/TableWrapper'
import TableLeaderBoard from 'src/components/TableLeaderBoard/TableLeaderBoard'
import { TableWrapperProps } from 'src/components/TableWrapper/TableWrapper';

type LeaderboardWrapperProps = TableWrapperProps & {
    leaderboard:any,
    griditemprops:any
}

export function LeaderboardTable({
    leaderboard,
    title,
    subtitle,
    width,
    height,
    px,
    squareRowProps,
    bodyStackProps,
    TitleDotProps,
    griditemprops,
    children,
    

  }: LeaderboardWrapperProps){
    const theme = useTheme()
    return (
        <Grid item xs={griditemprops.xs} lg2={griditemprops.lg2} sm2={griditemprops.sm2}>
        <TableWrapper 
            title={title}
            subtitle={subtitle}
            width={width}
            height={height}
            px={px}
            bodyStackProps={bodyStackProps}
            TitleDotProps={TitleDotProps}
            squareRowProps={squareRowProps}
        >
            <TableLeaderBoard {...leaderboard} 
                 rootAvatarProps={{
                    sx: { [theme.breakpoints.down('sm')]: { mr: '5px' } },
                  }}
            />
        </TableWrapper>
        </Grid>
    );
}
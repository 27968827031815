import React from 'react'
import { Grid, Stack, StackProps, useTheme } from '@mui/material'
import ChallengeCard, { ChallengeCardProps } from 'src/components/ChallengeCard/ChallengeCard'
import { ImageProps } from 'src/components/Image/Image'
import Button from 'src/components/Button/Button'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import useMediaQuery from '@mui/material/useMediaQuery'
import EnterChallengePopup from 'src/components/Popup/EnterChallengePopup/EnterChallengePopup'
import ctx from 'src/context/context'
import { GameTemplateType, DataHolderType } from "src/helpers/types";
import { defaultData } from 'src/helpers/data'
import { signup } from "src/actions/challengeActions";
import { useAppSelector, useAppDispatch } from 'src/hooks'
import ModalSignup from '../ModalSignup/ModalSignup'

type ChallengeListProps = StackProps & {
  list:  (Pick<ChallengeCardProps, 'bgSrc' | 'title' | 'dataSource' | 'btnProps'> & {gameTemplate: GameTemplateType, iconSrc: ImageProps['src']})[]
 
}

export default function GameList({ list = [], ...rootStackProps }: ChallengeListProps) {
  const theme = useTheme()
  const isSmView = useMediaQuery(theme.breakpoints.down('sm'))
  const sliceCount = isSmView ? 4 : 8
  const dispatch =  useAppDispatch();
  const auth = ctx.useAuth();
  const [currentGameTemplate, setCurrentGameTemplate] = React.useState(defaultData);

  return (
    <>
    <Stack direction="column" mb={{ xs: '9px', sm: '25px', md3: '0' }} {...rootStackProps}>
      <Grid container spacing={{ xs: '16px', sm: '28px' }} mb={{ xs: '16px', sm: '28px' }}>
        {list?.length
          ? list.slice(0, sliceCount).map((item, index) => (
              <Grid
                item
                xs={6}
                sm={4}
                sm2={3}
                md3={4}
                lg2={3}
                key={`challenge-card-${item.title}-${index}`}
              >
                <ChallengeCard
                  {...item}
                  disabled={item.gameTemplate.disabled}
                  bgSrc={item.bgSrc}
                  title={item.title}
                  dataSource={item.dataSource}
                  btnProps={
                    {
                      ...item.btnProps,
                      onClick: auth.isWalletConnected ? () => {setCurrentGameTemplate({ open: true, challengeCard: item });} : (undefined) 
                    }                    
                  }
                />
              </Grid>
            ))
          : null}
      </Grid>

      {list?.length ? (
        <Button disabled={true} variant="outlined" size="small" endIcon={<SvgIcon name="repeat-line" />}>
          Load more
        </Button>
      ) : null}
    </Stack>
     <ModalSignup 
        data={currentGameTemplate.challengeCard} 
        open={currentGameTemplate.open && auth.isWalletConnected} 
        onCancel={()=>setCurrentGameTemplate(defaultData)} 
        handleJoin={() => setCurrentGameTemplate(defaultData)}
        setOpen={()=>setCurrentGameTemplate(defaultData)}
     />
    </>
  )
}

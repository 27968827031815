import { Box, Stack, BoxProps, StackProps } from '@mui/material'
import React from 'react'

type TripleSquareProps = StackProps

const boxParams: BoxProps = {
  width: '12px',
  height: '3px',
  bgcolor: 'gray.700',
  component: 'span',
  display: 'block',
}

function TripleSquare({ ...restProps }: TripleSquareProps) {
  return (
    <Stack gap="1px" direction="row" {...restProps}>
      <Box component="span" {...boxParams} bgcolor="gray.500"></Box>
      <Box component="span" {...boxParams}></Box>
      <Box component="span" {...boxParams}></Box>
    </Stack>
  )
}

export default TripleSquare

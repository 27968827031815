import React from 'react'
import { Stack, StackProps } from '@mui/material'
import Typography from 'src/components/Typography/Typography'

export type PageNumberRangeProps = {
  /** The current page. */
  page?: number
  /** The total number of pages. */
  count?: number
  rootStackProps?: StackProps
}

export default function PageNumberRange({
  page = 1,
  count = 1,
  rootStackProps,
}: PageNumberRangeProps) {
  return (
    <Stack direction="row" spacing="2.6px" alignItems="center" color="gray.600" {...rootStackProps}>
      <Typography variant="uiElement" color="gray.500">
        {page}
      </Typography>
      <Typography variant="uiElement" color="gray.600">
        {' - '}
      </Typography>
      <Typography variant="uiElement" color="gray.500">
        {count}
      </Typography>
    </Stack>
  )
}

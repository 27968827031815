import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import TitleWithDot from 'src/components/TitleWithDot/TitleWithDot';
import PatternLines from 'src/components/PatternLines/PatternLines';
import PageContainer from 'src/components/PageContainer/PageContainer';
import Comment from 'src/components/Comment/Comment'
import {useAppDispatch, useAppSelector} from "src/hooks";
import ctx from 'src/context/context';
import { StakingType, TokenType } from 'src/helpers/types';
import { useFetchBlockchainFlexiStakingDataQuery, useFetchBlockchainVestedStakingDataQuery, useFetchFlexiStakingDataQuery, useFetchStakingSummaryDataQuery, useFetchVestedStakingDataQuery } from 'src/api/stakingApi';
import { claimRewards, stake, unstake } from 'src/actions/stakingActions';
import SEO from 'src/helpers/seo';
import StakingSumsWrapper from 'src/components/StakingSumsWrapper/StakingSumsWrapper';
import StakingBlocksWrapper from 'src/components/StakingBlocksWrapper/StakingBlocksWrapper';
import StakingBox from 'src/components/StakingBox/StakingBox';
import YourStaking from 'src/components/YourStaking/YourStaking';
import { formatTokenToNumber } from 'src/utils/tokenUtil';
//import { fetchStakingData } from 'src/api/stakingApi';
//import { useQuery } from '@tanstack/react-query';

const tabList = ['Flexi', 'Vested']
const tablist1 = ['Stake', 'Unstake']

export default function Staking() {  
    const theme = useTheme()
    const isMd834 = useMediaQuery(theme.breakpoints.down('md834'));
    const dispatch = useAppDispatch();
    const blockchain = ctx.useBlockchain();
    const auth = ctx.useAuth();

    const useBackend = !auth.isWalletConnected || (blockchain.isWalletConnected && blockchain.isNetworkMismatch===true);
    const useBlockchain = auth.isWalletConnected && blockchain.isWalletConnected && blockchain.isNetworkMismatch===false;
    //console.log('useBackend:', useBackend);
    //console.log('useBlockchain:', useBlockchain);

    //React query
    //const { isLoading, error, data } = useQuery({queryKey:['backendStakingData'], queryFn: fetchStakingData});
    const resultFlexi1  = useFetchFlexiStakingDataQuery(undefined, { skip: !useBackend }); //Must have a first argument   
    const resultFlexi2 = useFetchBlockchainFlexiStakingDataQuery(auth.user?.username, { skip: !useBlockchain, pollingInterval: 180000}); //undefined,{ pollingInterval: 15000}  //Must have a first argument
    const { data:flexiData, error:flexiError, isLoading:isFlexiLoading } = useBlockchain ? resultFlexi2 : resultFlexi1;
    //console.log('FlexiStaking data:', JSON.stringify(flexiData));

    const resultVested1  = useFetchVestedStakingDataQuery(undefined, { skip: !useBackend }); //Must have a first argument   
    const resultVested2 = useFetchBlockchainVestedStakingDataQuery(auth.user?.username, { skip: !useBlockchain, pollingInterval: 180000 }); //undefined,{ pollingInterval: 15000}  //Must have a first argument
    const { data:vestedData, error:vestedError, isLoading:isVestedLoading } = useBlockchain ? resultVested2 : resultVested1;
    //console.log('VestedStaking data:', JSON.stringify(vestedData));

    const { data:summaryData, error:summaryError, isLoading:isSummaryLoading, refetch } = useFetchStakingSummaryDataQuery(undefined, { pollingInterval: 180000  }); 

    
    //const flexiStaking =  useAppSelector(state => state.stakingReducer.flexiStaking);
    //const vestedStaking = useAppSelector(state => state.stakingReducer.vestedStaking);
    const totalBlance = (flexiData?.accountBalance!==undefined ? BigInt(flexiData?.accountBalance) :  0n) + (vestedData?.accountBalance!==undefined ? BigInt(vestedData?.accountBalance) : 0n);
    const totalSupply= (flexiData?.totalStaked!==undefined ? BigInt(flexiData?.totalStaked) :  0n) + (vestedData?.totalStaked!==undefined ? BigInt(vestedData?.totalStaked) : 0n); 
    const totalStakers = (flexiData?.stakerCount !== undefined ? BigInt(flexiData?.stakerCount): 0n) + (vestedData?.stakerCount !== undefined ? BigInt(vestedData?.stakerCount): 0n);
    const rewardForDuration = (flexiData?.rewardForDuration!==undefined ? BigInt(flexiData?.rewardForDuration) : 0n) + (vestedData?.rewardForDuration!==undefined ? BigInt(vestedData?.rewardForDuration) : 0n);

    let ccCoins = useAppSelector(state => state.userReducer.userInfo.coins?.challengeCoins);
    ccCoins = formatTokenToNumber(ccCoins,TokenType.CT,6,true);
        
    //To re-fetch summary when the wallet is connected/disconnected
    useEffect(() => {       
        refetch(); // This will re-fetch the data       
      }, [auth.isWalletConnected, refetch]);       

    function claim(stakingType: StakingType, amount:string): React.MouseEventHandler<HTMLButtonElement> | undefined {
        dispatch(claimRewards(stakingType,amount));
        return undefined;
    }

    function unStake(values: { unstakeAmount: number;  fee:number; type: StakingType; }): void {
        console.log('Unstake:', values);
        dispatch(unstake(values.unstakeAmount, values.fee, values.type));
    }

    function doStake(values: { value: number; type: StakingType; }): void {
        console.log('Stake:', values);
        dispatch(stake(values.value, values.type));
    }

    return (
        <>
        <SEO title="Staking | Challenge.gg" description={'Experience dividend staking where every stakeholder is rewarded in ETH by staking Challenge.'} />
        <Box bgcolor="gray.900" position="relative">
            <PageContainer
                sx={{
                pt: '17px',
                [theme.breakpoints.down('sm')]: {
                    pt: '37px',
                },
                }}
                innerContainerProps={{
                sx: { position: 'relative' },
                }}
            >
                <PatternLines
                    sx={{
                        height: '262px',
                        top: '9px',
                        [theme.breakpoints.down('lg4')]: {
                        ml: 'calc(-100vw - 12px)',
                        height: '238px',
                        },
                        [theme.breakpoints.down('sm')]: {
                        height: '66px',
                        ml: 'calc(-100vw - 8px)',
                        top: -10,
                        },
                    }}
                />
                <Stack
                    justifyContent="space-between"
                    alignContent="flex-start"
                    alignItems="flex-start"
                    sx={{
                        flexDirection: 'row',
                        gap: '26px',
                        [theme.breakpoints.down('md834')]: {
                        flexDirection: 'column',
                        mb: '36px',
                        gap: 0,
                        },
                    }}
                    >
                    <TitleWithDot
                            typographyProps={{ variant: isMd834 ? 'h3' : 'h2' }}
                            dotProps={{ bottom: { xs: '12px', sm: '16px' } }}
                            rootStackProps={{
                            spacing: { xs: '6px', sm: '10px' },
                            mb: { xs: '20px', md834: '25px', md2: '50px' },
                            }}
                        >
                            staking
                    </TitleWithDot>
                    <StakingSumsWrapper 
                        totalStaked={totalSupply} 
                        totalRewards={rewardForDuration} 
                        usersInvolved={summaryData !==undefined? BigInt(summaryData.totalNumberOfStakers) : undefined} />
                </Stack>
                <StakingBlocksWrapper 
                    flexiStaking={flexiData}
                    vestedStaking={vestedData} />
                <Box mb={{ xs: '38px', md: '68px' }}>
                <Comment>Staking</Comment>
                <Stack
                    sx={{
                    gap: { xs: '16px', md834: '27px' },
                    [theme.breakpoints.up('lg2')]: {
                        gap: '28px',
                        flexDirection: 'row',
                    },
                    }}
                >
                    <Box
                    sx={{
                        [theme.breakpoints.up('lg2')]: {
                        maxWidth: '521px',
                        width: '100%',
                        },
                    }}
                    >
                    <StakingBox
                        disabled={ccCoins===undefined || ccCoins===0 ? true : false}
                        availableAmount={ccCoins}
                        onSubmit={doStake}
                        flexiStaking={flexiData}
                        vestedStaking={vestedData}        
                        token="CT"
                        status="Period not Started" // Specify amount, Period not Started
                        sliderDisabled={ccCoins===undefined || ccCoins===0 ? true : false}
                    />
                    </Box>
                    <YourStaking  
                        flexiStaking={flexiData}
                        vestedStaking={vestedData}
                        summary={summaryData} 
                        onClaim={claim} 
                        onUnstake={unStake}
                        />                        
                        
                </Stack>
                </Box>
                {/*
                <Box mb={{ xs: '38px', md: '68px' }}>
                    <Comment>Staking</Comment>
                    <Grid
                        container
                        direction={{ xs: 'column', sm2: 'row' }}
                        spacing={{ xs: '20px', sm: '28px' }}     
                        alignItems="flex-start"
                                              >
                       
                        <Grid item xs={12} sm={4}
                            sx={{

                            }}>
                            <Box>
                                <Comment>Flexi Staking</Comment>
                                <Box
                                    sx={{
                                        border: `1px solid ${theme.palette.gray[700]}`,
                                        padding: '17px',
                                      
                                        '&:hover': {
                                        borderColor: theme.palette.gray[500],
                                        },
                                        [theme.breakpoints.down('md2')]: {
                                        width: '100%',
                                        padding: '11px',
                                        },
                                    }}
                                    >
                                        {isTimestampGreaterThanCurrentLocalTime(flexiData?.currentPeriodFinish) ? `Reward period running, ends ${formatUnixTimestampToDateTime(flexiData?.currentPeriodFinish as string)}`:'Reward period not started'}
                                    <WalletItem
                                        iconData={{ src: PNG_ICONS.weth, sx: { width: '24px', height: '24px' } }}
                                        title={`Rewards for period ${formatDuration(Number(flexiData?.currentRewardsDuration ?? 0))}`}
                                        total={flexiData ? `${flexiData.rewardForDuration}` : undefined}
                                        tokenType={TokenType.WETH}
                                    />
                                    <WalletItem
                                        iconData={{ src: PNG_ICONS.ct, sx: { width: '24px', height: '24px' } }}
                                        title={`Total staked by ${flexiData?.stakerCount ?? 0} users`}
                                        total={flexiData ? `${flexiData.totalStaked}` : undefined}
                                        tokenType={TokenType.CT}
                                    />
                                     <WalletItem
                                        iconData={{ src: PNG_ICONS.ct, sx: { width: '24px', height: '24px' } }}
                                        title={'You staked'}
                                        total={(flexiData && flexiData.accountBalance!==undefined) ? `${flexiData.accountBalance}` : undefined}
                                        tokenType={TokenType.CT}
                                    />
                                </Box>
                                
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}
                            sx={{
                      
                            }}>
                            <Box>
                                <Comment>Total staked</Comment>
                                <Box
                                    sx={{
                                        border: `1px solid ${theme.palette.gray[700]}`,
                                        padding: '17px',
                                      
                                        '&:hover': {
                                        borderColor: theme.palette.gray[500],
                                        },
                                        [theme.breakpoints.down('md2')]: {
                                        width: '100%',
                                        padding: '11px',
                                        },
                                    }}
                                    >
                                    <WalletItem
                                        iconData={{ src: PNG_ICONS.ct, sx: { width: '24px', height: '24px' } }}
                                        title={`Total staked by ${totalStakers} users`}
                                        total={totalSupply.toString()}
                                        tokenType={TokenType.CT}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}
                            sx={{
                               
                            }}>
                            <Box>
                                <Comment>Vested staking</Comment>
                                <Box
                                    sx={{
                                        border: `1px solid ${theme.palette.gray[700]}`,
                                        padding: '17px',
                                      
                                        '&:hover': {
                                        borderColor: theme.palette.gray[500],
                                        },
                                        [theme.breakpoints.down('md2')]: {
                                        width: '100%',
                                        padding: '11px',
                                        },
                                    }}
                                    >
                                    {isTimestampGreaterThanCurrentLocalTime(vestedData?.currentPeriodFinish) ? `Reward period running, ends ${formatUnixTimestampToDateTime(vestedData?.currentPeriodFinish as string)}`:'Reward period not started'}
                                    <WalletItem
                                        iconData={{ src: PNG_ICONS.weth, sx: { width: '24px', height: '24px' } }}
                                        title={`Rewards for period ${formatDuration(Number(vestedData?.currentRewardsDuration ?? 0))}`}
                                        total={vestedData ? `${vestedData.rewardForDuration}` : undefined}
                                        tokenType={TokenType.WETH}
                                    />
                                    <WalletItem
                                        iconData={{ src: PNG_ICONS.ct, sx: { width: '24px', height: '24px' } }}
                                        title={`Total staked by ${vestedData?.stakerCount ?? 0} users`}
                                        total={vestedData ? `${vestedData.totalStaked}` : undefined}
                                        tokenType={TokenType.CT}
                                    />
                                     <WalletItem
                                        iconData={{ src: PNG_ICONS.ct, sx: { width: '24px', height: '24px' } }}
                                        title={'You staked'}
                                        total={vestedData && vestedData.accountBalance!==undefined ? `${vestedData.accountBalance}` : undefined}
                                        tokenType={TokenType.CT}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                                */}
                {/*
                <Box mb={{ xs: '31px', sm: '38px', md: '68px'}} sx={{ml: '30px'}}>
                    <Grid
                        container
                        direction={{ xs: 'column', sm2: 'row' }}
                        spacing={{ xs: '20px', sm: '28px' }}     
                        alignItems="flex-start"
                    >
                        <Grid item xs={12} sm={6}>
                            <SimpleTabsWrapper showBorder={tabs?.length === 2 ? true : (activeTab) !== 0}>
                            <SimpleTabs
                                    list={tabs}
                                    value={activeTab}
                                    onChange={handleTabs}
                                    showBorder={tabs?.length === 2 ? false : (activeTab) === 0}
                                />
                                <SimpleTabPanel value={activeTab} index={0}>
                                    <SimpleTabsWrapper showBorder={(activeFlexiTab) !== 0}>
                                    <SimpleTabs
                                            list={tabs2}
                                            value={activeFlexiTab}
                                            onChange={handleFlexiTabs}
                                            showBorder={(activeFlexiTab) === 0}
                                        />
                                         <SimpleTabPanel value={activeFlexiTab} index={0}>
                                            <StakingForm type={StakingType.FLEXI} actionType={StakeActionType.STAKE} ccCoins={BigInt(ccCoins ?? 0)} />
                                        </SimpleTabPanel>
                                        <SimpleTabPanel value={activeFlexiTab} index={1}>
                                            <StakingForm type={StakingType.FLEXI} actionType={StakeActionType.UNSTAKE} ccCoins={BigInt(flexiData?.accountBalance ?? 0)} fee={flexiData?.unstakeFee}/>
                                        </SimpleTabPanel>
                                    </SimpleTabsWrapper>
                                </SimpleTabPanel>
                                <SimpleTabPanel value={activeTab} index={1}>
                                    <SimpleTabsWrapper showBorder={(activeVestedTab) !== 0}>
                                    <SimpleTabs
                                            list={tabs2}
                                            value={activeVestedTab}
                                            onChange={handleVestedTabs}
                                            showBorder={(activeVestedTab) === 0}
                                        />
                                         <SimpleTabPanel value={activeVestedTab} index={0}>
                                            <StakingForm type={StakingType.VESTED} actionType={StakeActionType.STAKE} ccCoins={BigInt(ccCoins ?? 0)}/>
                                        </SimpleTabPanel>
                                        <SimpleTabPanel value={activeVestedTab} index={1}>
                                            <StakingForm type={StakingType.VESTED} actionType={StakeActionType.UNSTAKE} ccCoins={BigInt(vestedData?.accountBalance ?? 0)} fee={vestedData?.unstakeFee}/>
                                        </SimpleTabPanel>
                                    </SimpleTabsWrapper>
                                
                                </SimpleTabPanel>
                            </SimpleTabsWrapper>
                        </Grid>
                        <Grid item xs={12} sm={6}>

                            {vestedData && vestedData?.vestingSchedule?.periods.length > 0 && 
                                <div>
                                {vestingType(vestedData.vestingSchedule.vestingType)}
                                {vestedData.vestingSchedule.periods.map((period:number, index:number) => {
                                    return (
                                        <Box key={index}>
                                            <Comment>Vesting period {index + 1}</Comment>
                                            <Box
                                                sx={{
                                                    border: `1px solid ${theme.palette.gray[700]}`,
                                                    padding: '17px',
                                                  
                                                    '&:hover': {
                                                    borderColor: theme.palette.gray[500],
                                                    },
                                                    [theme.breakpoints.down('md2')]: {
                                                    width: '100%',
                                                    padding: '11px',
                                                    },
                                                }}
                                                >
                                                <WalletItem
                                                    iconData={{ src: PNG_ICONS.ct, sx: { width: '24px', height: '24px' } }}
                                                    title={`released at ${formatUnixTimestampToDateTime(period)}`}   	
                                                    total={vestedData.vestingSchedule.amounts[index]+''}
                                                    tokenType={TokenType.CT}
                                                />
                                            </Box>
                                        </Box>
                                    )
                                }) }
                                 <Stack
                                    direction="row"
                                    gap="5px">
                                        <Typography
                                            variant="bodySmall"
                                            align="center"
                                            color={theme.palette.gray[25]}
                                            sx={{
                                            m: '0 auto 22px auto',
                                            [theme.breakpoints.down('sm')]: {
                                                m: '0 auto 18px auto',
                                                width: '100%',
                                            },
                                            }}
                                        >
                                            Reward: {vestedData.accountEarned!==undefined ? formatToken(vestedData.accountEarned,TokenType.WETH):''}
                                        </Typography>
            
                                    <Button variant="outlined" onClick={() => claim(StakingType.VESTED)}>Claim</Button>
                                    </Stack>    
                                </div>
                            }
                            {flexiData && BigInt(flexiData?.accountBalance)>0 &&
                                <div>
                                <Box>
                                    <Comment>Flexi staking</Comment>
                                    <Box
                                        sx={{
                                            border: `1px solid ${theme.palette.gray[700]}`,
                                            padding: '17px',
                                            
                                            '&:hover': {
                                            borderColor: theme.palette.gray[500],
                                            },
                                            [theme.breakpoints.down('md2')]: {
                                            width: '100%',
                                            padding: '11px',
                                            },
                                        }}
                                        >
                                        <WalletItem
                                            iconData={{ src: PNG_ICONS.ct, sx: { width: '24px', height: '24px' } }}
                                            title={`Available now for a withdrawal fee of ${flexiData.unstakeFee}%`}   	
                                            total={flexiData!=undefined ? flexiData.accountBalance+'' : undefined}
                                            tokenType={TokenType.CT}
                                        />
                                    </Box>
                                </Box>
                                <Stack
                                direction="row"
                                gap="5px">
                                    <Typography
                                        variant="bodySmall"
                                        align="center"
                                        color={theme.palette.gray[25]}
                                        sx={{
                                        m: '0 auto 22px auto',
                                        [theme.breakpoints.down('sm')]: {
                                            m: '0 auto 18px auto',
                                            width: '100%',
                                        },
                                        }}
                                    >
                                        Reward: {flexiData.accountEarned !==undefined ? formatToken(flexiData.accountEarned,TokenType.WETH):''}
                                    </Typography>
        
                                <Button variant="outlined" onClick={() => claim(StakingType.FLEXI)}  >Claim</Button>
                                </Stack>    
                                </div>
                            }
                            
                        </Grid>
                    </Grid>
                        </Box> */}
            </PageContainer>
        </Box>
        </>
    )
    
}
import React, { useCallback, useState  } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material'
import  LobbyStatusBar  from 'src/components/LobbyStatusBar/LobbyStatusBar'
import { useAppSelector, useAppDispatch } from 'src/hooks'
import { ChallengeStatus,ActiveChallengeType, TournamentType, TournamentStatus, StartType } from "src/helpers/types";
import TimerToast from '../TimerToast/TimerToast';
import { secondsLeft } from 'src/helpers/commonData';
import { tournamentRoundIsStarting,tournamentRoundIsFinishing } from 'src/actions/tournamentActions';
import { selectActiveChallengesOrdered } from 'src/selectors/activeChallengesSelector';
import LobbyStatusBarChangingText from '../LobbyStatusBar/LobbyStatusBarChangingText';

export function ActiveChallenge() {
		
	//const loggedIn = useAppSelector(state => state.userReducer.loggedIn);
	//const userId = useAppSelector(state => state.userReducer.userInfo.id);
	//const activeChallengeStatus = useAppSelector(state => state.challengeReducer.activeChallengeStatus);
	const activeChallenges = useAppSelector(state => selectActiveChallengesOrdered(state));  

	const location = useLocation();
	const navigate = useNavigate();
	const dispatch =  useAppDispatch();

	const handleRoundStartingTimer = useCallback((tournamentId: number, round:number) => {
		dispatch(tournamentRoundIsStarting(tournamentId, round));
	}, [dispatch]);	

	const handleRoundFinishingTimer = useCallback((tournamentId: number) => {
		dispatch(tournamentRoundIsFinishing(tournamentId));
	}, [dispatch]);	


	if (activeChallenges === undefined || activeChallenges.length === 0 || location.pathname.startsWith('/lobby') 
		|| /\/tournaments\/[^\/]+\/signup/.test(location.pathname)){  //exclude signup page /tournaments/$any}/signup
		return null;
	  
	}	
	
	return (		
		<Box display="flex" 
			flexDirection="column-reverse" 
			alignItems="flex-end" 
			position="fixed" 
			bottom={10} 
			right={10} 
			zIndex={100}>
				{activeChallenges.map((challenge:ActiveChallengeType, index:number) => {
					if (!challenge.isTournamentBase && challenge.isParticipating && !((challenge.tournamentId!==undefined && challenge.status === ChallengeStatus.NOT_STARTED) || challenge.status === ChallengeStatus.FINAL_DONE || challenge.status === ChallengeStatus.CANCELLED ||	challenge.error)) {
							return (						
								<Box key={index}  mb={index !== 0 ? 1 : 0}>
									
										<LobbyStatusBar status={getChallengeStatus(challenge)} 
											text={getChallengeText(challenge,challenge.startType)} 
											btnProps={{
												onClick: () => navigate(`/lobby/${challenge.id}`),
											}}/>									
								</Box>
							);
					}
					if (challenge.isTournamentBase && challenge.isParticipating && (challenge as TournamentType).currentRound && (challenge as TournamentType).tournamentStatus===TournamentStatus.WAITING_FOR_NEXT_ROUND) {
						return (						
							<Box key={index}  mb={index !== 0 ? 1 : 0}>
								<TimerToast  text={(challenge as TournamentType).currentRound < (challenge as TournamentType).numberOfRounds ? `Tournament round ${(challenge as TournamentType).currentRound} starts in:` : `Tournament final round starts in:`} 
									expirySeconds={secondsLeft((challenge as TournamentType).roundStartTime as Date,0)} 
									hideButton={location.pathname.startsWith('/tournaments')}
									onExpire={() => handleRoundStartingTimer(challenge.tournamentId as number, (challenge as TournamentType).currentRound)}
									btnProps={{
										children: 'Open tournament',
										onClick: () => navigate(`/tournaments/${challenge.tournamentId}`),
									}}
									/>						
							</Box>
						);
					}
					else if(challenge.isTournamentBase && challenge.isParticipating 
						&& (challenge as TournamentType).tournamentStatus===TournamentStatus.NOT_STARTED
						&& !location.pathname.startsWith('/tournaments')){
						return (						
							<Box key={index}  mb={index !== 0 ? 1 : 0}>
								
								{challenge.startType===StartType.ON_TIME ?
									<LobbyStatusBarChangingText status={getChallengeStatus(challenge)} 
										startTime={(challenge as TournamentType).startTime as Date}
										text={'Waiting for tournament to start'} 
										btnProps={{
											children: 'Open tournament',
											onClick: () => navigate(`/tournaments/${challenge.tournamentId}`),
										}}/>
										:
									<LobbyStatusBar status={getChallengeStatus(challenge)} 
										text={getChallengeText(challenge,challenge.startType)} 
										btnProps={{
											children: 'Open tournament',
											onClick: () => navigate(`/tournaments/${challenge.tournamentId}`),
										}}/>	
								}								
							</Box>
						);
					}
					else if((challenge as TournamentType).tournamentStatus===TournamentStatus.RUNNING ){
				
							return (						
								<Box key={index}  mb={index !== 0 ? 1 : 0}>
									<TimerToast key={index} 
										text={(challenge as TournamentType).currentRound < (challenge as TournamentType).numberOfRounds ? `Round finishes in:` : `Tournament finishes in:`} 
										expirySeconds={secondsLeft((challenge as TournamentType).currentRoundFinishes as Date,0)} 
										hideButton={location.pathname.startsWith('/tournaments')}
										onExpire={() => handleRoundFinishingTimer(challenge.tournamentId as number)}
										btnProps={{
											children: 'Open tournament',
											onClick: () => navigate(`/tournaments/${challenge.tournamentId}`),
										}}
										/>						
								</Box>
							);					

					}	
					else {
						return null;
					}
				})	
		}	
		</Box>
	)	       
}

enum Status {
	Waiting = 'waiting',
	Commencing = 'commencing',
	Cancelled = 'cancelled',
  }
  
  type StatusType = `${Status}`

function getChallengeStatus(challenge: ActiveChallengeType) : StatusType{
	if(challenge.error){
		return Status.Cancelled;
	}

	switch(challenge.status){
	  case ChallengeStatus.NOT_STARTED:
		return Status.Waiting;
	  case ChallengeStatus.READY_TO_START:
	  case ChallengeStatus.RUNNING:
	  case ChallengeStatus.STARTING_SCORE_COLLECTED:
	  case ChallengeStatus.PRE_COOLDOWN_DONE:
	  case ChallengeStatus.PLAY_TIME_OVER:
	  case ChallengeStatus.FINAL_DONE:
		return Status.Commencing;
	 case ChallengeStatus.CANCELLED:
		return Status.Cancelled;
	  default:
		return Status.Waiting;
	}
  }

  function getChallengeText(challenge: ActiveChallengeType, startType: StartType) : string{
	if(challenge.error){
		return Status.Cancelled;
	}
		
	switch(challenge.status){
	  case ChallengeStatus.NOT_STARTED:
		return startType===StartType.ON_TIME ? 'Challenge is about to start!' :
		 (challenge.maxParticipans > 2 ? 'Waiting for opponents' : 'Waiting for an opponent');
	  case ChallengeStatus.READY_TO_START:
		return 'Challenge is commencing';
	  case ChallengeStatus.RUNNING:
	  case ChallengeStatus.STARTING_SCORE_COLLECTED:
	  case ChallengeStatus.PRE_COOLDOWN_DONE:
		return 'Challenge is in progress';	  
	  case ChallengeStatus.PLAY_TIME_OVER:
		return 'Getting final results';
	  case ChallengeStatus.FINAL_DONE:
		return 'Challenge is completed!';
	 case ChallengeStatus.CANCELLED:
		return 'Challenge has been cancelled';
	  default:
		return 'Unknown';;
	}
  }

import React from 'react'
import { Box, Stack } from '@mui/material'
import dayjs from 'src/dayjs.config'
import { SvgIconProps } from 'src/components/SvgIcon/SvgIcon'
import Typography from 'src/components/Typography/Typography'
import SvgIconWithTitle from 'src/components/SvgIconWithTitle/SvgIconWithTitle'
import TournamentPrizesRow, {
  TournamentPrizesRowProps,
} from 'src/components/TournamentPrizesRow/TournamentPrizesRow'

export enum EventInfo {
  Date = 'date',
  Date2 = 'date2', //Since these are key we can not use the same twice
  Text = 'text',
  Text2 = 'text2', //Since these are key we can not use the same twice
  Fee = 'fee',
  Prizes = 'prizes',
}

export type EventInfoOptProps = {
  event: `${EventInfo}`
  label: string
  icon: string
  value: Date | string | Pick<TournamentPrizesRowProps, 'prizes' | 'otherPrizes' | 'prizeIconSrc'>
  props?: object
}

export default function EventInfoOpt({ event, label, icon, value, props }: EventInfoOptProps) {
  if ((event === EventInfo.Date || event === EventInfo.Date2) && value instanceof Date) {
    return <EventDate label={label} icon={icon} value={value} {...props} />
  } else if ((event === EventInfo.Fee || event === EventInfo.Text || event === EventInfo.Text2) && typeof value === 'string') {
    return (
      <EventInfoWrapper label={label} icon={icon}>
        <Typography variant="h7" color="gray.0">
          {value}
        </Typography>
      </EventInfoWrapper>
    )
  } else if (event === EventInfo.Prizes && !(value instanceof Date) && typeof value !== 'string') {
    return (
      <EventInfoWrapper label={label} icon={icon}>
        <TournamentPrizesRow
          variant="banner"
          prizes={value?.prizes}
          otherPrizes={value?.otherPrizes}
          prizeIconSrc={value?.prizeIconSrc}
          showCoin={true}
        />
      </EventInfoWrapper>
    )
  } else {
    return null
  }
}

type EventInfoWrapperProps = {
  label: string
  icon: string
} & React.PropsWithChildren

function EventInfoWrapper({ label, icon, children }: EventInfoWrapperProps) {
  return (
    <Box>
      <SvgIconWithTitle
        StackProps={{ gap: '6px' }}
        SvgIconProps={{
          name: icon as SvgIconProps['name'],
          fontSize: 'extraSmall',
          sx: {
            color: 'gray.300',
          },
        }}
      >
        {label}
      </SvgIconWithTitle>
      <Stack direction="row" alignItems="center" spacing="8px" mt="12px" ml="24px">
        {children}
      </Stack>
    </Box>
  )
}

type EventDateProps = {
  label: string
  icon: string
  value: Date
}

function EventDate({ label, icon, value }: EventDateProps) {
  return (
    <EventInfoWrapper label={label} icon={icon}>
      <>
        <Typography variant="h7" color="gray.0">
          {dayjs(value).format('DD MMM YYYY')}
        </Typography>
        <Typography variant="bodySmall" color="gray.50">
          {dayjs(value).format('HH:mm A')}
        </Typography>
      </>
    </EventInfoWrapper>
  )
}

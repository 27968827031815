import React, {useMemo} from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material'
import BlockBgLogoAndText from 'src/components/BlockBgLogoAndText/BlockBgLogoAndText'
import GameDownloadBanner from 'src/components/GameDownloadBanner/GameDownloadBanner'
import Comment from 'src/components/Comment/Comment'
import MonthlyChallengesBox from 'src/components/MonthlyChallengesBox/MonthlyChallengesBox'
import HotStreakBox from 'src/components/HotStreakBox/HotStreakBox'
import ChallengeImageWrapper from 'src/components/ChallengeImageWrapper/ChallengeImageWrapper'
import GameList from 'src/components/GameList/GameList'
import PatternLines from 'src/components/PatternLines/PatternLines'
import GameSortFilterHeader from 'src/components/GameSortFilterHeader/GameSortFilterHeader'
import TableWrapper from 'src/components/TableWrapper/TableWrapper'
import LiveActivityNew from 'src/components/LiveActivityNew/LiveActivityNew'
import { challengesData, gameTemplateMapper } from 'src/helpers/data'
import { getSortedGameTemplates } from "src/selectors/gameTemplateSelector";
import { filteredPlayedGames } from "src/selectors/userPlayedGamesSelector";
import { filteredStreaks } from "src/selectors/userStreakSelector";
import { getGameLink } from "src/selectors/gameLinkSelector";
import { useAppSelector } from 'src/hooks'
import { getSortOrder } from 'src/selectors/sortOrderSelector'
import { appendUrlPrefix, isTargetBlank } from 'src/helpers/commonData'
import { GameId } from 'src/utils/const'
import { StreakType } from 'src/helpers/types'

export default function Game() {
  const navigate = useNavigate()
  const { gameId } = useParams<{ gameId: string }>();
  const theme = useTheme()
  const isSmView = useMediaQuery(theme.breakpoints.down('sm'))

  let data = null
  if (gameId) {
    // @ts-ignore
    data = challengesData[gameId]
  }
  const sortOrderMemo = useMemo(() => getSortOrder, []);
  const sortOrder = useAppSelector(state => sortOrderMemo(state, gameId));
  
  const sortedGamesCollection = useMemo(() => getSortedGameTemplates, []);
  const gameTemplates = useAppSelector(state => sortedGamesCollection(state, gameId, undefined, false, sortOrder));
  // @ts-ignore
  const list = gameTemplateMapper(gameTemplates.collection);
  data.list=list;
  
  const playedGames = useAppSelector(state => filteredPlayedGames(state, gameId as string));
  data.totalGames=playedGames.playedGamesCount;
  data.winGamesCount=playedGames.wonGamesCount;
  data.loseGamesCount=playedGames.lostGamesCount;

  const filteredStreaksMemo = useMemo(() => filteredStreaks, []);
  const streaks = useAppSelector(state => filteredStreaksMemo(state, gameId as string, StreakType.ALLTIME));

  const linkPart = useAppSelector(state => getGameLink(state, gameId as GameId));
  const frontlandGameLink = appendUrlPrefix(linkPart);
  const targetBlank = isTargetBlank(linkPart);

  if(gameId===GameId.FRONTLAND){
     data.gameDownloadBanner.link=frontlandGameLink;
     data.gameDownloadBanner.target=targetBlank ? '_blank' : undefined;
     data.gameDownloadBanner.disabled=frontlandGameLink!=="" ? false : true;
  }
  return (
    <>
      <ChallengeImageWrapper
        pt="26px"
        mb="106px"
        leftShadow={isSmView ? false : true}
        rightShadow={isSmView ? false : true}
        bottomShadow={isSmView ? false : true}
        mobileShadow={isSmView ? true : false}
        bgHeight={isSmView ? '600px' : '665px'}
        bgImage={data?.bgImage}
        imgWrapperBoxProps={{ border: 'none' }}
        sx={{
          [theme.breakpoints.down('lg4')]: {
            mx: '-28px',
            px: '28px',
          },
          [theme.breakpoints.down('md3')]: {
            mb: '36px',
            pt: '17px',
          },
          [theme.breakpoints.down('md')]: {
            mb: '42px',
          },
          [theme.breakpoints.down('sm')]: {
            mb: '36px',
            mx: '-16px',
            px: '16px',
            mt: '16px',
            pt: '12px',
          },
        }}
      >
        <PatternLines height="238px" />
        <Stack direction={{ xs: 'column', md3: 'row' }} gap="28px">
          <Box flexGrow={1}>
            <GameSortFilterHeader
              mb={{ xs: '2px', sm: '14px', md3: '45px' }}
              imageProps={{ src: data?.gameSortFilterHeaderProps?.src }}
              title={data?.gameSortFilterHeaderProps?.title}
              gameId={gameId as GameId}
              onGoBack={() => navigate(-1)}
            />
            <GameList list={data?.list} />
          </Box>

          <Stack
            direction="column"
            gap={{ xs: '16px', sm2: '24px', md3: '18px' }}
            position="relative"
          >
	   <Stack direction={{ xs: 'column', sm2: 'row', md3: 'column' }}>
            <Box flexGrow={1}>
              <Comment rootStackProps={{ position: 'absolute', mt: '-16px', left: 0 }}>
                 {`${GameId.FRONTLAND===gameId ? 'Frontline' : 'Fortnite'} statistics`}
              </Comment>
              <MonthlyChallengesBox
                totalGames={data?.totalGames}
                winGamesCount={data?.winGamesCount}
                loseGamesCount={data?.loseGamesCount}
              />
	      </Box>
              <HotStreakBox fiveKillRow={streaks?.streak} treeKillRow={streaks?.bestStreak} />
             </Stack>
	     <TableWrapper
              title="Live Activity"
              width={{ xs: '100%', md3: '412px' }}
              height="auto"
              padding={{ xs: '5px 2px 0', sm: '12px 11px 0' }}
              bodyStackProps={{
                pt: '6px',
                pr: '5px',
                pb: '17px',
                pl: '6px',
                sx: {
                  [theme.breakpoints.down('sm')]: {
                    pb: '12px',
                    pl: '9px',
                    pr: '9px',
                  },
                },
              }}
            >
	          <LiveActivityNew
                gameId={gameId}
                TableContainerProps={{
                  sx: { maxHeight: { xs: '391px', sm: '461px', md3: '634px' } },
                }}
              />
	  </TableWrapper>
          </Stack>
        </Stack>
      </ChallengeImageWrapper>

      <BlockBgLogoAndText rootBoxProps={{ mb: { xs: '37px', sm: '22px' } }}>
        <GameDownloadBanner
          photoImgSrc={data?.gameDownloadBanner?.photoImgSrc}
          iconSrc={data?.gameDownloadBanner?.iconSrc}
          title={data?.gameDownloadBanner?.title}
          subtitle={data?.gameDownloadBanner?.subtitle}
          longText={data?.gameDownloadBanner?.longText}
          link={data?.gameDownloadBanner?.link!==undefined ? data?.gameDownloadBanner?.link : ''}
          target={data?.gameDownloadBanner?.target}
          disabled={data?.gameDownloadBanner?.disabled}
        />
      </BlockBgLogoAndText>
    </>
  )
}

import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Box, Stack } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import Image, { ImageProps } from 'src/components/Image/Image'
import Typography from 'src/components/Typography/Typography'
import GameNumber from 'src/components/GameNumber/GameNumber'
import VoteCardButton from 'src/components/VoteCardButton/VoteCardButton'
import ImageWithTitle from '../ImageWithTitle/ImageWithTitle'
import useMediaQuery from '@mui/material/useMediaQuery'

type GameBannerProps = {
  id: string | number
  bodyImgSrc: ImageProps['src']
  gamesCount: number
  iconSrc: ImageProps['src']
  title: string
  disabled?: boolean
  onClick: (id: string | number) => void
}

export default function VoteCard({
  id,
  bodyImgSrc,
  gamesCount,
  iconSrc,
  title,
  disabled,
  onClick,
}: GameBannerProps) {
  const theme = useTheme()
  const isMdView = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.gray[700]}`,
        width: 411,
        height: 116,
        transition: 'border-color 0.25s ease-in-out',
        '&:hover': {
          borderColor: theme.palette.gray[500],
        },
        mb: '27px',
        [theme.breakpoints.down('md')]: {
          width: '100%',
          maxWidth: '375px',
          mb: '28px',
        },
        [theme.breakpoints.down('md834')]: {
          maxWidth: '343px',
        },
        [theme.breakpoints.down('sm2')]: {
          mb: '16px',
        },
      }}
    >
      <Grid container gap="1px" sx={{ m: '1px' }}>
        <Grid xs>
          <Box
            sx={{
              minHeight: '112px',
              position: 'relative',
              overflow: 'hidden',
              bgcolor: theme.palette.gray[800],
            }}
          >
            <Image src={bodyImgSrc} alt="Vote banner" fill />
            <Box
              sx={{
                width: '324px',
                position: 'absolute',
                top: 0,
                bottom: 0,
                zIndex: 1,
                background: theme.palette.background.gameBanner,
                transform: 'rotate(180deg)',
              }}
            />
            <Box sx={{ position: 'absolute', top: 10, left: 14, zIndex: 2 }}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="uiElement" color="gray.700">
                  {'//'}
                </Typography>
                <GameNumber count={gamesCount} />
              </Stack>
            </Box>
            <Box
              sx={{
                position: 'absolute',
                bottom: 12,
                left: 14,
                zIndex: 2,
              }}
            >
              <ImageWithTitle
                rootStackProps={{}}
                typographyProps={{ variant: isMdView ? 'h6' : 'h5' }}
                imageProps={{ src: iconSrc }}
                title={title}
              />
            </Box>
          </Box>
        </Grid>
        <Grid>
          <VoteCardButton disabled={disabled} label="Vote" onClick={() => onClick(id)} />
        </Grid>
      </Grid>
    </Box>
  )
}

import React from 'react'
import { Stack, StackProps as MuiStackProps } from '@mui/material'
import GameNumberRange, {
  GameNumberRangeProps,
} from 'src/components/GameNumberRange/GameNumberRange'
import Comment from 'src/components/Comment/Comment'
import VerticalDividerWithContent from 'src/components/VerticalDividerWithContent/VerticalDividerWithContent'

type ChallengeRangeListHeaderProps = Pick<GameNumberRangeProps, 'startNumber' | 'endNumber'> & {
  /** [The root element of this component uses the StackProps from Material UI](https://mui.com/material-ui/react-stack/) */
  StackProps?: MuiStackProps
  text: string
}

export default function ChallengeRangeListHeader({
  text,
  startNumber,
  endNumber,
  StackProps,
}: ChallengeRangeListHeaderProps) {
  return (
    <VerticalDividerWithContent rootStackProps={StackProps ? StackProps : {}}>
      <Stack direction="row" alignItems="center" spacing="2.6px">
        <GameNumberRange startNumber={startNumber} endNumber={endNumber} />
        {text && <Comment typographySlashProps={{ color: 'gray.600' }}>{text}</Comment>}
      </Stack>
    </VerticalDividerWithContent>
  )
}

import React from 'react'
import Button, { ButtonProps } from '../Button/Button'
import SvgIcon from '../SvgIcon/SvgIcon'

type GameCardButtonProps = Pick<ButtonProps, 'startIcon' | 'sx'> & {
  label: string
  disabled?: boolean
  onClick?: () => void
}

export default function VoteCardButton({
  label,
  disabled,
  startIcon = <SvgIcon name="done-vote" />,
  ...restProps
}: GameCardButtonProps) {
  return (
    <Button disabled={disabled} variant="game" startIcon={startIcon} {...restProps}>
      {label}
    </Button>
  )
}

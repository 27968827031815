import React, { useCallback, useMemo } from 'react'
import { Box, Container, Grid, Stack, useMediaQuery } from '@mui/material'
import ChallengeCard, { ChallengeCardProps } from 'src/components/ChallengeCard/ChallengeCard'
import UnderChallengeButton from 'src/components/UnderChallengeButton/UnderChallengeButton'
import Comment from 'src/components/Comment/Comment'
import ChallengePagination from 'src/components/ChallengePagination/ChallengePagination'
import TitleWithDot from '../TitleWithDot/TitleWithDot'
import PatternLines from 'src/components/PatternLines/PatternLines'
import GameSidebar from 'src/components/GameSidebar/GameSidebar'
import { useTheme } from '@mui/material/styles'
import { PNG_ICONS } from 'src/assets/icons/icons'
import bgChallenges from 'src/assets/images/bgChallenges.png'
import mobileBgChallenges from 'src/assets/images/mobileBgChallenges.png'
import ctx from 'src/context/context'
import { useAppSelector, useAppDispatch } from 'src/hooks'
import { getSortedGameTemplates } from "src/selectors/gameTemplateSelector";
import { availableGamesList , gameTemplateMapper, defaultData } from 'src/helpers/data'
import { DataHolderType } from 'src/helpers/types'
import { setCurrentGame } from 'src/actions/pageActions'
import ModalSignup from '../ModalSignup/ModalSignup'

export default function AvailableGames() {
  const dispatch =  useAppDispatch();
  const auth = ctx.useAuth();
  const theme = useTheme()
  const isTabletView = useMediaQuery(theme.breakpoints.down('md'))
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))
  const isLg4View = useMediaQuery(theme.breakpoints.down('lg4'))
 // const [gameId, setGameId] = useState(GameId.FRONTLAND);
  const [currentGameTemplate, setCurrentGameTemplate] = React.useState(defaultData);

  const currentGameId = useAppSelector(state => state.pageReducer.currentGameId);

  const sortedGamesCollection = useMemo(() => getSortedGameTemplates, []);
  const gameTemplates = useAppSelector(state => sortedGamesCollection(state, currentGameId, undefined, true, undefined));
  var pages= Math.ceil(gameTemplates.collection.length/gameTemplates.count)

  const challengeCardList = gameTemplateMapper(gameTemplates.collection);

  const onSelect = useCallback((id: string) => {
   // setGameId(id)
    dispatch(setCurrentGame(id));
  }, [])

if (isMobileView) {
    return (
      <MobileAvailableGames
        selectedId={currentGameId}
        onSelect={onSelect}
        gameList={availableGamesList}
        cardList={challengeCardList.slice(0, 4)}
        gameTemplates={gameTemplates}
        isWalletConnected={auth.isWalletConnected}
        currentGameTemplate={currentGameTemplate}
        setCurrentGameTemplate={setCurrentGameTemplate}
      />
    )
  }

  if (isTabletView) {
    return (
      <TabletAvailableGames
        selectedId={currentGameId}
        onSelect={onSelect}
        gameList={availableGamesList}
        cardList={challengeCardList.slice(0, 3)}
        gameTemplates={gameTemplates}
        isWalletConnected={auth.isWalletConnected}
        currentGameTemplate={currentGameTemplate}
        setCurrentGameTemplate={setCurrentGameTemplate}
      />
    )
  }	

  return (
    <Box
      pt="17px"
      pb="36px"
      mb="60px"
      sx={{
        backgroundImage: `url(${bgChallenges})`,
        backgroundPosition: 'center 94px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        [theme.breakpoints.down('lg4')]: {
          mx: '-28px',
          px: '28px',
        },
      }}
    >
      <Container maxWidth="lg2" disableGutters sx={{ position: 'relative' }}>
        <PatternLines
          {...(isLg4View && { ml: 'calc(-100vw - 12px)' })}
          sx={{ height: '262px', top: '9px' }}
        />
        <TitleWithDot
          typographyProps={{ variant: 'h2' }}
          dotProps={{ bottom: '16px' }}
          rootStackProps={{ spacing: '8px', pt: 0 }}
        >
          Challenges
        </TitleWithDot>
        <Box mt="25px" mb={0}>
          <Comment>Available games</Comment>
        </Box>
        <Stack direction="row" gap="28px">
          <GameSidebar list={availableGamesList} selectedId={currentGameId} onSelect={onSelect} />
          <Box flexGrow={1} mt="4px">
            <Stack
              position="relative"
              zIndex={1}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={0}
              mb="28px"
            >
              <TitleWithDot
                typographyProps={{ variant: 'h5' }}
                dotProps={{ bottom: '10px' }}
                rootStackProps={{
                  spacing: '8px',
                  mt: '1px',
                }}
              >
                featured challenges
              </TitleWithDot>

              <ChallengePagination page={1} count={5} total={gameTemplates.collection.length} title="Frontline challenges" />
            </Stack>
            <Grid container spacing={{ md: '28px', md3: '8px', lg: '28px' }} mb="28px">
                {
                    challengeCardList.map((item:any, key:number) => (
                      <Grid item xs={4} md3={2.4} key={key}>
                      <ChallengeCard {...item} 
                      disabled={item.gameTemplate.disabled}
                        btnProps={{
                          ...item.btnProps,                         
                          onClick: auth.isWalletConnected ? () => {setCurrentGameTemplate({ open: true, challengeCard: item });} : (undefined),
                        }}
                      />
                      </Grid>
                    ))
                }
            </Grid>
            <UnderChallengeButton
              dataSource={[{ label: 'Available challenges', value: gameTemplates.count }]}
              linkProps={{ href: `game/${currentGameId}`, label: 'See all challenges' }}
            />
          </Box>
        </Stack>
        <EnterChallengePopupHelper 
          currentGameTemplate={currentGameTemplate} 
          setCurrentGameTemplate={setCurrentGameTemplate} 
          isWalletConnected={auth.isWalletConnected}/>        
      </Container>
    </Box>
  )
}
type EnterChallengePopupProps = {
  currentGameTemplate: DataHolderType
  setCurrentGameTemplate: (data: DataHolderType) => void
  isWalletConnected: boolean
}

function EnterChallengePopupHelper({currentGameTemplate, setCurrentGameTemplate,isWalletConnected}: EnterChallengePopupProps){
  const dispatch =  useAppDispatch();
  return (
    <ModalSignup 
      data={currentGameTemplate.challengeCard} 
      open={currentGameTemplate.open && isWalletConnected} 
      onCancel={()=>setCurrentGameTemplate(defaultData)} 
      handleJoin={() => setCurrentGameTemplate(defaultData)}
      setOpen={()=>setCurrentGameTemplate(defaultData)}
    />    
  );
}


type GameTemplatesType = {
  collection: never[];
  disabled: boolean;
  count: number;
}

type TabletAvailableGamesProps = {
  selectedId: string
  onSelect: (id: string) => void
  gameList: (typeof availableGamesList)[0][]
  cardList: ChallengeCardProps[]
  gameTemplates: GameTemplatesType
  isWalletConnected: boolean
  currentGameTemplate: DataHolderType
  setCurrentGameTemplate: (data: DataHolderType) => void
}

function TabletAvailableGames({
  selectedId,
  onSelect,
  gameList,
  cardList,
  gameTemplates,
  isWalletConnected,
  currentGameTemplate,
  setCurrentGameTemplate
}: TabletAvailableGamesProps) {
  const theme = useTheme()
  return (
    <Box
      sx={{
        position: 'relative',
        mx: '-28px',
        mb: '48px',
        px: '28px',
        overflow: 'hidden',
        pt: '17px',
        pb: '37px',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '70px',
          left: 0,
          backgroundImage: `url(${PNG_ICONS.tabletBgChallenges})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          width: '100%',
          minHeight: '713px',
          [theme.breakpoints.down(766)]: {
            minHeight: '1000px',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '116px',
            background: `linear-gradient(180deg, #040811 0%, rgba(4, 8, 17, 0) 100%)`,
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            maxWidth: '403px',
            height: '100%',
            background: `linear-gradient(90deg, #040811 0%, rgba(4, 8, 17, 0) 100%)`,
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: '100%',
            maxWidth: '402px',
            height: '100%',
            background: `linear-gradient(270deg, rgba(4, 8, 17, 0.5) 0%, rgba(4, 8, 17, 0) 100%)`,
          }}
        />
      </Box>
      <Container maxWidth="md" disableGutters sx={{ position: 'relative' }}>
        <PatternLines ml={'calc(-100vw - 12px)'} sx={{ height: '238px', top: '-3px' }} />
        <TitleWithDot
          typographyProps={{ variant: 'h2' }}
          dotProps={{ bottom: '16px' }}
          rootStackProps={{ spacing: '8px', pt: 0 }}
        >
          Challenges
        </TitleWithDot>
        <Box mt="25px" mb={0}>
          <Comment>Available games</Comment>
        </Box>
        <Stack direction="row" gap="28px">
          <GameSidebar list={gameList} selectedId={selectedId} onSelect={onSelect} />
          <Box flexGrow={1} mt="3px">
            <Stack
              position="relative"
              zIndex={1}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap="16px"
              mb="24px"
              sx={{
                [theme.breakpoints.down(806)]: {
                  flexDirection: 'column',
                },
              }}
            >
              <TitleWithDot
                typographyProps={{ variant: 'h5' }}
                dotProps={{ bottom: '10px' }}
                rootStackProps={{
                  spacing: '8px',
                  mt: '1px',
                }}
              >
                featured challenges
              </TitleWithDot>

              <ChallengePagination
                page={1}
                count={cardList?.length}
                total={gameTemplates.collection.length}
                title="challenges"
              /> 
            </Stack>
            <Grid container spacing={{ xs: '8px', sm2: '28px' }} mb="28px">
              {cardList.map((item, key) => (
                <Grid item xs={4} key={key}>
                   <ChallengeCard {...item} 
                      disabled={item.gameTemplate?.disabled}
                        btnProps={{
                          ...item.btnProps,                         
                          onClick: isWalletConnected ? () => {setCurrentGameTemplate({ open: true, challengeCard: item });} : (undefined),
                        }}
                      />
                </Grid>
              ))}
            </Grid>
            <UnderChallengeButton
              dataSource={[{ label: 'Available challenges', value:  gameTemplates.count }]}
              linkProps={{ href: `game/${selectedId}`, label: 'See all challenges' }}
            />
          </Box>
        </Stack>
        <EnterChallengePopupHelper 
          currentGameTemplate={currentGameTemplate} 
          setCurrentGameTemplate={setCurrentGameTemplate} 
          isWalletConnected={isWalletConnected}/>        
      </Container>
    </Box>
  )
}

type MobileAvailableGamesProps = {
  selectedId: string
  onSelect: (id: string) => void
  gameList: (typeof availableGamesList)[0][]
  cardList: ChallengeCardProps[]
  gameTemplates: GameTemplatesType
  isWalletConnected: boolean
  currentGameTemplate: DataHolderType
  setCurrentGameTemplate: (data: DataHolderType) => void
}

function MobileAvailableGames({
  selectedId,
  onSelect,
  gameList,
  cardList,
  gameTemplates,
  isWalletConnected,
  currentGameTemplate,
  setCurrentGameTemplate
}: MobileAvailableGamesProps) {
  return (
    <Box pt="28px" pb="36px" mb="1px">
      <Container maxWidth="lg2" disableGutters sx={{ position: 'relative' }}>
        <PatternLines sx={{ top: 0, height: '66px', ml: 'calc(-100vw - 8px)' }} />
        <TitleWithDot
          typographyProps={{ variant: 'h3' }}
          dotProps={{ bottom: '12px' }}
          rootStackProps={{ spacing: '8px', pt: '9px' }}
        >
          Challenges
        </TitleWithDot>
        <Box mt="20px" mb="1px">
          <Comment>Available games</Comment>
        </Box>
        <Stack direction="column" gap="20px">
          <GameSidebar list={gameList} selectedId={selectedId} onSelect={onSelect} />
          <Box
            sx={{
              position: 'relative',
              flexGrow: 1,
              mt: 0,
              mx: '-16px',
              px: '16px',
              pb: '18px',
              backgroundImage: `url(${mobileBgChallenges})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: 124,
                width: '100%',
                background: `linear-gradient(180deg, #040811 0%, rgba(4, 8, 17, 0) 100%)`,
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                height: 644,
                width: '100%',
                background: `linear-gradient(270deg, #040811 0%, rgba(4, 8, 17, 0) 100%)`,
                transform: `matrix(-1, 0, 0, 1, 0, 0)`,
              }}
            />
            <Stack
              position="relative"
              zIndex={1}
              direction="column"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing="8px"
              mb="16px"
            >
              <TitleWithDot
                typographyProps={{ variant: 'h5' }}
                dotProps={{ bottom: '8px' }}
                rootStackProps={{
                  spacing: '7px',
                  mt: 0,
                }}
              >
                featured challenges
              </TitleWithDot>

              <ChallengePagination
                page={1}
                count={cardList?.length}
                total={gameTemplates.collection.length}
                title="Frontline challenges"
              />
            </Stack>
            <Grid container spacing="15px" mb="16px">
              {cardList.map((item, key) => (
                <Grid item xs={6} key={key}>
                   <ChallengeCard {...item} 
                      disabled={item.gameTemplate?.disabled}
                        btnProps={{
                          ...item.btnProps,                         
                          onClick: isWalletConnected ? () => {setCurrentGameTemplate({ open: true, challengeCard: item });} : (undefined),
                        }}
                      />
                </Grid>
              ))}
            </Grid>
            <UnderChallengeButton
              dataSource={[{ label: 'Available challenges', value:  gameTemplates.count }]}
              linkProps={{ href: `game/${selectedId}`, label: 'See all challenges' }}
            />
          </Box>
        </Stack>
        <EnterChallengePopupHelper 
          currentGameTemplate={currentGameTemplate} 
          setCurrentGameTemplate={setCurrentGameTemplate} 
          isWalletConnected={isWalletConnected}/>        
      </Container>
    </Box>
  )
}

import React, { useMemo } from 'react'
import Typography from 'src/components/Typography/Typography'

const formatNumber = (num: number | string) => num.toString().padStart(3, '0')

let color = 'gray.600'

type GameNumberProps = {
  count?: number | string
}

export default function GameNumber({ count = 1 }: GameNumberProps) {
  const countStr = useMemo(() => formatNumber(count), [count])
  const digitsArr = useMemo(() => countStr.split(''), [countStr])

  return (
    <span>
      {digitsArr.map((digit, index) => {
        if (digit !== '0' || (digit === '0' && parseInt(countStr.slice(index)) > 0)) {
          color = digit === '0' ? 'gray.600' : 'gray.500'
        }
        return (
          <Typography key={index} variant="uiElement" color={color}>
            {digit}
          </Typography>
        )
      })}
    </span>
  )
}

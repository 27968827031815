import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Box, Grid, Stack, useMediaQuery } from '@mui/material'
import ImageCardMedia from 'src/components/ImageCardMedia/ImageCardMedia'
import ImageWithTitle, { ImageWithTitleProps } from 'src/components/ImageWithTitle/ImageWithTitle'
import Typography from 'src/components/Typography/Typography'
import ChallengeCardItem, {
  ChallengeCardItemProps,
} from 'src/components/ChallengeCardItem/ChallengeCardItem'
import Button from 'src/components/Button/Button'
import ChallengeCardButton, {
  ChallengeCardButtonProps,
} from 'src/components/ChallengeCardButton/ChallengeCardButton'

export type ChallengeDetailsCardProps = DetailsProps & {
  gameBoxProps: GameBoxProps
  onQuit?: () => void
 ChallengeCardButtonProps?: ChallengeCardButtonProps
  quitDisabled?: false
  quitButtonText?: undefined
}

export default function ChallengeDetailsCard({
  gameBoxProps,
  list,
  onQuit,
 ChallengeCardButtonProps,
  quitDisabled,
  quitButtonText,
}: ChallengeDetailsCardProps) {
  const theme = useTheme()
  const isMdView = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Box
      sx={{
        width: '521px',
        minHeight: '343px',
        [theme.breakpoints.down('md')]: {
          width: 'auto',
        },
      }}
    >
      <Grid container>
        <Grid
          item
          xs={isMdView}
          sx={{
            [theme.breakpoints.down('sm')]: {
              order: 2,
              flexGrow: 0,
            },
          }}
        >
          <GameBox {...gameBoxProps} />
        </Grid>
        <Grid
          item
          xs
          sx={{
            [theme.breakpoints.down('sm')]: {
              order: 1,
            },
          }}
        >
          <Details list={list} />
          <Box
            ml="1px"
            mt="1px"
            sx={{
              [theme.breakpoints.down('sm')]: {
                mt: '2px',
                ml: '2px',
                mr: '1px',
              },
            }}
          >
            {ChallengeCardButtonProps ? (
              <ChallengeCardButton fullWidth {...ChallengeCardButtonProps} />
            ) : (
            <Button fullWidth onClick={onQuit} disabled={quitDisabled}>
              {quitButtonText ? quitButtonText : 'Quit lobby'}
            </Button>
		)}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

type GameBoxProps = {
  imageProps: {
    src: string
    alt?: string
  }
  imageWithTitleProps: Pick<ImageWithTitleProps, 'imageProps' | 'title'>
}

function GameBox({ imageProps, imageWithTitleProps }: GameBoxProps) {
  const theme = useTheme()

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
      width="221px"
      height="343px"
      border="1px solid"
      borderColor="gray.700"
      bgcolor="gray.900"
      sx={{
        [theme.breakpoints.down('md')]: {
          width: 'auto',
        },
        [theme.breakpoints.down('sm')]: {
          width: '74px',
        },
      }}
    >
      <Box m="2px" position="relative" display="flex" flexGrow={1}>
        <Box zIndex={1}>
          <ImageCardMedia
            fill
            {...imageProps}
            alt={imageProps?.alt || imageWithTitleProps?.title || 'Game'}
          />
        </Box>
        <Box
          position="relative"
          display="flex"
          alignSelf="flex-end"
          height="292px"
          width="217px"
          sx={{
            [theme.breakpoints.down('md')]: {
              width: '100%',
            },
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              height: 'calc(100% - 30px)',
              opacity: 0.3,
              background: theme.palette.background.linesFooter,
            }}
          />
          <Box
            sx={{
              width: '100%',
              background: 'linear-gradient(0deg, #040811 0%, rgba(4, 8, 17, 0) 100%)',
              zIndex: 2,
              opacity: 0.3,
            }}
          />
          <Box
            sx={{
              transformOrigin: '0px 0px',
              transform: 'translate(-4px, 44px) rotate(-90deg)',
              position: 'absolute',
              bottom: '22px',
              left: '26.5px',
              zIndex: 3,
              [theme.breakpoints.down('sm')]: {
                left: '14px',
              },
            }}
          >
            <ImageWithTitle
              {...imageWithTitleProps}
              rootStackProps={{ spacing: '11px' }}
              imageBoxProps={{ sx: { transform: 'rotate(90deg)' } }}
              typographyProps={{ color: 'rgba(255,255,255, 0.26)' }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

type DetailsProps = {
  list?: ChallengeCardItemProps[]
}

function Details({ list = [] }: DetailsProps) {
  const theme = useTheme()
  return (
    <Box
      border="1px solid"
      borderColor="gray.700"
      bgcolor="gray.900"
      borderLeft={0}
      minHeight="288px"
      p="17px"
      sx={{
        [theme.breakpoints.down('sm')]: {
          borderLeft: `1px solid ${theme.palette.gray[700]}`,
          borderRight: 0,
          pb: '13px',
          minHeight: '284px',
        },
      }}
    >
      <Typography
        variant="h6"
        textTransform="uppercase"
        height="31px"
        display="flex"
        alignItems="center"
      >
        challenge details
      </Typography>
      <Box pt="18px">
        {list?.length > 0 && (
          <Stack direction="column" spacing="8px">
            {list.map(({ icon, label, value, ...restProps }, index, arr) => (
              <ChallengeCardItem
                key={label}
                icon={icon}
                label={label}
                value={value}
                {...restProps}
                {...(index === arr.length - 1 ? { pb: 0 } : {})}
              />
            ))}
          </Stack>
        )}
      </Box>
    </Box>
  )
}

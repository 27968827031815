import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from "./api";
import { TopListItemType, TopListType } from 'src/helpers/types';

export enum Tags  {
    Leaderboard= 'Leaderboard',
    WeeklyLeaderboard= 'WeeklyLeaderboard',
    MonthlyLeaderboard= 'MonthlyLeaderboard',
 }
   
export const apiLeaderboardSlice = createApi({
    reducerPath: 'apiLeaderboard',
    baseQuery: axiosBaseQuery({ baseUrl: '/api' }),
    tagTypes: [Tags.Leaderboard,Tags.MonthlyLeaderboard,Tags.WeeklyLeaderboard], // Define a tag for invalidating and refetching
    endpoints: (builder) => ({
        fetchLeaderboard: builder.query<TopListType[],void>({
            query: () => ({ url: '/statistics/general', method: 'GET' }),
            transformResponse: (response: TopListType[]): TopListType[] => {  //axios interceptor JSONbig will transform 46.4353453453535 to BigNUmber for some reason, need to fix
            if (Array.isArray(response)) {
                return response.map((item: TopListType) => {
                    if (item && item.topList && Array.isArray(item.topList)) {
                        item.topList.forEach((listItem: TopListItemType) => {                    
                                if(typeof listItem.value === 'object'){  //if value is a big number, convert it to number
                                    listItem.value = Number((listItem.value as object).toString());
                                }
                        });
                    }
                    return item;
                });
            }
            return [];
            },
            keepUnusedDataFor: 300, // Keep data in cache for 5 minutes
            providesTags: [Tags.Leaderboard],
        }),
        fetchWeeklyLeaderboard: builder.query<TopListType[],void>({
            query: () => ({ url: '/leaderboards/weekly', method: 'GET' }),           
            keepUnusedDataFor: 300, // Keep data in cache for 5 minutes
            providesTags: [Tags.WeeklyLeaderboard],
        }),
        fetchMonthlyLeaderboard: builder.query<TopListType[],void>({
            query: () => ({ url: '/leaderboards/monthly', method: 'GET' }),           
            keepUnusedDataFor: 300, // Keep data in cache for 5 minutes
            providesTags: [Tags.MonthlyLeaderboard],
        }),
    })
});

export const { useFetchLeaderboardQuery } = apiLeaderboardSlice;
export const { resetApiState : resetLeaderboardApiState } = apiLeaderboardSlice.util;
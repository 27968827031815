import { Box, BoxProps as MuiBoxProps, Stack } from '@mui/material'
import React from 'react'
import Typography from '../Typography/Typography'
type ZebraMiniTablePops = {
  /** [The root element of this component uses the BoxProps from Material UI](https://mui.com/material-ui/react-box/) */
  BoxProps?: MuiBoxProps
  data: {
    title: string | (() => React.ReactNode)
    value: string | (() => React.ReactNode)
  }[]
  highlightFirstRow?: boolean
}
function ZebraMiniTable({ data, highlightFirstRow = true, BoxProps }: ZebraMiniTablePops) {
  const startHighlight = highlightFirstRow ? 0 : 1
  return (
    <Box {...BoxProps}>
      {data &&
        data.map((item, index) => (
          <Stack
            key={index}
            direction="row"
            justifyContent="space-between"
            bgcolor={index % 2 === startHighlight ? 'gray.700' : 'none'}
            minHeight="24px"
            my={index % 2 === startHighlight ? '0' : '4px'}
            alignItems="center"
            px="4px"
          >
            {typeof item.title === 'function' ? (
              item.title()
            ) : (
              <Typography variant="paragraphSmall" align="left" color="gray.200">
                {item.title}
              </Typography>
            )}
            {typeof item.value === 'function' ? (
              item.value()
            ) : (
              <Typography variant="paragraphSmall" align="left" color="gray.200">
                {item.value}
              </Typography>
            )}
          </Stack>
        ))}
    </Box>
  )
}

export default ZebraMiniTable

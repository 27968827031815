import React from 'react'
import { Stack, StackProps, useMediaQuery, useTheme } from '@mui/material'
import Typography from 'src/components/Typography/Typography'
import Link from 'src/components/Link/Link'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'

type GameTipProps = {
  StackProps?: StackProps
  text: string
  link: string  
  target?: string
  disabled?: boolean
}

export default function GameTip(props: GameTipProps) {
  const theme = useTheme()
  const isSmView = useMediaQuery(theme.breakpoints.down('sm'))
  
  return (
    <Stack alignItems="center" gap="8px" {...props?.StackProps}>
      <Typography variant="bodySmall" color="gray.100" textAlign="center">
       {props.text}
      </Typography>
      <Link
        href={props.link}
        target={props.target}
        disabled={props.disabled}
        size={isSmView ? 'small' : 'medium'}
        startIcon={<SvgIcon name="windows-fill" />}
      >
        Download Frontland
      </Link>
    </Stack>
  )
}

import React from 'react'
import Button, { ButtonProps } from '../Button/Button'
import SvgIcon from '../SvgIcon/SvgIcon'
import { Box } from '@mui/material'

type GameCardButtonProps = Pick<ButtonProps, 'startIcon' | 'sx'> & {
  label: string
  onClick?: () => void
}

export default function GameCardButton({
  label,
  startIcon = (
    <SvgIcon name="arrow-right-up-line" sx={{ position: 'relative', top: '-1px', left: '3px' }} />
  ),
  ...restProps
}: GameCardButtonProps) {
  return (
    <Button variant="game" startIcon={startIcon} {...restProps}>
      <Box sx={{ position: 'relative', top: '-4px' }}>{label}</Box>
    </Button>
  )
}

import React, { useState } from 'react'
import { Box, Stack, useTheme } from '@mui/material'
import { PNG_ICONS } from 'src/assets/icons/icons'
import AvailableGames from 'src/components/AvailableGames/AvailableGames'
import AfterVotingBlock from 'src/components/AfterVotingBlock/AfterVotingBlock'
import BlockBgLogoAndText from 'src/components/BlockBgLogoAndText/BlockBgLogoAndText'
import VoteCard from 'src/components/VoteCard/VoteCard'
import voteVoteImg from 'src/assets/images/voteBg.png'

const voteData = [
  {
    id: '1',
    bodyImgSrc: voteVoteImg,
    gamesCount: 1,
    iconSrc: PNG_ICONS.voteCard,
    title: 'Battlefield 2042',
    disabled: true,
  },
  {
    id: '2',
    bodyImgSrc: voteVoteImg,
    gamesCount: 1,
    iconSrc: PNG_ICONS.voteCard,
    title: 'Battlefield 2042',
    disabled: true,
  },
  {
    id: '3',
    bodyImgSrc: voteVoteImg,
    gamesCount: 1,
    iconSrc: PNG_ICONS.voteCard,
    title: 'Battlefield 2042',
    disabled: true,
  },
  {
    id: '4',
    bodyImgSrc: voteVoteImg,
    gamesCount: 1,
    iconSrc: PNG_ICONS.voteCard,
    title: 'Battlefield 2042',
    disabled: true,
  },
]

export default function Challenges() {
  const [selectedVoteId, setSelectedVoteId] = useState<string | number>('')

  const onVoteClick = (id: string | number) => {
    setSelectedVoteId(id)
  }

  const theme = useTheme()

  return (
    <>
      <AvailableGames />
      <Box sx={{ mb: { xs: '35px', sm: '37px', md: '58px' } }}>
        <BlockBgLogoAndText title={'Vote for our'} subtitle={'Next game'}>
          {selectedVoteId ? (
            <AfterVotingBlock
              subtitle="We will inform you when new game will arrive in Challenge.gg"
              sx={{
                pt: { xs: '40px', sm2: '8px' },
                pb: '11px',
              }}
              title="Thank you for your vote"
            />
          ) : (
            <Stack
              direction="row"
              flexWrap="wrap"
              justifyContent="space-between"
              width="100%"
              sx={{
                [theme.breakpoints.down('sm2')]: {
                  flexDirection: 'column',
                  alignItems: 'center',
                },
              }}
            >
              {voteData.map((item, i) => (
                <VoteCard
                  key={`${i}-vote-card`}
                  id={item.id}
                  bodyImgSrc={item.bodyImgSrc}
                  gamesCount={item.gamesCount}
                  iconSrc={item.iconSrc}
                  title={item.title}
                  disabled={item.disabled}
                  onClick={(id) => onVoteClick(id)}
                />
              ))}
            </Stack>
          )}
        </BlockBgLogoAndText>
      </Box>
    </>
  )
}

import { Box, Stack, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import WinnerLobbyBox from '../WinnerLobbyBox/WinnerLobbyBox'
import TournamentViewBox from '../TournamentViewBox/TournamentViewBox'
import { CSSProperties } from '@mui/styles'
import Typography from '../Typography/Typography'
import { WinnerPlaceTextT } from '../WinnerPlaceNum/WinnerPlaceNum'
import TitleWithDot from '../TitleWithDot/TitleWithDot'

export type TournamentWinnersBoxT = {
  name: string
  place: number
  amount: string
  tokenImgSrc: string
  avatar: string
  bgText?: string
}

type TournamentWinnersWrapperProps = {
  title: string
  otherPrizesTitle: string
  data: {
    winners: TournamentWinnersBoxT[]
    otherPrizes: TournamentWinnersBoxT[]
  }
}

function TournamentWinnersWrapper({
  data,
  title,
  otherPrizesTitle,
}: TournamentWinnersWrapperProps) {
  const theme = useTheme()
  const isMd834 = useMediaQuery(theme.breakpoints.down('md834'))

  const winnersStyle: { [key: string]: CSSProperties } = {
    place1: {
      order: isMd834 ? 1 : 2,
      height: '497px',
      minHeight: 'auto',
      m: 0,
    },
    place2: {
      order: isMd834 ? 2 : 1,
      height: '424px',
      minHeight: 'auto',
      m: 0,
    },
    place3: {
      order: 3,
      height: '424px',
      minHeight: 'auto',
      m: 0,
    },
  }

  return (
    <Box
      mb="66px"
      sx={{
        [theme.breakpoints.down('lg')]: {
          mt: '-7px',
          mb: '37px',
        },
        [theme.breakpoints.down('md834')]: {
          mt: '-3px',
        },
      }}
    >
      <TitleWithDot
        rootStackProps={{ spacing: { xs: '8px', md834: '9px' }, mb: { xs: '23px', md834: '-8px' } }}
        dotProps={{ bottom: { xs: '6px', md834: '10px' } }}
      >
        <div>
          <Typography variant={isMd834 ? 'h6' : 'h5'} color="inherit">
            {title}
          </Typography>
        </div>
      </TitleWithDot>
      <Stack
        direction="row"
        flexWrap="wrap"
        justifyContent="center"
        gap="30px"
        alignItems="flex-end"
        position="relative"
        sx={{
          [theme.breakpoints.down('lg')]: {
            gap: '42px',
          },
          [theme.breakpoints.down('md')]: {
            gap: '0',
            justifyContent: 'space-between',
          },
          [theme.breakpoints.down('md834')]: {
            flexDirection: 'column',
            alignContent: 'flex-start',
            ml: '-28px',
            gap: '4px',
          },
          [theme.breakpoints.down('sm')]: {
            ml: '-16px',
          },
        }}
      >
        {data &&
          data.winners.map((item, index) => (
            <TournamentViewBox
              key={index}
              title={item.bgText || ''}
              place={item.place}
              BoxProps={{
                position: 'relative',
                sx: {
                  ...winnersStyle[`place${item.place}`],
                  [theme.breakpoints.down('lg')]: {
                    width: '230px',
                  },
                  [theme.breakpoints.down('md834')]: {
                    width: '100%',
                    maxWidth: '475px',
                    paddingTop: '16px',
                    height: '114px',
                    pr: '8px',
                  },
                  [theme.breakpoints.down('sm')]: { maxWidth: '420px', pt: '26px' },
                },
              }}
              bgImageProps={{
                sx: {
                  [theme.breakpoints.down('md834')]: {
                    transform: 'rotate(90deg)',
                    width: '800px',
                    height: '800px',
                    right: '0',
                    top: '0',
                    left: 'auto',
                    bottom: 'auto',
                  },
                },
              }}
              childrenStackProps={{
                sx: {
                  [theme.breakpoints.down('md834')]: {
                    justifyContent: 'left',
                    pl: '20px',
                  },
                  [theme.breakpoints.down('sm')]: {
                    pl: '16px',
                  },
                },
              }}
              titleTypographyProps={{
                variant: isMd834 ? 'h2' : 'h1',
                sx: {
                  [theme.breakpoints.down('md834')]: {
                    left: 'auto',
                    top: '-5px',
                    pr: '0px',
                  },
                },
              }}
            >
              <WinnerLobbyBox
                amount={item.amount}
                tokenImgSrc={item.tokenImgSrc}
                avatarSrc={item.avatar}
                isVertical={isMd834 ? false : true}
                name={item.name}
                place={item.place}
                placeText={winnersPlaceText}
                showAmount={item.amount!==undefined && item.amount!=='0'}
              />
            </TournamentViewBox>
          ))}
        {data.otherPrizes.length > 0 && (
        <Typography
          variant="h6"
          color="white"
          position="absolute"
          bottom="17px"
          left="0"
          right="0"
          textAlign="center"
          sx={{
            [theme.breakpoints.down('md834')]: {
              position: 'relative',
              textAlign: 'left',
              order: 4,
              bottom: 0,
              alignSelf: 'flex-start',
              pl: '28px',
              mt: '22px',
              mb: '10px',
            },
            [theme.breakpoints.down('sm')]: {
              pl: '16px',
            },
          }}
        >
          {otherPrizesTitle}
        </Typography>
        )}
      </Stack>

      <Stack
        direction="row"
        flexWrap="wrap"
        justifyContent="center"
        gap="15px"
        sx={{
          [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            alignContent: 'center',
            gap: '12px',
          },
          [theme.breakpoints.down('md834')]: {
            alignContent: 'flex-start',
            gap: '8px',
          },
        }}
      >
        {data &&
          data.otherPrizes.map((item, index) => (
            <WinnerLobbyBox
              key={index}
              amount={item.amount}
              tokenImgSrc={item.tokenImgSrc}
              avatarSrc={item.avatar}
              name={item.name}
              place={item.place}
              placeText={otherPrizesPlaceText}
              isVertical={false}
              showAmount={item.amount!==undefined && item.amount!=='0'}
            />
          ))}
      </Stack>
    </Box>
  )
}

const winnersPlaceText = {
  first: 'Winner',
  second: '2-nd place',
  third: '3-rd place',
} as WinnerPlaceTextT

const otherPrizesPlaceText = {
  first: 'Prize',
  second: 'Prize',
  third: 'Prize',
  default: 'Prize',
} as WinnerPlaceTextT

export default TournamentWinnersWrapper

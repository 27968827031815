import { metamaskWallet, useConnect, useDisconnect, useEmbeddedWallet } from "@thirdweb-dev/react";
import { showErrorPopup } from "src/actions/dialogActions";
import { UserType, WalletType } from "src/helpers/types"
import { useAppDispatch } from "src/hooks";
import { parseMetamaskError } from "src/utils/errorParser";
import SignerManager from "src/utils/signerManager";
import WalletSelectDialog from '../SelectWalletDialog/SelectWalletDialog';
import { useCallback, useEffect, useState } from "react";

export type SelectWalletProps = {
    chainId: number,
    open: boolean,
    onClose: () => void;
}

export default function SelectWallet({ chainId, open, onClose }: SelectWalletProps) {
    const [cartWalletModalIsOpen, setCartWalletModalIsOpen] = useState(false);
    const dispatch = useAppDispatch();
    const connectTo = useConnect();
    const metamaskConfig = metamaskWallet(
        {projectId: '633be614e1a54f275007f6cb5dd5327d', connectionMethod: 'walletConnect', recommended: true} //metamaskBrowser
      );
    /*
    const embeddedConfig = embeddedWallet({
              auth: {
                options: ["email", "google", "facebook", "apple"],
              },
              recommended: true,
            });
        
    const walletConnectConfig = walletConnect( {projectId: '633be614e1a54f275007f6cb5dd5327d',qrModal: "walletConnect"});
    */
    const { connect } = useEmbeddedWallet();
    const disconnect = useDisconnect();    

    useEffect(() => {
        setCartWalletModalIsOpen(open);
      }, [open]);

    const handleClose = useCallback(() => {
        setCartWalletModalIsOpen(false);
        onClose(); // Ensure parent component knows the dialog is closed
    }, [onClose]);

    const walletSelected = async (wallet: string): Promise<void> =>{
        handleClose();
        if (wallet === WalletType.METAMASK) {
          try{
            const wallet = await connectTo(metamaskConfig,{chainId: chainId});
            const signer = await wallet.getSigner();
            SignerManager.signer = signer;
            dispatch({ type: 'LOGIN_USER_SMART_WALLET', loginType: WalletType.METAMASK});
          }
          catch(error){
              const msg = parseMetamaskError(error);
              dispatch(showErrorPopup('Connect error', msg));
              await disconnect();
              console.log(error)
          }
        }
        else if (wallet === WalletType.EMBEDDED) {
          try{          
            const wallet = await connect({            
              strategy: "iframe",
            });
            const email = await wallet.getEmail();
            const signer = await wallet.getSigner();
            SignerManager.signer = signer;
            dispatch({ type: 'LOGIN_USER_SMART_WALLET', loginType: WalletType.EMBEDDED, email: email});
          }
          catch(error){
            dispatch(showErrorPopup('Connect error', (error as Error).message));
            await disconnect();
            console.log(error)
          }
        }
        else{
          dispatch(showErrorPopup('Wallet not supported'));
        }
      }   

    return (
        <>
            <WalletSelectDialog 
                open={cartWalletModalIsOpen}  
                onClose={handleClose}
                onCancel={handleClose}
                walletSelected={walletSelected}
            />
        </>
    );

}
import { Token } from '@mui/icons-material';
import { TokenType } from '../helpers/types'

let user = JSON.parse(localStorage.getItem('user'));
let userInfoStorage = JSON.parse(localStorage.getItem('userInfo'));
userInfoStorage = userInfoStorage ? userInfoStorage : { 

};

let initialState = user ? {
    loggedIn: true,
    user,
    userInfo: {   
        playedGamesStatistics:[],
    },
    userInfoFresh: false,
    cookies: [],
    transactions: {
        result: []
    },
    working:false 
} :
{
    loggedIn: false,
    user: {},
    userInfo: {       
        playedGamesStatistics:[],
   
    },
    userInfoFresh: false,
    cookies: [],
    transactions: {
        result: []
    },
    working:false    
};

//TO handle ts checking
initialState = {
    ...initialState,
    userInfo:{
        ...userInfoStorage
    }
}

export function userReducer(state = initialState, action) {
    switch (action.type) {
        case "IS_WORKING":
            return {
                ...state,
                working: true,
               
            }
        case "FINISHED_WORKING":
            return {
                ...state,
                working: false,

            }
        case "USER_LOGGED_IN":
            return {
                ...state,
                working: false,
                loggedIn: true,
                user: {
                    token: action.userData.token,
                    expires: action.userData.expires
                },
                type: 'alert-success',
                message: '',
                userInfo: action.userData.user,
                userInfoFresh: true,
                transactions: {
                    result: []
                }
            }
        case "LOGIN_FAILED":
            return {
                ...state,
                working: false,
                loggedIn: false,
                user: {},
                type: 'alert-danger',
                message: action.payload,
                userInfo: {
                    playedGamesStatistics:[]
                },
                userInfoFresh: false,
                transactions: {
                    result: []
                }
            }
        case "USER_INFO":
            var coins;
            if (action.userInfo.coins===undefined)
                coins = state.userInfo.coins; //To preserve coins when userinfo does not have coins
            else
                coins = action.userInfo.coins
            
            return {
                ...state, 
                userInfo: {
                    ...action.userInfo,
                    coins: coins,
                },
                userInfoFresh: true,
            }
        case "PICTURE_INFO":
            return {
                ...state,
                userInfo: { ...state.userInfo, ...action.pictureInfo }

            }
        case "AVATAR_UPDATED":
            return {
                ...state,
                userInfo: { 
                    ...state.userInfo, 
                    profilePictureUrl: action.profilePictureUrl,
                    profilePictureUpdated: action.profilePictureUpdated 
                }

            }
        case "UPDATE_USER_SUCCESS":
            return {
                ...state,
                userInfo: { ...state.userInfo, ...action.userInfo }
            }
        case "2FA_VERIFICATION": 
            return {
                ...state,
                cookies: action.cookies
            }
        case "TRANSACTION_INFO": 
            return {
                ...state,
                transactions: action.transactions
            }    
        case "LOGOUT":
            return {
                ...state,
                loggedIn: false,
                user: {},
                userInfo: {
                    playedGamesStatistics:[]
                },
                userInfoFresh: false,
                cookies: [],
                transactions: {
                    result: []
                },
                matchesPlayed: []
            };
        case "USER_BALANCE":
            return {
                ...state,
                userInfo: { 
                    ...state.userInfo, 
                    coins: {
                        ...state.userInfo.coins,
                        ...action.balance 
                    }
                },
            }
        case "USER_BALANCE_TOKEN":

            // determine which coin to update
            let coinToUpdate = null;
            if (action.tokenType === TokenType.WETH) {
                coinToUpdate = 'weth';
            } else if (action.tokenType === TokenType.CT) {
                coinToUpdate = 'challengeCoins';
            } else if (action.tokenType === TokenType.ETH) {
                coinToUpdate = 'eth';
            }

            if (coinToUpdate) {
                return {
                    ...state,
                    userInfo: { 
                        ...state.userInfo,
                        coins: {
                            ...state.userInfo.coins,
                            [coinToUpdate]: action.balance,
                        } 
                    },
                }
            }
            return state;

        case "CONNECTED_ACCOUNT":  
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    connectedGames: state.userInfo.connectedGames.map((item, index) => {   
                        if (item.intGameId === action.account.intGameId) {
                            return {
                                ...item,
                                nickname: action.account.nickname,
                                connected: action.account.connected,
                            }
                        }
                        return item;
                    })
                } 
            }     
        default:
            return state
    }
}
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useWidth from 'src/helpers/hooks/useWidth'
import FrontlandLobby from 'src/pages/lobby/frontland'
import FortniteLobby from 'src/pages/lobby/fortnite'
import Popup from 'src/components/Popup/Popup'
import { useAppSelector, useAppDispatch } from 'src/hooks'
import { getSortedParticipants } from "src/selectors/participantSortSelector";
import { getSortedLogEventsCollection } from 'src/selectors/logEventsSelector'
import { subscribeToLobby,leave, closeLobby } from "src/actions/challengeActions";
import { ActiveChallengeStatus, ChallengeStatus, GameId } from 'src/utils/const'
import { frontlandLobbyDataMapper, fortniteLobbyDataMapper, liveEventMapper } from 'src/helpers/data'
import NotFound from '../notFound'

export default function Lobby() {
  const { challengeId }  = useParams<{ challengeId: string }>()
  const dispatch =  useAppDispatch();
  const width = useWidth();
  const [open, setOpen] = React.useState(false)
  
  useEffect(() => {
    dispatch(subscribeToLobby(challengeId,false));
  }, [dispatch,challengeId])

  const user = useAppSelector(state => state.userReducer.userInfo);
  const activeChallengeStatus = useAppSelector(state => state.challengeReducer.activeChallengeStatus);
  const activeChallenge = useAppSelector(state => getSortedParticipants(state, +(challengeId as string)));  
  const logEvents = useAppSelector(state => getSortedLogEventsCollection(state, +(challengeId as string)));  
  
  if(activeChallengeStatus === ActiveChallengeStatus.NOT_FOUND){
    return <NotFound text={'Challenge not found'}/>
  }
  else if(activeChallengeStatus === ActiveChallengeStatus.NOT_LOADED || activeChallenge===undefined)
    return null;
  

  const quitButtonAction = () => {
    if(activeChallenge.error){
      return {
        quitAction:() => dispatch(closeLobby(activeChallenge.id, activeChallenge.tournamentId!==undefined ? `/tournaments/${activeChallenge.tournamentId}` : '/')),
        quitDisabled: false,
        quitButtonText: 'Close Lobby'
      }
    }
    switch(activeChallenge.status){
      case ChallengeStatus.NOT_STARTED:
        return activeChallenge.isParticipating && activeChallenge.tournamentId===undefined ? 
            {
              quitAction: () => setOpen(true),
              quitDisabled: false,
            }
          :
            {
              quitAction: () => dispatch(closeLobby(activeChallenge.id, activeChallenge.tournamentId!==undefined ? `/tournaments/${activeChallenge.tournamentId}` : '/')),
              quitDisabled: false,
              quitButtonText: 'Close Lobby'
            }
      case ChallengeStatus.FINAL_DONE:
      case ChallengeStatus.CANCELLED:
        return {
          quitAction:() => dispatch(closeLobby(activeChallenge.id, activeChallenge.tournamentId!==undefined ? `/tournaments/${activeChallenge.tournamentId}` : '/')),
          quitDisabled: false,
          quitButtonText: 'Close Lobby'
        
        }
      default:
        return {
          quitAction: () => undefined,
          quitDisabled: true,
        }
    }
  };

  function QuitDialog() {
    return  (
              <Popup
                title={'Quit lobby'}
                description={
                  'Are you sure you want to leave lobby? If you decide to leave you will be refunded the Challenge fee'
                }
                iconData={{
                  name: 'logout-box-line',
                }}
                cancelButtonData={{
                  label: 'Cancel',
                  onClick: () => {                   
                    setOpen(false)
                },
                }}
                primaryButtonData={{
                  label: 'Yes, exit lobby',
                  variant: 'contained',
                  onClick: () => {
                    setOpen(false)
                    dispatch(leave(activeChallenge.id, activeChallenge.blockchainChallengeId, activeChallenge.fee, activeChallenge.type, false))
                  }
                }}
                open={open}
                onClose={() => {
                    setOpen(false)
                }}
              />
            )
  }

  
  let events = liveEventMapper(logEvents,user, activeChallenge.status, activeChallenge.description, activeChallenge.intGameId);

  
  if (activeChallenge.intGameId === GameId.FRONTLAND) {
    let data = frontlandLobbyDataMapper(activeChallenge,user,width);  
    if(data!==undefined){
      let quitAction = quitButtonAction();
      data.challengeDetailsCardProps.onQuit = quitAction.quitAction;
      data.challengeDetailsCardProps.quitDisabled = quitAction.quitDisabled;
      data.challengeDetailsCardProps.quitButtonText = quitAction.quitButtonText;
    }
    return (
          <>
              <FrontlandLobby {...data} status={activeChallenge.status} liveUpdateList={events} />
              <QuitDialog/>
          </>
    );
  } 
  else if (activeChallenge.intGameId === GameId.FORTNITE) {
    let data = fortniteLobbyDataMapper(activeChallenge,user,width);  
    if(data!==undefined){
      let quitAction = quitButtonAction();
      data.challengeDetailsCardProps.onQuit = quitAction.quitAction;
      data.challengeDetailsCardProps.quitDisabled = quitAction.quitDisabled;
      data.challengeDetailsCardProps.quitButtonText = quitAction.quitButtonText;
    }

    return ( 
      <>
          <FortniteLobby {...data} status={activeChallenge.status} liveUpdateList={events}/>
          <QuitDialog/>
      </>
    );
  } else {
    return null
  }
}

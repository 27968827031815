import React from 'react'
import { Box, Stack, useTheme } from '@mui/material'
import Typography from '../Typography/Typography'
import Image, { ImageProps } from 'src/components/Image/Image'
import Link from '../Link/Link'
import SvgIcon from '../SvgIcon/SvgIcon'
import ImageWithTitle from '../ImageWithTitle/ImageWithTitle'
import CustomHorizontalDivider from '../CustomHorizontalDivider/CustomHorizontalDivider'
import PatternLines from '../PatternLines/PatternLines'
import useMediaQuery from '@mui/material/useMediaQuery'

export type GameDownloadBanerProps = {
  photoImgSrc?: ImageProps['src']
  iconSrc: ImageProps['src']
  title: string
  subtitle: string
  longText?: string
  link: string
  target?: string
  disabled?: boolean
}

export default function GameDownloadBanner({
  photoImgSrc,
  iconSrc,
  title,
  subtitle,
  longText,
  link,
  target,
  disabled,
}: GameDownloadBanerProps) {
  const theme = useTheme()
  const isLgView = useMediaQuery(theme.breakpoints.down('lg'))
  const isMdView = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Stack direction={{ xs: 'column', sm2: 'row' }} pt="4px" width="100%">
      <Box
        sx={{
          mr: '78px',
          position: 'relative',
          mb: '96px',
          '& img': {
            border: '1px solid #444',
            display: 'block',
            zIndex: 1,
            position: 'relative',
            zoom: 1,
          },
          '&:before': {
            display: 'block',
            content: '" "',
            position: 'absolute',
            top: -4,
            left: -4,
            width: 16,
            height: 16,
            borderLeft: `4px solid ${theme.palette.primary.main}`,
            borderTop: `4px solid ${theme.palette.primary.main}`,
            zIndex: 1,
            zoom: 1,
          },
          width: '500px',
          minWidth: '500px',
          height: '400px',
          [theme.breakpoints.down('lg')]: {
            width: '375px',
            minWidth: '375px',
            height: '397px',
            mr: '31px',
            mb: '38px',
          },
          [theme.breakpoints.down('sm2')]: {
            width: '100%',
            minWidth: '100%',
            mr: 0,
          },
          [theme.breakpoints.down('sm')]: {
            height: '264px',
          },
        }}
      >
        <PatternLines
          top={{ xs: '2px', sm: '12px' }}
          right={{ xs: '-16px', sm: '-28px', sm2: '-13px', lg: '-28px' }}
          bottom={{ xs: '-20px', sm2: '96px', lg: '-28px' }}
          height="auto"
        />
        <Box width="100%" height="100%" overflow="hidden">
          <Image
            src={photoImgSrc}
            width="100%"
            height="100%"
            sx={{
              objectFit: 'cover',
            }}
          />
        </Box>
      </Box>
      <Box>
        <CustomHorizontalDivider rootStackProps={{ mb: { xs: '20px', sm2: '36px' } }} />
        <ImageWithTitle
          typographyProps={{ variant: 'h3' }}
          rootStackProps={{
            direction: 'column',
            alignItems: 'baseline',
            spacing: { xs: '7px', sm: '8px' },
            mb: { xs: '21px', sm: '28px', lg: '37px' },
          }}
          imageProps={{ src: iconSrc }}
          imageBoxProps={{ size: 'medium' }}
          title={title}
        />
        <Typography
          variant={isLgView ? 'labelMedium' : 'labelBig'}
          mb={{ xs: '16px', lg: '12px' }}
          sx={{ display: 'block' }}
        >
          {subtitle}
        </Typography>
        <Typography
          variant={isMdView ? 'bodyMedium' : 'bodyBig'}
          mb={{ xs: '21px', sm: '28px', lg: '35px' }}
          maxWidth="690px"
        >
          {longText}
        </Typography>
        <Link target={target} href={link} disabled={disabled}  size="medium" startIcon={<SvgIcon name="windows-fill" />}>
          Download for free
        </Link>
      </Box>
    </Stack>
  )
}

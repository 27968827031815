import React from 'react'
import GameCardButton from '../GameCardButton/GameCardButton'
import Image, { ImageProps } from '../Image/Image'
import { Box, Stack, BoxProps as MuiBoxProps, useTheme, useMediaQuery } from '@mui/material'
import Typography from '../Typography/Typography'
import TitleWithDot from '../TitleWithDot/TitleWithDot'
import PatternLines from '../PatternLines/PatternLines'
import CustomHorizontalDivider from '../CustomHorizontalDivider/CustomHorizontalDivider'
import TournamentIconTextPair, {
  TournamentIconTextPairProps,
} from '../TournamentIconTextPair/TournamentIconTextPair'
import Button from '../Button/Button'
import SvgIcon from '../SvgIcon/SvgIcon'
import TournamentBannerTimer, {
  TournamentBannerTimerProps,
} from '../TournamentBannerTimer/TournamentBannerTimer'

export type TournamentBannerProps = {
  /** [The root element of this component uses the BoxProps from Material UI](https://mui.com/material-ui/react-box/) */
  BoxProps?: MuiBoxProps
  bodyImgSrc: ImageProps['src']
  title: string
  subTitle?: string
  bgText?: string
  buttonText?: string
  data?: TournamentIconTextPairProps[]
  timer?: TournamentBannerTimerProps
  onClick?: () => void
}

function TournamentBanner({
  BoxProps,
  bodyImgSrc,
  title,
  subTitle,
  bgText,
  buttonText,
  data,
  timer,
  onClick,
}: TournamentBannerProps) {
  const theme = useTheme()
  const isLg2 = useMediaQuery(theme.breakpoints.down('lg2'))
  const isMd834 = useMediaQuery(theme.breakpoints.down('md834'))
  const bannerButtonText = buttonText!=undefined ? buttonText: 'See Details';

  const textPairArrayValueStyle = {
    [theme.breakpoints.down('md834')]: {
      flexGrow: 1,
      width: '100%',
      ml: '0 !important',
      mt: '10px',
    },
  }

  return (
    <Box width="100%" border="1px solid" borderColor="gray.700" p="1px" {...BoxProps}>
      <Stack
        direction="row"
        height="196px"
        sx={{
          [theme.breakpoints.down('md834')]: { height: 'auto', flexDirection: 'column' },
        }}
      >
        <Box flexGrow={1} position="relative" overflow="hidden">
          <Image src={bodyImgSrc} alt="Game banner" fill sx={{ filter: 'blur(1px)' }} />

          {bgText && (
            <Typography
              color="white"
              position="absolute"
              bottom={0}
              left="45px"
              fontSize="144px"
              height="115px"
              lineHeight="100%"
              sx={{ opacity: '0.03' }}
            >
              {bgText}
            </Typography>
          )}

          <Box
            position="absolute"
            top={0}
            left={0}
            bottom={0}
            width="324px"
            sx={{
              background: 'linear-gradient(270deg, #040811 0%, rgba(4, 8, 17, 0) 100%)',
              transform: 'rotate(180deg)',
            }}
          />
          <Stack
            direction="row"
            height="100%"
            zIndex={1}
            position="relative"
            sx={{
              [theme.breakpoints.down('md834')]: {
                flexDirection: 'column',
              },
            }}
          >
            <Stack
              direction="row"
              position="relative"
              alignItems="center"
              height="100%"
              pl="44px"
              sx={{
                [theme.breakpoints.down('lg2')]: {
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  pl: '24px',
                },
                [theme.breakpoints.down('md834')]: {
                  pt: '3px',
                  pb: timer ? '8px' : '17px',
                  pl: '14px',
                },
              }}
            >
              <PatternLines
                width="28px"
                height="115px"
                ml="0"
                left="0"
                top="38px"
                position="absolute"
                color="#FFFFFF"
                sx={{
                  opacity: 0.1,
                  [theme.breakpoints.down('lg2')]: {
                    display: 'none',
                  },
                  [theme.breakpoints.down('md834')]: {
                    display: 'block',
                    width: '7px',
                    top: '18px',
                  },
                }}
              />
              <Box width={{ xs: '100%', lg2: 'auto' }} mr="54px">
                <Typography
                  variant={isLg2 ? 'labelSmall' : 'labelMedium'}
                  color="primary.light"
                  mt="12px"
                  mb="9px"
                  display="block"
                  sx={{
                    [theme.breakpoints.down('lg2')]: { mt: '25px', mb: '8px' },
                    [theme.breakpoints.down('md834')]: {
                      mt: '12px',
                      mb: '4px',
                    },
                  }}
                >
                  {title}
                </Typography>

                <TitleWithDot
                  rootStackProps={{ spacing: { xs: '5px', lg2: '9px' } }}
                  dotProps={{ bottom: { xs: '8px', lg2: '12px' } }}
                >
                  <div>
                    <Typography variant={isLg2 ? 'h6' : 'h3'} color="inherit">
                      {subTitle}
                    </Typography>
                  </div>
                </TitleWithDot>

                <CustomHorizontalDivider
                  thickLineColor="gray.200"
                  rootStackProps={{ mt: { xs: '1px', md834: '7px' } }}
                />
              </Box>

              <Stack
                flexGrow={1}
                direction="row"
                flexWrap="wrap"
                spacing={{ xs: '37px', md834: '18px', lg2: '29px' }}
                mt="8px"
                sx={{
                  [theme.breakpoints.down('lg2')]: { mt: '23px' },
                  [theme.breakpoints.down('md834')]: {
                    mt: '15px',
                    ml: timer ? 'auto' : '0',
                    mr: timer ? 'auto' : '0',
                  },
                }}
              >
                {data &&
                  data.map((item, index) => (
                    <TournamentIconTextPair
                      key={index}
                      title={item.title}
                      icon={item.icon}
                      value={item.value}
                      prizeIconSrc={item.prizeIconSrc}
                      otherPrizes={item.otherPrizes}
                      StackProps={{
                        sx: Array.isArray(item.value) ? { ...textPairArrayValueStyle } : {},
                      }}
                    />
                  ))}
              </Stack>
            </Stack>

            {timer && (
              <Stack
                alignItems="center"
                pt="33px"
                flexGrow="1"
                sx={{
                  [theme.breakpoints.down('lg2')]: {
                    alignItems: 'flex-end',
                    pr: '20px',
                  },
                  [theme.breakpoints.down('md834')]: {
                    pt: '0',
                    pr: '0',
                    alignItems: 'center',
                    mb: '27px',
                  },
                }}
              >
                <TournamentBannerTimer {...timer} />
              </Stack>
            )}
          </Stack>
        </Box>
        {isMd834 ? (
          <Button
            onClick={onClick}
            variant="contained"
            size="medium"
            sx={{ justifyContent: 'space-between' }}
            endIcon={<SvgIcon name="arrow-right-up-line" />}
          >
            {bannerButtonText}
          </Button>
        ) : (
          <GameCardButton
            label={bannerButtonText}
            onClick={onClick}
            sx={{
              maxHeight: '100%',
              pr: '25px',
            }}
          />
        )}
      </Stack>
    </Box>
  )
}

export default TournamentBanner

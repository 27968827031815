import React from 'react'
import { Box, BoxProps, useMediaQuery, useTheme } from '@mui/material'
import VideoCardMedia from 'src/components/VideoCardMedia/VideoCardMedia'
import ImageCardMedia from 'src/components/ImageCardMedia/ImageCardMedia'
import ShadowBox, { ShadowBoxProps } from 'src/components/ShadowBox/ShadowBox'

type LobbyPageWrapperProps = BoxProps & {
  imageProps?: {
    src: string
    alt?: string
  }
  videoProps?: {
    src: string
  }
  leftShadowBoxProps?: ShadowBoxProps
  rightShadowBoxProps?: ShadowBoxProps
  bottomShadowBoxProps?: ShadowBoxProps
  height?: string
}

export default function PageMediaWithShadowBox({
  imageProps,
  videoProps,
  leftShadowBoxProps,
  rightShadowBoxProps,
  bottomShadowBoxProps,
  height = '398px',
  ...restRootBoxProps
}: LobbyPageWrapperProps) {
  const theme = useTheme()
  const isMdView = useMediaQuery(theme.breakpoints.down('md'))
  const isSm2View = useMediaQuery(theme.breakpoints.down('sm2'))
  const mobileHeight = isSm2View ? '192px' : '370px'

  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      zIndex={0}
      height={isMdView ? mobileHeight : height}
      sx={{
        [theme.breakpoints.down('lg4')]: {
          left: '-28px',
          right: '-28px',
        },
        [theme.breakpoints.down('sm2')]: {
          top: '8px',
        },
        [theme.breakpoints.down('sm')]: {
          left: '-16px',
          right: '-16px',
        },
      }}
      {...restRootBoxProps}
    >
      <Box position="relative">
        <ShadowBox
          height={{ xs: '29px', sm2: '75px' }}
          maxWidth="100%"
          bottom={0}
          zIndex={2}
          sx={{
            background: 'linear-gradient(180deg, rgba(4, 8, 17, 0) 0%, #040811 100%)',
            [theme.breakpoints.down('md')]: {
              maxWidth: '100%',
            },
            [theme.breakpoints.down('sm')]: {
              maxWidth: '100%',
            },
          }}
          {...bottomShadowBoxProps}
        />
        <Box
          position="relative"
          bgcolor="gray.900"
          height={isMdView ? mobileHeight : height}
          mx={{ xs: '0', md: '8px' }}
          border="1px solid"
          borderColor="gray.700"
          overflow="hidden"
        >
          {!imageProps?.src && videoProps?.src && <VideoCardMedia {...videoProps} fill />}
          {!videoProps?.src && imageProps?.src && <ImageCardMedia {...imageProps} fill />}
          <ShadowBox {...leftShadowBoxProps} sx={{ transform: 'matrix(-1, 0, 0, 1, 0, 0)' }} />
          <ShadowBox
            right={0}
            sx={{
              background: 'linear-gradient(270deg, rgba(4, 8, 17, 0.5) 0%, rgba(4, 8, 17, 0) 100%)',
            }}
            {...rightShadowBoxProps}
          />
        </Box>
      </Box>
    </Box>
  )
}

import React from 'react'
import { Box, BoxProps as MuiBoxProps } from '@mui/material'
import Image, { ImageProps } from '../Image/Image'
type ModalHeaderImageProps = {
  /** [The root element of this component uses the BoxProps from Material UI](https://mui.com/material-ui/react-box/) */
  BoxProps?: MuiBoxProps
  image: ImageProps['src']
}

function ModalHeaderImage({ image, BoxProps }: ModalHeaderImageProps) {
  return (
    <Box
      height="101px"
      width="100%"
      flexGrow={1}
      position="relative"
      overflow="hidden"
      {...BoxProps}
    >
      <Image src={image} alt="Game banner" fill />
    </Box>
  )
}

export default ModalHeaderImage

import React from 'react'
import { Box } from '@mui/material'
import Button, { ButtonProps } from '../Button/Button'
import Typography from '../Typography/Typography'
import SvgIcon from '../SvgIcon/SvgIcon'
import CoinImageWithAmount from '../CoinImageWithAmount/CoinImageWithAmount'

export type ChallengeCardButtonProps = Pick<
  ButtonProps,
  'endIcon' | 'disabled' | 'size' | 'sx' | 'fullWidth' | 'onClick'
> & {
  label: React.ReactNode | string
  subLabel?: string
  iconSrc?: string
}

export default function ChallengeCardButton({
  label,
  subLabel,
  iconSrc,
  size = 'medium',
  endIcon = <SvgIcon name="arrow-right-line" />,
  ...restProps
}: ChallengeCardButtonProps) {

  return (
    <Button variant="challenge" size={size} endIcon={endIcon} {...restProps} >
      <Box textAlign="left">
        <Typography
          variant="buttonMedium"
          sx={{
            lineHeight: '18px',
            display: 'block',
          }}
          textAlign="left"
          color="gray.800"
        >
          {label}
        </Typography>
       {iconSrc !== undefined ?
        <CoinImageWithAmount
          amount={subLabel} 
          avatarProps={{ src: iconSrc, sx: { width: '18px', height: '18px',
          '& img': {
            width: '100% !important',
            height: '100% !important',
          } }}}
          amountTypographyProps={{color: 'gray.800'}}
          />
       :
        <Typography
          variant="bodySmall"
          textAlign="left"
          color="gray.800"
          sx={{ lineHeight: '12px' }}
        >
          {subLabel}
        </Typography> 
      }
      </Box>
    </Button>
  )
}

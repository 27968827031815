import { Box, BoxProps, Collapse, Grid, useTheme } from '@mui/material'

import React, { ReactElement } from 'react'
import Button from 'src/components/Button/Button'
import Image, { ImageProps } from 'src/components/Image/Image'
import Typography from 'src/components/Typography/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

export type EnterChallengeBodyProps = {
  title: string
  description: string
  showDescriptionLength: number
  tableData?: Array<
    Array<{ text: string; picture: ImageProps['src']; imageSx?: BoxProps['sx'] } | string>
  >
}
export const EnterChallengeBody = (props: EnterChallengeBodyProps) => {
  const [readMore, setReadMore] = React.useState(false)
  const theme = useTheme()
  const isSmView = useMediaQuery(theme.breakpoints.down('sm'))
  const descriptionLength = props.description.length
  const showDescriptionLength = props.showDescriptionLength
  const descriptionIsLong = descriptionLength > showDescriptionLength
  const commonButtonSx = {
    backgroundColor: 'transparent',
    color: theme.palette.gray[25],
    '&:hover': {
      backgroundColor: 'transparent !important',
      color: theme.palette.gray[0],
    },
  }
  return (
    <Grid
      container
      flexDirection="column"
      sx={{
        gap: '25px',
        [theme.breakpoints.down('md')]: {
          gap: '20px',
        },
        [theme.breakpoints.down('sm')]: {
          gap: '18px',
          pt: '25px',
        },
      }}
    >
      <Typography variant={isSmView ? 'h6' : 'h5'} align="center">
        {props.title}
      </Typography>
      {props.tableData && (
        <Box display={'grid'} gap="4px" margin={{ xs: '0', sm: '0px -4px' }}>
          <Grid container flexDirection={'column'} sx={{ gap: '4px' }}>
            {!!props.tableData?.length &&
              props.tableData.map((row, rowIndex) => (
                <Grid
                  container
                  key={`table row${rowIndex}`}
                  justifyContent={'space-between'}
                  sx={{
                    height: '24px',
                    padding: { xs: '0px 5px', sm: '0px 7px', md: '0px 4px' },
                    alignItems: 'center',
                    '&:nth-of-type(even)': {
                      backgroundColor: theme.palette.gray[700],
                    },
                    borderRadius: '1px',
                  }}
                >
                  {!!row?.length &&
                    row.map((column, columnIndex) => {
                      let content: ReactElement | string | undefined

                      if (typeof column === 'object') {
                        content = (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '10px',
                            }}
                          >
                            <Box
                              component={Image}
                              src={column.picture}
                              alt={column.text}
                              sx={{
                                width: '28px',
                                height: '22px',
                                ...(column.imageSx || {}),
                              }}
                            />
                            {column.text || '-'}
                          </Box>
                        )
                      }

                      if (typeof column === 'string') {
                        content = column
                      }
                      return (
                        <Typography
                          key={`table row${rowIndex} - column${columnIndex}`}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '3px',
                            color:
                              columnIndex === 0 ? theme.palette.gray[200] : theme.palette.gray[50],
                          }}
                          variant={columnIndex === 0 ? 'paragraphSmall' : 'bodySmall'}
                        >
                          {content}
                        </Typography>
                      )
                    })}
                </Grid>
              ))}
          </Grid>
        </Box>
      )}
      <div>
        <Typography
          variant="bodySmall"
          style={{ whiteSpace: 'pre-line' }}
          align="left"
          sx={{
            mt: { xs: '8px', sm: '4px' },
            color: theme.palette.gray[200],
          }}
        >
          {descriptionIsLong ? (
            <>
            {props.description && (
                <span className="inline-text">
                    {readMore
                        ? props.description
                        : `${props.description.substring(0, showDescriptionLength)}...`}
                </span>
            )}          
          </>
          ) : (
            props.description
          )}
        </Typography>
        {descriptionIsLong && (
          <Collapse
            in={!readMore}
            sx={{
              '& .MuiCollapse-wrapperInner': {
                display: 'flex',
                justifyContent: 'center',
              },
            }}
          >
            <Button
              onClick={() => setReadMore(true)}
              size="small"
              sx={{
                mt: '5px',
                height: '15px',
                fontSize: '14px',
                lineHeight: '14px',
                letterSpacing: '0px',
                marginRight: 'auto',
                paddingLeft: '0px',
                ...commonButtonSx,
                [theme.breakpoints.down('md')]: {
                  mt: '9px',
                  mb: '3px',
                  minHeight: '16px',
                },
                [theme.breakpoints.down('sm')]: {
                  mt: '8px',
                  mb: 0,
                  minHeight: '15px',
                },
              }}
            >
              Read More
            </Button>
          </Collapse>
        )}
      </div>
    </Grid>
  )
}

export default EnterChallengeBody

import React from 'react'
// svg
import { ReactComponent as MoreLineSvg } from './more-line.svg'
import { ReactComponent as ArrowRightLine } from './arrow-right-line.svg'
import { ReactComponent as ArrowLeftLine } from './arrow-left-line.svg'
import { ReactComponent as ArrowDownLine } from './arrow-down-line.svg'
import { ReactComponent as AlertError } from './alert-error.svg'
import { ReactComponent as AlertWarning } from './alert-warning.svg'
import { ReactComponent as AlertSuccess } from './alert-success.svg'
import { ReactComponent as ExitIcon } from './exit-icon.svg'
import { ReactComponent as ArrowRightUpLine } from './arrow-right-up-line.svg'
import { ReactComponent as WalletLine } from './wallet-line.svg'
import { ReactComponent as MenuFill } from './menu-fill.svg'
import { ReactComponent as UserLocked } from './user-locked.svg'
import { ReactComponent as LogoutBoxLine } from './logout-box-line.svg'
import { ReactComponent as LogoutBoxLine2 } from './logout-box-line2.svg'
import { ReactComponent as WindowsFill } from './windows-fill.svg'
import { ReactComponent as logoColored } from './logo-colored.svg'
import { ReactComponent as logoWhite } from './logo-white.svg'
import { ReactComponent as logoBlack } from './logo-black.svg'
import { ReactComponent as SnapLineSvg } from './snap-line.svg'
import { ReactComponent as ArrowLeftFill } from './arrow-left-s-fill.svg'
import { ReactComponent as ArrowRightSFill } from './arrow-right-s-fill.svg'
import { ReactComponent as ContactsLine } from './contacts-line.svg'
import { ReactComponent as ListCheck } from './list-check.svg'
import { ReactComponent as Settings5Line } from './settings-5-line.svg'
import { ReactComponent as ImageEditLine } from './image-edit-line.svg'
import { ReactComponent as EditLine } from './edit-line.svg'
import { ReactComponent as EyeLine } from './eye-line.svg'
import { ReactComponent as EyeCloseLine } from './eye-close-line.svg'
import { ReactComponent as FileCopyLine } from './file-copy-line.svg'
import { ReactComponent as HandCoinLine } from './hand-coin-line.svg'
import { ReactComponent as ShareForwardLine } from './share-forward-line.svg'
import { ReactComponent as EpicGames } from './epicgames.svg'
import { ReactComponent as PlayFab } from './playfab.svg'
import { ReactComponent as CheckFill } from './check-fill.svg'
import { ReactComponent as TimeLine } from './time-line.svg'
import { ReactComponent as CloseFill } from './close-fill.svg'
// import { ReactComponent as Frontland2} from './frontland2.svg'
import { ReactComponent as UserGroup2Fill } from './user-group-2-fill.svg'
import { ReactComponent as RepeatLine } from './repeat-line.svg'
import { ReactComponent as PriceTag3Fill } from './price-tag-3-fill.svg'
import { ReactComponent as lineUp } from './lineUI.svg'
import { ReactComponent as Facebook } from './facebook.svg'
import { ReactComponent as Twitter} from './ver2/twitter.svg'
import { ReactComponent as Discord} from './ver2/discord.svg'
import { ReactComponent as Telegram} from './ver2/telegram.svg'
import { ReactComponent as IconDoneVote } from './IconDoneVote.svg'
import { ReactComponent as DoneVoteCircle } from './done-vote-circle.svg'
import { ReactComponent as ArrowShare } from './arrow-share.svg'
import { ReactComponent as HandleGive } from './handle-give.svg'
import { ReactComponent as TeamFill } from './team-fill.svg'
import { ReactComponent as Medal } from './medal.svg'
import { ReactComponent as TrophyFill } from './trophy-fill.svg'
import { ReactComponent as EqualizerLine } from './equalizer-line.svg'
import { ReactComponent as HomeHeartLine } from './home-heart-line.svg'
import { ReactComponent as ArrowRightFill } from './arrow-right-fill.svg'
import { ReactComponent as UserSearchLine } from './user-search-line.svg'
import { ReactComponent as WinnerPlaceNum1 } from './winner-place-num-1.svg'
import { ReactComponent as WinnerPlaceNum2 } from './winner-place-num-2.svg'
import { ReactComponent as WinnerPlaceNum3 } from './winner-place-num-3.svg'
import { ReactComponent as CloseCircleLine } from './close-circle-line.svg'
import { ReactComponent as CheckDoubleLine } from './check-double-line.svg'
import { ReactComponent as InformationLine } from './information-line.svg'
import { ReactComponent as MobileHeroLogo } from 'src/assets/icons/mobile-logo.svg'
import { ReactComponent as Menu4Line } from './menu-4-line.svg'
import { ReactComponent as BarChartBoxLine } from './bar-chart-box-line.svg'
import { ReactComponent as Flag2Line } from './flag-2-line.svg'
import { ReactComponent as PriceTag3Line } from './price-tag-3-line.svg'
import { ReactComponent as TrophyLine } from './trophy-line.svg'
import { ReactComponent as LogoutCircleRLine } from './logout-circle-r-line.svg'
import { ReactComponent as UserLine } from './user-line.svg'
import { ReactComponent as CheckboxCircleLine2 } from './checkbox-circle-line-2.svg'
import { ReactComponent as QuestionnaireLine } from './questionnaire-line.svg'
import { ReactComponent as CheckboxLine } from './checkbox-line.svg'
import { ReactComponent as GroupLine } from './group-line.svg'
import { ReactComponent as ArrowRightSLine } from './arrow-right-s-line.svg'
import { ReactComponent as CalendarEventFill } from './calendar-event-fill.svg'
import { ReactComponent as RefundCCLine } from './refund-CC-line.svg'
import { ReactComponent as Metamask } from './wallets/metamask.svg'
import { ReactComponent as Embedded } from './wallets/embedded.svg'
import { ReactComponent as Locked } from './locked.svg'

// png
import VoteCardIcon from './voteCard.png'
import CTPng from './tokens/ct.png'
import WETHPng from './tokens/token-weth.png'
import ETHPng from './tokens/token-eth.png'
import WCTPng from './tokens/wct.png'
import Fortnite from './fortnite.png'
import FortniteBig from './fortniteBig.png'
import Frontland from './frontland.png'
import FrontlandBig from './frontlandBig.png'
import GraphIcon from './graph.png'
import registrationIconLogo from './registrationIconLogo.png'
import BgImg from '../images/background.png'
import BgImg2 from '../images/background2.png'
import BgHeroImg from '../images/hero.png'
import BgAvailable from '../images/bgAvailable.png'
import BgLogoImg from '../images/logo.png'
import patternLinesInfo from './patternLinesInfo.png'
import LobbyFrontlandDetails from 'src/assets/images/lobbyFrontlandDetails.png'
import TabletLobbyFrontlandDetails from 'src/assets/images/tabletLobbyFrontlandDetails.png'
import TabletLobbyFortniteDetails from 'src/assets/images/tabletLobbyFortniteDetails.png'
import LobbyFortniteDetails from 'src/assets/images/lobbyFortniteDetails.png'
import LobbyFrontlandDetailsMap from 'src/assets/images/lobbyFrontlandDetailsMap.png'
import FortnitePageImg from 'src/assets/images/fortnitePageImg.png'
import TournamentPageImg from 'src/assets/images/tournamentPageImg.png'
import TabletTournamentPageImg from 'src/assets/images/tabletTournamentPageImg.png'
import MobileTournamentPageImg from 'src/assets/images/mobileTournamentPageImg.png'
import TournamentWaitingRoomPageImg from 'src/assets/images/tournamentWaitingRoomPageImg.png'
import TabletTournamentWaitingRoomPageImg from 'src/assets/images/tabletTournamentWaitingRoomPageImg.png'
import Leaderboard from 'src/assets/images/leaderboard.png'
import Coin from 'src/assets/images/coin.png'
import EpicGamesPng from '../images/epicgames.png'
import PlayFabPng from '../images/playfab.png'
import tabletBgChallenges from 'src/assets/images/tabletBgChallenges.png'
import TournamentBanner from '../images/tournament-banner.png'
import EthereumIcon from '../images/ethereum-icon.png'

const ICONS = {
  'more-line': MoreLineSvg,
  'alert-warning': AlertWarning,
  'alert-success': AlertSuccess,
  'alert-error': AlertError,
  'arrow-right-line': ArrowRightLine,
  'arrow-left-line': ArrowLeftLine,
  'arrow-down-line': ArrowDownLine,
  'arrow-right-up-line': ArrowRightUpLine,
  'exit-icon': ExitIcon,
  'wallet-line': WalletLine,
  'user-locked': UserLocked,
  'logout-box-line': LogoutBoxLine,
  'windows-fill': WindowsFill,
  'logo-colored': logoColored,
  'logo-white': logoWhite,
  'logo-black': logoBlack,
  'snap-line': SnapLineSvg,
  // 'frontland2': Frontland2,
  'user-group-2-fill': UserGroup2Fill,
  'repeat-line': RepeatLine,
  'price-tag-3-fill': PriceTag3Fill,
  lineUp: lineUp,
  facebook: Facebook,
  twitter: Twitter,
  discord: Discord,
  telegram: Telegram,
  'done-vote': IconDoneVote,
  'done-vote-circle': DoneVoteCircle,
  'arrow-share': ArrowShare,
  'handle-give': HandleGive,
  'team-fill': TeamFill,
  'arrow-left-fill': ArrowLeftFill,
  'arrow-right-s-fill': ArrowRightSFill,
  'equalizer-line': EqualizerLine,
  medal: Medal,
  'trophy-fill': TrophyFill,
  'home-heart-line': HomeHeartLine,
  'arrow-right-fill': ArrowRightFill,
  'user-search-line': UserSearchLine,
  WinnerPlaceNum1: WinnerPlaceNum1,
  WinnerPlaceNum2: WinnerPlaceNum2,
  WinnerPlaceNum3: WinnerPlaceNum3,
  'close-circle-line': CloseCircleLine,
  'check-double-line': CheckDoubleLine,
  InformationLine: InformationLine,
  'logout-box-line2': LogoutBoxLine2,
  'contacts-line': ContactsLine,
  'list-check': ListCheck,
  'settings-5-line': Settings5Line,
  'image-edit-line': ImageEditLine,
  'edit-line': EditLine,
  'eye-line': EyeLine,
  'eye-close-line': EyeCloseLine,
  'file-copy-line': FileCopyLine,
  'hand-coin-line': HandCoinLine,
  'share-forward-line': ShareForwardLine,
  'epic-games': EpicGames,
  'play-fab': PlayFab,
  'check-fill': CheckFill,
  'time-line': TimeLine,
  'menu-fill': MenuFill,
  'close-fill': CloseFill,
  'mobile-hero-logo': MobileHeroLogo,
  'menu-4-line': Menu4Line,
  'bar-chart-box-line': BarChartBoxLine,
  'flag-2-line': Flag2Line,
  'price-tag-3-line': PriceTag3Line,
  'trophy-line': TrophyLine,
  'logout-circle-r-line': LogoutCircleRLine,
  'user-line': UserLine,
  'checkbox-circle-line-2': CheckboxCircleLine2,
  'questionnaire-line': QuestionnaireLine,
  'checkbox-line': CheckboxLine,
  'group-line': GroupLine,
  'arrow-right-s-line': ArrowRightSLine,
  'calendar-event-fill': CalendarEventFill,
  'refund-CC-line': RefundCCLine,
  'metamask': Metamask,
  'embedded': Embedded,
  'locked': Locked,
}

const PNG_ICONS = {
  ct: CTPng,
  weth: WETHPng,
  eth: ETHPng,
  wct: WCTPng,
  frontland: Frontland,
  frontlandBig: FrontlandBig,
  fortnite: Fortnite,
  fortniteBig: FortniteBig,
  graph: GraphIcon,
  registerLogo: registrationIconLogo,
  voteCard: VoteCardIcon,
  bgImg: BgImg,
  bgImg2: BgImg2,
  bgHeroImg: BgHeroImg,
  bgAvailable: BgAvailable,
  bgLogoImg: BgLogoImg,
  patternLinesInfo,
  lobbyFrontlandDetails: LobbyFrontlandDetails,
  tabletLobbyFrontlandDetails: TabletLobbyFrontlandDetails,
  tabletLobbyFortniteDetails: TabletLobbyFortniteDetails,
  lobbyFortniteDetails: LobbyFortniteDetails,
  lobbyFrontlandDetailsMap: LobbyFrontlandDetailsMap,
  fortnitePageImg: FortnitePageImg,
  tournamentPageImg: TournamentPageImg,
  tabletTournamentPageImg: TabletTournamentPageImg,
  mobileTournamentPageImg: MobileTournamentPageImg,
  tournamentWaitingRoomPageImg: TournamentWaitingRoomPageImg,
  tabletTournamentWaitingRoomPageImg: TabletTournamentWaitingRoomPageImg,
  leaderboard: Leaderboard,
  coin: Coin,
  'epic-games': EpicGamesPng,
  'play-fab': PlayFabPng,
  tabletBgChallenges,
  tournamentBanner: TournamentBanner,
  ethereumIcon: EthereumIcon,
}

export { PNG_ICONS }

export default ICONS as { [key in keyof typeof ICONS]: React.FC<React.SVGProps<SVGSVGElement>> }

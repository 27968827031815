import React from 'react'
import { Box, Stack, Grid, useMediaQuery, useTheme } from '@mui/material'
import Comment from 'src/components/Comment/Comment'
import Button from 'src/components/Button/Button'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import Typography from 'src/components/Typography/Typography'
import Avatar from 'src/components/Avatar/Avatar'

export type Avatar = {
  id: string
  component?: React.ElementType<any>
  src?: string
}

export type SelectAvatarProps = {
  avatars: Avatar[]
  selected?: Avatar
  onSelect?: (item: Avatar) => void
  onNext?: () => void
}

export default function SelectAvatar({ avatars, selected, onSelect, onNext }: SelectAvatarProps) {
  const theme = useTheme()
  const isUpSmView = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <Box sx={{ maxWidth: '630px' }}>
      <Comment>Select avatar</Comment>
      <Stack
        sx={{
          mt: '2px',
          // gap: '2px',
          border: '1px solid',
          borderColor: 'gray.700',
        }}
      >
        <Box
          sx={(theme) => ({
            m: '1px',
            bgcolor: 'gray.800',
            p: '16px 16px 8px',
            [theme.breakpoints.up('sm')]: {
              p: '24px 24px 8px',
            },
          })}
        >
          <Typography variant="bodySmall" color="gray.25" align="center">
            Select your avatar, you can change it later from your Profile
          </Typography>
          <Box
            sx={(theme) => ({
              mt: '20px',
              height: '328px',
              overflowY: 'auto',
              px: '5.5px',
              position: 'relative',
              [theme.breakpoints.up('sm')]: {
                mt: '24px',
                px: '18px',
                height: '274px',
              },
              [theme.breakpoints.up('md')]: {
                px: '3px',
              },
              // width: '307px',
              // '&::-webkit-scrollbar': {},
              // '&::-webkit-scrollbar-track': {},
            })}
          >
            <Grid container gap={{ xs: '8px', sm: '16px' }}>
              {avatars.map((avatar) => {
                const { id, src, component } = avatar
                return (
                  <Grid key={id} item>
                    {src ? (
                      <Avatar
                        selected={selected?.id === id}
                        src={src}
                        onClick={() => onSelect && onSelect(avatar)}
                      />
                    ) : (
                      <Avatar
                        selected={selected?.id === id}
                        component={component}
                        onClick={() => onSelect && onSelect(avatar)}
                      />
                    )}
                  </Grid>
                )
              })}
            </Grid>
          </Box>
        </Box>
        <Box p="1px">
          <Button
            variant="spaceBetween"
            size={isUpSmView ? 'large' : 'big'}
            fullWidth
            endIcon={<SvgIcon name="arrow-right-line" />}
            onClick={onNext}
            disabled={!selected}
          >
            Next
          </Button>
        </Box>
      </Stack>
    </Box>
  )
}

import React from 'react'
import { Box, BoxProps, Stack, StackProps } from '@mui/material'
import assets from 'src/assets'
import Typography, { TypographyProps } from 'src/components/Typography/Typography'
import SvgIconWithTitle, {
  SvgIconWithTitleProps,
} from 'src/components/SvgIconWithTitle/SvgIconWithTitle'
import ImageWithTitle, { ImageWithTitleProps } from 'src/components/ImageWithTitle/ImageWithTitle'
import TitleWithLinkSvgIcon, {
  TitleWithLinkSvgIconProps,
} from 'src/components/TitleWithLinkSvgIcon/TitleWithLinkSvgIcon'
import TournamentPrizesRow, {
  TournamentPrizesRowProps,
} from '../TournamentPrizesRow/TournamentPrizesRow'
import dayjs from 'dayjs'

export enum GamingEventDetails {
  PlayersJoin = 'playersJoin',
  GameMode = 'gameMode',
  Game = 'game',
  TournamentType = 'tournamentType',
  Prizes = 'prizes',
  Date = 'date',
}

export type GamingEventDetailsOptProps = {
  event: `${GamingEventDetails}`
  label?: string
  value: string | number[] | TournamentPrizesRowProps | Date
  href?: string
  props?: object
}

export default function GamingEventDetailsOpt({
  event,
  label,
  value,
  href,
  props,
}: GamingEventDetailsOptProps) {
  if (event === GamingEventDetails.PlayersJoin && Array.isArray(value)) {
    return <PlayersJoinEvent label={label} value={value} {...props} />
  } else if (typeof value === 'string') {
    if (event === GamingEventDetails.GameMode) {
      return <GameModeEvent label={label} value={value} {...props} />
    } else if (event === GamingEventDetails.Date) {
      return <GameDateEvent label={label} value={value} {...props} />
    } else if (event === GamingEventDetails.Game) {
      return <GameEvent label={label} value={value} {...props} />
    } else if (event === GamingEventDetails.TournamentType) {
      return <TournamentTypeEvent label={label} value={value} href={href} {...props} />
    } else {
      return null
    }
  } else if (event === GamingEventDetails.Prizes) {
    return <TournamentPrizesEvent label={label} value={value as TournamentPrizesRowProps | number[]} {...props} />
  } else {
    return null
  }
}

type PlayersJoinEventProps = {
  label?: string
  labelProps?: SvgIconWithTitleProps
  value: number[]
  StackProps?: StackProps
  StackTypographyProps?: StackProps
  currentPlayersTypographyProps?: TypographyProps
  maxPlayersTypographyProps?: TypographyProps
  minPlayersTypographyProps?: TypographyProps
  showDot?: false
  dotProps?: BoxProps
}

function PlayersJoinEvent({
  label = 'Players joined',
  labelProps,
  value,
  StackProps,
  StackTypographyProps,
  currentPlayersTypographyProps,
  maxPlayersTypographyProps,
  minPlayersTypographyProps,
  showDot,
  dotProps,
}: PlayersJoinEventProps) {
  const [currentPlayers, maxPlayers, minPlayers] = value
  return (
    <Stack direction="column" gap="12px" {...StackProps}>
      <SvgIconWithTitle {...labelProps}>{label}</SvgIconWithTitle>
      <Stack direction="row" gap="2px" alignItems="center" height="29px" {...StackTypographyProps}>
        <Typography variant="h6" color="gray.25" {...currentPlayersTypographyProps}>
          {currentPlayers}
        </Typography>
        <Typography
          variant="h7"
          color="gray.50"
          position="relative"
          height="22px"
          {...maxPlayersTypographyProps}
        >
          /{maxPlayers}
          {showDot && (
            <Box
              width="4px"
              height="4px"
              bgcolor="primary.light"
              position="absolute"
              right="-10px"
              bottom="5px"
              {...dotProps}
            />
          )}
        </Typography>
        {minPlayers &&
          <Typography  variant="bodySmall" color="gray.50" {...minPlayersTypographyProps}>
            {`(min ${minPlayers} players)`}
          </Typography>
        }
      </Stack>
    </Stack>
  )
}

type GameModeEventProps = {
  StackProps?: StackProps
  TypographyProps?: TypographyProps
  label?: string
  labelProps?: SvgIconWithTitleProps
  value: string
}

function GameModeEvent({
  label = 'Game mode',
  labelProps,
  value,
  StackProps,
  TypographyProps,
}: GameModeEventProps) {
  return (
    <Stack direction="column" gap="12px" {...StackProps}>
      <SvgIconWithTitle {...labelProps}>{label}</SvgIconWithTitle>
      <Typography
        variant="h7"
        color="gray.25"
        display="flex"
        alignItems="center"
        height="29px"
        {...TypographyProps}
      >
        {value}
      </Typography>
    </Stack>
  )
}

type GameDateEventProps = {
  StackProps?: StackProps
  TypographyProps?: TypographyProps
  label?: string
  labelProps?: SvgIconWithTitleProps
  value: string
}

function GameDateEvent({
  label = 'Game mode',
  labelProps,
  value,
  StackProps,
  TypographyProps,
}: GameDateEventProps) {
  return (
    <Stack direction="column" gap="12px" {...StackProps}>
      <SvgIconWithTitle {...labelProps}>{label}</SvgIconWithTitle>
      <Typography
        variant="h7"
        color="gray.25"
        display="flex"
        alignItems="center"
        height="29px"
        {...TypographyProps}
      >
         {dayjs(value).format('DD MMM YYYY')}  {dayjs(value).format('HH:mm A')}
      </Typography>
    </Stack>
  )
}

type GameEventProps = {
  label?: string
  labelProps?: SvgIconWithTitleProps
  value: string
  StackProps?: StackProps
  ImageWithTitleProps?: ImageWithTitleProps
}

function GameEvent({
  label = 'Game',
  labelProps,
  value,
  StackProps,
  ImageWithTitleProps,
}: GameEventProps) {
  return (
    <Stack direction="column" gap="12px" {...StackProps}>
      <SvgIconWithTitle {...labelProps}>{label}</SvgIconWithTitle>
      <ImageWithTitle
        {...ImageWithTitleProps}
        rootStackProps={{
          spacing: '8px',
          height: '29px',
          ...ImageWithTitleProps?.rootStackProps,
        }}
        imageBoxProps={{
          width: '24px',
          height: '24px',
          ...ImageWithTitleProps?.imageBoxProps,
        }}
        typographyProps={{
          variant: 'h7',
          color: 'gray.25',
          sx: { transform: 'unset' },
          ...ImageWithTitleProps?.typographyProps,
        }}
        imageProps={{
          src: assets.png.frontland,
          width: '18.67px',
          height: '18.67px',
          ...ImageWithTitleProps?.imageProps,
        }}
        title={value}
      />
    </Stack>
  )
}

type TournamentTypeEventProps = {
  label?: string
  labelProps?: SvgIconWithTitleProps
  value: string
  href?: string
  StackProps?: StackProps
  TitleWithLinkSvgIconProps?: TitleWithLinkSvgIconProps
}

function TournamentTypeEvent({
  label = 'Tournament type',
  labelProps,
  value,
  href = '/',
  StackProps,
  TitleWithLinkSvgIconProps,
}: TournamentTypeEventProps) {
  return (
    <Stack direction="column" gap="12px" {...StackProps}>
      <SvgIconWithTitle {...labelProps}>{label}</SvgIconWithTitle>
      <TitleWithLinkSvgIcon
        SvgIconProps={{ name: 'InformationLine', sx: { color: 'gray.50' } }}
        LinkProps={{ href }}
        StackProps={{
          height: '29px',
        }}
        {...TitleWithLinkSvgIconProps}
      >
        {value}
      </TitleWithLinkSvgIcon>
    </Stack>
  )
}

type TournamentPrizesEventProps = {
  label?: string
  labelProps?: SvgIconWithTitleProps
  value: TournamentPrizesRowProps | number[]
  StackProps?: StackProps
}

function TournamentPrizesEvent({
  label = 'Prizes',
  labelProps,
  value,
  StackProps,
}: TournamentPrizesEventProps) {
  return (
    <Stack direction="column" gap="12px" {...StackProps}>
      <SvgIconWithTitle {...labelProps}>{label}</SvgIconWithTitle>
      <TournamentPrizesRow variant="bigText" showCoin={true} {...value} />
    </Stack>
  )
}

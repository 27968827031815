import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Grid, Stack, useMediaQuery, useTheme } from '@mui/material'
import PageContainer from 'src/components/PageContainer/PageContainer'
import ChallengeDetailsCard from 'src/components/ChallengeDetailsCard/ChallengeDetailsCard'
import LiveUpdateList from 'src/components/LiveUpdateList/LiveUpdateList'
import ButtonIcon from 'src/components/ButtonIcon/ButtonIcon'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import TitleWithLoader from 'src/components/TitleWithLoader/TitleWithLoader'
import VerticalDividerWithContent from 'src/components/VerticalDividerWithContent/VerticalDividerWithContent'
import GameNumber from 'src/components/GameNumber/GameNumber'
import Comment from 'src/components/Comment/Comment'
import WinnerLobbyBox from 'src/components/WinnerLobbyBox/WinnerLobbyBox'
import PageMediaWithShadowBox from 'src/components/PageMediaWithShadowBox/PageMediaWithShadowBox'
import TableEnemy from 'src/components/TableEnemy/TableEnemy'
import Typography from 'src/components/Typography/Typography'
import { ChallengeStatus } from 'src/helpers/types'
import Timer from 'src/components/Timer/Timer'
import Button from 'src/components/Button/Button'
/**
 * The FrontlandLobby props are temporarily here only for the storybook to be able to show the Frontland page.
 */

export default function FrontlandLobby({
  status,
  pageMediaWithShadowBoxProps,
  loading,
  title,
  subtitle,
  timerExpirySeconds,
  winner,
  leftTable,
  rightTable,
  challengeDetailsCardProps,
  liveUpdateList,
}: any) {
  const navigate = useNavigate()
  const theme = useTheme()
  const isSm2View = useMediaQuery(theme.breakpoints.down('sm2'))
  const isMdView = useMediaQuery(theme.breakpoints.down('md'))
  // const params = useParams<{ gameId: string }>()

  return (
    <Box bgcolor="gray.900" position="relative">
      <PageMediaWithShadowBox {...pageMediaWithShadowBoxProps} />
      <PageContainer
        sx={{
          minHeight: '398px',
          pt: '18px',
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            minHeight: 'auto',
          },
          [theme.breakpoints.down('sm')]: {
            position: 'relative',
            top: '8px',
            paddingTop: '19px',
            minHeight: 'auto',
          },
        }}
        innerContainerProps={{ sx: { display: 'flex', flexDirection: 'column' } }}
      >
        <>
          <Box
            sx={{
              [theme.breakpoints.down('md')]: {
                position: 'absolute',
                top: '18px',
                left: 0,
                zIndex: 1,
              },
              [theme.breakpoints.down('sm2')]: {
                position: 'absolute',
                top: '28px',
              },
              [theme.breakpoints.down('sm')]: {
                top: '20px',
              },
            }}
          >
            <ButtonIcon
              variant="outlined"
              size="small"
              onClick={() => {
                navigate(-1)
              }}
            >
              <SvgIcon name="arrow-left-line" />
            </ButtonIcon>
            { !isSm2View && !isMdView &&  (status === ChallengeStatus.NOT_STARTED || status === ChallengeStatus.READY_TO_START ) && (
            <Button
              endIcon={<SvgIcon name="arrow-right-s-line" sx={{ marginRight: '4px' }} />}
              sx={{ minWidth: '100px', marginLeft: '12px' }}
              variant="contained"
              size={ 'small'}
              onClick={() => window.open('frontland:start', '_blank')}
            >
                Start Frontland
            </Button>
           )}
                     
          </Box>
          <Box
            position="relative"
            width="100%"
            height="100%"
            pt="18px"
            sx={{
              [theme.breakpoints.down('md')]: {
                pt: '15px',
                height: 'auto',
              },
              [theme.breakpoints.down('sm2')]: {
                pt: '24px',
              },
              [theme.breakpoints.down('sm')]: {
                pt: '11px',
              },
            }}
          >
            <Grid
              container
              height="100%"
              columnSpacing={{ xs: '0', sm2: '28px', lg: '0' }}
              direction={{ xs: 'column', sm2: 'row' }}
            >
              <Grid item xs container alignItems="flex-end" order={{ xs: 2, lg: 1 }}>
                <Box width="100%">
                  <VerticalDividerWithContent rootStackProps={{ mb: { xs: '12px', sm2: '22px' } }}>
                    <Stack direction="row" spacing="3px">
                      <GameNumber count={leftTable.count} />
                      <Comment>{leftTable.text}</Comment>
                    </Stack>
                  </VerticalDividerWithContent>
                  <Box
                    display="flex"
                    minHeight="64px"
                    mb={{
                      xs: '17px',
                      sm2: '38px',
                      md: '50px',
                      lg: status === 'completed' ? '56px' : '50px',
                    }}
                  >
                    <TableEnemy {...leftTable} />
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                sx={{
                  order: 2,
                  [theme.breakpoints.down('lg')]: {
                    order: 1,
                    width: '100%',
                    minHeight: '204px',
                  },
                  [theme.breakpoints.down('sm2')]: {
                    minHeight: '162px',
                  },
                }}
              >
                <TitleWithLoader
                  loading={loading}
                  title={title}
                  spacing={status === 'calculating' ? { md: '48px' } : {}}
                  titleTypographyProps={{
                    maxWidth: '411px',
                    sx: {
                      height: '31px',
                      alignItems: 'flex-start',
                      ...(status === 'calculating' && {
                        alignItems: 'center',
                      }),
                      [theme.breakpoints.down('sm')]: {
                        maxWidth: status === 'calculating' ? '240px' : '411px',
                        height: 'auto',
                        alignItems: 'center',
                      },
                    },
                  }}
                > 
                  {subtitle && <Typography variant="bodySmall">{subtitle}</Typography>}
                  {status === ChallengeStatus.FINAL_DONE && winner && winner.nickname && (
                    <Box
                      sx={{
                        [theme.breakpoints.down('sm')]: {
                          pt: '21px',
                        },
                      }}
                    >
                      <Comment>Winner</Comment>
                      <WinnerLobbyBox
                        place={1}
                        name={winner.nickname}
                        amount={`+ ${winner.prize}`}
                        tokenImgSrc={winner.tokenImgSrc}
                        isVertical={!isMdView}
                        avatarSrc={winner.avatarSrc}
                        showAmount={winner.prize!==undefined && winner.prize!=='0'}
                      />
                    </Box>
                  )}
                  {(timerExpirySeconds) && (
                    <Timer
                      expirySeconds={timerExpirySeconds}
                      pt={{ xs: '1px', sm: '8px', md: 0 }}
                      pb={{ xs: '42px', sm: '46px', md: '6px' }}
                    />
                  )}
                </TitleWithLoader>
              </Grid>
              <Grid container item xs justifyContent="flex-end" alignItems="flex-end" order={3}>
                <Box width={{ xs: '100%', md: 'auto' }}>
                  <VerticalDividerWithContent
                    fromRight={!isSm2View}
                    rootStackProps={{
                      mb: '22px',
                      justifyContent: 'flex-end',
                      sx: {
                        [theme.breakpoints.down('sm2')]: {
                          mb: '12px',
                          justifyContent: 'flex-start',
                        },
                      },
                    }}
                  >
                    <Stack direction="row" spacing="3px">
                      <GameNumber count={rightTable.count} />
                      <Comment>{rightTable.text}</Comment>
                    </Stack>
                  </VerticalDividerWithContent>
                  <Box
                    display="flex"
                    minHeight="64px"
                    mb={{
                      xs: 0,
                      sm2: '38px',
                      md: '50px',
                      lg: status === 'completed' ? '56px' : '50px',
                    }}
                  >
                    <TableEnemy {...rightTable} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      </PageContainer>
      <Box
        pt={{ xs: '44px', sm: 0, md: status === 'completed' ? '89px' : '95px' }}
        pb={{ xs: '24px', sm: '24px', md: '48px' }}
        position="relative"
        zIndex={1}
      >
        <PageContainer>
          <Grid
            container
            gap="30px"
            sx={{
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
                gap: '16px',
              },
            }}
          >
           
              
            <Grid
              item
              sx={{
                [theme.breakpoints.down('md')]: {
                  order: 2,
                  maxHeight: '343px',
                },
              }}
            >
              <ChallengeDetailsCard {...challengeDetailsCardProps}  />
            </Grid>
            <Grid
              item
              xs
              sx={{
                [theme.breakpoints.down('md')]: {
                  order: 1,
                },
              }}
            >
              <LiveUpdateList list={liveUpdateList} />
            </Grid>
          </Grid>
        </PageContainer>
      </Box>
    </Box>
  )
}

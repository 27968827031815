import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Bracket, Seed, SeedItem } from 'react-brackets'
import { IRoundProps } from 'react-brackets/dist/types/Rounds'
import { IRenderSeedProps, ISeedProps } from 'react-brackets/dist/types/Seed'
import { Box } from '@mui/material'
import {
  FILTER,
  useTournamentBraketsFilter,
} from 'src/components/TournamentBraketsFilter/TournamentBraketsFilter'
import TournamentBracket, {
  TOURNAMENT_BRACKET_STATUSES,
} from 'src/components/TournamentBracket/TournamentBracket'
import TournamentViewBox from 'src/components/TournamentViewBox/TournamentViewBox'
import Comment from 'src/components/Comment/Comment'
import { motion, useDragControls } from 'framer-motion'
import { ChallengeStatus } from 'src/helpers/types'

type SingleEliminationBracketProps = {
  rounds: IRoundProps[]
  onWatchGame?: (status:ChallengeStatus, error:boolean, id: string | number) => void
  thirdPlaceMatch?: ISeedProps
}

export default function SingleEliminationBracket({
  rounds,
  onWatchGame,
  thirdPlaceMatch,
}: SingleEliminationBracketProps) {
  const bracketsTableWrapperRefs = useRef<HTMLDivElement | null>(null)
  const bracketsTableRefs = useRef<HTMLDivElement | null>(null)
  const [tableDrag, setTableDrag] = useState(false)

  const filter = useTournamentBraketsFilter()
  const isFilterFull = filter === FILTER.FULL

  const dragControls = useDragControls()

  useEffect(() => {
    if (bracketsTableWrapperRefs.current && bracketsTableRefs.current) {
      const wrapperWidth = bracketsTableWrapperRefs.current.offsetWidth
      const tableWidth = bracketsTableRefs.current.offsetWidth

      if (wrapperWidth < tableWidth) {
        setTableDrag(true)
      }
    }

    const handleResize = () => {
      if (bracketsTableWrapperRefs.current && bracketsTableRefs.current) {
        const wrapperWidth = bracketsTableWrapperRefs.current.offsetWidth
        const tableWidth = bracketsTableRefs.current.offsetWidth

        if (wrapperWidth < tableWidth) {
          setTableDrag(true)
        } else {
          setTableDrag(false)
        }
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <Box ref={bracketsTableWrapperRefs} display="flex">
      <Box
        component={motion.div}
        drag={tableDrag ? 'x' : false}
        dragConstraints={bracketsTableWrapperRefs}
        dragElastic={true}
        dragMomentum={true}
        dragControls={dragControls}
      >
        <Box
          ref={bracketsTableRefs}
          sx={{
            minHeight: '600px',
            userSelect: 'none',
            // minWidth:
            cursor: tableDrag ? 'grab' : 'default',
            '&:active': {
              cursor: tableDrag ? 'grabbing' : 'default',
            },
            '& .Seed-root': {
              px: '29px',
              '&:before': {
                borderColor: '#1E283A !important',
              },
              '&:after': {
                borderColor: '#1E283A !important',
              },
            },
            '& .Bracket .RoundTitle + div': {
              marginTop: isFilterFull ? 0 : '178px',
            },
            '& .Seed-root .Seed-item': {
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
              bgcolor: 'transparent',
              width: '268px',
              textAlign: 'left',
            },
          }}
        >
          <Bracket
            bracketClassName="Bracket"
            rounds={rounds}
            roundTitleComponent={roundTitleComponent(rounds)}
            mobileBreakpoint={0}
            renderSeedComponent={(props) => <CustomSeed {...props} onWatchGame={onWatchGame} thirdPlaceMatch={thirdPlaceMatch} />}
          />
        </Box>
      </Box>
    </Box>
  )
}

type CustomSeedProps = IRenderSeedProps & Pick<SingleEliminationBracketProps, 'onWatchGame'> & { thirdPlaceMatch?: ISeedProps }

const TBD = { status: 'TBD' }

export function CustomSeed(props: CustomSeedProps) {
  // console.log('CustomSeed props', props)
  const { rounds = [], seed, breakpoint, roundIndex, onWatchGame, thirdPlaceMatch } = props

  const semifinalRound = useMemo(
    () => rounds.find((round) => round.filter === FILTER.SEMI_FINAL),
    [rounds]
  )
  const semifinalLoosers = useMemo(
    () =>
      semifinalRound?.seeds.reduce((acc, curr) => {
        const looser = curr.teams.filter((team) => team.error || team.status === TOURNAMENT_BRACKET_STATUSES.LOSE || team.status === TOURNAMENT_BRACKET_STATUSES.EMPTY)

        if (looser) {
          // @ts-ignore
          acc = [...acc, ...looser]
        }
        return acc
      }, []),
    [semifinalRound]
  )

  const isFinal = useMemo(
      () => rounds[roundIndex].filter===FILTER.FINAL,
      [roundIndex, rounds]
  )

  return (
    <Seed mobileBreakpoint={breakpoint} className="Seed-root">
      <SeedItem className="Seed-item">
        <TournamentBracket participant={seed.teams[0]} onWatchGame={() => onWatchGame && seed.id ? onWatchGame(seed.status, seed.error, seed.id): () => {}} />
        <TournamentBracket participant={seed.teams[1]} onWatchGame={() => onWatchGame && seed.id ? onWatchGame(seed.status, seed.error, seed.id): () => {}} />
        {isFinal && thirdPlaceMatch && (
          <Box position="absolute" top="146px" width="100%">
            <Comment>3 Place Match</Comment>
            <Box mt="12px" />
            <Box marginBottom={1.5}>
            <TournamentBracket participant={thirdPlaceMatch.teams[0]} onWatchGame={() => onWatchGame && thirdPlaceMatch.id ? onWatchGame(thirdPlaceMatch.status, thirdPlaceMatch.error, thirdPlaceMatch.id): () => {}} />
            </Box>
            <TournamentBracket participant={thirdPlaceMatch.teams[1]} onWatchGame={() => onWatchGame && thirdPlaceMatch.id ? onWatchGame(thirdPlaceMatch.status, thirdPlaceMatch.error, thirdPlaceMatch.id): () => {}} />
          </Box>
        )}
      </SeedItem>
    </Seed>
  )
}

const roundTitleComponent =
  // eslint-disable-next-line
  (rounds: IRoundProps[]) => (title: React.ReactNode, roundIndex: number) => {
    const renderTournamentViewBox = () => {
      const round = rounds[roundIndex]
      if (typeof title === 'string' && round?.filter) {
        if (round.filter === FILTER.QUARTER_FINAL) {
          return <TournamentViewBox place={3} title={title} />
        } else if (round.filter === FILTER.SEMI_FINAL) {
          return <TournamentViewBox place={2} title={title} />
        } else if (round.filter === FILTER.FINAL) {
          return <TournamentViewBox place={1} title={title} />
        }
      } else {
        return null
      }
      return null
    }

    return (
      <Box className="RoundTitle" position="relative">
        {renderTournamentViewBox()}
      </Box>
    )
  }

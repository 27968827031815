import React from 'react'
import { useTimer } from 'react-timer-hook'
import { Box, BoxProps, Stack, useTheme } from '@mui/material'
import Comment from '../Comment/Comment'
import Typography from '../Typography/Typography'
import TitleWithDot from '../TitleWithDot/TitleWithDot'

type TimerProps = BoxProps & {
  expirySeconds?: number // Timer reference time in seconds
  onExpire?: () => void
  title?: string
  showSeconds?: boolean
  dateLabels?: {
    day?: string
    hrs?: string
    min?: string
    sec?: string
  }
  showZeroTime?: {
    zeroDay?: boolean
    zeroHrs?: boolean
    zeroMin?: boolean
  }
}

export default function Timer({
  expirySeconds = 1200,
  onExpire,
  showSeconds = true,
  title = 'Timer',
  dateLabels,
  showZeroTime,
  ...restProps
}: TimerProps) {
  const theme = useTheme()

  const expiryDate: Date = new Date(new Date().setSeconds(new Date().getSeconds() + expirySeconds))

  // Start Function when timer is stopped, if needed
  const handleExpire = () => {
    if (onExpire && typeof onExpire === 'function') {
      onExpire()
    }
  }

  const {
    seconds,
    minutes,
    hours,
    days,
    //isRunning, // isRunning variable is needed if you need to display the text when the counting reaches zero
  } = useTimer({ expiryTimestamp: expiryDate, onExpire: handleExpire })

  const { day = 'd', hrs = 'hrs', min = 'min', sec = 'sec' } = dateLabels || {}
  const { zeroDay = false, zeroHrs = false, zeroMin = true } = showZeroTime || {}

  return (
    <Box
      display="inline-block"
      sx={{
        width: 'auto',
        [theme.breakpoints.down('xsm')]: {
          width: '100%',
        },
      }}
      {...restProps}
    >
      <Comment rootStackProps={{ mb: '-1px' }}>{title}</Comment>
      <Box
        display="flex"
        alignItems="center"
        bgcolor="gray.900"
        border="1px solid"
        borderColor="gray.700"
        position="relative"
        height="82px"
        p={{ xs: '12px', sm: '12px 16px' }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
          width={{ xs: '100%', sm: 'auto' }}
        >
          {(days > 0 || zeroDay) && (
            <>
              <Typography
                variant="h2"
                color="primary.light"
                mr="5px"
                sx={{
                  [theme.breakpoints.down('sm')]: {
                    ...theme.typography.h3,
                  },
                }}
              >
                {days >= 10 ? days : `0${days}`}
              </Typography>
              <TitleWithDot
                rootStackProps={{ spacing: '2px' }}
                typographyProps={{ variant: 'h7', color: 'gray.0' }}
                dotProps={{ width: '4px', height: '4px', bottom: '4px' }}
              >
                {day}
              </TitleWithDot>
              <Typography alignSelf="center" variant="h6" color="gray.0" ml="15px" mr="21px">
                :
              </Typography>
            </>
          )}
          {(hours > 0 || zeroHrs) && (
            <>
              <Typography
                variant="h2"
                color="primary.light"
                mr="5px"
                sx={{
                  [theme.breakpoints.down('sm')]: {
                    ...theme.typography.h3,
                  },
                }}
              >
                {hours >= 10 ? hours : `0${hours}`}
              </Typography>
              <TitleWithDot
                rootStackProps={{ spacing: '2px' }}
                typographyProps={{ variant: 'h7', color: 'gray.0' }}
                dotProps={{ width: '4px', height: '4px', bottom: '4px' }}
              >
                {hrs}
              </TitleWithDot>
              <Typography alignSelf="center" variant="h6" color="gray.0" ml="15px" mr="21px">
                :
              </Typography>
            </>
          )}

          {(minutes > 0 || zeroMin) && (
            <>
              <Typography
                variant="h2"
                color="primary.light"
                mr="5px"
                sx={{
                  [theme.breakpoints.down('sm')]: {
                    ...theme.typography.h3,
                  },
                }}
              >
                {minutes >= 10 ? minutes : `0${minutes}`}
              </Typography>
              <TitleWithDot
                rootStackProps={{ spacing: '2px' }}
                typographyProps={{ variant: 'h7', color: 'gray.0' }}
                dotProps={{ width: '4px', height: '4px', bottom: '4px' }}
              >
                {min}
              </TitleWithDot>
            </>
          )}

          {showSeconds && (
            <>
              <Typography alignSelf="center" variant="h6" color="gray.0" ml="15px" mr="21px">
                :
              </Typography>
              <Typography
                variant="h2"
                color="primary.light"
                mr="5px"
                sx={{
                  [theme.breakpoints.down('sm')]: {
                    ...theme.typography.h3,
                  },
                }}
              >
                {seconds >= 10 ? seconds : `0${seconds}`}
              </Typography>
              <TitleWithDot
                rootStackProps={{ spacing: '2px' }}
                typographyProps={{ variant: 'h7', color: 'gray.0' }}
                dotProps={{ width: '4px', height: '4px', bottom: '4px' }}
              >
                {sec}
              </TitleWithDot>
            </>
          )}
        </Stack>
      </Box>
      {/* {!isRunning && <Typography variant="h7" color="gray.0" mt='8px'>Time is up</Typography>}  */}
    </Box>
  )
}

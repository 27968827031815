import React from 'react'
import { Box, BoxProps } from '@mui/material'
import VideoCardMedia from 'src/components/VideoCardMedia/VideoCardMedia'
import ImageCardMedia from 'src/components/ImageCardMedia/ImageCardMedia'
import ShadowBox, { ShadowBoxProps } from 'src/components/ShadowBox/ShadowBox'

type LobbyPageWrapperProps = BoxProps & {
  imageProps?: {
    src: string
    alt?: string
  }
  videoProps?: {
    src: string
  }
  leftShadowBoxProps?: ShadowBoxProps
  rightShadowBoxProps?: ShadowBoxProps
  bottomShadowBoxProps?: ShadowBoxProps
  height?: string
  rootMediaBoxProps?: BoxProps
}

export default function PageMediaWithShadows({
  imageProps,
  videoProps,
  leftShadowBoxProps,
  rightShadowBoxProps,
  bottomShadowBoxProps,
  height = '398px',
  rootMediaBoxProps,
  ...restRootBoxProps
}: LobbyPageWrapperProps) {
  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      zIndex={0}
      height={height}
      {...restRootBoxProps}
    >
      <Box position="relative">
        <ShadowBox
          height="75px"
          maxWidth="100%"
          bottom={0}
          zIndex={2}
          {...bottomShadowBoxProps}
          sx={{
            background: 'linear-gradient(180deg, rgba(4, 8, 17, 0) 0%, #040811 100%)',
            ...bottomShadowBoxProps?.sx,
          }}
        />
        <Box
          position="relative"
          bgcolor="gray.900"
          height={height}
          mx="8px"
          border="1px solid"
          borderColor="gray.700"
          overflow="hidden"
          {...rootMediaBoxProps}
        >
          {!imageProps?.src && videoProps?.src && <VideoCardMedia {...videoProps} fill />}
          {!videoProps?.src && imageProps?.src && <ImageCardMedia {...imageProps} fill />}
          <ShadowBox {...leftShadowBoxProps} />
          <ShadowBox
            right={0}
            sx={{
              background: 'linear-gradient(270deg, rgba(4, 8, 17, 0.5) 0%, rgba(4, 8, 17, 0) 100%)',
            }}
            {...rightShadowBoxProps}
          />
        </Box>
      </Box>
    </Box>
  )
}

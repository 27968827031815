import React from 'react'
import { Stack, StackProps } from '@mui/material'
import GameNumber from 'src/components/GameNumber/GameNumber'

export type GameNumberRangeProps = {
  startNumber: number | string
  endNumber: number | string
  rootStackProps?: StackProps
}

export default function GameNumberRange({
  startNumber,
  endNumber,
  rootStackProps,
}: GameNumberRangeProps) {
  return (
    <Stack direction="row" spacing="2.6px" alignItems="center" color="gray.600" {...rootStackProps}>
      <GameNumber count={startNumber} />
      <span>{' - '}</span>
      <GameNumber count={endNumber} />
    </Stack>
  )
}

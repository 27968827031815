import React from 'react'
import { Box, Grid, Stack, Typography, useTheme } from '@mui/material'
import YourWallet from 'src/components/YourWallet/YourWallet'
import MonthlyChallengesBox from 'src/components/MonthlyChallengesBox/MonthlyChallengesBox'
import Comment from 'src/components/Comment/Comment'
import PatternLines from 'src/components/PatternLines/PatternLines'
import useMediaQuery from '@mui/material/useMediaQuery'
import { UserType } from 'src/helpers/types'

type LoginMainBannerProps = {
  user: UserType
}

export default function LoginMainBanner(props: LoginMainBannerProps) {
  const theme = useTheme()
  const isSm2View = useMediaQuery(theme.breakpoints.down('sm2'))
  const isLg4View = useMediaQuery(theme.breakpoints.down('lg4'))

  return (
    <Box position="relative" bgcolor="gray.900">
      <PatternLines
        height="95px"
        top={{ xs: '0', lg2: '21px' }}
        {...(isLg4View && {
          ml: { xs: 'calc(-100vw - 8px)', sm: 'calc(-100vw - 12px)' },
        })}
      />
      <Grid container justifyContent="space-between">
        <Grid
          item
          mr="auto"
          sx={{
            [theme.breakpoints.down('lg2')]: {
              width: '100%',
            },
          }}
        >
          <Stack
            direction="column"
            pt="21px"
            width="100%"
            sx={{
              [theme.breakpoints.down('lg2')]: {
                pt: '4px',
                mb: '15px',
              },
              [theme.breakpoints.down('sm2')]: {
                mb: '20px',
              },
            }}
          >
            <Typography
              variant={isSm2View ? 'labelMedium' : 'labelBig'}
              color="primary.light"
              mb={{ xs: '9px', sm2: '8px' }}
            >
              {'Welcome back'}
            </Typography>
            <Typography variant="h3">{props.user.nickname}</Typography>
          </Stack>
        </Grid>

        <Grid
          item
          sx={{
            [theme.breakpoints.down('md2')]: {
              width: '59.6%',
            },
            [theme.breakpoints.down('sm2')]: {
              width: '100%',
            },
          }}
        >
          <Box position="relative" ml="1px" pt="1px" mb={{ xs: '12px', sm2: '0' }}>
            <Comment rootStackProps={{ mb: '1px' }}>your wallet</Comment>
            <YourWallet user={props.user}/>
          </Box>
        </Grid>

        <Grid
          item
          sx={{
            [theme.breakpoints.down('md2')]: {
              width: '36.8%',
            },
            [theme.breakpoints.down('sm2')]: {
              width: '100%',
            },
          }}
        >
          <Box position="relative" pt="1px" ml={{ xs: '0', lg2: '28px' }}>
            <Comment rootStackProps={{ mb: '1px' }}>monthly challenges</Comment>
            <MonthlyChallengesBox totalGames={props.user.statistics.currentMonth.totalGamesPlayed} winGamesCount={props.user.statistics.currentMonth.totalGamesWon}
                  loseGamesCount={props.user.statistics.currentMonth.totalGamesLost} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

import React, { useCallback, useEffect, useMemo } from 'react'
import { Box, Stack, useTheme, useMediaQuery } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { toast, TOAST_IDS } from 'src/components/Notification/Notification'
import PageContainer from 'src/components/PageContainer/PageContainer'
import PatternLines from 'src/components/PatternLines/PatternLines'
import TitleWithDot from 'src/components/TitleWithDot/TitleWithDot'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import ButtonIcon from 'src/components/ButtonIcon/ButtonIcon'
import TournamentBraketsFilter, {
  useTournamentBraketsFilter,
} from 'src/components/TournamentBraketsFilter/TournamentBraketsFilter'
import SingleEliminationBracket from 'src/components/SingleEliminationBracket/SingleEliminationBracket'
import TournamentTopStatWrapper from 'src/components/TournamentTopStatWrapper/TournamentTopStatWrapper'
import TournamentInfoBox from 'src/components/TournamentInfoBox/TournamentInfoBox'
import TournamentWinnersWrapper from 'src/components/TournamentWinnersWrapper/TournamentWinnersWrapper'
import ChallengeImageWrapper from 'src/components/ChallengeImageWrapper/ChallengeImageWrapper'
import tournamentWinnersBg from '../../../assets/images/tournament-winners-bg.jpg'
import { ChallengeStatus, TournamentStatus, TournamentType, UserType } from 'src/helpers/types'
import { mapBrackets, mapTournamentTopDetailsInfo, mapTournamentTopDetailsUserStats, mapWinners } from 'src/helpers/tournamentData'
import { IRoundProps } from 'react-brackets/dist/types/Rounds'
import { FILTER } from 'src/components/TournamentBraketsFilter/TournamentBraketsFilter'
import ctx from 'src/context/context'

export enum TOURNAMENT_STATUS {
  COMMENCING = 'commencing',
  PLAYING = 'playing',
  PROGRESS = 'progress',
  CALCULATE = 'calculate',
  FINAL = 'final',
}

type TournamentStatusProps = {
  user: UserType
  tournament: TournamentType
}

/**
 * The statusId props is temporarily here only for the storybook to be able to show the pages with different status
 * most likely you will use the statusId from useParams of react-router-dom
 * to get the statusId param and based on it to fetch data
 */
export default function TournamentRunning({ user, tournament}: TournamentStatusProps) {
  const theme = useTheme()
  const isMdView = useMediaQuery(theme.breakpoints.down('md'))
  const isSmView = useMediaQuery(theme.breakpoints.down('sm'))
  const auth = ctx.useAuth();

  const navigate = useNavigate()

  const statusId = TOURNAMENT_STATUS.COMMENCING;

  const params = useParams<{ statusId: string }>()

  const handleWatchGame = useCallback(
    (status:ChallengeStatus, error:boolean,  id:  string | number) => {
      toast.dismiss(TOAST_IDS.LobbyStatusBar.toastId);
      (error || status === ChallengeStatus.FINAL_DONE || status===ChallengeStatus.CANCELLED) ? 
      navigate('/lobby/history/'+id) 
      :
      navigate('/lobby/'+id)
    },
    [navigate]
  )

  useEffect(() => {
    if (params?.statusId === TOURNAMENT_STATUS.COMMENCING) {
      toast.timerInfo(
        {
          text: 'Match starts in:',
          expirySeconds: 900,
        },
        {
          autoClose: 900000,
          position: 'bottom-right',
        }
      )
    } else if (params?.statusId === TOURNAMENT_STATUS.PLAYING) {
      toast.lobbyStatusBarInfo(
        {
          status: 'commencing',
          text: 'Game is commencing',
          btnProps: {
            onClick: () => {
              navigate('/lobby/frontland')
            },
          },
        },
        {
          autoClose: false,
          position: 'bottom-right',
        }
      )
    } else if (params?.statusId === TOURNAMENT_STATUS.PROGRESS) {
      toast.timerInfo(
        {
          text: 'Match starts in:',
          expirySeconds: 984,
        },
        {
          autoClose: 984000,
          position: 'bottom-right',
        }
      )
    } else if (params?.statusId === TOURNAMENT_STATUS.CALCULATE) {
      toast.lobbyStatusBarInfo(
        {
          status: 'waiting',
          text: 'Calculate winners',
          btnProps: {
            disabled: true,
            onClick: () => {
              navigate('/lobby/frontland')
            },
          },
        },
        {
          autoClose: false,
          position: 'bottom-right',
        }
      )
    }
    toast.onChange((payload) => {
      if (payload.id === 'TimerToast' && payload.status === 'removed') {
        //
      }
    })
    // eslint-disable-next-line
  }, [])

  const onLeaveTournament = () => {}

  const filter = useTournamentBraketsFilter()

  // This is temporary you will replace it with your fetch logic based on the statusId from useParams
  /*const data = useMemo(() => {
    const id = params?.statusId || statusId
    if (id) {
      // @ts-ignore
      return tournamentMockData[id](filter)
    } else {
      return null
    }
  }, [filter, params?.statusId, statusId])*/

  const detailsInfo = mapTournamentTopDetailsInfo(tournament);
  const userStats = mapTournamentTopDetailsUserStats(tournament, auth.user?.id as number|undefined);
  const result = mapBrackets(tournament.brackets);
  const brackets =  getRoundsByFilter(result.rounds, filter as `${FILTER}`);
  const thirdPlaceMatch = result.thirdPlaceMatch;

  
  useMemo(() => {
    toast.dismiss(TOAST_IDS.LobbyStatusBar.toastId); //dismiss toast if any
  }, [tournament.tournamentStatus])

  const winners = useMemo(() => {
    if(tournament.tournamentStatus === TournamentStatus.FINAL_DONE){
      return mapWinners(tournament);
    }
    return undefined
 }, [tournament.status])

  //console.log("Brackets:"+JSON.stringify(brackets));
  return (
    <Box bgcolor="gray.900" position="relative">
      {tournament.tournamentStatus === TournamentStatus.READY_TO_START && 
        <TournamentInfoBox title='Starting tournament' subtitle='Creating brackets' />
      }
      {tournament.tournamentStatus === TournamentStatus.WAITING_FOR_NEXT_ROUND && tournament.roundIsStarting &&
        <TournamentInfoBox title={tournament.currentRound < tournament.numberOfRounds ? `Round ${tournament.currentRound}`:`Final round`} subtitle='Is about to start' />
      }
      {tournament.tournamentStatus === TournamentStatus.RUNNING && tournament.roundIsFinishing &&
        <TournamentInfoBox title={tournament.currentRound < tournament.numberOfRounds ? `Round ${tournament.currentRound}`:`Final round`} subtitle='Is finishing' />
      }
      <PageContainer
        sx={{
          pt: '17px',
          display: 'flex',
          [theme.breakpoints.down('sm2')]: {
            position: 'relative',
            top: '8px',
            pt: '20px',
          },
        }}
        innerContainerProps={{
          sx: { display: 'flex', flexDirection: 'column', position: 'relative' },
        }}
      >
        {!isMdView && (
          <PatternLines
            sx={{
              height: '262px',
              top: '10px',
              [theme.breakpoints.down('lg4')]: {
                ml: 'calc(-100vw - 12px)',
                height: '238px',
              },
              [theme.breakpoints.down('sm')]: {
                height: '66px',
                ml: 'calc(-100vw - 8px)',
                top: 0,
              },
            }}
          />
        )}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={{ xs: '17px', sm: '28px', md: '24px' }}
        >
          <Stack direction="row" gap={{ xs: '14px', sm: '20px' }} alignItems="center">
            <ButtonIcon
              sx={{ mb: '8px' }}
              variant="outlined"
              size="small"
              onClick={() => {
                navigate(-1)
              }}
            >
              <SvgIcon name="arrow-left-line" />
            </ButtonIcon>
            <TitleWithDot
              typographyProps={{ variant: isSmView ? 'h4' : isMdView ? 'h3' : 'h2' }}
              dotProps={{
                bottom: '16px',
                sx: {
                  [theme.breakpoints.down('md')]: { bottom: '12px' },
                },
              }}
              rootStackProps={{ spacing: { xs: '2px', sm: '9px', md: '15px' } }}
            >
              tournament
            </TitleWithDot>
          </Stack>
          {/*isSmView ? (
            <ButtonIcon
              sx={{ mb: '8px' }}
              variant="outlined"
              size="small"
              onClick={onLeaveTournament}
            >
              <SvgIcon name="logout-circle-r-line" />
            </ButtonIcon>
          ) : (
            <Button
              variant="outlined"
              size="medium"
              endIcon={<SvgIcon name="logout-circle-r-line" />}
              onClick={onLeaveTournament}
            >
              Leave tournament
            </Button>
          )*/}
        </Stack>
        <TournamentTopStatWrapper
          dataDetailInfo={detailsInfo}
          dataUserStatistic={userStats}
        />
      </PageContainer>

      {tournament.tournamentStatus === TournamentStatus.FINAL_DONE && winners!==undefined && (
        <ChallengeImageWrapper
          topShadow={true}
          bottomShadow={true}
          leftShadow={true}
          rightShadow={true}
          bgHeight="670px"
          bgImage={tournamentWinnersBg}
          imgWrapperBoxProps={{ border: 'none' }}
          bottomShadowProps={{
            height: '126px',
          }}
          topShadowProps={{
            height: '84px',
          }}
          leftShadowProps={{
            width: '208px',
          }}
          rightShadowProps={{
            width: '208px',
            sx: {
              background: 'linear-gradient(90deg, rgba(4, 8, 17, 0.0) 0%, #040811 100%)',
            },
          }}
        >
          <TournamentWinnersWrapper
            title="Winners"
            otherPrizesTitle="Other prizes"
            data={winners}
          />
        </ChallengeImageWrapper>
      )}

      <PageContainer sx={{ display: 'flex' }}>
        <TournamentBraketsFilter StackProps={{ mb: { xs: '20px', sm: '28px', md: '44px' } }} />
      </PageContainer>

      <Box
        mt="-14px"
        mb="48px"
        overflow="hidden"
        sx={{
          width: '100vw',
          [theme.breakpoints.down('lg4')]: {
            ml: '-28px',
            mr: '-28px',
          },
        }}
      >
        <PageContainer>
          <Box
            ml="-29px"
            sx={{
              [theme.breakpoints.down('lg4')]: {
                ml: '0',
                mr: '0',
              },
            }}
          >
            {/* We need this key={filter} to mount the SingleEliminationBracket when filter changes, otherwise it will not work correctly. */}
            <SingleEliminationBracket
            key={filter}
            rounds={brackets}
            thirdPlaceMatch={thirdPlaceMatch}
            onWatchGame={handleWatchGame}
          />
          </Box>
        </PageContainer>
      </Box>
    </Box>
  )
}

export const getRoundsByFilter = (
  rounds: IRoundProps[] = [],
  filter: `${FILTER}` = FILTER.FULL
) => {
  if (filter === FILTER.FULL) {
    return rounds
  } else {
    const roundIndex = rounds.findIndex((round) => round.filter === filter)
    return roundIndex >=0 ? rounds.slice(roundIndex) : rounds;
  }
}

import React from 'react'
import { Box, Stack, StackProps as MuiStackProps, useTheme, useMediaQuery } from '@mui/material'
import Typography from '../Typography/Typography'
import SvgIcon from '../SvgIcon/SvgIcon'
import TournamentPrizesRow, { OtherPrizesT } from '../TournamentPrizesRow/TournamentPrizesRow'
import ICONS from 'src/assets/icons/icons'

export type TournamentIconTextPairProps = {
  /** [The root element of this component uses the StackProps from Material UI](https://mui.com/material-ui/react-stack/) */
  StackProps?: MuiStackProps
  icon?: keyof typeof ICONS
  title: string
  value: string | (() => React.ReactNode) | string[]
  prizeIconSrc?: string
  otherPrizes?: OtherPrizesT
}

function TournamentIconTextPair({
  icon,
  title,
  value,
  prizeIconSrc,
  otherPrizes,
  StackProps,
}: TournamentIconTextPairProps) {
  const stringSplitSign = '/'
  const isArray = Array.isArray(value)
  const theme = useTheme()
  const isMd834 = useMediaQuery(theme.breakpoints.down('md834'))

  return (
    <Stack direction="row" gap={{ xs: '2px', md834: '7px' }} {...StackProps}>
      {icon && <SvgIcon name={icon} fontSize="inherit" color="gray300" sx={{ mt: '2px' }} />}
      <Box>
        <Typography
          variant={isMd834 ? 'paragraphSmall' : 'paragraphMedium'}
          color="gray.300"
          mb={{ xs: '4px', md834: '12px', md: '11px' }}
        >
          {title}
        </Typography>

        {typeof value === 'function' && value()}

        {isArray && (
          <TournamentPrizesRow variant="banner" prizes={value} showCoin={prizeIconSrc !==undefined} prizeIconSrc={prizeIconSrc}  otherPrizes={otherPrizes} />
        )}

        {typeof value === 'string' &&
          (value.includes(stringSplitSign) ? (
            <Stack
              direction="row"
              alignItems="center"
              sx={{ transform: { xs: 'translateY(-3px)', md: 'translateY(-2px)' } }}
            >
              {value.split(stringSplitSign).map((item, index) =>
                index == 0 ? (
                  <Typography key={index} variant="h6" color="inherit" mr="5px">
                    {item}
                  </Typography>
                ) : (
                  <React.Fragment key={index}>
                    <Typography variant="h7" color="inherit" sx={{ transform: 'translateY(-2px)' }}>
                      {stringSplitSign}
                    </Typography>
                    <Typography variant="h7" color="inherit" sx={{ transform: 'translateY(-2px)' }}>
                      {item}
                    </Typography>
                  </React.Fragment>
                )
              )}
            </Stack>
          ) : (
            <Typography variant="h7" color="inherit">
              {value}
            </Typography>
          ))}
      </Box>
    </Stack>
  )
}

export default TournamentIconTextPair

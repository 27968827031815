import React from 'react'
import { Stack, Box, StackProps } from '@mui/material'
import Typography from 'src/components/Typography/Typography'

export type Tab = {
  id: string
  active?: boolean
  disabled?: boolean
  label: string
}

export type TabsProps = {
  StackProps?: StackProps
  list: Tab[]
  activeTab?: string | null
  onChange?: (tab: Tab) => void
}

export default function Tabs({ list, activeTab, onChange, ...restProps }: TabsProps) {
  return (
    <Stack direction="row" gap={{ xs: '12px', md: '16px' }} {...restProps}>
      {list.map((item) => (
        <Tab key={item.label} {...item} active={activeTab === item?.id} onChange={onChange} />
      ))}
    </Stack>
  )
}

type TabProps = Pick<TabsProps, 'StackProps' | 'onChange'> & Tab

export function Tab(props: TabProps) {
  const { StackProps, onChange = () => {}, ...restProps } = props
  return (
    <Stack direction="column" gap="5px" {...StackProps}>
      <Typography
        variant="buttonMedium"
        color="gray.50"
        sx={{
          cursor: 'pointer',
          '&:hover': {
            color: 'gray.0',
          },
          ...(restProps?.active && {
            color: 'primary.main',
            pointerEvents: 'none',
          }),
          ...(restProps?.disabled && {
            color: 'gray.600',
            pointerEvents: 'none',
          }),
        }}
        onClick={() => onChange(restProps)}
      >
        {restProps?.label}
      </Typography>
      <Box
        visibility={restProps?.active ? 'unset' : 'hidden'}
        height="4px"
        width="100%"
        bgcolor="gray.800"
      />
    </Stack>
  )
}

import React , {useEffect} from 'react';
import { logout } from "../actions/userActions";
import { useAppDispatch } from 'src/hooks';
import { useDisconnect} from '@thirdweb-dev/react';
import * as Sentry from '@sentry/browser';
import { resetProfileApiState } from 'src/api/profileApi';
import { resetLeaderboardApiState } from 'src/api/leaderboardApi';
import { resetStakingApiState } from 'src/api/stakingApi';

export function Logout() {
    const dispatch = useAppDispatch();
    const disconnect = useDisconnect();

    useEffect(() => {
        const doLogout: () => Promise<void> = async () => {
            dispatch(logout());
            dispatch(resetProfileApiState());
            dispatch(resetLeaderboardApiState());
            dispatch(resetStakingApiState());
            Sentry.setUser(null);
            await disconnect();
        }
        doLogout();

    }, [dispatch])
   
    return null;
   
}

export default Logout;
// rootSaga.js
import { all } from 'redux-saga/effects';
import apiSagas from './apiSagas';
import stakingSagas from './stakingSagas';
import profileSagas from './profileSagas';


export default function* rootSaga() {
  yield all([
    stakingSagas(),
    profileSagas(),
    apiSagas()
 ]);
}
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery, handleAxiosError } from "./api";
import { axiosInstance } from "./api";
import { FlexiStakingType, StakeRequest, StakingData, StakingSummary, StakingFormType, VestedStakingType } from 'src/helpers/stakingTypes';
import SignerManager from 'src/utils/signerManager';
import { getFlexiStakingContract, getVestedStakingBaseContract, getVestedStakingExtendedContract } from 'src/utils/ethersUtil';


export enum Tags  {

  BlockchainFlexiStaking = 'BlockchainFlexiStaking',
  BlockchainVestedStaking = 'BlockchainVestedStaking',
  Staking = 'Staking',
  FlexiStaking = 'FlexiStaking',
  VestedStaking = 'VestedStaking',
  StakingSummary = 'StakingSummary',
}


// TypeScript function to perform GET request
export const fetchStakingData = async (): Promise<any> => {
  try {
    const response = await axiosInstance.get('/api/blockchain/staking');
    return response.data;
  } catch (error) {
    // Error handling
    handleAxiosError(error)
  }
};

export const apiStakingSlice = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery({ baseUrl: '/api/blockchain' }),
  tagTypes: [Tags.BlockchainFlexiStaking,Tags.BlockchainVestedStaking,Tags.Staking,Tags.StakingSummary,Tags.FlexiStaking,Tags.VestedStaking], // Define a tag for invalidating and refetching
  endpoints: (builder) => ({
    fetchStakingData: builder.query<StakingData,void>({
      query: () => ({ url: '/staking', method: 'GET' }),
      transformResponse: (response: any) => {
        return {
          flexiStaking: response.flexiStaking,
          vestedStaking: response.vestedStaking,
        };
      },
      providesTags: [Tags.Staking], // Tag this query for invalidation
    }),
    fetchStakingSummaryData: builder.query<StakingSummary,void>({
      query: () => ({ url: '/staking/summary', method: 'GET' }),
      transformResponse: (response: any) => {
        return {         
          totalStaked: response.totalStaked.toFixed(),
          totalUnstaked: response.totalUnstaked.toFixed(),
          totalRewards: response.totalRewards.toFixed(),
          totalNumberOfStakers: response.totalNumberOfStakers,
        };
      },
      keepUnusedDataFor: 300, // 5min, overrides the global setting for this query
      providesTags: [Tags.StakingSummary], // Tag this query for invalidation
    }),
    fetchFlexiStakingData: builder.query<FlexiStakingType,void>({
      query: () => ({ url: '/staking/flexi', method: 'GET' }),
      transformResponse: (response: any) => {
        return {         
            accountBalance: "", //Amount of CT staked by user
            stakerCount: response.stakerCount.toFixed(), //BigInt of stakers           
            totalStaked: response.totalStaked.toFixed(), //Total amount of CT staked
            accountEarned: "", //Amount of rewards earned by user
            unstakeFee: Number(response.unstakeFee), //Unstake fee
            currentRewardsDuration: response.currentRewardsDuration.toFixed(),  //Duration of current rewards period, default 90 days
            currentPeriodFinish: response.currentPeriodFinish.toFixed(), //End time of current rewards period
            currentRewardPerToken: response.currentRewardPerToken.toFixed(), //Reward per token staked
            rewardForDuration: response.rewardForDuration.toFixed(), //Amount of rewards available for duration of staking period         
        };
      },
      keepUnusedDataFor: 300, // 5min, overrides the global setting for this query
      providesTags: [Tags.FlexiStaking], // Tag this query for invalidation
    }),
    fetchVestedStakingData: builder.query<VestedStakingType,void>({
      query: () => ({ url: '/staking/vested', method: 'GET' }),
      transformResponse: (response: any) => {
        return {
          accountBalance: "", //BigInt() to avoid serialization issues with BigInt
          stakerCount: response.stakerCount.toFixed(),
          totalStaked: response.totalStaked.toFixed(),
          accountEarned: "",
          unstakeFee: 0,
          currentRewardsDuration: response.currentRewardsDuration.toFixed(),
          currentPeriodFinish: response.currentPeriodFinish.toFixed(),
          currentRewardPerToken: response.currentRewardPerToken.toFixed(),
          rewardForDuration: response.rewardForDuration.toFixed(),
          vestingSchedule: {
            periods:  [],
            amounts:  [],
            withdrawn: "",
            vestingType: 0,
          },
        };
      },
      keepUnusedDataFor: 300, // 5min, overrides the global setting for this query
      providesTags: [Tags.VestedStaking], // Tag this query for invalidation
    }),
    fetchBlockchainFlexiStakingData:  builder.query<FlexiStakingType, string|undefined>({
      queryFn: async (param) => {
        try {
          const signer = SignerManager.signer;
          if (!signer) {
              throw new Error('No signer found');
          }
          const flexi = getFlexiStakingContract(signer);
          const account = param ? param : await signer.getAddress(); //If param is passed, use it as account, otherwise get signer address
          const [totalStaked, stakerCount, currentRewardsDuration, currentPeriodFinish,currentRewardPerToken, rewardForDuration, unstakeFee, accountBalance,accountEarned] = await flexi.getAccountStakingInfo(account);
          
          return {
            data: {
                accountBalance: accountBalance.toString(), //Amount of CC staked by user //BigInt() to avoid serialization issues with BigInt
                stakerCount: stakerCount.toString(), //BigInt of stakers
                totalStaked: totalStaked.toString(), //Total amount of CC staked
                accountEarned: accountEarned.toString(), //Amount of rewards earned by user
                unstakeFee: Number(unstakeFee), //Unstake fee
                currentRewardsDuration: currentRewardsDuration.toString(),  //Duration of current rewards period, default 90 days
                currentPeriodFinish: currentPeriodFinish.toString(), //End time of current rewards period
                currentRewardPerToken: currentRewardPerToken.toString(), //Reward per token staked
                rewardForDuration: rewardForDuration.toString(), //Amount of rewards available for duration of staking period
                lastUpdated:Date.now()
            },
          };
        }
        catch (error) {
          // First, we check if error is an instance of Error
          if (error instanceof Error) {
            return { error: { status: 'CUSTOM', error: error.message } };
          }
          // If it's not an Error, handle it as an unknown error
          return { error: { status: 'CUSTOM', error: 'An unknown error occurred' } };
        }
      },      
      providesTags: [Tags.BlockchainFlexiStaking], // Tag this query for invalidation
    }),
    fetchBlockchainVestedStakingData:  builder.query<VestedStakingType, string|undefined>({
      queryFn: async (param) => {
        try {
          const signer = SignerManager.signer;
          if (!signer) {
              throw new Error('No signer found');
          }
          const [ vestedBasic, vestedExtended ] =  await Promise.all([
            getVestedStakingBaseContract(signer),
            getVestedStakingExtendedContract(signer)
          ]);  
          const account = param ? param : await signer.getAddress(); //If param is passed, use it as account, otherwise get signer address
          const [basic, vestingSchedule]:[StakingFormType,any] = await Promise.all([
            vestedBasic.getAccountStakingInfo(account),
            vestedExtended.getVestingSchedule(account),
          ]);


          const schedule ={
            periods:  vestingSchedule[0].length ? vestingSchedule[0] : [],
            amounts:  vestingSchedule[1].length ? vestingSchedule[1] : [],
            withdrawn: vestingSchedule[2].toString(),
            vestingType: vestingSchedule[3]
         };

          //do toString() to avoid serialization issues with BigInt on periods and amounts
          schedule.periods = schedule.periods.map((p: string) => Number(p));
          schedule.amounts = schedule.amounts.map((a: string) =>a.toString());

          return {
            data: {
              accountBalance: basic.accountBalance.toString(), //BigInt() to avoid serialization issues with BigInt
              stakerCount: basic.stakerCount.toString(),
              totalStaked: basic.totalStaked.toString(),
              accountEarned: basic.accountEarned.toString(),
              unstakeFee: 0,
              currentRewardsDuration: basic.currentRewardsDuration.toString(),
              currentPeriodFinish: basic.currentPeriodFinish.toString(),
              currentRewardPerToken: basic.currentRewardPerToken.toString(),
              rewardForDuration: basic.rewardForDuration.toString(),
              vestingSchedule: schedule,
              lastUpdated:Date.now()
            },
          };
        }
        catch (error) {
          // First, we check if error is an instance of Error
          if (error instanceof Error) {
            return { error: { status: 'CUSTOM', error: error.message } };
          }
          // If it's not an Error, handle it as an unknown error
          return { error: { status: 'CUSTOM', error: 'An unknown error occurred' } };
        }
      },
      providesTags: [Tags.BlockchainVestedStaking], // Tag this query for invalidation
    }),
    stake: builder.mutation<any, StakeRequest>({
      query: ({ amount }) => ({
        url: '/stake',
        method: 'POST',
        data: { amount },
      }),
      invalidatesTags: [Tags.BlockchainFlexiStaking], // Invalidate 'Staking' tagged queries upon success
    }),
  }),
});

export const {
  useFetchStakingDataQuery,
  useFetchStakingSummaryDataQuery,
  useFetchFlexiStakingDataQuery,
  useFetchVestedStakingDataQuery,
  useFetchBlockchainFlexiStakingDataQuery,
  useFetchBlockchainVestedStakingDataQuery,
  useStakeMutation,
} = apiStakingSlice;

export const { resetApiState : resetStakingApiState } = apiStakingSlice.util;

import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, Stack, Grid, useTheme, useMediaQuery  } from '@mui/material'
import { useAppSelector, useAppDispatch } from 'src/hooks'
import assets from 'src/assets'
import PageMediaWithShadows from 'src/components/PageMediaWithShadows/PageMediaWithShadows'
import PageContainer from 'src/components/PageContainer/PageContainer'
import GameSidebar from 'src/components/GameSidebar/GameSidebar'
import PatternLines from 'src/components/PatternLines/PatternLines'
import TitleWithDot from 'src/components/TitleWithDot/TitleWithDot'
import Comment from 'src/components/Comment/Comment'
import { availableGamesList  } from 'src/helpers/data'
import { setCurrentLeaderboardGame } from 'src/actions/pageActions'
import { leaderboardDataMapper} from 'src/helpers/data'
import {LeaderboardTable} from './leaderboardTable'
import { useFetchLeaderboardQuery } from 'src/api/leaderboardApi';
import { selectFilteredLeaderboard } from 'src/selectors/leaderBoardSelector';
export default function Leaderboard() {  
  const theme = useTheme()
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();

  const currentGameId = useAppSelector(state => state.pageReducer.currentLeaderboardGameId);
  const onSelect = useCallback((id: string) => {
    // setGameId(id)
     dispatch(setCurrentLeaderboardGame(id));
   }, [])

  const { data: allData, error, isLoading } = useFetchLeaderboardQuery();
  const topLists = useAppSelector((state) => selectFilteredLeaderboard(state, currentGameId, undefined));
  let data = leaderboardDataMapper(topLists,theme);

 return (

    <Box bgcolor="gray.900" minHeight="713px" position="relative">
      <PageMediaWithShadows
        height="600px"
        imageProps={{ src: assets.png.leaderboard }}
        rootMediaBoxProps={{
          border: 0,
          sx: {
            mx: 0,
            [theme.breakpoints.down('lg4')]: {
              mx: '-20px',
            },
            [theme.breakpoints.down('sm')]: {
              mx: '-16px',
            },
          },
        }}
        leftShadowBoxProps={{
          width: '602px',
          sx: {
            background: `linear-gradient(270deg, rgba(4, 8, 17, 0.5) 0%, rgba(4, 8, 17, 0) 100%)`,
            transform: `matrix(-1, 0, 0, 1, 0, 0)`,
          },
        }}
        rightShadowBoxProps={{
          width: '602px',
        }}
        bottomShadowBoxProps={{
          height: '94px',
          sx: {
            [theme.breakpoints.down('md')]: {
              maxWidth: '100%',
            },
            [theme.breakpoints.down('lg4')]: {
              left: '-28px',
              right: '-28px',
              width: 'auto',
              maxWidth: 'unset',
            },
            [theme.breakpoints.down('sm')]: {
              left: '-16px',
              right: '-16px',
              width: 'auto',
              maxWidth: 'unset',
            },
          },
        }}
      />
      <PageContainer
        sx={{
          pt: '17px',
          display: 'flex',
          [theme.breakpoints.down('sm')]: {
            pt: '38px',
          },
        }}
        innerContainerProps={{
          sx: { position: 'relative', display: 'flex', flexDirection: 'column', zIndex: 1 },
        }}
      >
        <PatternLines
          sx={{
            height: '262px',
            top: '9px',
            [theme.breakpoints.down('lg4')]: {
              ml: 'calc(-100vw - 12px)',
              height: '238px',
            },
            [theme.breakpoints.down('sm')]: {
              height: '66px',
              ml: 'calc(-100vw - 8px)',
              top: 0,
            },
          }}
        />
        <TitleWithDot
          typographyProps={{ variant: isMobileView ? 'h3' : 'h2' }}
          dotProps={{
            bottom: '16px',
            sx: { [theme.breakpoints.down('sm')]: { bottom: '13px', left: '-2px' } },
          }}
          rootStackProps={{ spacing: '10px' }}
        >
          leaderboard
        </TitleWithDot>
        <Box mt={{ xs: '20px', sm: '24px' }}>
          <Comment>Available games</Comment>
        </Box>
        <Stack direction={{ xs: 'column', sm: 'row' }} gap={{ xs: '20px', sm: '28px' }} mb="44px">
          <GameSidebar list={availableGamesList} selectedId={currentGameId} onSelect={onSelect} />
          <Box flexGrow={1} mt="1px">
             <Grid container spacing={{ xs: '18px', sm2: '28px' }}>
                            
              {
                
                data.map((item:any, index:number) =>{
                 let column = index % 3;
                 return <LeaderboardTable key={index} title={item.title}
                    subtitle={item.subtitle}
                    width={item.width}
                    height={item.height}
                    px={item.px}
                    bodyStackProps={item.bodystackprops}
                    TitleDotProps={item.titledotprops}
                    squareRowProps={{ count: index<3 ? 3:2, highlightedIndex: column }}
                    griditemprops={item.griditemprops}
                    leaderboard={item}/>
                })
              }  
            </Grid>
          </Box>
        </Stack>
      </PageContainer>
    </Box>
    );
}


{/* <>
  <div className="container">
      <div className="section-title">
          <h3>Leaderboard filter</h3>
          <div className="line"></div>
     </div>
  </div>

  <div className="container">
      <div className="row">
              <div className="col-lg-6">
              <div onClick={()=>toggleVesus(!showVersus)}>
                  <div className="game-panel versus category">
                      <div className="row">
                          <div className="col-lg-12">
                              <img src="/images/versus-logo.png" className="logo" alt=""/>
                                  <h4>Frontland</h4>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="col-lg-6">
              <div onClick={()=>toggleFortnite(!showFortnite)}>
                  <div className="game-panel fortnite category">
                      <div className="row">
                          <div className="col-lg-12">
                              <img src="/images/fortnite-logo.png" className="logo" alt=""/>
                                  <h4>Fortnite</h4>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
              
          <div className="leaderboard-filter">
              <div className="col-lg-12">
                  <div className="row">
                          <div className="col-lg-10">
                              <input type="text" name="" placeholder="Search for player" value="" onChange={this.onChange} />
                      </div>
                      <div className="col-lg-2">
                          <button type="button" className="search-button" name="button">Search</button>
                      </div>
                  </div>
              </div>
          </div> 
      </div>
  </div>

  <div className="container" style={!showVersus ? { display: 'none' } : {}}>
      <div className="section-title">
          <h3>Frontland Leaderboard</h3>
          <div className="line"></div>
      </div>
  </div>

  <div className="container mt-3" style={!showVersus ? { display: 'none' } : {}}>
      <div className="row mt-4">

          {typeof versusTopLists === 'undefined' ? '' : (versusTopLists.map((topList, index) => (
              <TopListTable topList={topList} key={index} />
          )))}
          
      </div>
  </div>


  <div className="container" style={!showFortnite ? { display: 'none' } : { }}>
    <div className="section-title">
      <h3>Fortnite Build Leaderboard</h3>
      <div className="line"></div>
    </div>
  </div>

   <div className="container mt-3" style={!showFortnite ? { display: 'none' } : {}}>
      <div className="row mt-4">

          {typeof fortniteBuildTopLists === 'undefined' ? '' : (fortniteBuildTopLists.map((topList, index) => (
             <TopListTable topList={topList} key={index} />
          )))}
         
       </div>
  </div>

  <div className="container" style={!showFortnite ? { display: 'none' } : {}}>
      <div className="section-title">
          <h3>Fortnite No Build Leaderboard</h3>
          <div className="line"></div>
      </div>
  </div>

  <div className="container mt-3" style={!showFortnite ? { display: 'none' } : {}}>
      <div className="row mt-4">

              {typeof fortniteNoBuildTopLists === 'undefined' ? '' : (fortniteNoBuildTopLists.map((topList, index) => (
              <TopListTable topList={topList} key={index} />
          )))}
              
      </div>
  </div>
   </>
              */}

//import { createStore, applyMiddleware } from "redux";
import { configureStore } from '@reduxjs/toolkit'
import { rootReducer } from "../reducers/rootReducer";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from 'history'
import { createRouterMiddleware } from '@lagunovsky/redux-react-router'
import { signalrMiddleware } from "../signalrmiddleware/signalrconnection";
import { config, EnvironmentType } from "../config/config";
import rootSaga from "../sagas/rootSaga";
//import LogRocket from 'logrocket';
import * as Sentry from "@sentry/react";
import { apiStakingSlice } from 'src/api/stakingApi';
import { apiProfileSlice } from 'src/api/profileApi';
import { apiLeaderboardSlice } from 'src/api/leaderboardApi';

//import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';

export const history = createBrowserHistory();
//To apply enhancers see https://www.npmjs.com/package/@redux-devtools/extension
const initialiseSagaMiddleware = createSagaMiddleware();
//const store = createStore(rootReducer(history), composeWithDevToolsDevelopmentOnly(applyMiddleware(createRouterMiddleware(history), signalrMiddleware, initialiseSagaMiddleware, LogRocket.reduxMiddleware())));

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options listed below
    //See https://docs.sentry.io/platforms/javascript/guides/react/features/redux/
  });

const store = configureStore({
    reducer: rootReducer(history),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(createRouterMiddleware(history), signalrMiddleware, initialiseSagaMiddleware, apiStakingSlice.middleware,apiProfileSlice.middleware,apiLeaderboardSlice.middleware), //, LogRocket.reduxMiddleware()
    devTools: config.environment!==EnvironmentType.PRODUCTION,
    enhancers: [sentryReduxEnhancer],
});

initialiseSagaMiddleware.run(rootSaga);

export default store;
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

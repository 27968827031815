import React from 'react'
import { Box, BoxProps, Container, useTheme } from '@mui/material'
import Typography from '../Typography/Typography'
import LogoBg from 'src/assets/icons/logo-white.svg'
import useMediaQuery from '@mui/material/useMediaQuery'

type BlockBgLogoAndTextProps = {
  title?: string
  subtitle?: string
  children?: React.ReactNode
  rootBoxProps?: BoxProps
}

function BlockBgLogoAndText({ title, subtitle, children, rootBoxProps }: BlockBgLogoAndTextProps) {
  const theme = useTheme()
  const isLgView = useMediaQuery(theme.breakpoints.down('lg'))
  const isMd2View = useMediaQuery(theme.breakpoints.down('md2'))
  const isSm2View = useMediaQuery(theme.breakpoints.down('sm2'))
  const isSmView = useMediaQuery(theme.breakpoints.down('sm'))
  const bgStyle: React.CSSProperties = {}
  const wrapMinHeight = 465

  if (title || subtitle) {
    bgStyle.backgroundPosition = 'top center'
    bgStyle.top = isLgView ? (isSm2View ? 68 : 72) : 55
    bgStyle.maxHeight = isSm2View ? 274 : 408 // Picture height size in design
  } else {
    bgStyle.backgroundPosition = isSm2View ? '-96px center' : 'bottom center'
    bgStyle.bottom = isMd2View ? (isSm2View ? 'auto' : 90) : 3
    bgStyle.top = isSm2View ? (isSmView ? 317 : 450) : 'auto'
    bgStyle.maxHeight = 239 // Picture height size in design
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.gray[900],
        position: 'relative',
        overflow: { xs: 'visible', lg4: 'hidden' },
      }}
      {...rootBoxProps}
    >
      <Box
        sx={{
          backgroundImage: `url(${LogoBg})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          opacity: '0.05',
          position: 'absolute',
          height: wrapMinHeight,
          left: 0,
          right: 0,
          [theme.breakpoints.down('lg4')]: {
            left: '-28px',
            right: '-28px',
          },
          [theme.breakpoints.down('sm2')]: {
            backgroundSize: 'cover',
          },
          [theme.breakpoints.down('sm')]: {
            left: '-16px',
            right: '-16px',
          },
          ...bgStyle,
        }}
      ></Box>
      <Container
        maxWidth="lg2"
        disableGutters
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: { xs: 'auto', lg: wrapMinHeight },
        }}
      >
        {title && (
          <Typography
            variant={isSm2View ? 'labelMedium' : 'labelBig'}
            color={theme.palette.primary.light}
            sx={{ mb: '7px' }}
          >
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography
            variant={isSm2View ? 'h3' : 'h2'}
            color={theme.palette.common.white}
            sx={{ mb: { xs: '19px', sm2: '64px' } }}
          >
            {subtitle}
          </Typography>
        )}
        {children && children}
      </Container>
    </Box>
  )
}

export default BlockBgLogoAndText

import { Box, BoxProps as MuiBoxProps, Stack, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import Comment from '../Comment/Comment'
import GamingEventDetailsOpt, {
  GamingEventDetailsOptProps,
} from '../GamingEventDetailsOpt/GamingEventDetailsOpt'

type TournamentUserStatisticProps = {
  /** [The root element of this component uses the BoxProps from Material UI](https://mui.com/material-ui/react-box/) */
  BoxProps?: MuiBoxProps
  title: string
  data: GamingEventDetailsOptProps[]
}

function TournamentUserStatistic({ title, data, BoxProps }: TournamentUserStatisticProps) {
  const theme = useTheme()
  const isLg = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <Box {...BoxProps}>
      <Comment rootStackProps={{ mb: '1px' }}>{title}</Comment>
      <Stack
        direction="row"
        gap="31px"
        bgcolor="gray.800"
        border="1px solid"
        borderColor="gray.700"
        padding="17px 24px 0 24px"
        height="98px"
        sx={{
          [theme.breakpoints.down('lg')]: {
            height: '148px',
            minWidth: '274px',
            padding: '15px 0 0 14px',
            gap: '50px',
          },
          [theme.breakpoints.down('md834')]: {
            padding: '8px 0 10px 13px',
            gap: '57px',
            height: 'auto',
          },
        }}
      >
        <Box>
          <GamingEventDetailsOpt
            {...data[0]}
            props={{
              StackProps: {
                sx: {
                  gap: '17px',
                  [theme.breakpoints.down('lg')]: {
                    gap: '15px',
                  },
                },
              },
              labelProps: { TypographyProps: { variant: 'bodySmall' } },
              TypographyProps: {
                color: 'primary.light',
                variant: isLg ? 'h5' : 'h3',
              },
            }}
          />
        </Box>

        <Stack
          direction="row"
          gap="23px"
          sx={{
            [theme.breakpoints.down('lg')]: {
              flexDirection: 'column',
              gap: '14px',
            },
            [theme.breakpoints.down('md834')]: {
              flexDirection: 'row',
              gap: '58px',
              pt: '6px',
            },
          }}
        >
          {data &&
            data.map(
              (item, index) =>
                index > 0 && (
                  <GamingEventDetailsOpt
                    key={index}
                    {...item}
                    props={{
                      StackProps: {
                        gap: '21px',
                        sx: {
                          [theme.breakpoints.down('lg')]: {
                            gap: '9px',
                          },
                        },
                      },
                      labelProps: { TypographyProps: { variant: 'bodySmall' } },
                      TypographyProps: {
                        variant: 'h7',
                      },
                    }}
                  />
                )
            )}
        </Stack>
      </Stack>
    </Box>
  )
}

export default TournamentUserStatistic

import React, { useMemo } from 'react'
import { Box, Stack, StackProps, BoxProps, Avatar, AvatarProps } from '@mui/material'
import SvgIcon, { SvgIconProps } from 'src/components/SvgIcon/SvgIcon'
import Typography from 'src/components/Typography/Typography'
import Image, { ImageProps } from 'src/components/Image/Image'
import { PNG_ICONS } from 'src/assets/icons/icons'

export type ChallengeCardItemProps = StackProps &
  Pick<ChallengeCardItemValueProps, 'value' | 'showAllPrizes'> & {
    icon?: SvgIconProps['name']
    avatarProps?: AvatarProps
    label: string
    tokenIconSrc?: string
  }

export default function ChallengeCardItem({
  icon,
  avatarProps,
  label,
  value,
  showAllPrizes = false,
  tokenIconSrc,
  ...restRootStackProps
}: ChallengeCardItemProps) {
  return (
    <Stack
      direction="row"
      spacing={avatarProps ? '8px' : '6px'}
      alignItems={avatarProps ? 'center' : 'unset'}
      color="gray.500"
      pb="4px"
      {...restRootStackProps}
    >
      {icon && !avatarProps && (
        <Box fontSize={14} display="flex">
          <SvgIcon name={icon} fontSize="inherit" color="inherit" />
        </Box>
      )}
      {!icon && avatarProps && (
        <Avatar
          variant="square"
          {...avatarProps}
          sx={{
            width: '64px',
            height: '50px',
            border: '1px solid',
            borderColor: 'gray.800',
            ...avatarProps?.sx,
          }}
        />
      )}
      <div>
        <Typography
          variant="paragraphSmall"
          color="inherit"
          height="13px"
          display="flex"
          alignItems="center"
        >
          {label}
        </Typography>
        <ChallengeCardItemValue value={value} tokenIconSrc={tokenIconSrc} showAllPrizes={showAllPrizes} />
      </div>
    </Stack>
  )
}

type ChallengeCardItemValueProps = {
  value: string | string[]
  showAllPrizes?: boolean
  tokenIconSrc?: string
}

function ChallengeCardItemValue({ value, tokenIconSrc, showAllPrizes = false }: ChallengeCardItemValueProps) {
  return useMemo(() => {
    if (Array.isArray(value) && value.length) {
      return (
        <Stack
          mt="4px"
          direction="row"
          spacing={showAllPrizes ? '13px' : '4px'}
          alignItems="center"
        >
          {tokenIconSrc && 
            <Box 
              component={Image}
              src={tokenIconSrc}
              sx={{
                width: '13px',
                height: '13px',
              }}
              />
          }
          {value.map((val, index) => {
            if (showAllPrizes ? index <= 2 : index <= 1) {
              return <PrizesRow key={val} value={val} index={index} showAllPrizes={showAllPrizes} />
            } else {
              return (
                <Typography key={val} variant="uiElement" color="gray.50" alignSelf="center">
                  ...
                </Typography>
              )
            }
          })}
        </Stack>
      )
    } else if (typeof value === 'string') {
      return (
        <Typography variant="h7" mt="4px" color="gray.25">
          {value}
        </Typography>
      )
    } else {
      return null
    }
  }, [value, showAllPrizes])
}

type PrizesRowProps = Pick<ChallengeCardItemValueProps, 'showAllPrizes'> & {
  value: string
  index: number
}

const getPrizeColor = (placeNum: number) => {
  switch (placeNum) {
    case 1:
      return 'primary.main'
    case 2:
      return 'gray.100'
    case 3:
      return '#C69040'
    default:
      return 'gray.100'
  }
}

function PrizesRow({ value, index, showAllPrizes = false }: PrizesRowProps) {
  return useMemo(() => {
    const fontSize = index === 0 || showAllPrizes ? '16px' : '12px'
    const color = getPrizeColor(index + 1)
    const sx = index === 0 || showAllPrizes ? {} : { fontSize: '5px' }
    const typographyVariant = index === 0 || showAllPrizes ? 'h7' : 'uiElement'
    const typographyColor = index === 0 || showAllPrizes ? 'gray.25' : 'gray.50'
    return (
      <Stack key={value} direction="row" spacing="2px" alignItems="center">
        <Box fontSize={fontSize} color={color} display="flex" position="relative">
          <SvgIcon name="trophy-fill" fontSize="inherit" color="inherit" />
          <WinningPlaceNum sx={sx}>{index + 1}</WinningPlaceNum>
        </Box>
        <Typography variant={typographyVariant} color={typographyColor}>
          {value}
        </Typography>
      </Stack>
    )
  }, [value, index, showAllPrizes])
}

type WinningPlaceNumProps = BoxProps & {
  children: React.ReactNode
}

function WinningPlaceNum({ children, sx = {}, ...restProps }: WinningPlaceNumProps) {
  return (
    <Box
      {...restProps}
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: 'gray.900',
        fontSize: '6px',
        fontWeight: 'bold',
        paddingBottom: '3px',
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

import React from 'react'
import { Box, BoxProps, Container, ContainerProps, useMediaQuery, useTheme } from '@mui/material'
import Image from '../Image/Image'

type HeroImageWrapper2Props = BoxProps & {
  children?: React.ReactNode
  bgHeight?: string
  bgImage: string
  containerProps?: ContainerProps
  leftShadow?: boolean
  rightShadow?: boolean
  topShadow?: boolean
  bottomShadow?: boolean
  mobileShadow?: boolean
  imgWrapperBoxProps?: BoxProps
  leftShadowProps?: BoxProps
  rightShadowProps?: BoxProps
  topShadowProps?: BoxProps
  bottomShadowProps?: BoxProps
  mobileShadowProps?: BoxProps
}

const commonShadowProps: BoxProps = {
  position: 'absolute',
  zIndex: 1,
}

const LeftShadow = (props: BoxProps) => (
  <Box
    width="1410px"
    left={0}
    top={0}
    bottom={0}
    {...props}
    sx={{
      background: 'linear-gradient(90deg, #040811 0%, rgba(4, 8, 17, 0) 100%)',
      ...props.sx,
    }}
    {...commonShadowProps}
  />
)
const RightShadow = (props: BoxProps) => (
  <Box
    width="602px"
    right={0}
    top={0}
    bottom={0}
    {...props}
    sx={{
      background: 'linear-gradient(270deg, rgba(4, 8, 17, 0.5) 0%, rgba(4, 8, 17, 0) 100%)',
      ...props.sx,
    }}
    {...commonShadowProps}
  />
)
const TopShadow = (props: BoxProps) => (
  <Box
    height="116px"
    left={0}
    top={0}
    right={0}
    {...props}
    sx={{
      background: 'linear-gradient(0deg, rgba(4, 8, 17, 0) 0%, #040811 100%)',
      ...props.sx,
    }}
    {...commonShadowProps}
  />
)
const BottomShadow = (props: BoxProps) => (
  <Box
    height="270px"
    left={0}
    right={0}
    bottom={0}
    {...props}
    sx={{
      background: 'linear-gradient(180deg, rgba(4, 8, 17, 0) 0%, #040811 100%)',
      ...props.sx,
    }}
    {...commonShadowProps}
  />
)

const MobileShadow = (props: BoxProps) => (
  <Box
    left={0}
    top={0}
    right={0}
    bottom={0}
    {...props}
    sx={{
      background: 'rgba(4, 8, 17, 0.4)',
      ...props.sx,
    }}
    {...commonShadowProps}
  />
)

function ChallengeImageWrapper({
  leftShadow,
  rightShadow,
  topShadow,
  bottomShadow,
  mobileShadow,
  children,
  bgImage,
  bgHeight = '600px',
  containerProps,
  imgWrapperBoxProps,
  leftShadowProps,
  rightShadowProps,
  topShadowProps,
  bottomShadowProps,
  mobileShadowProps,
  ...restProps
}: HeroImageWrapper2Props) {
  const theme = useTheme()
  const isMd3View = useMediaQuery(theme.breakpoints.down('md3'))
  return (
    <Box position="relative" overflow="hidden" minHeight={bgHeight} zIndex={0} {...restProps}>
      <Box
        position="absolute"
        overflow="hidden"
        height={bgHeight}
        top={0}
        left={isMd3View ? 0 : 8}
        right={isMd3View ? 0 : 8}
        bgcolor="#000"
        border="1px solid"
        borderColor="gray.700"
        {...imgWrapperBoxProps}
      >
        <>
          <Image src={bgImage} alt="" fill />
          {topShadow && <TopShadow {...topShadowProps} />}
          {rightShadow && <RightShadow {...rightShadowProps} />}
          {bottomShadow && <BottomShadow {...bottomShadowProps} />}
          {leftShadow && <LeftShadow {...leftShadowProps} />}
          {mobileShadow && <MobileShadow {...mobileShadowProps} />}
        </>
      </Box>
      {children && (
        <Container
          maxWidth="lg2"
          disableGutters
          sx={{ zIndex: 2, position: 'relative' }}
          {...containerProps}
        >
          {children}
        </Container>
      )}
    </Box>
  )
}

export default ChallengeImageWrapper

import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { TournamentStatus, TournamentType } from 'src/helpers/types';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { ActiveChallengeStatus } from 'src/utils/const';
import TournamentWaitingRoom from './waiting-room';
import TournamentRunning from './tournament-status';
import { subscribeToLobby } from 'src/actions/challengeActions';
import NotFound from '../notFound';
import { makeGetTournamentWithCurrentRound } from 'src/selectors/activeTournamentSelector';

export default function Tournament() {
    const dispatch =  useAppDispatch();
    
    const { tournamentId }  = useParams<{ tournamentId: string }>()   
   
    var tournamentIdNumber=0;
    if (tournamentId !== undefined) {
        tournamentIdNumber = +tournamentId;
    }

   
    useEffect(() => {
        dispatch(subscribeToLobby(tournamentId,true));
      }, [dispatch,tournamentId])  

    const user = useAppSelector(state => state.userReducer.userInfo);
    const activeChallengeStatus = useAppSelector(state => state.challengeReducer.activeChallengeStatus);
        
    const getTournamentWithCurrentRound = makeGetTournamentWithCurrentRound(tournamentIdNumber);
    const tournamentChallenge : TournamentType =  useAppSelector(state => getTournamentWithCurrentRound(state));

    if(activeChallengeStatus === ActiveChallengeStatus.NOT_FOUND){
        return <NotFound text={'Tournament not found'}/>
      }
    else if(activeChallengeStatus === ActiveChallengeStatus.NOT_LOADED || tournamentChallenge===undefined)
        return null;
    else if(tournamentChallenge.tournamentStatus===TournamentStatus.NOT_STARTED || tournamentChallenge.tournamentStatus===TournamentStatus.CANCELLED){
        return (
           <TournamentWaitingRoom user={user} tournament={tournamentChallenge}/>
        );
    }
    else{
        return (
           <TournamentRunning user={user} tournament={tournamentChallenge}/>
        );
    }
  }
import React, { useState, useCallback, useEffect } from 'react'
import { Box, Stack, Grid, useTheme, useMediaQuery } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { TOAST_IDS, toast } from 'src/components/Notification/Notification'
import assets from 'src/assets'
import PageContainer from 'src/components/PageContainer/PageContainer'
import PatternLines from 'src/components/PatternLines/PatternLines'
import TitleWithDot from 'src/components/TitleWithDot/TitleWithDot'
import TournamentPageMediaWrapper from 'src/components/TournamentPageMediaWrapper/TournamentPageMediaWrapper'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import ButtonIcon from 'src/components/ButtonIcon/ButtonIcon'
import Timer from 'src/components/Timer/Timer'
import TournamentTabs from 'src/components/TournamentTabs/TournamentTabs'
import GameTip from 'src/components/GameTip/GameTip'
import TableTournament from 'src/components/TableTournament/TableTournament'
import ReadyTournamentBox from 'src/components/ReadyTournamentBox/ReadyTournamentBox'
import { closeLobby, leave } from 'src/actions/challengeActions'
import { getGameLink } from "src/selectors/gameLinkSelector";
import { useAppDispatch, useAppSelector } from 'src/hooks'
import { mapTableTournament } from 'src/helpers/tournamentData'
import { appendUrlPrefix, isTargetBlank, secondsLeft } from 'src/helpers/commonData'
import Popup from 'src/components/Popup/Popup'
import { TournamentType, TournamentStatus, ChallengeStatus, UserType, StartType } from 'src/helpers/types'
import Typography from 'src/components/Typography/Typography'
import { config } from 'src/config/config'
import TournamentInfoBox from 'src/components/TournamentInfoBox/TournamentInfoBox'


type TournamentWaitingRoomProps = {
  user: UserType
  tournament: TournamentType
}

export default function TournamentWaitingRoom({ user, tournament }:TournamentWaitingRoomProps) {
  const theme = useTheme()
  const isMdView = useMediaQuery(theme.breakpoints.down('md'))
  const isSmView = useMediaQuery(theme.breakpoints.down('sm'))
  const isMd834 = useMediaQuery(theme.breakpoints.down('md834'))
  const dispatch =  useAppDispatch();
  const navigate = useNavigate();

  const [showReadyTournamentBox, setShowReadyTournamentBox] = useState(false)
  const [open, setOpen] = React.useState(false)

  const handleExpireTimer = useCallback(() => {
    setShowReadyTournamentBox(true)    
    {/*
    toast.dismiss(TOAST_IDS.LobbyStatusBar.toastId); 
    toast.lobbyStatusBarInfo(
      {
        status: 'waiting',
        text: 'Tournament is about to start!',
        hideButton: true,
        btnProps: {
          children: 'Open tournament',
          disabled: true,       
        },
      },
      {
        autoClose: 60000,
        position: 'bottom-right',
      }
    ) */}
    
  }, [])

  const quitButtonAction = () => {
    if(tournament.tournamentError){
      return {
        quitAction:() => dispatch(closeLobby(tournament.id, '/')),
        quitDisabled: false,
        quitButtonText: 'Close Tournament'
      }
    }
    switch(tournament.tournamentStatus){
      case TournamentStatus.NOT_STARTED:
        return tournament.isParticipating ? 
            {
              quitAction: () => setOpen(true),
              quitDisabled: false,
            }
          :
            {
              quitAction: () => dispatch(closeLobby(tournament.id, '/')),
              quitDisabled: false,
              quitButtonText: 'Close Tournament'
            }
      case TournamentStatus.FINAL_DONE:
      case TournamentStatus.CANCELLED:
        return {
          quitAction:() => dispatch(closeLobby(tournament.id, '/')),
          quitDisabled: false,
          quitButtonText: 'Close Tournament'
        
        }
      default:
        return {
          quitAction: () => undefined,
          quitDisabled: true,
        }
    }
  };

  function QuitDialog() {
    return  (
              <Popup
                title={'Quit Tournament'}
                description={
                  'Are you sure you want to leave tournament? If you decide to leave you will be refunded the Challenge fee'
                }
                iconData={{
                  name: 'logout-box-line',
                }}
                cancelButtonData={{
                  label: 'Cancel',
                  onClick: () => {                   
                    setOpen(false)
                },
                }}
                primaryButtonData={{
                  label: 'Yes, exit tournament',
                  variant: 'contained',
                  onClick: () => {
                    setOpen(false)
                    dispatch(leave(tournament.id, tournament.blockchainChallengeId, tournament.fee, tournament.type, true))
                  }
                }}
                open={open}
                onClose={() => {
                    setOpen(false)
                }}
              />
            )
  }

  const linkPart = useAppSelector(state => getGameLink(state, tournament?.intGameId));
  var frontlandGameLink = appendUrlPrefix(linkPart);
  const targetBlank = isTargetBlank(linkPart);
 
  let tableTournament = mapTableTournament(tournament,user);  
  let quitAction = quitButtonAction();
  
  return (
    <>
    <Box bgcolor="gray.900" position="relative">
      {tournament.tournamentStatus === TournamentStatus.NOT_STARTED  && showReadyTournamentBox &&
        <TournamentInfoBox title='Starting tournament' subtitle='' />
      }
      <TournamentPageMediaWrapper
        imageProps={{
          src: isSmView
            ? assets.png.mobileTournamentPageImg
            : isMdView
            ? assets.png.tabletTournamentWaitingRoomPageImg
            : assets.png.tournamentWaitingRoomPageImg,
        }}
        height="670px"
        leftShadowBoxProps={{
          height: { sm2: '670px' },
        }}
        rightShadowBoxProps={{
          display: 'none',
        }}
        bottomShadowBoxProps={{
          height: { xs: '126px' },
          sx: {
            [theme.breakpoints.down('sm2')]: {
              maxWidth: '100%',
            },
          },
        }}
      />
      <PageContainer
        sx={{
          pt: '17px',
          display: 'flex',
          [theme.breakpoints.down('sm2')]: {
            position: 'relative',
            top: '8px',
            pt: '20px',
          },
        }}
        innerContainerProps={{
          sx: { display: 'flex', flexDirection: 'column', position: 'relative' },
        }}
      >
        {!isMdView && (
          <PatternLines
            sx={{
              height: '262px',
              top: '17px',
              [theme.breakpoints.down('lg4')]: {
                ml: 'calc(-100vw - 12px)',
                height: '238px',
              },
              [theme.breakpoints.down('sm')]: {
                height: '66px',
                ml: 'calc(-100vw - 8px)',
                top: 0,
              },
            }}
          />
        )}
        <Stack
          direction="row"
          gap={{ xs: '14px', sm: '20px' }}
          alignItems="center"
          mb={{ xs: '17px', sm: '24px', md: '28px' }}
        >
          <ButtonIcon
            sx={{ mb: '8px' }}
            variant="outlined"
            size="small"
            onClick={() => {
              navigate(-1)
            }}
          >
            <SvgIcon name="arrow-left-line" />
          </ButtonIcon>
          <TitleWithDot
            typographyProps={{ variant: isSmView ? 'h4' : isMdView ? 'h3' : 'h2' }}
            dotProps={{
              bottom: '16px',
              sx: {
                [theme.breakpoints.down('md')]: { bottom: '12px' },
              },
            }}
            rootStackProps={{ spacing: { xs: '2px', sm: '9px', md: '15px' } }}
          >
            tournament {tournament.tournamentName}
          </TitleWithDot>
        </Stack>
        <Grid
          container
          spacing={{ xs: '24px', md834: '28px', md: '33px' }}
          flexWrap={{ xs: 'wrap' }}
          justifyContent="space-between"
        >
          <Grid item xs={12} sm>
            <Box maxWidth="634px">
              {tournament.startType===StartType.ON_TIME && tournament.status===ChallengeStatus.NOT_STARTED ? (
                <Timer
                  mb={{ xs: '36px', sm: '24px' }}
                  title="tournament starts in"
                  showSeconds={true}
                  showZeroTime={{ zeroDay: true, zeroHrs: true, zeroMin: true }}
                  dateLabels={{ day: 'day' }}
                  onExpire={handleExpireTimer}
                  expirySeconds={secondsLeft(tournament.startTime as Date,0)}
                />
              ) : (tournament.status===ChallengeStatus.CANCELLED ?
                  <Typography
                      variant="bodyBig"
                      color="gray.25"
                      mb="24px"
                      sx={{
                        [theme.breakpoints.up('md')]: {
                          ...theme.typography.bodyMedium,
                        },
                      }}
                  >
                    Tournament canceled
                  </Typography> : <></>  //<ReadyTournamentBox BoxProps={{ mb: { xs: '36px', sm: '24px' } }} />
                )
              }
              {isMd834 && (
                <Box mb="24px">
                  <TournamentTabs tableTournament={tableTournament} challenge={tournament} 
                    onLeaveTournament={quitAction.quitAction} quitDisabled={quitAction.quitDisabled} quitText={quitAction.quitButtonText}/>
                </Box>
              )}
              {!isMd834 && (
                <Box mb="52px">
                  <TableTournament {...tableTournament} challenge={tournament}  />
                </Box>
              )}
            </Box>
          </Grid>
          {!isMd834 && (
            <Grid item xs={12} sm>
              <Box
                minWidth={{ xs: 'auto', sm: '370px' }}
                maxWidth="520px"
                ml={{ xs: 0, md: 'auto' }}
                mt="12px"
                mb="24px"
              >
                <TournamentTabs tableTournament={tableTournament} challenge={tournament}
                  onLeaveTournament={quitAction.quitAction} quitDisabled={quitAction.quitDisabled} quitText={quitAction.quitButtonText}/>
                <GameTip StackProps={{ my: '32px' }} 
                 text='If you don’t have the Frontland game yet, download it here'
                 link={frontlandGameLink}
                 disabled={frontlandGameLink==="" ? true : false}
                 target={targetBlank ? '_blank' : undefined}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </PageContainer>
    </Box>
    <QuitDialog/>
    </>
  )
}

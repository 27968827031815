import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from "./api";
import { Reward, RewardSummary } from "src/helpers/rewardTypes";
import { GameHistoryType, PasswordType, TokenBalanceType } from 'src/helpers/types';


export enum Tags  {
 RewardSummary= 'RewardSummary',
 LatestRewards= 'LatestRewards',
 MatchHistory= 'MatchHistory',
 Pwd= 'Pwd',
 CTBalance = 'CTBalance',
}

export const apiProfileSlice = createApi({
    reducerPath: 'apiProfile',
    baseQuery: axiosBaseQuery({ baseUrl: '/api' }),
    tagTypes: [Tags.RewardSummary,Tags.LatestRewards,Tags.MatchHistory,Tags.Pwd,Tags.CTBalance], // Define a tag for invalidating and refetching
    endpoints: (builder) => ({
        fetchRewardSummary: builder.query<RewardSummary,void>({
            query: () => ({ url: '/rewards/users/me/summary', method: 'GET' }),
            keepUnusedDataFor: 300, // Keep data in cache for 5 minutes
            providesTags: [Tags.RewardSummary],
        }),
        fetchLatestRewards: builder.query<Reward[],void>({
            query: () => ({ url: '/rewards/users/me/latest', method: 'GET' }),
            keepUnusedDataFor: 300, // Keep data in cache for 5 minutes
            providesTags: [Tags.LatestRewards],
        }),
        fetchMatchHistory: builder.query<GameHistoryType[],void>({
            query: () => ({ url: '/users/me/matchesplayed', method: 'GET' }),
            keepUnusedDataFor: 300, // Keep data in cache for 5 minutes
            providesTags: [Tags.MatchHistory],
            transformResponse: (response:GameHistoryType[]) => {
                return response.map((item: any) => {
                  if (item.payout !== undefined) {
                    return {
                      ...item,
                      payout: item.payout.toString(),
                    };
                  }
                  return item;
                });
              },
        }),
        fetchPwd: builder.query<PasswordType,void>({
            query: () => ({ url: '/users/me/pwd', method: 'GET' }),
            keepUnusedDataFor: Infinity, // Keep data in cache indefinitely
            providesTags: [Tags.Pwd],
        }),

        fetchCTBalance: builder.query<TokenBalanceType,{ address: string, chainId: string }>({
            query: ({ address, chainId }) => ({ url: `/blockchain/users/${address}/tokens/ct/balance?chainId=${chainId}`, method: 'GET' }),
            keepUnusedDataFor: 300, // Keep data in cache for 5 minutes
            providesTags: [Tags.CTBalance],           
        }),
        claimRewards: builder.mutation<any, { points: number }>({
          query: ({ points }) => ({
            url: '/rewards/users/me/claim/ct',
            method: 'POST',
            data: { points },
          }),    
          invalidatesTags: (result, error) => error ? [] : [Tags.RewardSummary],      
        }),
    })
});

export const { useFetchRewardSummaryQuery,useFetchLatestRewardsQuery,useFetchMatchHistoryQuery,
  useFetchPwdQuery, useFetchCTBalanceQuery, useClaimRewardsMutation } = apiProfileSlice;
export const { resetApiState : resetProfileApiState } = apiProfileSlice.util;

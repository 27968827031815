import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableContainerProps as MuiTableContainerProps,
  styled,
  TableCell,
  tableCellClasses,
  TableRow,
  Stack,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import React from 'react'
import GradientBox from '../GradientBox/GradientBox'
import Typography from '../Typography/Typography'

// Typing of the table Data
type TableDataT = {
  headRow: { cell: { value: string; color?: string; width?: string }[] }
  bodyRow: {
    cell: { value: string | (() => React.ReactNode) | undefined; color?: string }[] | null
  }[]
}

type TableHistoryProfileProps = {
  /** [The root element of this component uses the TableContainerProps from Material UI](https://mui.com/material-ui/react-table/) */
  TableContainerProps?: MuiTableContainerProps
  data: TableDataT
  limitRows?: number
}

function TableHistoryProfile({
  data,
  limitRows = 8,
  ...TableContainerProps
}: TableHistoryProfileProps) {
  const theme = useTheme()
  const isMdView = useMediaQuery(theme.breakpoints.down('md'))
  const isSmView = useMediaQuery(theme.breakpoints.down('sm'))
  const showTruncate = data.bodyRow.length < limitRows ? false : true
  let truncateHeight = 27
  if (isMdView) {
    truncateHeight = 21
  }
  if (isSmView) {
    truncateHeight = 31
  }

  return (
    <TableContainer
      component="div"
      sx={{ display: 'flex', flexDirection: 'column', width: 'auto', overflow: 'visible' }}
      {...TableContainerProps}
    >
      <Stack direction="row" sx={{ width: '100%' }} mb="2px">
        {data?.headRow?.cell?.map((th, indexHead) => (
          <StyledHeadCell key={indexHead}>
            <Typography variant="uiElement">{th.value}</Typography>
          </StyledHeadCell>
        ))}
      </Stack>
      <Box
        overflow="auto"
        width={`calc(100% + ${showTruncate ? '4px' : 0})`}
        maxHeight={getFullTableHeight(limitRows, truncateHeight)}
        position="relative"
        sx={{ transition: 'max-height 0.4s ease-in-out' }}
      >
        <Table aria-label="customized table">
          <TableBody>
            {data.bodyRow.map((row, index) => (
              <StyledTableRow key={index}>
                <>
                  {row?.cell?.map((cell, indexCell) => (
                    <StyledTableCell key={indexCell}>
                      {typeof cell.value === 'function'
                        ? cell.value()
                        : cell.value && (
                            <Typography variant="bodySmall" {...textTypographyColor(cell.color)}>
                              {cell.value}
                            </Typography>
                          )}
                    </StyledTableCell>
                  ))}
                </>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {showTruncate && (
          <GradientBox
            width="100%"
            position="sticky"
            bottom={0}
            zIndex={1}
            height={truncateHeight}
          />
        )}
      </Box>
    </TableContainer>
  )
}

// ===
// === Stylization table START
const tableStyleParams = {
  common: {
    td: {
      height: 50,
      firstWidth: '23.59%',
      secondWidth: '33.59%',
      thirdWidth: '13.59%',
      fourthWidth: '21.5%',
      fifthWidth: '7.73%',
    },
    tdTablet: {
      firstWidth: '23.5%',
      secondWidth: '33.5%',
      thirdWidth: '13%',
      fourthWidth: '22%',
      fifthWidth: '8%',
    },
    tdMobile: {
      firstWidth: '22%',
      secondWidth: '23%',
      thirdWidth: '10%',
      fourthWidth: '30%',
      fifthWidth: '15%',
    },
  },
}

const StyledHeadCell = styled(Box)(({ theme }) => ({
  padding: '2px 0px 0 8px',
  borderBottom: 'none',
  backgroundColor: theme.palette.gray[700],
  color: theme.palette.gray[500],
  height: '29px',
  alignItems: 'center',
  display: 'flex',
  '&:first-of-type': {
    width: tableStyleParams.common.td.firstWidth,
  },
  '&:nth-of-type(2)': {
    width: tableStyleParams.common.td.secondWidth,
  },
  '&:nth-of-type(3)': {
    width: tableStyleParams.common.td.thirdWidth,
  },
  '&:nth-of-type(4)': {
    width: tableStyleParams.common.td.fourthWidth,
  },
  '&:nth-of-type(5)': {
    width: tableStyleParams.common.td.fifthWidth,
  },
  [theme.breakpoints.down('md')]: {
    '&:first-of-type': {
      width: tableStyleParams.common.tdTablet.firstWidth,
    },
    '&:nth-of-type(2)': {
      width: tableStyleParams.common.tdTablet.secondWidth,
    },
    '&:nth-of-type(3)': {
      width: tableStyleParams.common.tdTablet.thirdWidth,
    },
    '&:nth-of-type(4)': {
      width: tableStyleParams.common.tdTablet.fourthWidth,
    },
    '&:nth-of-type(5)': {
      width: tableStyleParams.common.tdTablet.fifthWidth,
    },
  },
  [theme.breakpoints.down('sm')]: {
    '&:first-of-type': {
      width: tableStyleParams.common.tdMobile.firstWidth,
    },
    '&:nth-of-type(2)': {
      width: tableStyleParams.common.tdMobile.secondWidth,
    },
    '&:nth-of-type(3)': {
      width: tableStyleParams.common.tdMobile.thirdWidth,
    },
    '&:nth-of-type(4)': {
      width: tableStyleParams.common.tdMobile.fourthWidth,
    },
    '&:nth-of-type(5)': {
      width: tableStyleParams.common.tdMobile.fifthWidth,
    },
  },
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '0 0 0 8px',
  borderBottom: 'none',
  '&:first-of-type': {
    width: tableStyleParams.common.td.firstWidth,
  },
  '&:nth-of-type(2)': {
    width: tableStyleParams.common.td.secondWidth,
  },
  '&:nth-of-type(3)': {
    width: tableStyleParams.common.td.thirdWidth,
  },
  '&:nth-of-type(4)': {
    width: tableStyleParams.common.td.fourthWidth,
  },
  '&:nth-of-type(5)': {
    width: tableStyleParams.common.td.fifthWidth,
  },
  [theme.breakpoints.down('md')]: {
    '&:first-of-type': {
      width: tableStyleParams.common.tdTablet.firstWidth,
    },
    '&:nth-of-type(2)': {
      width: tableStyleParams.common.tdTablet.secondWidth,
    },
    '&:nth-of-type(3)': {
      width: tableStyleParams.common.tdTablet.thirdWidth,
    },
    '&:nth-of-type(4)': {
      width: tableStyleParams.common.tdTablet.fourthWidth,
    },
    '&:nth-of-type(5)': {
      width: tableStyleParams.common.tdTablet.fifthWidth,
    },
    [theme.breakpoints.down('sm')]: {
      '&:first-of-type': {
        width: tableStyleParams.common.tdMobile.firstWidth,
      },
      '&:nth-of-type(2)': {
        width: tableStyleParams.common.tdMobile.secondWidth,
      },
      '&:nth-of-type(3)': {
        width: tableStyleParams.common.tdMobile.thirdWidth,
      },
      '&:nth-of-type(4)': {
        width: tableStyleParams.common.tdMobile.fourthWidth,
      },
      '&:nth-of-type(5)': {
        width: tableStyleParams.common.tdMobile.fifthWidth,
      },
    },
  },
  [`&.${tableCellClasses.body}`]: {
    height: `${tableStyleParams.common.td.height}px`,
    color: theme.palette.gray[25],
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // Common styles for <tr>
  backgroundColor: theme.palette.gray[900],
  border: `1px solid ${theme.palette.gray[700]}`,
  borderTopWidth: '2px',
  '&:first-of-type': {
    borderTopWidth: 0,
  },
  position: 'relative',
  '& td:first-of-type:before': {
    content: '" "',
    display: 'block',
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: '2px',
    backgroundColor: '#4B5873',
    opacity: 0,
    transition: 'opacity ease-in-out 0.25s',
    zIndex: 2,
  },
  '&:hover': {
    '& td:first-of-type:before': {
      opacity: 1,
    },
  },
}))
// === Stylization table END
// ===

const textTypographyColor = (val: string | undefined) => {
  let out = {}
  if (val) {
    out = { color: val }
  }
  return out
}

// Getting the height of the table for smooth altitude changes
const getFullTableHeight = (val: number, truncateHeight: number) => {
  const truncate = truncateHeight
  // Calculation of the height of the table
  return tableStyleParams.common.td.height * val + 1 + truncate
}

export default TableHistoryProfile

import { Box } from '@mui/material'
import React from 'react'
import Typography from '../Typography/Typography'
import ModalHeaderImage from '../ModalHeaderImage/ModalHeaderImage'
import tournamentModalHeader from '../../assets/images/tournament-modal-header.jpg'
import ZebraMiniTable from '../ZebraMiniTable/ZebraMiniTable'
import Link from '../Link/Link'
import { mapTournamentModalMiniTable } from 'src/helpers/tournamentData'
import { TournamentType } from 'src/helpers/types'

type ModalContentTournamentProps = {
  tournament: TournamentType
}

function ModalContentTournament( {tournament}: ModalContentTournamentProps) {

  const tournamentModalMiniTable = mapTournamentModalMiniTable(tournament);
  return (
    <>
      <ModalHeaderImage image={tournamentModalHeader} />
      <Box padding="12px 12px 20px 12px">
        <Typography variant="h5" align="center" mt="15px" mb="18px">
          Enter Tournament
        </Typography>

        <ZebraMiniTable data={tournamentModalMiniTable} highlightFirstRow={true} />

        <Typography variant="bodySmall" align="left" color="gray.200" mt="25px" mb="4px">
          By purchasing a spot in the Tournament, you will send your tokens to an external escrow
          contract. There will be a timeout between rounds, and after all rounds in the tournament
          have been played, the winners will receive their prizes based on their positions in the
          tournament brackets.
        </Typography>
        <Link color="gray.25" size="small" href="/">
          Read more
        </Link>
      </Box>
    </>
  )
}

export default ModalContentTournament

import React from 'react'
import ImageWithTitle from '../ImageWithTitle/ImageWithTitle'
import { TypographyProps as TextTypographyProps } from 'src/components/Typography/Typography'
import { ImageBoxProps } from 'src/components/ImageBox/ImageBox'
import { ImageProps } from 'src/components/Image/Image'
import { StackProps as MuiStackProps, useTheme } from '@mui/material'

enum Variants {
  Default = 'default',
  Game = 'game',
  Avatar = 'avatar',
  Small = 'small',
}
type VariantT = `${Variants}` // Co "Enum Position" values to input options

type ImageWithTitleWrapperProps = {
  text: string
  icon: string
  /** [The root element of this component uses the TypographyProps from Material UI](https://mui.com/material-ui/react-typography/) */
  TypographyProps?: TextTypographyProps
  variant?: VariantT
  /** [The root element of this component uses the StackProps from Material UI](https://mui.com/material-ui/react-stack/) */
  StackProps?: MuiStackProps
}
function ImageWithTitleWrapper({
  text,
  icon,
  TypographyProps,
  variant = Variants.Default,
  StackProps,
}: ImageWithTitleWrapperProps) {
  const theme = useTheme()
  const { sx: typographySx, ...restTypographyProps } = TypographyProps || {}

  const typographyStyle: React.CSSProperties = {}
  const imageBoxParams: ImageBoxProps = { width: '24px', height: '24px' }
  const imageParams: ImageProps = { width: '18px', height: '18px' }

  switch (variant) {
    case Variants.Game:
      restTypographyProps.variant = 'bodySmall'
      typographyStyle.marginLeft = '8px !important'
      typographyStyle.transform = 'translateY(0px)'
      typographyStyle.textTransform = 'none'
      break
    case Variants.Avatar:
      restTypographyProps.variant = 'paragraphSmall'
      typographyStyle.marginLeft = '8px !important'
      typographyStyle.transform = 'translateY(0px)'
      typographyStyle.textTransform = 'none'
      imageBoxParams.width = '18px'
      imageBoxParams.height = '18px'
      imageBoxParams.border = 'none'
      break
    case Variants.Small:
      restTypographyProps.variant = 'bodySmall'
      typographyStyle.marginLeft = '8px !important'
      typographyStyle.transform = 'translateY(0px)'
      typographyStyle.textTransform = 'none'
      imageBoxParams.width = '16px'
      imageBoxParams.height = '16px'
      imageParams.width = '14px'
      imageParams.height = '14px'
      break
    case Variants.Default:
      typographyStyle.marginLeft = '4px !important'
      break
  }

  return (
    <ImageWithTitle
      imageBoxProps={{ ...imageBoxParams }}
      imageProps={{ ...imageParams, src: icon }}
      title={text}
      typographyProps={{
        variant: 'uiElement',
        noWrap: true,
        sx: {
          transform: 'translateY(2px)',
          whiteSpace: 'nowrap',
          ...typographyStyle,
          ...typographySx,
          [theme.breakpoints.down('md')]: {
            width: '90px',
          },
          [theme.breakpoints.down('sm')]: {
            width: '43px',
          },
        },
        ...restTypographyProps,
      }}
      rootStackProps={{ ...StackProps }}
    />
  )
}

export default ImageWithTitleWrapper

import React, { useCallback, useMemo, useState } from 'react'
import { Box, useTheme, useMediaQuery, Stack, Grid } from '@mui/material'
import SimpleTabs, {
  TabsProps,
  SimpleTabPanel,
  SimpleTabsWrapper,
} from 'src/components/SimpleTabs/SimpleTabs'
import Typography from 'src/components/Typography/Typography'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import EventInfoOpt, { EventInfo } from 'src/components/EventInfoOpt/EventInfoOpt'
import Button from 'src/components/Button/Button'
import BgLinesWrapper from 'src/components/BgLinesWrapper/BgLinesWrapper'
import TableTournament from '../TableTournament/TableTournament'
import GamingEventDetailsOpt, {
  GamingEventDetails,
} from 'src/components/GamingEventDetailsOpt/GamingEventDetailsOpt'
import { mapEventDetailsOpt2, mapEventInfoOpt2, mapTableTournament } from 'src/helpers/tournamentData'
import { To } from 'react-router'
import { TournamentType } from 'src/helpers/types'

const tabList = ['Players', 'Details', 'Description']

export type TournamentTabsProps = Partial<Pick<TabsProps, 'value' | 'onChange'>> & {
  onLeaveTournament?: () => void
  tableTournament: ReturnType<typeof mapTableTournament>
  challenge: TournamentType
  quitDisabled?: boolean
  quitText?: string
}

export default function TournamentTabs({
  value,
  onChange,
  onLeaveTournament,
  tableTournament,
  challenge,
  quitDisabled,
  quitText,

}: TournamentTabsProps) {
  const theme = useTheme()
  const isMd834View = useMediaQuery(theme.breakpoints.down('md834'))

  const [activeTab, setActiveTab] = useState<number>(value || 0)

  const handleTabs = useCallback(
    (val: number) => {
      if (onChange) {
        onChange(val)
      } else {
        setActiveTab(val)
      }
    },
    [onChange]
  )

  const tabs = useMemo(() => {
    return isMd834View ? tabList : tabList.slice(1)
  }, [isMd834View])

  const eventInfoOpt = mapEventInfoOpt2(challenge);
  const gamingEventDetailsOpt = mapEventDetailsOpt2(challenge);

  return (
    <SimpleTabsWrapper showBorder={tabs?.length === 2 ? true : (value || activeTab) !== 0}>
      <SimpleTabs
        list={tabs}
        value={value || activeTab}
        onChange={handleTabs}
        showBorder={tabs?.length === 2 ? false : (value || activeTab) === 0}
      />
      {isMd834View && (
        <SimpleTabPanel value={value || activeTab} index={0} transparent>
          <TableTournament {...tableTournament}  challenge={challenge}/>
        </SimpleTabPanel>
      )}
      <SimpleTabPanel value={value || activeTab} index={tabs?.length === 2 ? 0 : 1}>
        <Details eventInfoOpt={eventInfoOpt} gamingEventDetailsOpt={gamingEventDetailsOpt}/>
      </SimpleTabPanel>
      <SimpleTabPanel value={value || activeTab} index={tabs?.length === 2 ? 1 : 2}>
        <Description description={challenge.description} eventInfoOpt={eventInfoOpt} />
      </SimpleTabPanel>
      <Box bgcolor="gray.900">
        <BgLinesWrapper>
          <Button

            size="medium"
            fullWidth
            disabled={quitDisabled}
            endIcon={<SvgIcon name="logout-circle-r-line" />}
            onClick={onLeaveTournament}
          >
            {quitText?  quitText : 'Leave tournament'}
          </Button>
        </BgLinesWrapper>
      </Box>
    </SimpleTabsWrapper>
  )
}

type DetailsProps = {
  eventInfoOpt: ReturnType<typeof mapEventInfoOpt2>
  gamingEventDetailsOpt: ReturnType<typeof mapEventDetailsOpt2>
}

function Details({eventInfoOpt,gamingEventDetailsOpt}: DetailsProps) {
  const theme = useTheme()

  return (
    <>
      <Grid container direction="column" gap="24px" justifyContent={{ sm: 'space-between' }}>
        {gamingEventDetailsOpt.map((item) => (
          <Grid item key={item?.event} xs={6} sm="auto">
            <GamingEventDetailsOpt {...item} />
          </Grid>
        ))}
      </Grid>
      <Stack
        mt="24px"
        direction="row"
        gap="24px"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        bgcolor="gray.700"
        p={{ xs: '14px', sm: '16px' }}
        sx={{
          height: '176px',
          [theme.breakpoints.down('xsm')]: {
            height: '172px',
          },
        }}
      >
        {eventInfoOpt?.map((item) => (
          <EventInfoOpt key={item?.event} {...item} />
        ))}
      </Stack>
    </>
  )
}

type DescriptionProps = {
  description?: string
  eventInfoOpt: ReturnType<typeof mapEventInfoOpt2>
}


function Description({description, eventInfoOpt}: DescriptionProps) {
  const theme = useTheme()
  return (
    <>
      <Typography
        variant="bodySmall"
        color="gray.25"
        mb="24px"
        sx={{
          [theme.breakpoints.up('md')]: {
            ...theme.typography.bodyMedium,
          },
        }}
      > {description && 
          <>
          {description}
          <br/>
          <br/>
          </>
        }
        Welcome to the Frontland Single Elimination Tournament, where fierce competitors from all
        corners of the gaming realm gather to battle it out for glory!
        <br />
        <br />
        In this adrenaline-pumping event, players will engage in intense head-to-head matches, with
        only the strongest advancing to the next round. With each round, the stakes rise higher as
        participants push their skills to the limit, aiming to secure their place in the ultimate
        showdown.
        <br />
        <br />
        The Single Elimination format ensures that every match counts, leaving no room for error. As
        the tension builds and the competition heats up, who will emerge as the ultimate champion,
        etching their name in Frontland history? Brace yourself for epic clashes, breathtaking
        strategies, and nail-biting moments as the players give it their all to claim victory in
        this exhilarating Frontland tournament!
      </Typography>
      <Stack
        direction="row"
        gap="24px"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        bgcolor="gray.700"
        p={{ xs: '14px', sm: '16px' }}
        sx={{
          height: '176px',
          [theme.breakpoints.down('xsm')]: {
            height: '172px',
          },
        }}
      >
        {eventInfoOpt?.map((item) => (
          <EventInfoOpt key={item?.event} {...item} />
        ))}
      </Stack>
    </>
  )
}

import { PNG_ICONS } from 'src/assets/icons/icons'
import { ImageProps } from 'src/components/Image/Image'
import assets from 'src/assets'
import {ChallengeCardProps} from 'src/components/ChallengeCard/ChallengeCard'
import { EnterChallengePopupProps } from 'src/components/Popup/EnterChallengePopup/EnterChallengePopup'
import { ChallengeDetailsCardProps } from 'src/components/ChallengeDetailsCard/ChallengeDetailsCard'
import bgChallengesFrontland_Rooftop from 'src/assets/images/bgChallengesFrontland_Rooftop.jpg'
import bgChallengesFrontland_Street from 'src/assets/images/bgChallengesFrontland_Street.jpg'
import bgChallengesFortnite from 'src/assets/images/bgChallengesFortnite.jpg'
import bannerFrontlandPhoto from 'src/assets/images/photoDownloadBaner.jpg'
import bannerFortnitePhoto from 'src/assets/images/downloadBanerFortnite.jpg'
import ChallengeCardFrontland_Rooftop from 'src/assets/images/ChallengeCardFrontland_Rooftop.png'
import ChallengeCardFrontland_Street from 'src/assets/images/ChallengeCardFrontland_Street.png'
import ChallengeCardFrontland_Bridge from 'src/assets/images/ChallengeCardFrontland_Bridge.png'
import ChallengeCardFrontland_Hacienda from 'src/assets/images/ChallengeCardFrontland_Hacienda.png'
import ChallengeCardFortnine from 'src/assets/images/challengeCardFortnine.png'
import CoinImageWithAmount from 'src/components/CoinImageWithAmount/CoinImageWithAmount'
import { AccountOptions, Statuses } from 'src/components/LinkedAccounts/LinkedAccounts'
import { AccountItem }  from 'src/components/AccountFieldArrayItem/AccountFieldArrayItem'
import ImageWithTitleSmall from 'src/components/ImageWithTitleSmall/ImageWithTitleSmall'
import ImageWithTitleWrapper from 'src/components/ImageWithTitleWrapper/ImageWithTitleWrapper'
import Typography from 'src/components/Typography/Typography'
import TrophyNumber from 'src/components/TrophyNumber/TrophyNumber'
import { Stack, Breakpoint, Theme } from '@mui/material'
import { Options } from 'src/components/Select/Select'
import Link from 'src/components/Link/Link'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import TimeAgo from 'react-timeago'
import {translateGameType, logEventText, translateGameMode} from "src/utils/translate";
import { AccountType, GameTemplateType, DataHolderType, ActiveChallengeType, ChallengeStatus, ParticipantType,
    UserType, LogEventType, ChallengeEventType ,LiveEventType, 
    TopListType,TopList_Type, GameHistoryType, SortOrder, TokenType, StartType } from "./types";
import { connectEpicGames } from "src/sagas/apiSagas";
import { TableDataT } from 'src/components/LiveActivityNew/LiveActivityNew'
import { addOrdinalSuffix, formatPrizePool, getProfilePicture, getTokenImgSrc, secondsLeft } from './commonData'
import { GameId, GameMode } from 'src/utils/const'
import { formatTokenToString, formatTokenToStringWithSymbol } from 'src/utils/tokenUtil'

export function accountMapper(accounts:AccountType[],processingEpic:boolean) {
      
      let notConnected =[] as AccountOptions;
      let connected= [] as AccountItem[]; 
      accounts.forEach((account) => {
         //While we connect EPic we show it as connected with PROCESS status
         if(account.connected || (processingEpic && account.intGameId===GameId.FORTNITE)){
            connected.push(
            {
              value: account.nickname !== undefined ? account.nickname as string :  '',
              status: processingEpic && account.intGameId===GameId.FORTNITE ? Statuses.PROCESS : Statuses.CONNECTED,
              SvgIconProps: account.intGameId===GameId.FRONTLAND ? { name: 'play-fab' }: {name: 'epic-games'},
              disabled: account.intGameId===GameId.FRONTLAND ? true: false,
              LinkProps: {
                href: '#',
              },
              onChangeClick: () => connectEpicGames()
            });
         }
         else{
            notConnected.push(
              {
                id:  account.intGameId===GameId.FRONTLAND ? 'playfab': 'epicgames',
                SvgIconProps: account.intGameId===GameId.FRONTLAND ? { name: 'play-fab' }: {name: 'epic-games'},
                LinkProps: { 
                  href: '#' 
                },
              }
            );
         }

      });

      return {
        notConnected: notConnected,
        connected: connected
      };
}

export function gamesPlayedMapper(gameHistory:GameHistoryType[]|undefined){
    if(gameHistory===undefined){
      return {
        limitRows: 8,
        data:{
          headRow: {
            cell: [
              { value: 'Date',  },
              { value: 'Game' },
              { value: 'Players' },
              { value: 'Status' },
              { value: '' },
            ],
          },
          bodyRow: []
        }
      };
    }

    return {
      limitRows: 8,
      data:{
        headRow: {
          cell: [
            { value: 'Date',  },
            { value: 'Game' },
            { value: 'Players' },
            { value: 'Status' },
            { value: '' },
          ],
        },
        bodyRow: gameHistory.map((history) => (
           {
            cell: [
              { value: new Date(history.datePlayed).toLocaleDateString()+''},
              {
                value: history.intGameId===GameId.FRONTLAND ? 
                   () => <ImageWithTitleSmall icon={PNG_ICONS.frontland} text={`frontland${history.tournamentId !==undefined ? ' (Tournament)':''}`} mobileHideTitle={true}/>
                   :
                   () => <ImageWithTitleSmall icon={PNG_ICONS.fortnite} text={`fortnite${history.tournamentId !==undefined ? ' (Tournament)':''}`} mobileHideTitle={true}/>
              },
              { value: history.participantCount+'' },
              {
                value: historyStatus(history), color:historyStatusColor(history)                
              },
              {
                value: () => (
                  <Link href={history.tournamentId !==undefined ? `/tournaments/${history.tournamentId}` : `/lobby/history/${history.challengeId}`} color="gray.25" style={{ display: 'flex' }} disabled>
                    <SvgIcon name="arrow-right-line" />
                  </Link>
                ),
              },

            ]
           }  
        ))
      }
    };
}

function historyStatus(gameHistory:GameHistoryType){
  
  if(gameHistory.challengeError){
    return 'Failed';
  }  
  switch(gameHistory.challengeStatus){
    case ChallengeStatus.CANCELLED:
     return 'Cancelled';
    case ChallengeStatus.FINAL_DONE:
      if(gameHistory.participantError && (gameHistory.placement===undefined || gameHistory.placement<1)){
          return 'Disqualified'
      }
      else if(gameHistory.placement!==undefined){
        return (  
            () => (
              <>
                <Stack direction="row" gap="33px">
                  {(gameHistory.placement as number)<=3 ? <TrophyNumber place={gameHistory.placement as number} /> : addOrdinalSuffix(gameHistory.placement as number)}
                  {gameHistory.payout!==undefined && gameHistory.payout>0 &&
                    <CoinImageWithAmount 
                      amount={`+${formatTokenToString(gameHistory.payout, gameHistory.tokenType as TokenType)}`} 
                      avatarProps={{ src: getTokenImgSrc(gameHistory.tokenType as TokenType)}}
                      />}
                </Stack>
              </>
            )
        )
      }
      else{
        return 'Lost'
      }
    default:
      return '';
  }  
}

function historyStatusColor(gameHistory:GameHistoryType){
  
  if(gameHistory.challengeError){
    return '#EEA40D';
  }  
  switch(gameHistory.challengeStatus){
    case ChallengeStatus.CANCELLED:
     return '#EEA40D';
    case ChallengeStatus.FINAL_DONE:
      if(gameHistory.participantError){
          return '#EEA40D'
      }
      else if(gameHistory.placement!==undefined){
        return undefined;  
            
      }
      else{
        return undefined
      }
    default:
      return undefined;
  }  
}

function getTemplateDescription(intGameId:string, isTournament:boolean) : string{
  var name = intGameId===GameId.FRONTLAND ? 'Frontland' : 'Fortnite';
  var type= isTournament ? 'Tournament' : 'Challenge';
  
  var description = `Please review the following before you proceed:\n\nGas fee: To process the transaction you have to pay a blockchain gas fee.\nGame readiness: Confirm that ${name} is installed and updated on your device for a seamless gaming experience.`; 
  if(intGameId===GameId.FORTNITE){
    description=description+`\nMake sure you Epic account is linked on you profile page.`;
  }
  description=description+`\n\nOnce the payment is processed, you will be officially registered for the ${type}. 
  Good luck and prepare for an exciting challenge!`;
  return description;
}

export function gameTemplateMapper(gameTemplates : GameTemplateType[]) : Array<ChallengeCardProps  & {
    iconSrc: ImageProps['src']
    tableData?: EnterChallengePopupProps['tableData']
    gameTemplate?: GameTemplateType
  }>  {
    return  gameTemplates.map( (gameTemplate) => (
       mapSingleGameTemplate(gameTemplate)
    ));  
}

function selectTemplateTopImage(intGameId: GameId, gameMode: GameMode) : string{
  switch(intGameId){
    case GameId.FRONTLAND:
      switch(gameMode){
        case GameMode.ROOF_TOP:
          return ChallengeCardFrontland_Rooftop;
        case GameMode.ROOF_TOP_2:
          return ChallengeCardFrontland_Street;
        case GameMode.BRIDGE:
          return ChallengeCardFrontland_Bridge;
        case GameMode.HACIENDA:
          return ChallengeCardFrontland_Hacienda;
        default:
          return ChallengeCardFrontland_Rooftop;
      }      
    case GameId.FORTNITE:
     switch(gameMode){
        default:
          return ChallengeCardFortnine;
      }
    default:
      return '';
  }
}

export function mapSingleGameTemplate(gameTemplate : GameTemplateType) : ChallengeCardProps  & {
  iconSrc: ImageProps['src']
  tableData?: EnterChallengePopupProps['tableData']
  gameTemplate?: GameTemplateType
}  
{
    const shouldShowMinPlayers = gameTemplate.minParticipans!==undefined && gameTemplate.minParticipans<gameTemplate.maxParticipans;
    const data = {
      title: gameTemplate.intGameId === GameId.FRONTLAND ?  'Frontland' : 'Fortnite',
      description: getTemplateDescription(gameTemplate.intGameId, gameTemplate.isTournament),
      bgSrc: selectTemplateTopImage(gameTemplate.intGameId, gameTemplate.gameMode),
      iconSrc: gameTemplate.intGameId === GameId.FRONTLAND ? PNG_ICONS.frontland : PNG_ICONS.fortnite,
      dataSource: gameTemplate.intGameId === GameId.FRONTLAND ?
      [
        { icon: 'user-group-2-fill', label: 'Game mode', value: gameTemplate.description }, 
        { icon: 'repeat-line', label: gameTemplate.isTournament ? 'Players':'Rounds', value: gameTemplate.isTournament ? `${gameTemplate.maxParticipans}` : selectGameRoundsText(gameTemplate.intGameId)},
        { icon: 'trophy-fill', label: prizeCountLabel(gameTemplate.prices), value: formatPrizePool(gameTemplate.prices,gameTemplate.tokenType), tokenIconSrc: getTokenImgSrc(gameTemplate.payoutTokenType)},
      ] :
      [
        { icon: 'user-group-2-fill', label: 'Game mode', value: gameTemplate.description }, 
        { icon: 'repeat-line', label: 'Players', value: gameTemplate.maxParticipans+'' },
        { icon: 'trophy-fill', label: prizeCountLabel(gameTemplate.prices), value: formatPrizePool(gameTemplate.prices,gameTemplate.tokenType), tokenIconSrc: getTokenImgSrc(gameTemplate.payoutTokenType)},
      ],
      btnProps:{
          label: 'Join now',
          subLabel: formatTokenToStringWithSymbol(gameTemplate.fee, gameTemplate.tokenType),
          iconSrc: getTokenImgSrc(gameTemplate.tokenType),
      },
      tableData: [
          [
            'Map',
            {
              picture: selectTemplateTopImage(gameTemplate.intGameId, gameTemplate.gameMode),
              text: `${translateGameMode(gameTemplate.gameMode)}` , 
            },
          ],
          gameTemplate?.intGameId === GameId.FRONTLAND ? ['Rounds', 'First to 5'] : [shouldShowMinPlayers?'Max players':'Players', `${gameTemplate?.maxParticipans}`],
          ['Game mode',  translateGameType(gameTemplate?.gameType)],
          [
            'Prize pool',
    
            {
              picture: getTokenImgSrc(gameTemplate.payoutTokenType),
              text:  formatTokenToString(gameTemplate?.pricePotAfterCut,gameTemplate.payoutTokenType),
              imageSx: {
                width: '18px',
                height: '18px',
              },
            },
          ],
      ],
      gameTemplate: gameTemplate

  };

  if(shouldShowMinPlayers){
    data.tableData.splice(1,0,['Min players', `${gameTemplate?.minParticipans}`]);

  }


  return data as ChallengeCardProps  & {
    iconSrc: ImageProps['src']
    tableData?: EnterChallengePopupProps['tableData']
    gameTemplate?: GameTemplateType
    };
}

export function frontlandLobbyDataMapper(activeChallenge : ActiveChallengeType, user: UserType, breakpoint?: Breakpoint )  {  
  //Get template
  var data = frontlandLobbyData[activeChallenge.status];
  //Clone template so we do not update actual template when setting values
  data =  JSON.parse(JSON.stringify(data));

  if(data!==undefined){

    if(activeChallenge.error){
      data.title='Challenge has an error'   
      data.loading=false //Otherwise we will not see subtitle  
    }
    data.subtitle=activeChallenge.errorDescription!==undefined ? activeChallenge.errorDescription : data.subtitle
    
    //Tournament
    if(activeChallenge.status == ChallengeStatus.NOT_STARTED && activeChallenge.tournamentId!==undefined){
      data.title = 'Challenge starts in';
      data.loading = false;
    }
   
    data.timerExpirySeconds= showTimer(activeChallenge.status, activeChallenge.tournamentId!==undefined,activeChallenge.startType===StartType.ON_TIME) ? secondsToStart(activeChallenge) : undefined;    

    let tables=[] as any[];
    activeChallenge.participants.forEach((participant, index) =>{
        let table = JSON.parse(JSON.stringify(defaultLobbyTable)); //Need to clone otherwise we will copy over values
        let itsMe = participant.userId===user.id;
        table.data.bodyRow[0].cell[0].value = itsMe ? user.nickname : participant.nickname!==undefined ? participant.nickname : `Player ${index+1}`;
        table.data.bodyRow[0].cell[0].src = getProfilePicture(participant.profilePictureUrl);
        table.text = itsMe ? 'Your side' : 'opposite side'
        
        let placement =  getPlacement(activeChallenge.status,participant);
        table.placement = placement;
        table.withTrophy = placement ===1;

        if(data !== undefined && data.winner!==undefined && activeChallenge.status===ChallengeStatus.FINAL_DONE && placement===1){          
          data.winner.nickname = participant.nickname as string;
          data.winner.prize = participant.payout!==undefined ? formatTokenToString(participant.payout,activeChallenge.payoutTokenType) : '';       
          data.winner.tokenImgSrc = getTokenImgSrc(activeChallenge.payoutTokenType);    
          data.winner.avatarSrc = getProfilePicture(participant.profilePictureUrl,"") as string;
        }

        table.data.bodyRow[0].cell[1].value = getFrontlandStatus(activeChallenge.status,participant);
        table.data.bodyRow[0].cell[1].color = getFrontlandStatusColor(activeChallenge.status,participant);
        table.data.bodyRow[0].cell[2].value = getFrontlandScore(activeChallenge.status,participant);
        tables.push(table);
    })

    //Add spot/s for empty 
    tables = [...tables, ...(Array.apply(null,  Array(activeChallenge.maxParticipans-tables.length)).map((e, i) => (
         getEmptyLobbyTable(activeChallenge.status!==ChallengeStatus.NOT_STARTED)
    )))];

    data.leftTable=tables[0];
    data.rightTable=tables[1]; 

    data.challengeDetailsCardProps = LobbyCardMapper(activeChallenge, breakpoint);

  }
  
  return data;
}

export function leaderboardDataMapper(topLists : TopListType[], theme:Theme){

  if (!Array.isArray(topLists))  //To handle bingbot 2.0 web crawler error (e.forEach is not a function)
    return [];

  let leaderboards=[] as any;
  topLists.forEach((list, index) =>{
      
    if(list.topListType===TopList_Type.MOST_WINS 
      || list.topListType===TopList_Type.MOST_PLAYED_GAMES || list.topListType===TopList_Type.HIGEST_WIN_PERCENTAGE
      || list.topListType===TopList_Type.BEST_WEEKLY_STREAK || list.topListType===TopList_Type.BEST_ALLTIME_STREAK){
      let leaderboard = {
        withTrophy: true,
        rowCount: 8,
        title: list.title,
        subtitle: list.description,   
        width:'100%',
        height:'auto',    
        data: {
          headRow: [] as any,
          bodyRow:[] as any
        },
        px: 0,
        bodystackprops:{
          pt: '17px',
          pl: '19px',
          pb: '13px',
          sx: {
            [theme.breakpoints.down('sm')]: {
              pt: '15px',
              pl: '17px',
              pr: '16px',
            },
          },
        },
        titledotprops: {} as any,
        griditemprops: {} as any,
      };

      let headerRow;
      switch(list.topListType){
        case TopList_Type.MOST_WINS:
          headerRow = {
            cell: [
              { value: 'Player' },
              { value: 'Wins'},
            ],
          }; 
          leaderboard.titledotprops={ bottom: '8px' }; 
          leaderboard.griditemprops={xs:12, sm2:6, lg2:3.6};               
          break;
        case TopList_Type.MOST_PLAYED_GAMES:
          headerRow = {
            cell: [
              { value: 'Player' },
              { value: 'Games'},
            ],
          };          
          leaderboard.titledotprops={ bottom: '8px' };  
          leaderboard.griditemprops={xs:12, sm2:6, lg2:3.6};      
          break;
        case TopList_Type.HIGEST_WIN_PERCENTAGE:
          headerRow = {
            cell: [
              { value: 'Player' },
              { value: 'W/G', width: '68px' },
              { value: 'Win%', width: '68px' },
            ],
          };      
          leaderboard.titledotprops={ bottom: '8px' };   
          leaderboard.griditemprops={xs:12,  lg2:4.8};     
          break;
        case TopList_Type.BEST_WEEKLY_STREAK:
          headerRow = {
            cell: [
              { value: 'Player' },
              { value: 'Streak' },
            ],
          }; 
          leaderboard.titledotprops={ bottom: '8px' };
          leaderboard.griditemprops={xs:12, lg2:6};     
          break;
        case TopList_Type.BEST_ALLTIME_STREAK:
          headerRow = {
            cell: [
              { value: 'Player' },
              { value: 'Streak' },
            ],
          };  
          leaderboard.titledotprops={ bottom: { xs: '7px', sm: '8px' } }  
          leaderboard.griditemprops={xs:12, lg2:6};          
          break;
        default:
          headerRow =  {
            cell: [
             
            ],
          };
      }
      leaderboard.data.headRow=headerRow;

      list.topList.forEach((item,itemIndex) =>{
          let listItem;
          switch(list.topListType){
            case TopList_Type.MOST_WINS:
            case TopList_Type.MOST_PLAYED_GAMES:
            case TopList_Type.BEST_WEEKLY_STREAK:
            case TopList_Type.BEST_ALLTIME_STREAK:
             listItem={            
              cell: [
                { value: item.nickname, src: getProfilePicture(item.profilePictureUrl) },
                { value: item.value },
              ],            
             }
             break;
             case TopList_Type.HIGEST_WIN_PERCENTAGE:
              listItem={            
                cell: [
                  { value: item.nickname, src: getProfilePicture(item.profilePictureUrl) },
                  { value: `${item.longExtraValue1}/${item.longExtraValue2}` },
                  { value: item.formattedDecimalValue+'%' },
                ],            
               }
               break;
              default:
                listItem={};
          }

          leaderboard.data.bodyRow.push(listItem);
      })

      leaderboards.push(leaderboard);
    }

  });

  return leaderboards;
}

export function fortniteLobbyDataMapper(activeChallenge : ActiveChallengeType, user: UserType, breakpoint?: Breakpoint)  {
  // Get template
  var data = fortniteLobbyData[activeChallenge.status];
  //Clone template so we do not update actual template when setting values
  data =  JSON.parse(JSON.stringify(data));

  if(data!==undefined){

    if(activeChallenge.error){
      data.title='Challenge has an error'     
      data.loading=false //Otherwise we will not see subtitle
    }
    data.subtitle=activeChallenge.errorDescription!==undefined ? activeChallenge.errorDescription : data.subtitle
    
    //On Time
    if(activeChallenge.status == ChallengeStatus.NOT_STARTED && activeChallenge.startType===StartType.ON_TIME){
      data.title = 'Challenge starts in';
      data.loading = false;
    }

    data.timerExpirySeconds= showTimer(activeChallenge.status,false,activeChallenge.startType===StartType.ON_TIME) 
        ? (activeChallenge.status==ChallengeStatus.NOT_STARTED ? secondsToStart(activeChallenge) : secondsToDone(activeChallenge)) : undefined;    
    
    data.players.ready=activeChallenge.numberOfParticipants;
    data.players.total=activeChallenge.maxParticipans;

    data.table.data.bodyRow=[];    
    //Create winnerList, is undefined for other than FINAL_DONE
    if(activeChallenge.status===ChallengeStatus.FINAL_DONE){
      data.winnerList=[];
    }
    activeChallenge.participants.forEach((participant, index) =>{
        let itsMe = participant.userId===user.id;        
        let row = { 
          cell:[
            { value: activeChallenge.status===ChallengeStatus.NOT_STARTED ? index+1+'' :  '-'},
            { value: (itsMe ? user.nickname : (participant.nickname!==undefined ? participant.nickname : `Player ${index+1}`)), src: getProfilePicture(participant.profilePictureUrl)},
            { value: getFortniteStatus(activeChallenge.status,participant), color: getFortniteStatusColor(activeChallenge.status,participant) },
            { value: getFortniteKills(participant) },
            { value: getFortniteScore(participant) },
          ],
          noPlayers: false,
        };
        
        
        if(activeChallenge.status===ChallengeStatus.FINAL_DONE){
          if(participant.placement && participant.payout && participant.payout>0){
            let winner ={              
                name: participant.nickname as string,
                place: participant.placement,
                amount: `+ ${formatTokenToString(participant.payout,activeChallenge.payoutTokenType)}`,
                tokenImgSrc: getTokenImgSrc(activeChallenge.payoutTokenType),
                isVertical: false,
                avatarSrc: getProfilePicture(participant.profilePictureUrl,"") as string,
              
              }
              
            data.winnerList?.push(winner);
          }
          
          //For finishers we set placement
          if(participant.placement)
            row.cell[0].value=participant.placement+'';

        }
        
        data?.table.data.bodyRow.push(row);
    })
    //Add spot/s for empty 
    data.table.data.bodyRow = [...data.table.data.bodyRow, ...(Array.apply(null,  Array(activeChallenge.maxParticipans-activeChallenge.numberOfParticipants)).map((e, i) => (
      { cell: null,
        noPlayers: activeChallenge.status===ChallengeStatus.NOT_STARTED ? false : true,
      }
    )))];
   
    //Cut of trophies to number of winners
    if(data.winnerList){
      data.table.trophyLimit=data.winnerList.length
    }

    data.challengeDetailsCardProps = LobbyCardMapper(activeChallenge, breakpoint);

  }
  return data;
}

function secondsToStart(activeChallenge : ActiveChallengeType) : number{
  return secondsLeft(activeChallenge.startTime as Date, 0);  
}

function secondsToDone(activeChallenge : ActiveChallengeType) : number{
  let waitTimeInSec = ((activeChallenge.preCooldownTime as number) + 
  (activeChallenge.matchTime as number) + (activeChallenge.resultCollectionTime as number))

  return secondsLeft(activeChallenge.started as Date, waitTimeInSec);  
}

export function liveEventMapper(logEvents : LogEventType[], user: UserType|undefined, challengeStatus: ChallengeStatus , description: string, gameId: GameId) : LiveEventType[]{

  let events:LiveEventType[] = logEvents.map((logEvent) => {   
      return { 
          date: logEvent.created, 
          message: logEventText(logEvent,user?.id, user?.nickname, gameId) as string, 
          highlighted: !isNotHighlighted(logEvent),
          coinImageWithAmountProps: {
            amount: logEvent.payout!==undefined ? formatTokenToString(logEvent.payout,logEvent.tokenType as TokenType): undefined,
            avatarProps: {
              src: getTokenImgSrc(logEvent.tokenType as TokenType),
            }
        },
      }
  })
  //When players are suposed to start playing we set a information message in event log. 
  if(challengeStatus===ChallengeStatus.PRE_COOLDOWN_DONE){
    events.unshift(
      {
        date: new Date(),
        message: `Its time! Play one match of Fortnite ${description}, our system will detect if you try to do anything else, it will result disqualification. When you are done, our system will detect how it went and compare it to the other players in the lobby.`,
        highlighted: true,
        coinImageWithAmountProps: {
          amount: undefined,
        },
      }
    );
  }
  return events;
}

export function globalLiveEventTableMapper(logEvents : LogEventType[]) : TableDataT {
    return {      
        headRow: {
          cell: [{ value: 'Date' }, { value: 'Challenge' }, { value: 'User (s)' }, { value: 'Result' }],
        },
        bodyRow: logEvents.map((logEvent) => {   
          return { 
            cell: [
              { 
                value: ()=>(<Typography variant="paragraphSmall"><TimeAgo formatter={formatter} date={logEvent.created}/></Typography>) 
              },
              {
                value: () => (
                  <ImageWithTitleWrapper 
                    variant="game" 
                    icon={logEvent.intGameId===GameId.FRONTLAND ? PNG_ICONS.frontland : PNG_ICONS.fortnite} 
                    text={logEvent.challengeName as string} />
                ),
              },
              { 
                value: liveEventUsers(logEvent), 
              },
              {
                value: liveEventResult(logEvent),
              }
            ]
          }
        })
     };
}
function formatter( value:number, unit:string, suffix:string, epoc:any, nextFormatter:any ) {
  if (suffix==='from now' || (value < 60 && unit === 'second')) {
      return 'Just now'
  }
  else if(unit==='minute'){
    return `${value} min ${suffix}`;
  }
  else
      return nextFormatter();
}

function liveEventUsers(logEvent:LogEventType) : string | (() => JSX.Element){
  switch (logEvent.challengeEventType) {
      case ChallengeEventType.JOINED:
      case ChallengeEventType.CHALLENGE_PAYOUT:
          return () => (
            logEvent.profilePictureUrl!==undefined ?
            <ImageWithTitleWrapper
              variant="avatar"
              icon={getProfilePicture(logEvent.profilePictureUrl) as string}
              text={logEvent.nickname !== undefined ? `${logEvent.nickname}` :  'Player'}
            /> :
            <Typography variant="paragraphSmall">{logEvent.nickname !== undefined ? `${logEvent.nickname}` :  'Player'}</Typography>  
          ); 
      case ChallengeEventType.GAME_STARTED:
          return () => <Typography variant="paragraphSmall">{logEvent.numberOfParticipants} Players</Typography>;     
      default:
          return "";
  }
}

function liveEventResult(logEvent:LogEventType) : string | (() => JSX.Element){
  switch (logEvent.challengeEventType) {
      case ChallengeEventType.JOINED:
        return "Joined"
      case ChallengeEventType.CHALLENGE_PAYOUT:
          return () => <CoinImageWithAmount 
                        amount={`+ ${formatTokenToString(logEvent.payout,logEvent.tokenType as TokenType)}`} 
                        avatarProps={{ src: getTokenImgSrc(logEvent.tokenType as TokenType)}}
                        />;
      case ChallengeEventType.GAME_STARTED:
          return "Started";     
      default:
          return "";
  }
}


function showTimer(challengeStatus: ChallengeStatus, isTournament: boolean, startOnTime:boolean) : boolean{
  switch(challengeStatus){
    case ChallengeStatus.NOT_STARTED:
      if(isTournament || startOnTime)
        return true;
      else
        return false;
    case ChallengeStatus.PRE_COOLDOWN_DONE:
    case ChallengeStatus.PLAY_TIME_OVER:
      return true
    default:
      return false;
  }
}

function isNotHighlighted( event: LogEventType)   {
  return event.challengeEventType === ChallengeEventType.JOINED || event.challengeEventType === ChallengeEventType.LEFT ||
    event.challengeEventType === ChallengeEventType.GAME_ROUND_COMPLETE || event.challengeEventType === ChallengeEventType.QUEUED_FOR_START || event.challengeEventType === ChallengeEventType.CHALLENGE_PAYOUT || event.challengeEventType === ChallengeEventType.REFUNDED_CHALLENGE_FEE || event.challengeEventType === ChallengeEventType.PLAYER_ERROR || event.challengeEventType === ChallengeEventType.GAME_ERROR
}

function getFrontlandStatus(challengeStatus: ChallengeStatus,participant: ParticipantType) : string {
  if(participant.error)
    return participant.errorDescription as string
  
  switch(challengeStatus){
    case ChallengeStatus.RUNNING:
      return 'In game';
    case ChallengeStatus.NOT_STARTED:
    case ChallengeStatus.READY_TO_START:
      return (participant.startedMatchmaking!==undefined && participant.startedMatchmaking) ? 'In matchmaking' : 'In lobby'
    default:
      return 'In lobby';
  }
}

function getFrontlandStatusColor(challengeStatus: ChallengeStatus,participant: ParticipantType) :  string|undefined {
  if(participant.error)
    return '#EEA40D';
  
  switch(challengeStatus){
    case ChallengeStatus.RUNNING:
      return '#56E612';
    case ChallengeStatus.NOT_STARTED:
    case ChallengeStatus.READY_TO_START:
      return (participant.startedMatchmaking!==undefined && participant.startedMatchmaking) ? '#56E612' : undefined
    default:
      return undefined;
  }
}

function getFortniteStatus(challengeStatus: ChallengeStatus,participant: ParticipantType) : string {
  if(participant.error)
    return participant.errorDescription as string
  
  switch(challengeStatus){
    case ChallengeStatus.RUNNING:
    case ChallengeStatus.STARTING_SCORE_COLLECTED:
    case ChallengeStatus.PRE_COOLDOWN_DONE:
      return 'In game';
    case ChallengeStatus.PLAY_TIME_OVER:
    case ChallengeStatus.FINAL_DONE:
      return showFortniteFinishedTop(participant);
    default:
      return 'In lobby';
  }
}

function getFortniteStatusColor(challengeStatus: ChallengeStatus,participant: ParticipantType) : string|undefined {
  if(participant.error)
    return '#EEA40D';
  
  switch(challengeStatus){
    case ChallengeStatus.NOT_STARTED:
    case ChallengeStatus.READY_TO_START:
    case ChallengeStatus.RUNNING:
    case ChallengeStatus.STARTING_SCORE_COLLECTED:
    case ChallengeStatus.PRE_COOLDOWN_DONE:
    case ChallengeStatus.CANCELLED:
      return undefined;
    case ChallengeStatus.PLAY_TIME_OVER:
    case ChallengeStatus.FINAL_DONE:
      return getFortniteFinishedTopColor(participant);
    default:
      return '#56E612';
  }
}

function showFortniteFinishedTop(participant: ParticipantType) : string {

  if (participant.finishedTop1 !== undefined && participant.finishedTop1) {
      return 'Won game!';
  }
  else if (participant.finishedTop10 !== undefined && participant.finishedTop10) {
      return 'Finished top 10!';
  }
  else if (participant.finishedTop25 !== undefined && participant.finishedTop25) {
      return 'Finished top 25!';
  }
  else if (participant.finishedTop1 !== undefined &&  participant.finishedTop10 !== undefined &&  participant.finishedTop25 !== undefined && !participant.finishedTop1 && !participant.finishedTop10 && !participant.finishedTop25) {
      return 'Died early';
  }
  else {
      return 'In game';
  }
    
}

function getFortniteFinishedTopColor(participant: ParticipantType) : string|undefined {

  if (participant.finishedTop1 !== undefined && participant.finishedTop1) {
      return '#56E612';
  }
  else if (participant.finishedTop10 !== undefined && participant.finishedTop10) {
      return '#56E612';
  }
  else if (participant.finishedTop25 !== undefined && participant.finishedTop25) {
      return '#56E612';
  }
  else if (participant.finishedTop1 !== undefined &&  participant.finishedTop10 !== undefined &&  participant.finishedTop25 !== undefined && !participant.finishedTop1 && !participant.finishedTop10 && !participant.finishedTop25) {
      return '#56E612';
  }
  else {
      return undefined;
  }
    
}


function getFrontlandScore(challengeStatus: ChallengeStatus, participant: ParticipantType) : string {
  switch(challengeStatus){
    case ChallengeStatus.RUNNING:
    case ChallengeStatus.FINAL_DONE:
      return participant.roundsWon !== undefined ? participant.roundsWon+'' : '0';
    case  ChallengeStatus.CANCELLED:
      return '';
    default:
      return '-';
  }
}

function getFortniteScore(participant: ParticipantType) : string {
  return (participant.score !== undefined && participant.score !== null ? participant.score +' p.' : '');
}

function getFortniteKills(participant: ParticipantType) : string{
  return (participant.kills !== undefined && participant.kills !== null ? participant.kills+'' : '');
}

function getPlacement(challengeStatus: ChallengeStatus, participant: ParticipantType) : number {
   switch(challengeStatus){
    case ChallengeStatus.FINAL_DONE:
      return participant.placement !== undefined ? participant.placement : 0;
    default:
      return 0;
  }
}


function selectGameRoundsText(intGameId: GameId) {

  if (intGameId === GameId.FORTNITE) {
      return '1 Game';
  }
  else if (intGameId === GameId.FRONTLAND) {
      return 'First to 5';
  }
  else {
      return 'Unknown';
  }
}

export function LobbyCardMapper(activeChallenge : ActiveChallengeType,  breakpoint: Breakpoint = 'sm') : ChallengeDetailsCardProps {
  return  activeChallenge.intGameId === GameId.FRONTLAND ?   
    LobbyCardFrontlandMapper(activeChallenge, breakpoint) 
      : 
    LobbyCardFortniteMapper(activeChallenge, breakpoint);
  
}

export function LobbyCardFrontlandMapper(activeChallenge : ActiveChallengeType,  breakpoint: Breakpoint = 'sm') : ChallengeDetailsCardProps {
  return {
    gameBoxProps: {
      imageProps: {
        src: breakpoint === 'sm'
        ? PNG_ICONS.tabletLobbyFrontlandDetails
        : PNG_ICONS.lobbyFrontlandDetails,
      },
      imageWithTitleProps: {
        imageProps: {
          src: PNG_ICONS.frontland,
        },
        title: 'Frontland',
      },
    },
    list: [
      {
        avatarProps: {
          src: PNG_ICONS.lobbyFrontlandDetailsMap,
        },
        label: 'Map',
        value: activeChallenge.name,
      },
      { icon: 'user-group-2-fill', label: 'Game mode', value: translateGameType(activeChallenge.gameType) },
      { icon: 'repeat-line', label: 'Rounds', value: selectGameRoundsText(activeChallenge.intGameId) },
      { icon: 'trophy-fill', label: 'Prize', value:  formatTokenToStringWithSymbol(activeChallenge.pricePotAfterCut,activeChallenge.payoutTokenType) },
    ],    
  }
}

export function LobbyCardFortniteMapper(activeChallenge : ActiveChallengeType,  breakpoint: Breakpoint = 'sm') : ChallengeDetailsCardProps {
  
  const shouldShowMinPlayers = activeChallenge.minParticipans!==undefined && activeChallenge.minParticipans<activeChallenge.maxParticipans;

  var data = {
    gameBoxProps: {
      imageProps: {
        src: breakpoint === 'sm' ? PNG_ICONS.tabletLobbyFortniteDetails : PNG_ICONS.lobbyFortniteDetails,
      },
      imageWithTitleProps: {
        imageProps: {
          src: PNG_ICONS.fortnite,
        },
        title: 'Fortnite',
      },
    },
    list: [
      { icon: 'user-group-2-fill', label: 'Game mode', value: `${translateGameType(activeChallenge.gameType)} ${translateGameMode(activeChallenge.gameMode)}` },
      { icon: 'repeat-line', label: 'Rounds', value: '1' },
      { icon: 'team-fill', label: shouldShowMinPlayers ? 'Min/Max Players' : 'Players', value: shouldShowMinPlayers ? `${activeChallenge.minParticipans}/${activeChallenge.maxParticipans}`: `${activeChallenge.maxParticipans}`},
      {
        icon: 'trophy-fill',
        label: prizeCountLabel(activeChallenge.prices),
        value: formatPrizePool(activeChallenge.prices,activeChallenge.payoutTokenType),
        showAllPrizes: true,
        tokenIconSrc: getTokenImgSrc(activeChallenge.payoutTokenType),
      },
    ],    
  } 
 
  return data as ChallengeDetailsCardProps;
}



export const EmptyTemplate: 
    ChallengeCardProps & {
    iconSrc: ImageProps['src']
    tableData?: EnterChallengePopupProps['tableData']

  }
 = 
  {
    title: '',
    bgSrc: selectTemplateTopImage(GameId.FRONTLAND, GameMode.ROOF_TOP),
    iconSrc: PNG_ICONS.frontland,
    dataSource: [
      { icon: 'user-group-2-fill', label: 'Game mode', value: '' },
      { icon: 'repeat-line', label: 'Rounds', value: '' },
      { icon: 'trophy-fill', label: 'Prize', value: '' },
    ],
    btnProps:{
        label: '',
        subLabel: '',
    },
    tableData: [
      [
        'Map',
        {
          picture: selectTemplateTopImage(GameId.FRONTLAND, GameMode.ROOF_TOP),
          text: 'Name of the Map',
        },
      ],
      ['Rounds', ''],
      ['Game mode', ''],
      [
        'Prize pool',

        {
          picture: PNG_ICONS.weth,
          text: '',
          imageSx: {
            width: '18px',
            height: '18px',
          },
        },
      ],
    ],
  }

export const availableGamesList = [
    {
      id: GameId.FRONTLAND,
      icon: PNG_ICONS.frontland,
      name: 'Frontland',
    },
    {
      id: GameId.FORTNITE,
      icon: PNG_ICONS.fortnite,
      name: 'Fortnite',
    },
    //{
    //  id: 'battlefield2042',
    //  icon: PNG_ICONS.voteCard,
    //  name: 'Battlefield 2042',
    //  isComingSoon: true,
   // },
  ]

  export const gameSortFilterHeaderOptions: Options = [
    {
      // icon: 'home-heart-line',
      value:  SortOrder.FEE_HIGH,
      label: 'High Fee',
    },
    {
      value: SortOrder.FEE_LOW,
      label: 'Low fee',
    }    
  ]


  export const challengesData = {
    FRONTLAND: {
      bgImage: bgChallengesFrontland_Rooftop,
      gameSortFilterHeaderProps: {
        title: 'Frontland',
        src: PNG_ICONS.frontland,
      },
      list: undefined,
      totalGames: 0,
      winGamesCount: 0,
      loseGamesCount: 0,
      fiveKillRow: 0,
      treeKillRow: 0,
      gameDownloadBanner: {
        photoImgSrc: bannerFrontlandPhoto,
        iconSrc: PNG_ICONS.frontlandBig,
        title: 'Frontland',
        subtitle: 'Challenge originals',
        longText:
          "Frontland is a multiplayer online game that allows players to engage in fast-paced and intense battles in a third-person perspective. The game is built on Unreal Engine 5",
        link: undefined,//Will set this from loaded game
        target:'_blank',
        disabled: false,
        },
    },
    FORTNITE: {
      bgImage: bgChallengesFortnite,
      gameSortFilterHeaderProps: {
        title: 'Fortnite',
        src: PNG_ICONS.fortnite,
      },
      list: undefined,
      totalGames: 0,
      winGamesCount: 0,
      loseGamesCount: 0,
      fiveKillRow: 0,
      treeKillRow: 0,
      gameDownloadBanner: {
        photoImgSrc: bannerFortnitePhoto,
        iconSrc: PNG_ICONS.fortniteBig,
        title: 'Fortnite',
        subtitle: 'Challenge extended',
        longText: "Fortnite is a wildly popular online video game developed by Epic Games that has taken the gaming world by storm since its release in 2017. It is a battle royale game where players compete against each other to be the last person or team standing on a shrinking island. You can play Challenges in Fortnite with or without building",
        link: 'https://www.fortnite.com/download',
        target:'_blank',
        disabled: false,
      },
    },
  }

  const prizeCountLabel = (prizes:any[])=>{
    const count =  prizes?.filter((x:any) => x.payout>0).length;
    if(count>1){
      return `${count} prizes`
    }
    else{
      return 'Prize'
    }
  }

   export const defaultData = {
        open: false,
        challengeCard: EmptyTemplate,
    } as DataHolderType

   const defaultLobbyTable = {
      variant: 'commands',
      count: 1,
      text: 'opposite side',
      withTrophy: false,
      placement: 0,
      data: {
        headRow: {
          cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
        },
        bodyRow: [
          {
            cell: [
              { value: 'MasterChief', src: '' },
              { value: 'In lobby' },
              { value: '' },
            ],
          },
        ],
      },
    }
    function getEmptyLobbyTable(noPlayers: boolean){
       return (
          {
            variant: 'commands',
            count: 0,
            text: 'opposite side',
            data: {
              headRow: {
                cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
                noPlayers: noPlayers,
              },
              bodyRow: [{ cell: null }],
            },
          }
       )
    } 

    const frontlandLobbyData = {
     
        NOT_STARTED: {
          status: 'waiting',
          pageMediaWithShadowBoxProps: {
            videoProps: {
              src: assets.video.lobbySolo,
            },
          },
          loading: true,
          title: 'Waiting for an opponent',
          subtitle: undefined as unknown,
          timerExpirySeconds: undefined as number | undefined,
          winner: undefined,   
          leftTable: {
            variant: 'commands',
            count:1,
            text: 'opposite side',
            data: {
              headRow: {
                cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
              },
              bodyRow: [
                {
                  cell: [
                    { value: 'MasterChief', src: '' },
                    { value: 'In lobby' },
                    { value: '' },
                  ],
                  noPlayers: false,
                },
              ],
            },
          },          
          rightTable: getEmptyLobbyTable(false),
          challengeDetailsCardProps: undefined as any,
          liveUpdateList: []
        },
        READY_TO_START: {
          status: 'commencing',
          pageMediaWithShadowBoxProps: {
            videoProps: {
              src: assets.video.lobbyFilled,
            },
          },
          loading: false,
          title: "Start Frontland and 'Join Challenge lobby'",
          subtitle: undefined as unknown,
          timerExpirySeconds: undefined as number | undefined,
          winner: undefined,      
          leftTable: {
            variant: 'commands',
            count:1,
            text: 'opposite side',
            data: {
              headRow: {
                cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
              },
              bodyRow: [
                {
                  cell: [
                    { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                    { value: 'In lobby' },
                    { value: '' },
                  ],
                  noPlayers: false,
                },
              ],
            },
          },
          rightTable: {
            variant: 'commands',
            count:1,
            text: 'opposite side',
            data: {
              headRow: {
                cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
              },
              bodyRow: [
                {
                  cell: [
                    { value: 'Nickname', src: 'https://i.pravatar.cc/28' },
                    { value: 'In lobby' },
                    { value: '' },
                  ],
                  noPlayers: false,
                },
              ],
            },
          },
          challengeDetailsCardProps: undefined as any,
          liveUpdateList: []
        },
        RUNNING: {
          status: 'progress',
          pageMediaWithShadowBoxProps: {
            videoProps: {
              src: assets.video.lobbyFilled,
            },
          },
          loading: false,
          title: 'Challenge is in progress',
          subtitle: undefined as unknown,
          timerExpirySeconds: undefined as number | undefined,
          winner: undefined,   
          leftTable: {
            variant: 'commands',
            count:1,
            text: 'opposite side',
            data: {
              headRow: {
                cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
              },
              bodyRow: [
                {
                  cell: [
                    { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                    { value: 'In Game', color: '#FFD200' },
                    { value: '0' },
                  ],
                  noPlayers: false,
                },
              ],
            },
          },
          rightTable: {
            variant: 'commands',
            count:1,
            text: 'opposite side',
            data: {
              headRow: {
                cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
              },
              bodyRow: [
                {
                  cell: [
                    { value: 'Nickname', src: 'https://i.pravatar.cc/28' },
                    { value: 'In Game', color: '#FFD200' },
                    { value: '0' },
                  ],
                  noPlayers: false,
                },
              ],
            },
          },
          challengeDetailsCardProps: undefined as any,
          liveUpdateList: []
        },
        calculating: {
          status: 'calculating',
          pageMediaWithShadowBoxProps: {
            videoProps: {
              src: assets.video.lobbyFilled,
            },
          },
          loading: true,
          title: 'Challenge is completed Calculating winner',
          subtitle: undefined as unknown,
          timerExpirySeconds: undefined as number | undefined,
          winner: undefined,     
          leftTable: {
            variant: 'commands',
            count:1,
            text: 'opposite side',
            data: {
              headRow: {
                cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
              },
              bodyRow: [
                {
                  cell: [
                    { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                    { value: 'In lobby' },
                    { value: '5' },
                  ],
                  noPlayers: false,
                },
              ],
            },
          },
          rightTable: {
            variant: 'commands',
            count:1,
            text: 'opposite side',
            data: {
              headRow: {
                cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
              },
              bodyRow: [
                {
                  cell: [
                    { value: 'Nickname', src: 'https://i.pravatar.cc/28' },
                    { value: 'In lobby' },
                    { value: '3' },
                  ],
                  noPlayers: false,
                },
              ],
            },
          },
          challengeDetailsCardProps: undefined as any,
          liveUpdateList: []
        },
        FINAL_DONE: {
          status: 'completed',
          pageMediaWithShadowBoxProps: {
            videoProps: {
              src: assets.video.lobbyFilled,
            },
          },
          loading: false,
          title: 'Challenge is completed!',
          subtitle: undefined as unknown,
          timerExpirySeconds: undefined as number | undefined,
          winner : { 
            nickname: '',
            prize: '',
            tokenImgSrc: '',
            avatarSrc: '',
          },          
          leftTable: {
            variant: 'commands',
            count:1,
            text: 'opposite side',
            withTrophy: true,
            data: {
              headRow: {
                cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
              },
              bodyRow: [
                {
                  cell: [
                    { value: 'MasterChief', src: '' },
                    { value: '' },
                    { value: '5' },
                  ],
                  noPlayers: false,
                },
              ],
            },
          },
          rightTable: {
            variant: 'commands',
            count:1,
            text: 'opposite side',
            data: {
              headRow: {
                cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
              },
              bodyRow: [
                {
                  cell: [
                    { value: 'Nickname', src: '' },
                    { value: '' },
                    { value: '3' },
                  ],
                  noPlayers: false,
                },
              ],
            },
          },
          challengeDetailsCardProps: undefined as any,
          liveUpdateList: []
        },
        CANCELLED: {
          status: 'cancelled',
          pageMediaWithShadowBoxProps: {
            videoProps: {
              src: assets.video.lobbyFilled,
            },
          },
          loading: false,
          title: 'Challenge has been cancelled',
          subtitle: undefined as unknown,
          timerExpirySeconds: undefined as number | undefined,
          winner: undefined,   
          leftTable: {
            variant: 'commands',
            count:1,
            text: 'opposite side',
            data: {
              headRow: {
                cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
              },
              bodyRow: [
                {
                  cell: [
                    { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                    { value: '' },
                    { value: '' },
                  ],
                  noPlayers: false,
                },
              ],
            },
          },
          rightTable: {
            variant: 'commands',
            count:1,
            text: 'opposite side',
            data: {
              headRow: {
                cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
              },
              bodyRow: [
                {
                  cell: [
                    { value: 'Nickname', src: 'https://i.pravatar.cc/28' },
                    { value: '' },
                    { value: '' },
                  ],
                  noPlayers: false,
                },
              ],
            },
          },
          challengeDetailsCardProps: undefined as any,
          liveUpdateList: []
        },
        STARTING_SCORE_COLLECTED: undefined,
        PRE_COOLDOWN_DONE:undefined,
        PLAY_TIME_OVER: undefined,
           
    }


    const fortniteLobbyData = {
     
        NOT_STARTED:{
          status: 'waiting',
          pageMediaWithShadowBoxProps: {
            imageProps: {
              src: assets.png.fortnitePageImg,
            },
          },
          loading: true,
          title: 'Waiting for opponents',
          subtitle: undefined as unknown,
          timerExpirySeconds: undefined,
          winnerList: undefined,
          players: {
            ready: 1,
            total: 5,
          },
          table: {
            trophyLimit: undefined,
            data: {
              headRow: {
                cell: [
                  { value: '#' },
                  { value: 'Player' },
                  { value: 'Placement' },
                  { value: 'Kills' },
                  { value: 'Score' },
                ],
              },
              bodyRow: [
                {
                  cell: [
                    { value: ''},
                    { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                    { value: 'In lobby' },
                    { value: '' },
                    { value: '' },
                  ],
                  noPlayers: false,
                },
                { cell: null, noPlayers: false  },
                { cell: null, noPlayers: false },
                { cell: null, noPlayers: false },
              ],
            },
          },
          challengeDetailsCardProps: undefined as any,
          liveUpdateList: [],
        },
        READY_TO_START:{
          status: 'commencing',
          pageMediaWithShadowBoxProps: {
            imageProps: {
              src: assets.png.fortnitePageImg,
            },
          },
          loading: true,
          title: 'Challenge is commencing',
          subtitle: undefined as unknown,
          timerExpirySeconds: undefined,
          winnerList: undefined,
          players: {
            ready: 5,
            total: 5,
          },
          table: {
            trophyLimit: undefined,
            data: {
              headRow: {
                cell: [
                  { value: '#' },
                  { value: 'Player' },
                  { value: 'Placement' },
                  { value: 'Kills' },
                  { value: 'Score' },
                ],
              },
              bodyRow: [
                {
                  cell: [
                    { value: ''},
                    { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                    { value: 'In lobby' },
                    { value: '' },
                    { value: '' },
                  ],
                  noPlayers: false
                },
                { cell: null, noPlayers: false },
                { cell: null, noPlayers: false },
                { cell: null, noPlayers: false },
                { cell: null, noPlayers: false },
              ],
            },
          },
          challengeDetailsCardProps: undefined as any,
          liveUpdateList: [],
        },
        RUNNING:{
          status: 'progress',
          pageMediaWithShadowBoxProps: {
            imageProps: {
              src: assets.png.fortnitePageImg,
            },
          },
          loading: false,
          title: 'Challenge is in progress',
          subtitle: undefined as unknown,
          timerExpirySeconds: undefined,
          winnerList: undefined,
          players: {
            ready: 5,
            total: 5,
          },
          table: {
            trophyLimit: undefined,
            data: {
              headRow: {
                cell: [
                  { value: '#' },
                  { value: 'Player' },
                  { value: 'Placement' },
                  { value: 'Kills' },
                  { value: 'Score' },
                ],
              },
              bodyRow: [
                {
                  cell: [
                    { value: ''},
                    { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                    { value: 'In game' },
                    { value: '0' },
                    { value: '0' },
                  ],
                  noPlayers: false
                },
                { cell: null, noPlayers: false},
                { cell: null, noPlayers: false },
                { cell: null, noPlayers: false },
                { cell: null, noPlayers: false },
              ],
            },
          },
          challengeDetailsCardProps: undefined as any,
          liveUpdateList: []
        },
        STARTING_SCORE_COLLECTED:{
          status: 'progress',
          pageMediaWithShadowBoxProps: {
            imageProps: {
              src: assets.png.fortnitePageImg,
            },
          },
          loading: false,
          title: 'Challenge is in progress',
          subtitle: undefined as unknown,
          timerExpirySeconds: undefined,
          winnerList: undefined,
          players: {
            ready: 5,
            total: 5,
          },
          table: {
            trophyLimit: undefined,
            data: {
              headRow: {
                cell: [
                  { value: '#' },
                  { value: 'Player' },
                  { value: 'Placement' },
                  { value: 'Kills' },
                  { value: 'Score' },
                ],
              },
              bodyRow: [
                {
                  cell: [
                    { value: ''},
                    { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                    { value: 'In game' },
                    { value: '0' },
                    { value: '0' },
                  ],
                  noPlayers: false
                },
                { cell: null, noPlayers: false },
                { cell: null, noPlayers: false },
                { cell: null, noPlayers: false  },
                { cell: null, noPlayers: false  },
              ],
            },
          },
          challengeDetailsCardProps: undefined as any,
          liveUpdateList: []
        },
        PRE_COOLDOWN_DONE:{
          status: 'progress',
          pageMediaWithShadowBoxProps: {
            imageProps: {
              src: assets.png.fortnitePageImg,
            },
          },
          loading: false,
          title: 'Challenge is in progress',
          subtitle: undefined as unknown,
          timerExpirySeconds: 0,
          winnerList: undefined,
          players: {
            ready: 5,
            total: 5,
          },
          table: {
            trophyLimit: undefined,
            data: {
              headRow: {
                cell: [
                  { value: '#' },
                  { value: 'Player' },
                  { value: 'Placement' },
                  { value: 'Kills' },
                  { value: 'Score' },
                ],
              },
              bodyRow: [
                {
                  cell: [
                    { value: ''},
                    { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                    { value: 'In game' },
                    { value: '0' },
                    { value: '0' },
                  ],
                  noPlayers: false
                },
                { cell: null, noPlayers: false  },
                { cell: null, noPlayers: false  },
                { cell: null, noPlayers: false  },
                { cell: null, noPlayers: false  },
              ],
            },
          },
          challengeDetailsCardProps: undefined as any,
          liveUpdateList: []
        },
        PLAY_TIME_OVER: {
          status: 'progress',
          pageMediaWithShadowBoxProps: {
            imageProps: {
              src: assets.png.fortnitePageImg,
            },
          },
          loading: false,
          title: 'Challenge is in progress',
          subtitle: 'Getting final results',
          timerExpirySeconds: 0,
          winnerList: undefined,
          players: {
            ready: 5,
            total: 5,
          },
          table: {
            trophyLimit: undefined,
            data: {
              headRow: {
                cell: [
                  { value: '#' },
                  { value: 'Player' },
                  { value: 'Placement' },
                  { value: 'Kills' },
                  { value: 'Score' },
                ],
              },
              bodyRow: [
                {
                  cell: [
                    { value: ''},
                    { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                    { value: 'In game' },
                    { value: '0' },
                    { value: '0' },
                  ],
                  noPlayers: false
                },
                { cell: null, noPlayers: false  },
                { cell: null, noPlayers: false  },
                { cell: null, noPlayers: false  },
                { cell: null, noPlayers: false },
              ],
            },
          },
          challengeDetailsCardProps: undefined as any,
          liveUpdateList: []
        },
        FINAL_DONE:{
          status: 'completed',
          pageMediaWithShadowBoxProps: {
            imageProps: {
              src: assets.png.fortnitePageImg,
            },
          },
          loading: false,
          title: 'Challenge is completed!',
          subtitle: undefined as unknown,
          timerExpirySeconds: undefined,
          
          winnerList: [
            {
              name: 'MasterChief',
              place: 1,
              amount: '+ 1.8',
              isVertical: false,
              avatarSrc: 'https://media.sproutsocial.com/uploads/2022/06/profile-picture.jpeg',
            },
            {
              name: 'Nikman',
              place: 2,
              amount: '+ 1.0',
              isVertical: false,
              avatarSrc: 'https://media.sproutsocial.com/uploads/2022/06/profile-picture.jpeg',
            },
          ],
          players: {
            ready: 5,
            total: 5,
          },
          table: {
            withTrophy: true,
            trophyLimit: 1,
            data: {
              headRow: {
                cell: [
                  { value: '#' },
                  { value: 'Player' },
                  { value: 'Placement' },
                  { value: 'Kills' },
                  { value: 'Score' },
                ],
              },
              bodyRow: [
                {
                  cell: [
                    { value: ''},
                    { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                    { value: 'Top 25', color: '#56E612' },
                    { value: '4' },
                    { value: '51 p.' },
                  ],
                  noPlayers: false
                },
                { cell: null, noPlayers: false  },
                { cell: null, noPlayers: false  },
                { cell: null, noPlayers: false  },
                { cell: null, noPlayers: false  },
              ],
            },
          },
          challengeDetailsCardProps: undefined as any,
          liveUpdateList: []
        },
        CANCELLED: {
          status: 'cancelled',
          pageMediaWithShadowBoxProps: {
            imageProps: {
              src: assets.png.fortnitePageImg,
            },
          },
          loading: false,
          title: 'Challenge has been cancelled',
          subtitle: undefined as unknown,
          timerExpirySeconds: undefined,
          winnerList: undefined,
          players: {
            ready: 5,
            total: 5,
          },
          table: {
            trophyLimit: undefined,
            data: {
              headRow: {
                cell: [
                  { value: '#' },
                  { value: 'Player' },
                  { value: 'Placement' },
                  { value: 'Kills' },
                  { value: 'Score' },
                ],
              },
              bodyRow: [
                {
                  cell: [
                    { value: ''},
                    { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                    { value: 'In game' },
                    { value: '0' },
                    { value: '0' },
                  ],
                  noPlayers: false
                },
                { cell: null, noPlayers: false  },
                { cell: null, noPlayers: false  },
                { cell: null, noPlayers: false  },
                { cell: null, noPlayers: false  },
              ],
            },
          },
          challengeDetailsCardProps: undefined as any,
          liveUpdateList: []
        },
      
    }

    export const leaderboard2 = {
      hotStreak: {
        title:"Hot streak",
        subtitle:"Longest Kills in a Streak",
        width:"410px",
        height:"auto",
        withTrophy: true,
        rowCount: 8,
        data: {
          headRow: {
            cell: [{ value: 'Player' }, { value: 'Kills' }, { value: 'Date' }, { value: '' }],
          },
          bodyRow: [
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: '8' },
                { value: '01.03.23' },
                {
                  value: () => (
                    <CoinImageWithAmount amount="+0.12" amountTypographyProps={{ color: 'gray.0' }} />
                  ),
                },
              ],
            },
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: '7' },
                { value: '01.03.23' },
                {
                  value: () => (
                    <CoinImageWithAmount amount="+0.12" amountTypographyProps={{ color: 'gray.0' }} />
                  ),
                },
              ],
            },
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: '4' },
                { value: '01.03.23' },
                { value: '' },
              ],
            },
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: '4' },
                { value: '01.03.23' },
                { value: '' },
              ],
            },
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: '4' },
                { value: '01.03.23' },
                { value: '' },
              ],
            },
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: '4' },
                { value: '01.03.23' },
                { value: '' },
              ],
            },
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: '3' },
                { value: '01.03.23' },
                { value: '' },
              ],
            },
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: '3' },
                { value: '01.03.23' },
                { value: '' },
              ],
            },
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: '3' },
                { value: '01.03.23' },
                { value: '' },
              ],
            },
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: '3' },
                { value: '01.03.23' },
                { value: '' },
              ],
            },
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: '3' },
                { value: '01.03.23' },
                { value: '' },
              ],
            },
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: '3' },
                { value: '01.03.23' },
                { value: '' },
              ],
            },
          ],
        },
      },    
      
    }
import React from 'react'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import VerticalDividerWithContent from 'src/components/VerticalDividerWithContent/VerticalDividerWithContent'
import GameLocationsDivider from 'src/components/GameLocationsDivider/GameLocationsDivider'
import CustomHorizontalDivider from 'src/components/CustomHorizontalDivider/CustomHorizontalDivider'
import TitleWithDot from 'src/components/TitleWithDot/TitleWithDot'
import PatternLines from 'src/components/PatternLines/PatternLines'
import { gameLocationsDataSource } from 'src/helpers/mockData'
import useMediaQuery from '@mui/material/useMediaQuery'
import Button from '../Button/Button'

type MainBannerProps = {
  label?: string
  icon?: React.ReactNode
}

export default function MainBanner({
  icon = <SvgIcon name="arrow-right-up-line" />,
}: MainBannerProps) {
  const theme = useTheme()
  const isSmView = useMediaQuery(theme.breakpoints.down('sm'))
  const isLgView = useMediaQuery(theme.breakpoints.down('lg'))
  const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const onClickUrl = (url: string): (() => void) => () => openInNewTab(url)

  return (
    <Box
      position="relative"
      bgcolor="gray.900"
      minHeight="238px"
      sx={{
        [theme.breakpoints.down('lg2')]: {
          flexWrap: 'wrap',
        },
        [theme.breakpoints.down('md')]: {
          flexWrap: 'wrap',
        },
        [theme.breakpoints.down('sm')]: {
          flexWrap: 'wrap',
        },
      }}
    >
      <PatternLines
        {...(isLgView && {
          ml: { xs: 'calc(-100vw - 8px)', sm: 'calc(-100vw - 12px)' },
          height: '238px',
        })}
      />
      <Box pb={{ xs: '28px', sm: '30px', lg: '24px' }}>
        <CustomHorizontalDivider />
      </Box>
      <Grid container justifyContent="space-between">
        <Grid
          item
          sx={{
            [theme.breakpoints.down('lg2')]: {
              width: '100%',
            },
          }}
        >
          <Box display="inline-block">
            <Box mb={{ xs: 0, sm: '14px', lg: '13px' }}>
              <Typography
                component="div"
                variant={isSmView ? 'labelMedium' : 'labelBig'}
                mb={{ xs: '1px', sm: '2px', lg: '3px' }}
                height="31px"
                color="primary.light"
              >
                {'Competitive challenge platform'}
              </Typography>
              <Typography
                variant="h1"
                sx={{
                  height: '72px',
                  [theme.breakpoints.down('sm')]: {
                    fontSize: '64px',
                    height: '57px',
                  },
                }}
              >
                {'challenge'}
              </Typography>
            </Box>
            <TitleWithDot
              rootStackProps={{
                position: 'relative',
                justifyContent: 'flex-end',
              }}
              typographyProps={{
                variant: 'h1',
                height: '76px',
                color: 'gray.900',
                sx: {
                  textShadow:
                    '-1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white',
                  [theme.breakpoints.down('sm')]: {
                    fontSize: '64px',
                    height: '57px',
                  },
                },
              }}
              dotProps={{
                width: { xs: '6px', sm: '10px' },
                height: { xs: '6px', sm: '10px' },
                position: 'absolute',
                right: { xs: '-14px', sm: '-23px', lg: '-22px' },
                bottom: { xs: '0', sm: '-7px', lg: '-9px' },
              }}
            >
              earn coins
            </TitleWithDot>
          </Box>
        </Grid>

        <Grid
          item
          sx={{
            [theme.breakpoints.down('lg2')]: {
              flexGrow: 1,
              width: 'calc(100% - 350px)',
              pr: '41px',
            },
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              pr: 0,
              pb: '24px',
            },
          }}
        >
          <Box
            sx={{
              mt: '12px',
              ml: '36px',
              [theme.breakpoints.down('lg2')]: {
                ml: 0,
                mt: '40px',
              },
              [theme.breakpoints.down('sm')]: {
                mt: '27px',
                pr: '20px',
              },
            }}
          >
            <VerticalDividerWithContent
              rootStackProps={{ mb: { xs: '11px', sm: '20px', lg: '14px' } }}
            >
              What is challenge.gg
            </VerticalDividerWithContent>
            <Typography
              variant="bodyMedium"
              color="gray.100"
              maxWidth={{ xs: '465px', lg2: '329px' }}
              sx={{
                [theme.breakpoints.down('lg2')]: {
                  lineHeight: '127%',
                },
              }}
            >
              {"It's time to join the new ERA in gaming tournaments -" +
            ' one that is designed specifically for the enormous mass of gamers.' +
            " Don't let this opportunity pass you by - " +
            ' start your journey towards becoming a gaming champion today.' 
          }
            </Typography>
          </Box>
        </Grid>

        <Grid item alignSelf="flex-end">
          <Box
            sx={{
              width: '350px',
              [theme.breakpoints.down('lg')]: {
                width: '273px',
              },
            }}
          >
            <Button onClick={onClickUrl('https://challenge.gg')} variant="contained" size={isLgView ? 'big' : 'large'} endIcon={icon} fullWidth>
              {'more about challenge'}
            </Button>
          </Box>
        </Grid>

        {!isSmView && (
          <Box
            sx={{
              position: 'absolute',
              top: 2,
              right: 0,
            }}
          >
            <GameLocationsDivider dataSource={gameLocationsDataSource} />
          </Box>
        )}
      </Grid>
    </Box>
  )
}

import React from 'react'
import { Stack, StackProps, Box, Divider } from '@mui/material'
import Typography, { TypographyProps } from 'src/components/Typography/Typography'
import SvgIcon, { SvgIconProps } from 'src/components/SvgIcon/SvgIcon'

export type WinnerPlaceTextT = {
  first?: string
  second?: string
  third?: string
  default?: string
}
type WinnerPlaceNumProps = StackProps & {
  place?: number
  placetext?: WinnerPlaceTextT
  placeTypographyProps?: TypographyProps
  iconName?: SvgIconProps['name']
}

const getPlaceText = (place?: number, replaceText?: WinnerPlaceTextT) => {
  switch (place) {
    case 1:
      return replaceText?.first || '1-st place'
    case 2:
      return replaceText?.second || '2-nd place'
    case 3:
      return replaceText?.third || '3-rd place'
    default:
      return replaceText?.default || 'Winner'
  }
}

const getPlaceColor = (place?: number) => {
  switch (place) {
    case 1:
      return 'primary.light'
    case 2:
      return 'gray.100'
    case 3:
      return '#C68040'
    default:
      return 'primary.light'
  }
}

export default function WinnerPlaceNum({
  place,
  placetext,
  placeTypographyProps,
  iconName = 'trophy-fill',
  ...restStackProps
}: WinnerPlaceNumProps) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing="6px"
      py="2px"
      color={getPlaceColor(place)}
      {...restStackProps}
    >
      <Box display="flex" fontSize="18px">
        <SvgIcon name={iconName} fontSize="inherit" color="inherit" />
      </Box>
      <Divider orientation="vertical" sx={{ borderColor: 'gray.600', height: '12px' }} />
      <Typography
        variant="paragraphSmall"
        minHeight="16px"
        color="inherit"
        {...placeTypographyProps}
      >
        {getPlaceText(place, placetext)}
      </Typography>
    </Stack>
  )
}

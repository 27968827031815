import React from 'react'
import { Stack, StackProps, useMediaQuery, useTheme } from '@mui/material'
import Typography from 'src/components/Typography/Typography'
import Loader from 'src/components/Loader/Loader'

type TournamentInfoBoxProps = {
  StackProps?: StackProps
  title?: string
  subtitle?: string
}

export default function TournamentInfoBox({
  StackProps,
  title = 'Final completed!',
  subtitle = 'Calculated winners and build the final bracket',
}: TournamentInfoBoxProps) {
  const theme = useTheme()
  const isSmView = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Stack
      direction="column"
      minWidth="300px"
      alignItems="center"
      position="fixed"
      top="394px"  //194px FH
      left="50%"
      zIndex={1000}
      {...StackProps}
      sx={{
        transform: 'translateX(-50%)',
        [theme.breakpoints.down('sm')]: {
          top: '74px',
        },
        ...StackProps?.sx,
      }}
    >
      <Typography
        variant="h5"
        align="center"
        color="gray.0"
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="31px"
        mb="16px"
        sx={{
          [theme.breakpoints.down('sm')]: {
            ...theme.typography.h6,
            mb: '8px',
          },
        }}
      >
        {title}
      </Typography>
      <Typography variant="bodySmall" color="gray.25" mb={{ xs: '15px', sm: '35px' }}>
        {subtitle}
      </Typography>
      <Loader key={isSmView ? 'small' : 'medium'} size={isSmView ? 'small' : 'medium'} />
    </Stack>
  )
}

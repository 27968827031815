import React from 'react'
import Image, { ImageProps } from '../../Image/Image'
import EnterChallengeBody, {
  EnterChallengeBodyProps,
} from '../EnterChallengeBody/EnterChallengeBody'
import Popup from '../Popup'
import { useTheme } from '@mui/material'

export type EnterChallengePopupProps = {
  title: string
  description: string
  fee: string
  headerBackgroundSrc: ImageProps['src']
  tableData?: EnterChallengeBodyProps['tableData']
  gameTitle?: string
  gameIconSrc?: ImageProps['src']
  onCancel: () => void
  open: boolean
  handleJoin?: () => void | Promise<void>
  handleCancel?: () => void
}
const EnterChallengePopup = (props: EnterChallengePopupProps) => {
  const theme = useTheme()
  return (
    <Popup
      open={props.open}
      onClose={props.onCancel}
      sx={{
        width: '408px',
      }}
      headerData={{
        sx: {
          height: '100px',
          [theme.breakpoints.down('sm')]: {
            height: '76px',
            '& button': {
              height: '42px',
              width: '42px',
              padding: 0,
              minHeight: '30px',
              minWidth: '42px',
            },
          },
        },
        title: props.gameTitle,
        iconSrc: props?.gameIconSrc,
        backgroundImage: (
          <Image src={props.headerBackgroundSrc} alt="background" width={408} height={104} />
        ),
      }}
      bodyContainer={
        <EnterChallengeBody
          title={props.title}
          description={props.description}
          showDescriptionLength={200}
          tableData={props.tableData}
        />
      }
      cancelButtonData={{
        label: 'Cancel',
        sx: {
          [theme.breakpoints.down('sm')]: {
            width: '94px',
          },
        },
        onClick: () => {
          if (props.handleCancel) {
            props.handleCancel()
          }
          props.onCancel()
        },
      }}
      primaryButtonData={{
        label: 'Join now',
        subLabel: props.fee,
        sx: {
          padding: '12px',
          [theme.breakpoints.down('sm')]: {
            pl: '13px',
            pr: '16px',
          },
        },
        onClick: () => {
          if (props.handleJoin) {
            props.handleJoin()
            {/*if (props.handleJoin()?.then) {
              props.handleJoin()?.then(() => {
                props.onCancel()
              })
            } */}
          }
        },
      }}
    />
  )
}

export default EnterChallengePopup

import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useTheme } from '@mui/material/styles'
import { Stack } from '@mui/material'
import PageNumberRange, {
  PageNumberRangeProps,
} from 'src/components/PageNumberRange/PageNumberRange'
import VerticalDividerWithContent from 'src/components/VerticalDividerWithContent/VerticalDividerWithContent'
import Comment from 'src/components/Comment/Comment'
import GameNumber from 'src/components/GameNumber/GameNumber'
import Typography from 'src/components/Typography/Typography'
import ButtonIcon from 'src/components/ButtonIcon/ButtonIcon'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'

type ChallengePaginationProps = Omit<PageNumberRangeProps, 'rootStackProps'> & {
  /** The total number of items. */
  total?: number
  title?: string
  /**
   * Callback fired when the page is changed.
   * @param {number} page The page selected.
   * */
  onChange?: (page: number) => void
}

export default function ChallengePagination({
  page: initialPage = 1,
  count: initialCount = 1,
  total: initialTotal = 1,
  title,
  onChange,
}: ChallengePaginationProps) {
  const [page, setPage] = useState(initialPage)
  const [count, setCount] = useState(initialCount)
  const [total, setTotal] = useState(initialTotal)

  useEffect(() => {
    setPage(initialPage)
  }, [initialPage])

  useEffect(() => {
    setCount(initialCount)
  }, [initialCount])

  useEffect(() => {
    setTotal(initialTotal)
  }, [initialTotal])

  useEffect(() => {
    if (onChange) {
      onChange(page)
    }
    // eslint-disable-next-line
  }, [page])

  const onNextPage = useCallback(() => {
    if (page < Math.ceil(total / count)) {
      setPage(page + 1)
    }
  }, [page, count, total])

  const onPreviousPage = useCallback(() => {
    if (page > 1) {
      setPage(page - 1)
    }
  }, [page])

  const shouldDisableNextBtn = useMemo(() => page * count >= total, [page, count, total])

  const shouldDisablePreviousBtn = useMemo(() => page === 1, [page])

  const currentPage = (page - 1) * count + 1
  const currentCount = Math.min(page * count, total)

  const theme = useTheme()

  return (
    <Stack
      direction="row"
      spacing="24px"
      alignItems="center"
      sx={{
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          justifyContent: 'space-between',
        },
      }}
    >
      <VerticalDividerWithContent>
        {() => (
          <>
            <Stack direction="row" spacing="2.6px" alignItems="center" color="gray.600">
              <PageNumberRange page={currentPage} count={currentCount} />
              <Comment
                rootStackProps={{ spacing: '3px' }}
                typographySlashProps={{ color: 'gray.600' }}
              >
                <GameNumber count={total} />
              </Comment>
            </Stack>
            {title && (
              <Typography variant="uiElement" color="gray.500">
                {title}
              </Typography>
            )}
          </>
        )}
      </VerticalDividerWithContent>
      <Stack direction="row" spacing="4px" alignItems="center">
        <ButtonIcon
          variant="outlined"
          size="small"
          disabled={shouldDisablePreviousBtn}
          onClick={onPreviousPage}
        >
          <SvgIcon name="arrow-left-fill" />
        </ButtonIcon>
        <ButtonIcon
          variant="outlined"
          size="small"
          disabled={shouldDisableNextBtn}
          onClick={onNextPage}
        >
          <SvgIcon name="arrow-right-s-fill" />
        </ButtonIcon>
      </Stack>
    </Stack>
  )
}

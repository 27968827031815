import React, { useState, useMemo } from 'react'
import { Stack, StackProps, SelectChangeEvent, useTheme } from '@mui/material'
import SvgIcon, { SvgIconProps } from 'src/components/SvgIcon/SvgIcon'
import { ImageProps } from 'src/components/Image/Image'
import ButtonIcon from 'src/components/ButtonIcon/ButtonIcon'
import ImageWithTitle from 'src/components/ImageWithTitle/ImageWithTitle'
import Select from 'src/components/Select/Select'
import { gameSortFilterHeaderOptions } from 'src/helpers/data'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import { setSortOrder } from 'src/actions/pageActions'
import { getSortOrder } from 'src/selectors/sortOrderSelector'
import { GameId } from 'src/utils/const'

export type GameSortFilterHeaderProps = StackProps & {
  iconName?: SvgIconProps['name']
  imageProps: Pick<ImageProps, 'src' | 'alt'>
  title: string
  gameId: GameId
  onGoBack?: () => void
}

export default function GameSortFilterHeader({
  iconName = 'arrow-left-line',
  imageProps,
  title = '',
  gameId,
  onGoBack,
  ...rootStackProps
}: GameSortFilterHeaderProps) {
  const theme = useTheme()
  const dispatch = useAppDispatch();
   
  const sortOrderMemo = useMemo(() => getSortOrder, []);
  const sortOrder = useAppSelector(state => sortOrderMemo(state, gameId));

  //const [value, setValue] = useState(SortOrder.FEE_LOW)

  const onChange = (event: SelectChangeEvent<any>) => {
    //setValue(event.target.value)
    dispatch(setSortOrder(gameId,event.target.value));
  }

  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      justifyContent="space-between"
      flexWrap="wrap"
      gap="20px"
      {...rootStackProps}
    >
      <Stack direction="row">
        <ButtonIcon variant="outlined" size="small" onClick={onGoBack} aria-label="Go back">
          <SvgIcon name={iconName} />
        </ButtonIcon>
        <ImageWithTitle
          rootStackProps={{ ml: { xs: '14px', sm: '24px' }, mr: 'auto' }}
          imageProps={{ src: imageProps?.src, alt: imageProps?.alt || title }}
          title={title}
        />
      </Stack>
      <Stack
        direction="row"
        spacing="14px"
        sx={{
          [theme.breakpoints.down('sm')]: {
            width: '100%',
          },
        }}
      >
        <Select
          size="medium"
          variant='outlined'
          options={gameSortFilterHeaderOptions}
          onChange={onChange}
          value={sortOrder}
        />
        <ButtonIcon variant="outlined" size="small" aria-label="Filter options">
          <SvgIcon name="equalizer-line" />
        </ButtonIcon>
      </Stack>
    </Stack>
  )
}

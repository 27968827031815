import React, { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTheme, Stack, StackProps, useMediaQuery, SelectChangeEvent } from '@mui/material'
import TitleWithDot from 'src/components/TitleWithDot/TitleWithDot'
import Typography from 'src/components/Typography/Typography'
import Tabs, { Tab } from 'src/components/Tabs/Tabs'
// import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import Select from 'src/components/Select/Select'

export enum FILTER {
  FULL = 'full',
  QUARTER_FINAL = 'quarterfinal',
  SEMI_FINAL = 'semifinal',
  FINAL = 'final',
}

type TournamentBraketsFilterProps = {
  StackProps?: StackProps
}

export default function TournamentBraketsFilter(props: TournamentBraketsFilterProps) {
  const theme = useTheme()
  const isSm800 = useMediaQuery(theme.breakpoints.down('sm2'))
  const isXsm = useMediaQuery(theme.breakpoints.down('xsm'))

  const [searchParams, setSearchParams] = useSearchParams({ filter: 'full' })

  const activeTab = useMemo(() => searchParams.get('filter'), [searchParams])

  // I use the useMemo here, if you will want to control the condition of the disabled prop.
  const tabs = useMemo(
    () => [
      { id: 'full', label: 'Full', value: 'full' },
      { id: 'quarterfinal', label: 'QuarterFinal', value: 'quarterfinal' },
      { id: 'semifinal', label: 'Semifinal', value: 'semifinal' },
      { id: 'final', label: 'Final', value: 'final' },
    ],
    []
  )

  const onChange = useCallback(
    (tab: Tab) => {
      if (tab?.id) {
        setSearchParams({ filter: tab.id })
      }
    },
    [setSearchParams]
  )

  const onSelectChange = useCallback(
    (event: SelectChangeEvent<any>) => {
      if (event.target.value) {
        setSearchParams({ filter: event.target.value })
      }
    },
    [setSearchParams]
  )

  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      justifyContent="space-between"
      alignItems="center"
      gap="20px"
      {...props?.StackProps}
    >
      <TitleWithDot
        typographyProps={{
          variant: 'h5',
          color: theme.palette.common.white,
          sx: {
            [theme.breakpoints.down('md')]: {
              ...theme.typography.h6,
            },
          },
        }}
        dotProps={{
          bottom: '8px',
          sx: {
            [theme.breakpoints.down('md')]: { bottom: '8px' },
            [theme.breakpoints.down('sm')]: { bottom: '6px' },
          },
        }}
        rootStackProps={{ spacing: { xs: '7px', sm: '7px', md: '8px' } }}
      >
        Tournament brakets
      </TitleWithDot>
      <Stack
        direction="row"
        gap={{ xs: '14px', sm: '12px', md: '16px' }}
        alignItems={{ xs: 'center', sm2: 'unset' }}
        flexGrow={isXsm ? 1 : 0}
      >
        {!isSm800 && (
          <Typography
            variant="paragraphMedium"
            color="gray.500"
            sx={{
              [theme.breakpoints.down('md')]: {
                ...theme.typography.paragraphSmall,
              },
            }}
          >
            Tournament view:
          </Typography>
        )}
        {!isSm800 ? (
          <Tabs list={tabs} activeTab={activeTab} onChange={onChange} />
        ) : (
          <Select options={tabs} value={activeTab} variant='outlined' onChange={onSelectChange} fullWidth={isXsm} />
        )}
        {/*<Stack*/}
        {/*  ml={{ xs: 0, sm: '12px', md: '20px' }}*/}
        {/*  direction="row"*/}
        {/*  gap="8px"*/}
        {/*  mb={{ xs: '14px', sm2: 0 }}*/}
        {/*>*/}
        {/*  <SvgIcon name="user-line" fontSize="extraSmall" />*/}
        {/*  <Typography variant="buttonMedium" color="gray.25" sx={{ cursor: 'pointer' }}>*/}
        {/*    Find me*/}
        {/*  </Typography>*/}
        {/*</Stack>*/}
      </Stack>
    </Stack>
  )
}

export function useTournamentBraketsFilter(filter = FILTER.FULL) {
  const [searchParams] = useSearchParams({ filter })
  return useMemo(() => searchParams.get('filter') ?? FILTER.FULL, [searchParams])
}

import React, {useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import FrontlandLobby from 'src/pages/lobby/frontland'
import FortniteLobby from 'src/pages/lobby/fortnite'
import { useAppSelector, useAppDispatch } from 'src/hooks'
import { getFinishedChallenge } from "src/actions/challengeActions";
import { GameId } from 'src/utils/const'
import { frontlandLobbyDataMapper, fortniteLobbyDataMapper, liveEventMapper } from 'src/helpers/data'

export default function LobbyHistory() {
  const { challengeId }  = useParams<{ challengeId: string }>()
  const navigate = useNavigate();
  const dispatch =  useAppDispatch();
  
  useEffect(() => {
    dispatch(getFinishedChallenge(challengeId));
  }, [dispatch])

  const user = useAppSelector(state => state.userReducer.userInfo);
  const activeChallenge = useAppSelector(state => state.challengeReducer.challengeInfo.challenge);  
  const logEvents = activeChallenge?.logEvents;  

  if(activeChallenge===undefined)
      return null;
  
  const quitButtonAction = () => {
    return {
      quitAction:() => navigate(-1),
      quitDisabled: false,
      quitButtonText: 'Close Lobby'
    }
  }; 

  
  let events = liveEventMapper(logEvents, undefined, activeChallenge.status, activeChallenge.description, activeChallenge.intGameId);

  
  if (activeChallenge.intGameId === GameId.FRONTLAND) {
    let data = frontlandLobbyDataMapper(activeChallenge,user);  
    if(data!==undefined){
      let quitAction = quitButtonAction();
      data.challengeDetailsCardProps.onQuit = quitAction.quitAction;
      data.challengeDetailsCardProps.quitDisabled = quitAction.quitDisabled;
      data.challengeDetailsCardProps.quitButtonText = quitAction.quitButtonText;
    }
    return (

          <FrontlandLobby {...data} status={activeChallenge.status} liveUpdateList={events} />
    );
  } 
  else if (activeChallenge.intGameId === GameId.FORTNITE) {
    let data = fortniteLobbyDataMapper(activeChallenge,user);  
    if(data!==undefined){
      let quitAction = quitButtonAction();
      data.challengeDetailsCardProps.onQuit = quitAction.quitAction;
      data.challengeDetailsCardProps.quitDisabled = quitAction.quitDisabled;
      data.challengeDetailsCardProps.quitButtonText = quitAction.quitButtonText;
    }

    return ( 
          <FortniteLobby {...data} status={activeChallenge.status} liveUpdateList={events}/>
    );
  } else {
    return null
  }
}

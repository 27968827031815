import React from 'react'
import { Box, Grid } from '@mui/material'
import WalletItem from 'src/components/WalletItem/WalletItem'
import { useTheme } from '@mui/material/styles'
import { PNG_ICONS } from 'src/assets/icons/icons'
import { TokenType, UserType } from 'src/helpers/types'
import Link from '../Link/Link'
import { useAppDispatch } from 'src/hooks'
import { setCurrentProfileMenu } from 'src/actions/pageActions'

type YourWalletProps = {
    user: UserType
}

const YourWallet = ({user}: YourWalletProps) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.gray[700]}`,
        padding: '17px',
        width: '520px',
        '&:hover': {
          borderColor: theme.palette.gray[500],
        },
        [theme.breakpoints.down('md2')]: {
          width: '100%',
          padding: '11px',
        },
      }}
    >
      <Grid container spacing={{ xs: '0', md2: '44px' }}>
        <Grid
          item
          sx={{
            [theme.breakpoints.down('md2')]: {
              width: '53%',
            },
          }}
        >
          <Link onClick={()=>dispatch(setCurrentProfileMenu('myassets'))} href="/profile" color="secondary">
          <WalletItem
            iconData={{ src: PNG_ICONS.weth, sx: { width: '24px', height: '24px' } }}
            title={'Total tokens'}
            total={user.coins?.weth}
            tokenType={TokenType.WETH}
          />
          </Link>
        </Grid>
        <Grid
          item
          sx={{
            [theme.breakpoints.down('md2')]: {
              width: '47%',
            },
          }}
        >
          <WalletItem
            iconData={{ src: PNG_ICONS.graph, sx: { width: '20px', height: '20px' } }}
            title={'Last winning'}
            total={user.statistics.lastWinning ? user.statistics.lastWinning+"" : "0"}
            tokenType={user.statistics.lastWinning ? user.statistics.lastWinningTokenType : undefined}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default YourWallet

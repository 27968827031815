import React,{useEffect} from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { useLocation, useNavigationType,createRoutesFromChildren,matchRoutes } from 'react-router-dom';
import { ReduxRouter } from '@lagunovsky/redux-react-router'
//import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
import configureStore, { history } from "./store/configureStore";
import App from './app'; 
import { config } from "./config/config";
//import LogRocket from 'logrocket';
import * as Sentry from "@sentry/react";
import './index.css'
import { ErrorFallback } from './pages/errorFallback';

Sentry.init({
    dsn: config.sentry.dsn,
    normalizeDepth: 10,
    tracePropagationTargets: ["localhost", /^https:\/\/api.challenge\.gg\/api/, /^https:\/\/api.stage.challenge\.gg\/api/],
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
      }), //Replay
      Sentry.feedbackIntegration({
        // Additional SDK configuration goes in here, for example:
        colorScheme: "system",
        isNameRequired: true,
        isEmailRequired: true,
        autoInject: false,
        useSentryUser: {
          name: 'username',
          email: 'email',
        },
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring. Reduce in production!
    tracesSampleRate: 1.0,  //Performance
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,  //Replay
    replaysOnErrorSampleRate: 1.0, //Replay
  });

//LogRocket.init(config.logrocket.key);
const store = configureStore;
window.store = store;

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <Sentry.ErrorBoundary fallback={({error, componentStack, resetError}) => <ErrorFallback error={error} componentStack={componentStack} resetError={resetError}  />}>
    <Provider store={store}>
        <ReduxRouter history={history}>
            <App />
        </ReduxRouter>
    </Provider>
  </Sentry.ErrorBoundary>
);
unregister();
//registerServiceWorker();
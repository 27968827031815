import { History } from "history";
import { createRouterReducer } from '@lagunovsky/redux-react-router'
import { userReducer } from './userReducer';
import { challengeReducer } from './challengeReducer';
import { tournamentReducer } from './tournamentReducer';
import { challengeEventReducer } from './challengeEventReducer';
import { dialogReducer } from './dialogReducer';
import { caruselReducer } from './caruselReducer';
import { infoPanelReducer } from './infoPanelReducer';
import { gameReducer } from './gameReducer';
import { pageReducer } from './pageReducer';
import { apiStakingSlice } from "../api/stakingApi";
import { apiProfileSlice } from "src/api/profileApi";
import { apiLeaderboardSlice } from "src/api/leaderboardApi";

export const rootReducer = (history : History) => ({
        router: createRouterReducer(history),
        userReducer,
        challengeReducer,
        challengeEventReducer,
        tournamentReducer,
        dialogReducer,
        caruselReducer,
        infoPanelReducer,
        gameReducer,
        pageReducer,
        [apiStakingSlice.reducerPath]: apiStakingSlice.reducer,      
        [apiProfileSlice.reducerPath]: apiProfileSlice.reducer,   
        [apiLeaderboardSlice.reducerPath]: apiLeaderboardSlice.reducer,
});

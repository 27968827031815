import React from 'react'
import { Stack } from '@mui/material'
import Comment from 'src/components/Comment/Comment'
import VerticalDividerWithContent from 'src/components/VerticalDividerWithContent/VerticalDividerWithContent'
import GameNumber from 'src/components/GameNumber/GameNumber'

type ChallengeRangeListHeaderProps = {
  total: number
  text?: string
}

export default function TotalListHeader({ text, total }: ChallengeRangeListHeaderProps) {
  return (
    <VerticalDividerWithContent>
      <Stack direction="row" alignItems="center" spacing="1.8px">
        <GameNumber count={total} />
        {text && <Comment typographySlashProps={{ color: 'gray.600' }}>{text}</Comment>}
      </Stack>
    </VerticalDividerWithContent>
  )
}
